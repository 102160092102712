import React, {useEffect, useState} from 'react';
import {Alert, AlertTitle, Box, Container, Paper, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import instanceAxiosApi from "../../../../service/axiosApi";
import GroupPersonsBindingToolBar from "./GroupPersonsBindingToolBar";
import GroupPersonsBindingWorkspace from "./GroupPersonsBindingWorkspace";
import {initParamsOnPageStart, setIsLoading} from "../../../../store/administration/groupPersonsBindingPageSlice";
import GoGroupPersonsPage from "../../../../components/GoGroupPersonsPage";
import {setActiveSection, setBreadcrumbs} from "../../../../store/baseLayoutSlice";


const GroupEditUsersBindingPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {groupId} = useParams();

    const [isInit, setIsInit] = useState(false)
    const [isInitError, setIsInitError] = useState(false)


    useEffect(() => {
        document.title = 'Group Person binding | UniPORT E-slip';

        dispatch(setActiveSection({href: '/group_persons'}))

        const items = [
            {href:"/group_persons", caption:'pages.groupUsers.pageCaption', active: true},
            {href:"/users_binding", caption:'pages.groupBinding.pageTitle', active: false},
        ]
        dispatch(setBreadcrumbs(items))

        loadData()
    }, []);

    const fetchApi = async () => {
        return await instanceAxiosApi.get(`/ui/v1/group_users/${groupId}/info`)
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading:true}))
        try {
            const response = await fetchApi()
            let dataJson = response?.data || {id:null, name:null}

            // Очищаем стор от прошлых следов, и проставляем новые данные
            dispatch(initParamsOnPageStart({
                rowId: dataJson.id,
                rowName: dataJson.name
            }))

            setIsInit(true)
            setIsInitError(false)
        } catch (e) {
            setIsInitError(true)
            // Очищаем стор от прошлых следов
            dispatch(initParamsOnPageStart({
                rowId: null,
                rowName: null
            }))
        } finally {
            dispatch(setIsLoading({isLoading:false}))
        }
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <GroupPersonsBindingToolBar />
                <Box sx={{mt: 3}}>
                    {
                        isInitError &&
                        <Paper>
                            <Alert severity={"error"}>
                                <AlertTitle>t('systemCommon.initParamsErrorTitle')</AlertTitle>
                                <Box>
                                    <Typography >
                                        {t('systemCommon.initParamsErrorMsg')}
                                    </Typography>
                                    <GoGroupPersonsPage sx={{mt:'20px'}} />
                                </Box>
                            </Alert>
                        </Paper>
                    }
                    {
                        isInit && !isInitError &&
                        <GroupPersonsBindingWorkspace />
                    }

                </Box>
            </Container>
        </Box>
    );
};

export default GroupEditUsersBindingPage;