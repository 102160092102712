import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel, Grid,
    TextField
} from "@mui/material";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    fetchArchivingInfo, fetchSaveInfoByTab,
    initArchiveTab, updateArchiveTab, setNeedRefreshData
} from "../../../store/administration/systemParamsPageSlice";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";


const ArchivingTab = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    let schema = Yup.object({
        countDayAgeEslipForArchive: Yup.number()
            .typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(10, t('pages.systemParams.minLengthMsg') + ' 10')
            .max(1095, t('pages.systemParams.maxLengthMsg') + ' 1095')
            .required(t('pages.systemParams.requiredCaption')),
        countDayAgeEslipForDelete: Yup.number()
            .typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(10, t('pages.systemParams.minLengthMsg') + ' 10')
            .max(1095, t('pages.systemParams.maxLengthMsg') + ' 1095')
            .required(t('pages.systemParams.requiredCaption')),
        archiveMoveBatchSize: Yup.number()
            .typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(500, t('pages.systemParams.minLengthMsg') + ' 500')
            .max(10000, t('pages.systemParams.maxLengthMsg') + ' 10000')
            .required(t('pages.systemParams.requiredCaption')),
        archiveDeleteBatchSize: Yup.number()
            .typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(500, t('pages.systemParams.minLengthMsg') + ' 500')
            .max(10000, t('pages.systemParams.maxLengthMsg') + ' 10000')
            .required(t('pages.systemParams.requiredCaption')),
    });

    const {archiveTabValues, needRefreshData, error, status, is403} = useSelector(state => state.systemParamsPage)

    const {control, reset, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            ...archiveTabValues
        }
    })

    useEffect(() => {
        reset(archiveTabValues);
    }, [archiveTabValues]);

    useEffect(() => {
        async function initTab() {
            await dispatch(initArchiveTab())
        }

        // Все чистим в сторе
        initTab()
        // Грузим новые данные
        loadData()
    }, [dispatch])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    const loadData = async () => {
        await dispatch(fetchArchivingInfo({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    const handleSubmitLocal = (data) => {
        //console.log(data);
        dispatch(updateArchiveTab({...data}))
        dispatch(fetchSaveInfoByTab({
            tab: 'archive',
            showSnackMsg: showSnackMsg
        }))
    }

    if (is403) {
        return (
            <Box sx={{pt:'-24px', mt:'-20px'}}>
                <AccessDeniedComponent />
            </Box>
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(handleSubmitLocal)}>
        <Card>
            <CardHeader
                subheader={t('pages.systemParams.archiveSubheader')}
                title={t('pages.systemParams.archiveTitle')}
            />
            <Divider/>
            {/*{isProcessing && <LinearProgress/>}*/}
            <CardContent>
                <Grid container spacing={3} columns={16}>
                    {
                        (status === 'rejected') &&
                        <Grid xs={16} md={16} item>
                            <Alert severity={"error"}>{t(error)}</Alert>
                        </Grid>
                    }

                    {
                        (status === 'resolved' || status === 'rejected') &&
                        <>
                            <Grid xs={16} md={8} item>
                                <Controller
                                    name={"archiveEnable"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <FormControlLabel
                                            {...field}
                                            // disabled={isProcessing}
                                            control={<Checkbox checked={field.value}/>}
                                            label={t('pages.systemParams.archiveEnable')}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={0} md={8}>
                                <Box></Box>
                            </Grid>

                            <Grid xs={16} md={8} item>
                                <Controller
                                    name={"countDayAgeEslipForArchive"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            fullWidth
                                            // disabled={isProcessing}
                                            label={t('pages.systemParams.countDayAgeEslipForArchive')}
                                            required
                                            value={field.value}
                                            variant="outlined"
                                            type={"number"}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={0} md={8}
                                  item>
                                <Box></Box>
                            </Grid>

                            <Grid xs={16} md={8} item>
                                <Controller
                                    name={"archiveMoveBatchSize"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            fullWidth
                                            // disabled={isProcessing}
                                            label={t('pages.systemParams.archiveMoveBatchSize')}
                                            required
                                            value={field.value}
                                            variant="outlined"
                                            type={"number"}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={0} md={8}
                                  item>
                                <Box></Box>
                            </Grid>

                            <Grid xs={16} md={8} item>
                                <Controller
                                    name={"deleteArchiveEnable"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <FormControlLabel
                                            {...field}
                                            // disabled={isProcessing}
                                            control={<Checkbox checked={field.value}/>}
                                            label={t('pages.systemParams.deleteArchiveEnable')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={0} md={8}
                                  item>
                                <Box></Box>
                            </Grid>

                            <Grid item xs={16} md={8}>
                                <Controller
                                    name={"countDayAgeEslipForDelete"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                            // disabled={isProcessing}
                                            label={t('pages.systemParams.countDayAgeEslipForDelete')}
                                            required
                                            value={field.value}
                                            variant="outlined"
                                            type={"number"}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={0} md={8}
                                  item>
                                <Box></Box>
                            </Grid>

                            <Grid item xs={16} md={8}>
                                <Controller
                                    name={"archiveDeleteBatchSize"}
                                    control={control}
                                    render={({field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!error}
                                            helperText={error?.message}
                                            // disabled={isProcessing}
                                            label={t('pages.systemParams.archiveDeleteBatchSize')}
                                            required
                                            value={field.value}
                                            variant="outlined"
                                            type={"number"}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={0} md={8}
                                  item>
                                <Box></Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </CardContent>
            <Divider/>
            <Box sx={{
                display: "flex", justifyContent: 'flex-end',
                p: 2
            }}>
                <Button
                    color="primary"
                    variant="contained"
                    type={"submit"}
                    // disabled={isProcessing || isSubmitError}
                >
                    {t('pages.systemParams.saveInfoBtnCaption')}
                </Button>
            </Box>
        </Card>
        </form>
    );
};

export default ArchivingTab;