import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Container, Tab, Tabs, useMediaQuery} from "@mui/material";
import ArchivingTab from "./ArchivingTab";
import CommonSettingsTab from "./CommonSettingsTab";
import PasswordPolicyTab from "./PasswordPolicyTab";
import NotificationsTab from "./NotificationsTab";
import SettingsToolBar from "./SettingssToolBar";
import NotificationsTemplateTab from "./NotificationsTemplateTab"
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const SystemParamsPage = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {status} = useSelector(state => state.systemParamsPage)
    const [value, setValue] = useState(0)

    useEffect(() => {
        document.title = 'System Params | UniPORT E-slip';

        dispatch(setActiveSection({href: '/system/params'}))

        const items = [
            {href:"/system/params", caption:'pages.systemParams.pageTitle', active: false},
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Backdrop
                open={status === 'loading'}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>

            <Container maxWidth={false}>
                <SettingsToolBar />
                <Box sx={{mt: 3}}>
                    <Box sx={{ minHeight:'600px'}}>

                    <Tabs value={value}
                          onChange={handleChange}
                          variant={lgUp ? "standard" : "scrollable"}
                          centered={lgUp}
                    >
                        <Tab label={t('pages.systemParams.commonTabLabel')}/>
                        <Tab label={t('pages.systemParams.pwdPolicyTabLabel')}/>
                        <Tab label={t('pages.systemParams.noticeTabLabel')}/>
                        <Tab label={t('pages.systemParams.templateTabLabel')}/>
                        <Tab label={t('pages.systemParams.archiveTabLabel')}/>
                    </Tabs>


                    <TabPanel value={value} index={0}>
                        <CommonSettingsTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PasswordPolicyTab />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <NotificationsTab />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <NotificationsTemplateTab />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <ArchivingTab />
                    </TabPanel>
                    </Box>
                </Box>
            </Container>
        </Box>


    );
};

export default SystemParamsPage;