import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";
import {findItemInArrayObject} from "../../util/UtilFunction";


export const fetchSaveInfoByTab = createAsyncThunk(
    'systemParamsPage/fetchSaveInfoByTab',
    async function(addInfo, {getState, rejectWithValue}) {
        const {tab, showSnackMsg} = addInfo
        // Определяем какую вкладку сохранять
        const {commonTabValues, passwordPolicyTabValues, notificationTabValues, templateTabValues, archiveTabValues} = getState().systemParamsPage
        try {
            let tabSettings
            switch (tab) {
                case 'common':
                    tabSettings = {...commonTabValues}
                    break;
                case 'password_policy':
                    tabSettings = {...passwordPolicyTabValues}
                    break;
                case 'notification':
                    tabSettings = {...notificationTabValues}
                    break;
                case 'template':
                    tabSettings = {...templateTabValues}
                    break;
                case 'archive':
                    tabSettings = {...archiveTabValues}
                    break;
                default:
                    throw Error('unknown tab name')
            }

            // Этот объект будем сохранять
            let settingsForSave = []
            Object.keys(tabSettings).forEach(keyi => {
                let valuei = tabSettings[keyi]
                // Иногда необходимо конвертить значения в другой тип. например true -> 1 false -> 0
                valuei = convertValue(valuei)
                let keyii = mapperSetiingAndAppSetting(keyi)
                let itemi = {name:keyii, value:valuei}

                settingsForSave.push(itemi)
            })

            const response = await instanceAxiosApi.put(`/ui/v1/settings`, JSON.stringify(settingsForSave))
            showSnackMsg('success', 'systemCommon.saveDataSuccessMsg')

            return response
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* мапит значения из стора на ключи бэкенда */
const mapperSetiingAndAppSetting = (key) => {
    let mapp = {
        allowUnencryptedPackets: 'IS_UNSECURE_PROTOCOL_ALLOW',
        externalSiteAddress: 'PUBLIC_HOST_NAME',
        directoryCheckExport: 'EXPORT_DIRECTORY',
        linkExportChecks: 'EXPORT_DOWNLOAD_LINK',

        minLength: 'PASSWORD_LENGTH_MIN',
        maxLength: 'PASSWORD_LENGTH_MAX',
        defaultLength: 'PASSWORD_LENGTH_DEFAULT',
        pwdNotificationTransport: 'PASSWORDS_SEND_TYPE',

        smtpServer: 'SMTP_SERVER_ADDRESS',
        smtpPort: 'SMTP_SERVER_PORT',
        smtpLogin: 'SMTP_SERVER_USERNAME',
        smtpPassword: 'SMTP_MAIL_PASSWORD',
        smtpEmailFrom: 'SMTP_MAIL_FROM',
        smsProvider: 'SMS_PROVIDER',
        sms_provider_login: 'SMS_USERNAME',
        sms_provider_password: 'SMS_PASSWORD',

        userRegistrationEmailTemplate: 'USER_REGISTRATION_EMAIL_TEMPLATE',
        passwordEmailTemplate: 'PASSWORD_EMAIL_TEMPLATE',
        restorePasswordEmailTemplate: 'RESTORE_PASSWORD_EMAIL_TEMPLATE',
        userRegistrationSmsTemplate: 'USER_REGISTRATION_SMS_TEMPLATE',
        passwordSmsTemplate: 'PASSWORD_SMS_TEMPLATE',
        restoreCodeSmsTemplate: 'RESTORE_CODE_SMS_TEMPLATE',
        eslipSendTemplate: 'ESLIP_SEND_TEMPLATE',

        archiveEnable: 'IS_ARCHIVING_ON',
        countDayAgeEslipForArchive: 'ARCHIVE_ESLIPS_OLDER_THAN',
        deleteArchiveEnable: 'IS_DELETING_FROM_ARCHIVE_ON',
        countDayAgeEslipForDelete: 'DELETE_FROM_ARCHIVE_OLDER_THAN',
        archiveDeleteBatchSize: 'MAX_BATCH_SIZE_DELETE_ARCHIVE',
        archiveMoveBatchSize: 'MAX_BATCH_SIZE_MOVE_ARCHIVE'
    }

    return mapp[key]
}

const convertValue = (valuei) => {
    if (valuei === true || valuei === 'true') {
        return 1
    }

    if (valuei === false || valuei === 'false') {
        return 0
    }

    return valuei
}

/* Получение данных вкладка общие */
export const fetchCommonInfo = createAsyncThunk(
    'systemParamsPage/fetchCommonInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/settings/common`)

            return response.data
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Получение данных вкладка политика паролей */
export const fetchPasswordPolicyInfo = createAsyncThunk(
    'systemParamsPage/fetchPasswordPolicyInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/settings/pwd_policy`)

            return response.data
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Получение данных уведомления */
export const fetchNotificationInfo = createAsyncThunk(
    'systemParamsPage/fetchNotificationInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/settings/notifications`)

            return response.data
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Получение данных шаблоны */
export const fetchTemplateInfo = createAsyncThunk(
    'systemParamsPage/fetchTemplateInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/settings/templates`)

            return response.data
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Получение данных архивирование */
export const fetchArchivingInfo = createAsyncThunk(
    'systemParamsPage/fetchArchivingInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/settings/archive`)

            return response.data
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        return rejectWithValue({errorCode: 403,
            errorMessageForSnack: 'systemCommon.accessDeniedMessage',
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {

        return rejectWithValue({errorCode: 400,
            errorMessageForSnack: errorMessage,
            error: errorMessage}
        )
    } else {
        return rejectWithValue({errorCode: 500,
            errorMessageForSnack: 'authProvider.internalServerErrorMessage',
            error: 'authProvider.internalServerErrorMessage'}
        )
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error

    if (action.payload.errorCode === 403) {
        state.is403 = true
    } else {
        state.is403 = false
    }
}

const systemParamsPageSlice = createSlice({
    name: 'systemParamsPage',
    initialState: {
        is403: false,
        status: null,
        error: null,
        needRefreshData: false, // Когда произошли изменения в соседних окнах

        /* Вкладка общие */
        commonTabValues : {
            allowUnencryptedPackets: false,
            externalSiteAddress: '',
            directoryCheckExport: '',
            linkExportChecks: '',
        },

        /* Вкладка парольная политика */
        passwordPolicyTabValues : {
            minLength: '',
            maxLength: '',
            defaultLength: '',
            pwdNotificationTransport: 'EMAIL'
        },

        /* Вкладка уведомления */
        notificationTabValues: {
            smtpServer: '',
            smtpPort: '25',
            smtpLogin: '',
            smtpPassword: '',
            smtpEmailFrom: '',
            smsProvider: 'WEBSMS',
            sms_provider_login: '',
            sms_provider_password: ''
        },

        /* Вкладка шаблоны */
        templateTabValues: {
            userRegistrationEmailTemplate: '',
            passwordEmailTemplate: '',
            restorePasswordEmailTemplate: '',

            userRegistrationSmsTemplate: '',
            passwordSmsTemplate: '',
            restoreCodeSmsTemplate: '',

            eslipSendTemplate: '',
        },

        /* Вкладка общие архивирование */
        archiveTabValues: {
            archiveEnable: false,
            countDayAgeEslipForArchive: 0,
            deleteArchiveEnable: false,
            countDayAgeEslipForDelete: 0,
            archiveMoveBatchSize: 1000,
            archiveDeleteBatchSize: 1000
        }
    },

    reducers: {
        initCommonTab(state, action) {
            state.commonTabValues = {
                ...{
                    allowUnencryptedPackets: false,
                    externalSiteAddress: '',
                    directoryCheckExport: '',
                    linkExportChecks: '',
                }
            }
            state.needRefreshData = false
            state.error = null
            state.status = null

            state.is403 = false
        },

        initPasswordPolicyTab(state, action) {
            state.passwordPolicyTabValues = {
                ...{
                    minLength: '',
                    maxLength: '',
                    defaultLength: '',
                    pwdNotificationTransport: 'EMAIL'
                }
            }
            state.needRefreshData = false
            state.error = null
            state.status = null

            state.is403 = false
        },

        initNotificationTab(state, action) {
            state.notificationTabValues = {
                ...{
                    smtpServer: '',
                    smtpPort: '465',
                    smtpLogin: '',
                    smtpPassword: '',
                    smtpEmailFrom: '',
                    smsProvider: 'WEBSMS',
                    sms_provider_login: '',
                    sms_provider_password: ''
                }
            }
            state.needRefreshData = false
            state.error = null
            state.status = null

            state.is403 = false
        },

        initTemplateTab(state, action) {
            state.templateTabValues = {
                ...{
                    userRegistrationEmailTemplate: '',
                    passwordEmailTemplate: '',
                    restorePasswordEmailTemplate: '',

                    userRegistrationSmsTemplate: '',
                    passwordSmsTemplate: '',
                    restoreCodeSmsTemplate: '',

                    eslipSendTemplate: ''
                }
            }
            state.needRefreshData = false
            state.error = null
            state.status = null

            state.is403 = false
        },

        initArchiveTab(state, action) {
            state.archiveTabValues = {
                ...{
                    archiveEnable: false,
                    countDayAgeEslipForArchive: 0,
                    deleteArchiveEnable: false,
                    countDayAgeEslipForDelete: 0,
                    archiveMoveBatchSize: 1000,
                    archiveDeleteBatchSize: 1000
                }
            }
            state.needRefreshData = false
            state.error = null
            state.status = null

            state.is403 = false
        },

        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload
        },

        updateCommonTab(state, action) {
            state.commonTabValues = {
                ...action.payload
            }
        },

        updatePasswordPolicyTab(state, action) {
            state.passwordPolicyTabValues = {
                ...action.payload
            }
        },

        updateNotificationTab(state, action) {
            state.notificationTabValues = {
                ...action.payload
            }
        },

        updateTemplateTab(state, action) {
            state.templateTabValues = {
                ...action.payload
            }
        },

        updateArchiveTab(state, action) {
            state.archiveTabValues = {
                ...action.payload
            }
        },
    },

    extraReducers: (builder) => {
        builder
            /* Сохранение информации для вкладки */
            .addCase(fetchSaveInfoByTab.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchSaveInfoByTab.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                // Если все хорошо обновляем инфу на вкладке
                state.needRefreshData = true
            })
            .addCase(fetchSaveInfoByTab.rejected, (state, action) => setError(state, action))

            /* Получение данных вкладка общие */
            .addCase(fetchCommonInfo.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchCommonInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let actionPayload = action.payload

                state.commonTabValues.allowUnencryptedPackets = findItemInArrayObject([...actionPayload], 'IS_UNSECURE_PROTOCOL_ALLOW') === '1'
                state.commonTabValues.externalSiteAddress = findItemInArrayObject([...actionPayload], 'PUBLIC_HOST_NAME') || ''
                state.commonTabValues.directoryCheckExport = findItemInArrayObject([...actionPayload], 'EXPORT_DIRECTORY') || ''
                state.commonTabValues.linkExportChecks = findItemInArrayObject([...actionPayload], 'EXPORT_DOWNLOAD_LINK') || ''
            })
            .addCase(fetchCommonInfo.rejected, (state, action) => setError(state, action))

            /* Получение данных вкладка политика паролей */
            .addCase(fetchPasswordPolicyInfo.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPasswordPolicyInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let actionPayload = action.payload

                state.passwordPolicyTabValues.minLength = findItemInArrayObject([...actionPayload], 'PASSWORD_LENGTH_MIN') || ''
                state.passwordPolicyTabValues.maxLength = findItemInArrayObject([...actionPayload], 'PASSWORD_LENGTH_MAX') || ''
                state.passwordPolicyTabValues.defaultLength = findItemInArrayObject([...actionPayload], 'PASSWORD_LENGTH_DEFAULT') || ''
                state.passwordPolicyTabValues.pwdNotificationTransport = findItemInArrayObject([...actionPayload], 'PASSWORDS_SEND_TYPE') || 'EMAIL'
            })
            .addCase(fetchPasswordPolicyInfo.rejected, (state, action) => setError(state, action))

            /* Получение данных уведомления */
            .addCase(fetchNotificationInfo.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchNotificationInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let actionPayload = action.payload

                state.notificationTabValues.smtpServer = findItemInArrayObject([...actionPayload], 'SMTP_SERVER_ADDRESS') || ''
                state.notificationTabValues.smtpPort = findItemInArrayObject([...actionPayload], 'SMTP_SERVER_PORT') || '465'
                state.notificationTabValues.smtpLogin = findItemInArrayObject([...actionPayload], 'SMTP_SERVER_USERNAME') || ''
                state.notificationTabValues.smtpPassword = findItemInArrayObject([...actionPayload], 'SMTP_MAIL_PASSWORD') || ''
                state.notificationTabValues.smtpEmailFrom = findItemInArrayObject([...actionPayload], 'SMTP_MAIL_FROM') || ''
                state.notificationTabValues.smsProvider = findItemInArrayObject([...actionPayload], 'SMS_PROVIDER') || 'websms'
                state.notificationTabValues.sms_provider_login = findItemInArrayObject([...actionPayload], 'SMS_USERNAME') || ''
                state.notificationTabValues.sms_provider_password = findItemInArrayObject([...actionPayload], 'SMS_PASSWORD') || ''
            })
            .addCase(fetchNotificationInfo.rejected, (state, action) => setError(state, action))

            /* Получение данных шаблоны */
            .addCase(fetchTemplateInfo.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchTemplateInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let actionPayload = action.payload

                state.templateTabValues.userRegistrationEmailTemplate = findItemInArrayObject([...actionPayload], 'USER_REGISTRATION_EMAIL_TEMPLATE') || ''
                state.templateTabValues.passwordEmailTemplate = findItemInArrayObject([...actionPayload], 'PASSWORD_EMAIL_TEMPLATE') || ''
                state.templateTabValues.restorePasswordEmailTemplate = findItemInArrayObject([...actionPayload], 'RESTORE_PASSWORD_EMAIL_TEMPLATE') || ''

                state.templateTabValues.userRegistrationSmsTemplate = findItemInArrayObject([...actionPayload], 'USER_REGISTRATION_SMS_TEMPLATE') || ''
                state.templateTabValues.passwordSmsTemplate = findItemInArrayObject([...actionPayload], 'PASSWORD_SMS_TEMPLATE') || ''
                state.templateTabValues.restoreCodeSmsTemplate = findItemInArrayObject([...actionPayload], 'RESTORE_CODE_SMS_TEMPLATE') || ''
                state.templateTabValues.eslipSendTemplate = findItemInArrayObject([...actionPayload], 'ESLIP_SEND_TEMPLATE') || ''
            })
            .addCase(fetchTemplateInfo.rejected, (state, action) => setError(state, action))

            /* Получение данных архивирование */
            .addCase(fetchArchivingInfo.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchArchivingInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let actionPayload = action.payload

                state.archiveTabValues.archiveEnable = findItemInArrayObject([...actionPayload], 'IS_ARCHIVING_ON') === '1'
                state.archiveTabValues.countDayAgeEslipForArchive = findItemInArrayObject([...actionPayload], 'ARCHIVE_ESLIPS_OLDER_THAN') || 0
                state.archiveTabValues.deleteArchiveEnable = findItemInArrayObject([...actionPayload], 'IS_DELETING_FROM_ARCHIVE_ON') === '1'
                state.archiveTabValues.countDayAgeEslipForDelete = findItemInArrayObject([...actionPayload], 'DELETE_FROM_ARCHIVE_OLDER_THAN') || 0
                state.archiveTabValues.archiveMoveBatchSize = findItemInArrayObject([...actionPayload], 'MAX_BATCH_SIZE_MOVE_ARCHIVE')
                state.archiveTabValues.archiveDeleteBatchSize = findItemInArrayObject([...actionPayload], 'MAX_BATCH_SIZE_DELETE_ARCHIVE')
            })
            .addCase(fetchArchivingInfo.rejected, (state, action) => setError(state, action))

    },
})

export const {
    initCommonTab, setNeedRefreshData,
    initPasswordPolicyTab, initNotificationTab, initTemplateTab, initArchiveTab,
    updateCommonTab, updatePasswordPolicyTab, updateNotificationTab, updateTemplateTab, updateArchiveTab
} = systemParamsPageSlice.actions;

export default systemParamsPageSlice.reducer;