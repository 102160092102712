import React from 'react';
import {Button, MenuItem} from "@mui/material";
import Menu from "@mui/material/Menu";

const ButtonWithMenu = ( props) => {

    const {menuItems, children, ...rest} = props  //?.menuItems || []

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleClose = (event, cb) => {
        setAnchorEl(null);
        if (cb === "escapeKeyDown" || cb === "backdropClick") {
            return
        }

        cb()
    };

    return (
        <>
            <Button {...rest}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {children}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems.map((option, key) => (
                    <MenuItem
                        key={option.key}
                        onClick={(event) => {handleClose(event, option.fn)}}>
                        {option.caption}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ButtonWithMenu;