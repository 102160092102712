import React from 'react';
import {Box, Typography} from "@mui/material";
import {mappingAccessLevel} from "../../../../util/BackEndUiMapperValueUtils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import BankCurrentList from "./BankCurrentList";
import MerchantCurrentList from "./MerchantCurrentList";
import OrgCurrentList from "./OrgCurrentList";

const GroupAccessCurrentEdit = () => {
    const {t} = useTranslation()
    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)
    const groupId = useSelector(state => state.groupAccessPage.groupId)
    const groupName = useSelector(state => state.groupAccessPage.groupName)

    return (
        <Box>
            <Box sx={{display: "flex", pl: '1.5rem', pt: '1rem'}}>
                <Typography variant="h6" >
                    {t('pages.groupAccess.groupUserCaption')}
                </Typography>

                <Typography component="span" variant="h6" color='primary.main' sx={{ml:'0.5rem'}}>
                    {groupName}
                </Typography>
            </Box>

            <Box sx={{display: "flex", pl:'1.5rem', mt: '0.5rem'}}>
                <Typography variant="h6">
                    {t('pages.groupAccess.accessLevelCaption2')}
                </Typography>

                <Typography component="span" variant="h6" color='primary.main' sx={{ml:'0.5rem'}}>
                    {mappingAccessLevel(t, accessLevel)}
                </Typography>
            </Box>


            {
                'BANK' === accessLevel &&
                <BankCurrentList />
            }

            {
                'ORG' === accessLevel &&
                <OrgCurrentList />
            }

            {
                'MERCHANT' === accessLevel &&
                <MerchantCurrentList />
            }
        </Box>
    );
};

export default GroupAccessCurrentEdit;