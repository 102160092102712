import {createSlice} from "@reduxjs/toolkit";

const passwordChangePageSlice = createSlice({
    name: 'passwordChangePage',
    initialState: {
        login: null,
    },


    reducers: {
        setLoginUser(state, action) {
            state.login = action.payload.login
        },

    }
})

export const {setLoginUser} = passwordChangePageSlice.actions

export default passwordChangePageSlice.reducer