import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid, IconButton,
    Typography
} from "@mui/material";
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import instanceAxiosApi from "../../service/axiosApi";
import {styled} from "@mui/material/styles";
import {purple} from "@mui/material/colors";
import IconButtonWithLoader from "../buttons/IconButtonWithLoader";
import {RouteEnum} from "../../types/RouteEnum";
import {useNavigate} from "react-router-dom";
import {mappingRoleName} from "../../util/BackEndUiMapperValueUtils";
import {useTranslation} from "react-i18next";

const ColorButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));

const AccountInfo = (props) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    const [login, setLogin] = useState('--')
    const [email, setEmail] = useState('--')
    const [phone, setPhone] = useState('--')
    const [roles, setRoles] = useState([])

    useEffect(() => {
        refreshComponentInfo()
    }, []);

    const fetchApi = async () => {
        // Идем на сервер
        return await instanceAxiosApi({
            method: "get",
            url: "/ui/v1/users/account/info",
        })
    }

    const refreshComponentInfo = () => {
        setIsLoading(true)
        fetchApi()
            .then(response => {
                let userInfo = response.data;
                // {
                //     "login": "admin",
                //     "email": null,
                //     "phone": null,
                //     "roles": [
                //     "ADMIN"
                // ]
                // }

                setLogin(userInfo.login)
                setEmail(userInfo?.email || "-")
                setPhone(userInfo?.phone || "-")
                setRoles(userInfo?.roles || [])

            })
            .catch(e => {
                console.error("Error read account info")
                setLogin("--")
                setEmail("--")
                setPhone("--")
                setRoles([])
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Card {...props}>
            <CardHeader
                action={
                    <Button
                        color="primary"
                        startIcon={<ArrowBackIcon fontSize="small"/>}
                        onClick={() => navigate(RouteEnum.PROFILE)}
                        size="small"
                    >
                        {t('pages.dashboard.goToProfile')}
                    </Button>
                }
                avatar={
                    <IconButtonWithLoader isLoading={isLoading}
                                          backgroundColor="info.main"
                                          refreshComponentInfo={refreshComponentInfo}>
                        <AssignmentIndOutlinedIcon/>
                    </IconButtonWithLoader>
                }

                title={t('pages.dashboard.accountData')}
            />
            <Divider/>
            <CardContent>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', m: 1, p:1}}>
                        <Typography variant="subtitle1">
                            {t('pages.dashboard.login')}
                        </Typography>
                        <Typography variant="h5" color='primary.main'>
                            {login}
                        </Typography>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', m: 1, p:1}}>
                        <Typography variant="subtitle1">
                            {t('pages.dashboard.email')}
                        </Typography>
                        <Typography variant="h5" color='primary.main'>
                            {email}
                        </Typography>
                    </Box>


                    <Box sx={{display: 'flex', flexDirection: 'column', m: 1, p:1}}>
                        <Typography variant="subtitle1">
                            {t('pages.dashboard.phone')}
                        </Typography>
                        <Typography variant="h5" color='primary.main'>
                            {phone}
                        </Typography>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', m: 1, p:1}}>
                        <Typography variant="subtitle1">
                            {t('pages.dashboard.accessLevel')}
                        </Typography>
                        <Typography variant="h5" color='primary.main'>
                            {roles.map(r => mappingRoleName(t, r)).join(', ')}
                        </Typography>
                    </Box>
                </Box>


            </CardContent>
        </Card>
    );
};

export default AccountInfo;