import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import instanceAxiosApi from "../../service/axiosApi";

export const fetchOrganizationRows = createAsyncThunk(
    'eslipsPage/fetchOrganizationRows',
    async function(params, {rejectWithValue}) {
        try {
            const { searchString} = params

            return await instanceAxiosApi.get(`/ui/v1/organizations/filter?searchString=${searchString}`)
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchCurrencyRows = createAsyncThunk(
    'eslipsPage/fetchCurrencyRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/currency")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchOriginOperationRows = createAsyncThunk(
    'eslipsPage/fetchOriginOperationRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/origin_operations")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchIsEslipAttrRows = createAsyncThunk(
    'eslipsPage/fetchIsEslipAttrRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/is_eslips")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const processBufferedEslip = createAsyncThunk(
    'eslipsPage/processBufferedEslip',
    async function(addInfo, {rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            await instanceAxiosApi.post("/ui/v1/eslips/buffered_process")

            showSnackMsg('success', 'systemCommon.commandQueuedForExecutionMsg')
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

export const fetchEslipsRows = createAsyncThunk(
    'eslipsPage/fetchEslipRows',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const {page, limit, filterParams, filterNormal} = getState().eslipsPage

            if ('normal' !== filterNormal) {
                return {}
            }

            let params = buildParamsForRequest(filterParams, page, limit)

            const response = await instanceAxiosApi.get(`/ui/v1/eslips?${params}`)
            // Может понадобится вернуть сообщение
            //showSnackMsg('success', 'systemCommon.dataRowsResponseSuccessfully')
            return response
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

export const fetchExportEslipsSelected = createAsyncThunk(
    'eslipsPage/fetchExportEslipsSelected',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg, exportType, exportContentType} = addInfo
        // exportType = pdf, csv, tlv
        // exportContentType = 'application/pdf',
        try {
            const {page, limit, filterParams, filterNormal, dataRows, selectedRows} = getState().eslipsPage

            if ('normal' !== filterNormal) {
                showSnackMsg('warning', "pages.filterNotSetComponent.filterNotSetHint")
                return null
            }

            if (dataRows.length < 1) {
                showSnackMsg('warning', "systemCommon.dataNotFound")
                return null
            }

            let params = buildParamsForRequest(filterParams, page, limit, selectedRows)

            let processType
            let response
            if (selectedRows && selectedRows.length > 0 && selectedRows.length <= 30) {
                processType = "fast"
                // Сразу файл
                response = await instanceAxiosApi.get(`/ui/v1/show-eslip/export-${exportType}/selected?${params}`,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'content-type': 'application/json',
                            //'accept': "application/pdf"
                        },
                        'responseType': 'arraybuffer'
                    });
            } else {
                // Отдаем на выполнение
                processType = "long"
                response = await instanceAxiosApi.get(`/ui/v1/show-eslip/export-${exportType}/all-by-filter?${params}`)
            }

            return {response: response, processType: processType, exportType:exportType, exportContentType: exportContentType}
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)


const buildParamsForRequest = (filterParams, page, limit, selectedRows) => {

    let filterPredicateArray = []

    for (const [key, value] of Object.entries(filterParams)) {
        //console.log(`${key}: ${value}`);

        if (value && typeof value === "string") {
            filterPredicateArray.push(`&${key}=${value}`)
        } else if (value && typeof value === "object") {
            filterPredicateArray.push(`&${key}=${value.key}`)
        }
    }

    if (selectedRows && selectedRows.length > 0) {
        filterPredicateArray.push(`&eslipsIdList=${selectedRows}`)
    }

    let paramsString = filterPredicateArray.join('')

    let params = `page=${page}&limit=${limit}${paramsString}`

    return params
}

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'


    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({
            errorCode: 403,
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({
            errorCode: 400,
            error: errorMessage}
        )
    } else {
        // Сообщение выдаст центральный axios interceptor
        return rejectWithValue({
            errorCode: 500,
            error: 'authProvider.internalServerErrorMessage'
        })
    }
}





/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
}

const eslipsPageSlice = createSlice({
    name: 'eslipsPage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        status: null,
        error: null,

        dataRows: [],
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 30,

        selectedRows: [],

        filterParams: {
            currency: '',
            pan: '',
            amount: '',
            dateFrom: null,
            dateTo: null,
            terminalHostId: '',
            merchantName: '',
            cardHolder: '',
            origOperationCode: '',
            rrn: '',
            isEslip: '',
            email: '',
            phone: '',
            orgId: null
        },
        filterNormal: null,

        filterPanelOpen: false,

        settingsPanelOpen: false,

        columnSettings: [
            {key: "currency.codeAlpha", visible: true},
            {key: "pan", visible: true},
            {key: 'formattedAmount', visible: true},
            {key: 'serviceTimeFormatted', visible: true},
            {key: 'terminal.terminalhostId', visible: true},
            {key: 'terminal.merchants.name', visible: true},
            {key: 'cardHolder', visible: true},
            {key: 'origOperationName', visible: true},
            {key: 'rrn', visible: true},
            {key: 'isEslipAttributeName', visible: true},
            {key: 'email', visible: true},
            {key: 'phone', visible: true}
        ],

        currencyRows: [],
        originOperationRows: [],
        isEslipAttrs: [],

        isLoadingOrganizations: false,
        organizationRow: [],

        openShowTaskIdDlg: false,
        exportObject: null,
    },
    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        changeFilterParams(state, action) {
            state.filterParams = {...state.filterParams, ...action.payload}
        },

        clearFilterInfo(state, action) {
            let clearParams = {
                currency: '',
                pan: '',
                amount: '',
                dateFrom: null,
                dateTo: null,
                terminalHostId: '',
                merchantName: '',
                cardHolder: '',
                origOperationCode: '',
                rrn: '',
                isEslip: '',
                email: '',
                phone: '',
                orgId: null
            }

            state.filterParams = {
                ...state.filterParams,
                ...clearParams
            }
        },

        changeFilterPanelOpenStatus(state, action) {
            state.filterPanelOpen = action.payload.filterPanelOpen;
        },

        changSettingsPanelOpenStatus(state, action) {
            state.settingsPanelOpen = action.payload
        },

        changeVisibleColumn(state, action) {
            let elementi = state.columnSettings.find(element => element.key === action.payload)
            elementi.visible = !elementi.visible

            localStorage.setItem('eslipsColumnSetting', JSON.stringify(state.columnSettings))
        },

        /* Инициализация всей страницы при открытии */
        initPageData(state, action) {
            state.status = null
            state.error = null

            let lsinfo = JSON.parse(localStorage.getItem("eslipsColumnSetting"))

            // Если ничего нет, ставим из стейта и уходим
            if (!lsinfo) {
                localStorage.setItem('eslipsColumnSetting', JSON.stringify(state.columnSettings))

                return;
            }

            // Если настройки есть, применяем
            let columnSettingL = [...state.columnSettings]

            columnSettingL.map(e => {
                let t = lsinfo.find(el => el.key === e.key)
                if (t) {
                    e.visible = t.visible
                }
            })

            state.columnSettings = columnSettingL
            localStorage.setItem('eslipsColumnSetting', JSON.stringify(state.columnSettings))
        },

        setLimit(state, action) {
            state.limit = action.payload
        },

        setPage(state, action) {
            state.page = action.payload
        },

        setFilterNormal(state, action) {
            state.filterNormal = action.payload
        },

        setOpenShowTaskIdDlg(state, action) {
            state.openShowTaskIdDlg = action.payload
            state.exportObject = null
        },

        updateSelectedRows(state, action) {
            state.selectedRows = [...action.payload]
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEslipsRows.pending, (state, action) => {
                state.status = 'loading'
                // state.dataRows = [] // не очень выглядит, сначала строки пропадаю, потом появляются, закоментил
            })

            .addCase(fetchEslipsRows.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let dataJson = action.payload?.data || {rows: [], totalPages: 0}

                state.dataRows = dataJson.rows

                state.totalPages = dataJson.totalPages

                // Если на какой-то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
                if (dataJson.totalPages < state.page) {
                    state.page = 1
                }
            })
            .addCase(fetchEslipsRows.rejected, (state, action) => setError(state, action))

            .addCase(fetchIsEslipAttrRows.pending, (state, action) => {})
            .addCase(fetchIsEslipAttrRows.fulfilled, (state, action) => {
                state.isEslipAttrs = action.payload?.data || []
            })
            .addCase(fetchIsEslipAttrRows.rejected, (state, action) => {
                state.isEslipAttrs = []
            })

            .addCase(fetchOriginOperationRows.pending, (state, action) => {})
            .addCase(fetchOriginOperationRows.fulfilled, (state, action) => {
                state.originOperationRows = action.payload?.data || []
            })
            .addCase(fetchOriginOperationRows.rejected, (state, action) => {
                state.originOperationRows = []
            })

            .addCase(fetchCurrencyRows.pending, (state, action) => {})
            .addCase(fetchCurrencyRows.fulfilled, (state, action) => {
                state.currencyRows = action.payload?.data || []
            })
            .addCase(fetchCurrencyRows.rejected, (state, action) => {
                state.currencyRows = []
            })

            .addCase(fetchOrganizationRows.pending, (state, action) => {
                state.isLoadingOrganizations = true
            })
            .addCase(fetchOrganizationRows.fulfilled, (state, action) => {
                state.organizationRow = action.payload?.data || []
                state.isLoadingOrganizations = false
            })
            .addCase(fetchOrganizationRows.rejected, (state, action) => {
                state.organizationRow = []
                state.isLoadingOrganizations = false
            })

            .addCase(processBufferedEslip.pending, (state, action) => {})
            .addCase(processBufferedEslip.fulfilled, (state, action) => {})
            .addCase(processBufferedEslip.rejected, (state, action) => {})

            .addCase(fetchExportEslipsSelected.pending, (state, action) => {
                state.openShowTaskIdDlg = false
                state.exportObject = null
            })
            .addCase(fetchExportEslipsSelected.fulfilled, (state, action) => {
                if (!action.payload) {
                    return
                }

                // {response: response, exportType:exportType, exportContent: exportContentType}
                let response = action.payload.response
                let exportType = action.payload.exportType
                let exportContentType = action.payload.exportContentType
                let processType = action.payload.processType

                if (processType === "long") {
                    state.exportObject = {
                        processType: 'long',
                        taskId: response.data.task_id,
                    }
                    state.openShowTaskIdDlg = true
                } else {
                    let fileName = response.headers["content-disposition"].split('filename=')[1].split(';')[0]
                    const file = new Blob([response.data], {
                        type: exportContentType,
                    });
                    state.exportObject = {
                        processType: 'fast',
                        blob: file,
                        fileName: fileName
                    }
                }
            })
            .addCase(fetchExportEslipsSelected.rejected, (state, action) => {
                state.openShowTaskIdDlg = false
                state.exportObject = null
            })
    }
})

/* Обычная функция */
export {buildParamsForRequest}

export const {
    setNeedRefreshData,
    changeFilterParams, changeFilterPanelOpenStatus,
    changSettingsPanelOpenStatus, changeVisibleColumn, initPageData,
    setLimit, setPage, setFilterNormal, clearFilterInfo,
    setOpenShowTaskIdDlg, updateSelectedRows
} = eslipsPageSlice.actions;

export default eslipsPageSlice.reducer;