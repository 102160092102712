import React from 'react';
import {styled} from "@mui/material/styles";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {purple} from "@mui/material/colors";
import orange from "@mui/material/colors/orange";


const ColorButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: orange[300],
    },
}));

const IconButtonWithLoader = ({children, refreshComponentInfo, isLoading, backgroundColor, ...props}) => {

    // const refreshComponentInfo = props.refreshComponentInfo;
    // const isLoading = props.isLoading
    // const backgroundColor = props.backgroundColor

    return (
        <>
            {
                isLoading
                ? <Box sx={{height: 56, width: 56, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress/>
                </Box>
                : <ColorButton {...props}
                               onClick={refreshComponentInfo}
                               sx={{
                                   backgroundColor: backgroundColor,
                                   height: 56,
                                   width: 56
                               }}
                >
                    {children}
                </ColorButton>
            }
        </>
    );
};

export default IconButtonWithLoader;