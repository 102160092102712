import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

/**
 * Диалог для добавления нового или изменения старого банка.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddEditBankDialog = (props) => {
    const {t} = useTranslation()

    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, oldBankName, bankId, refreshListRows} = props

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            formik.setFieldValue("bankName", oldBankName);
        }
    }, [open])

    const fetchApi = async (newBankName) => {
        let baseUrl = "/ui/v1/banks";
        let params = {"id": null, "name": newBankName}
        if (isNewBank()) {
            return await instanceAxiosApi.post(`${baseUrl}`, JSON.stringify(params));
        } else {
            params.id = bankId
            return await instanceAxiosApi.put(`${baseUrl}/${bankId}`, JSON.stringify(params));
        }
    }

    const isNewBank = () => {
        return bankId === -1
    }

    const handleClose = () => {
        clearError()
        formik.resetForm()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    const formik = useFormik({
        initialValues: {
            bankName: oldBankName
        },
        validationSchema: Yup.object({
            bankName: Yup
                .string()
                .notOneOf([oldBankName, null], t('pages.banks.addDialog.diffOldNameCaption'))
                .max(50)
                .required(t('pages.banks.addDialog.requiredCaption')),
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)

            await fetchApi(values.bankName)
                .then((response) => {
                    handleClose()
                    // Заставляем обновить данные на сервере
                    refreshListRows()

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoSaveSuccessCaption'), {
                        variant: 'success',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .catch(e => {
                    let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.infoErrorSaveCaption')
                    formikHelpers.setSubmitting(false)
                    setErrorSubmitMsg(clientMessage)
                    setIsSubmitError(true)

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoErrorSaveCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .finally(() => setIsProcessed(false))
        }
    });

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{
                    }}>
                        <DialogTitle>{isNewBank() ? t('pages.banks.addDialog.titleCreateCaption') : t('pages.banks.addDialog.titleEditCaption')}</DialogTitle>
                        {
                            isProcessed &&
                            <Box sx={{}}>
                                <LinearProgress/>
                            </Box>
                        }
                        <DialogContent>
                            <DialogContentText>
                                {
                                    !isNewBank() &&
                                    <Box sx={{display: 'flex', mb: '1rem'}}>
                                        <Typography>
                                            {t('pages.banks.addDialog.oldNameCaption')}
                                        </Typography>
                                        <Typography color={'secondary.main'} sx={{ml:'0.5rem'}}>
                                            {oldBankName}
                                        </Typography>
                                    </Box>
                                }

                                {isNewBank() ? t('pages.banks.addDialog.nameNewBankCaption') : t('pages.banks.addDialog.newNameCaption')}
                            </DialogContentText>

                            <TextField
                                error={Boolean(formik.touched.bankName && formik.errors.bankName)}
                                fullWidth
                                helperText={formik.touched.bankName && formik.errors.bankName}
                                label={t('pages.banks.addDialog.nameBankLabel')}
                                margin="normal"
                                name="bankName"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.bankName}
                                variant="outlined"
                                disabled={isProcessed}
                            />

                            {
                                isSubmitError &&
                                <Alert severity="error">{errorSubmitMsg}</Alert>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isProcessed} color={"error"} onClick={handleClose}>{t('systemCommon.dlgCancelBtnCaption')}</Button>
                            <Button disabled={isProcessed} type={"submit"}>{t('systemCommon.dlgSaveBtnCaption')}</Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

export default AddEditBankDialog;