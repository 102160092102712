import React, {createContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import instanceAxiosApi from "../service/axiosApi";
import {RouteEnum} from "../types/RouteEnum";
import {setActiveSection} from "../store/baseLayoutSlice";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {setLoginUser} from "../store/auth/passwordChangePageSlice";
import {checkEmptyString, intersectionArray} from "../util/UtilFunction";

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const apiBaseUrl = useSelector(state => state.baseLayout.apiBaseUrl)
    const {t} = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [userAuth, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [roles, setRoles] = useState(JSON.parse(localStorage.getItem("roles")));

    useEffect(() => {
        // console.log("Auth Провайдер Сработал")
        if (userAuth) {
            localStorage.setItem('user', JSON.stringify(userAuth));

            if (roles)
                localStorage.setItem('roles', JSON.stringify(roles));
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('roles');
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken")
        }
    }, [userAuth]);

    const requestServer = async (login, password) => {
        try {
            let bodyFormData = new FormData();
            bodyFormData.append('uname', login);
            bodyFormData.append('psw', password);

            let authData = {login:login,password:password}
            let userResponse = await instanceAxiosApi.post(`/auth/login`, JSON.stringify(authData));

            let user = await userResponse.data

            return user;
        } catch (e) {
            let errorStatus = e?.response?.status || 401
            let errorCause = e?.response?.headers?.unauthorized_add_code || "E_401_1"

            // 401.1 - Доступ запрещен
            // 401.2 - Пользователь заблокирован
            // 401.3 - Необходимо изменить пароль
            if (errorStatus === 401) {
                switch (errorCause) {
                    case "E_401_1" : throw new Error(t('authProvider.accessDeniedMessage'))
                    case "E_401_2" : throw new Error(t('authProvider.userBlockedMessage'))
                    case "E_401_3" : userPasswordNeedChange() //navigate("/auth/password_change", {replace: true})
                    case "E_401_4" : userPasswordNeedChange() //navigate("/auth/password_change", {replace: true})
                    default : throw new Error(t('authProvider.loginErrorMessage'))
                }
            }

            if (errorStatus === 500) {
                throw new Error(t('authProvider.apiServerErrorMessage'))
            }

            throw new Error(t('authProvider.loginErrorMessage'))
        }
    }

    const requestUserInfo = async () => {
        let token = localStorage.getItem('accessToken') || "empty";

        let userInfoResponse = await instanceAxiosApi({
            method: "get",
            url: "/auth/user/info",
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
            },
            withCredentials: false
        })
        let userInfo = await userInfoResponse.data

        return userInfo
    }

    /**
     * Вход на сервер.
     *
     * @param login
     * @param password
     * @param callback
     * @returns {Promise<void>}
     */
    const signin = async (login, password, callback) => {
        if (!apiBaseUrl) {
            throw new Error(t('authProvider.urlApiServerNotSetMessage'))
        }

        // Проставляем имя пользователя в локал стор, если вход не получится нужно например изменить пароль, логин должен быть
        localStorage.setItem('userLastLogin', login);
        const loginResponse = await requestServer(login, password)

        localStorage.setItem('accessToken', loginResponse.access_token);
        localStorage.setItem('refreshToken', loginResponse.refresh_token);

        // Если зашли нормально, получаем данные для отображения пользователя
        const userInfoResponse = await requestUserInfo();

        // Что ожидаем с сервера
        /*UserInfo:
            Long userId, String userLogin, String userFirstName, String userPatronymic, String userLastName, List<RoleInfo> roles*/
        /*RoleInfo:
            Long id, String caption, String description */

        let userInfo = {id:userInfoResponse.userId, login: '', fName: '', mName: '', lName: '',}
        userInfo.avatar = userInfoResponse.avatar ? userInfoResponse.avatar.replace("\ufeff", "") : null
        userInfo.login = userInfoResponse.userLogin;
        userInfo.fName = userInfoResponse.userFirstName || '';
        userInfo.mName = userInfoResponse.userPatronymic || '';
        userInfo.lName = userInfoResponse.userLastName || '';

        let userRoles = []
        userInfoResponse.roles.map(({sysName}) => {
            userRoles.push(sysName)
        })

        // Проставлеят в стор инфу
        setRoles(userRoles)
        setUser(userInfo)

        // Проставляем имя пользователя в стор, для перелогина
        localStorage.setItem('userLastLogin', login);

        // Делаем что-то дополнительно
        callback();
    }

    const signout = async (callback) => {
        setRoles(null)
        setUser(null)

        dispatch(setActiveSection({href:"/home"}))

        let token = localStorage.getItem('accessToken') || "empty";
        try {
            let userLogoutResponse = await instanceAxiosApi({
                method: "post",
                url: "/auth/logout",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*'
                }
            })
        } catch (e) {
            let errorStatus = e?.response?.status || 400

            if (errorStatus === 400) {
                console.error("Ошибка бизнес логики на сервере") // TODO : показать всплывающее сообщение
            } else {
                console.error("Internal Server Error: " + errorStatus)
            }
        }

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Эту функцию запускаем в любом случае
        callback();
    };

    const roleInfo = () => {
        if (!userAuth) {
            setRoles([])
            return []
        }

        return roles;
    }

    const clearUserInfoFromLS = () => {
        setUser(null)
        navigate("/auth/login", {replace: true})
    }

    const forbiddenPageRedirect = () => {
        navigate(RouteEnum.FORBIDDEN,  {replace: false} )

        enqueueSnackbar('Insufficient rights to view this section', {
            variant: 'error',
            preventDuplicate: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right'},
        })
    }

    const userPasswordNeedChange = () => {
        // пробуем определить пользователя из стора, при любой попытке входа обновляется
        // Если еще нет (это нормально, не входил успешно еще) берем последнее имя с которым входил (при любой попытке входа тоже обновляется )
        let userLastLogin = '';
        if (userAuth && Object.entries(userAuth).length > 0) {
            userLastLogin = userAuth.login
        } else {
            userLastLogin = localStorage.getItem('userLastLogin') || ''
        }

        setUser(null)
        dispatch(setActiveSection({href:"/home"}))
        dispatch(setLoginUser({login:userLastLogin}))
        navigate("/auth/password_change", {replace: true})
    }

    /**
     * Проверка на наличие прав у пользователя.
     *
     * @param a
     * @param b
     * @constructor
     */
    const checkExistsRoles = (auditableRoles) => {
        if (!auditableRoles) {
            console.error("auditableRoles is null")
            return false
        }
        // Дальше считаем, роли для проверки или строка или массив строк
        let auditableRolesL = []
        if (!Array.isArray(auditableRoles)) {
            // строка
            if (!checkEmptyString(auditableRoles)) {
                auditableRolesL.push(auditableRoles)
            }
        } else {
            auditableRolesL = auditableRoles
        }

        if (auditableRolesL.length < 1) {
            console.error("auditableRoles is empty")
            return false
        }

        return intersectionArray(auditableRolesL, roles)
    }

    const reloadUserInfo = async () => {
        const userInfoResponse = await requestUserInfo();

        // Что ожидаем с сервера
        /*UserInfo:
            Long userId, String userLogin, String userFirstName, String userPatronymic, String userLastName, List<RoleInfo> roles*/
        /*RoleInfo:
            Long id, String caption, String description */

        let userInfo = {id:userInfoResponse.userId, login: '', fName: '', mName: '', lName: '',}
        userInfo.avatar = userInfoResponse.avatar ? userInfoResponse.avatar.replace("\ufeff", "") : null
        userInfo.login = userInfoResponse.userLogin;
        userInfo.fName = userInfoResponse.userFirstName || '';
        userInfo.mName = userInfoResponse.userPatronymic || '';
        userInfo.lName = userInfoResponse.userLastName || '';

        let userRoles = []
        userInfoResponse.roles.map(({sysName}) => {
            userRoles.push(sysName)
        })

        // Проставлеят в стор инфу
        setRoles(userRoles)
        setUser(userInfo)
    }

    /**
     * Обновление токена доступа.
     *
     * @returns {Promise<void>}
     */
    const refreshAccessToken = async () => {
        let accessToken = localStorage.getItem('accessToken') || "empty";
        let refreshToken = localStorage.getItem('refreshToken') || "empty";

        let refreshRequestBody = {access_token:accessToken,refresh_token:refreshToken}

        let refreshTokenResponse = await instanceAxiosApi.post(`/auth/token/refresh`, JSON.stringify(refreshRequestBody));
        let refreshResponseInfo = await refreshTokenResponse.data

        localStorage.setItem('accessToken', refreshResponseInfo.access_token);
        localStorage.setItem('refreshToken', refreshResponseInfo.refresh_token);
    }

    let value = {
        signin, signout, userAuth,
        reloadUserInfo, roleInfo, clearUserInfoFromLS,
        userPasswordNeedChange, forbiddenPageRedirect, checkExistsRoles,
        refreshAccessToken
    };

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
};

export default AuthProvider;