import React from 'react';
import {
    BsBarChartLine,
    BsArchive,
    BsReceipt,
    BsTools,
    BsPerson,
    BsPeople,
    BsShieldCheck,
    BsBank2,
    BsShop,
    BsWifi,
    BsDownload,
    BsFileSpreadsheet,
    BsPersonSquare
} from "react-icons/bs";
import {RoleEnum} from "../types/RoleEnum";

const iconS = {
    className: "w-5 h-5 text-inherit",
};

export const appMenuArray = [
    {
        //title: '',
        pages: [
            {
                icon: <BsBarChartLine {...iconS}/>,
                name: "appMenu.dashboardItem",
                path: "/home",
            },
        ]
    },
    {
        title: 'appMenu.commonSection',
        pages: [
            {
                icon: <BsReceipt {...iconS}/>,
                name: "appMenu.listSlipItem",
                path: "/eslips",
            },
            {
                icon: <BsArchive {...iconS}/>,
                name: "appMenu.archiveItem",
                path: "/archive",
            },
            {
                icon: <BsDownload {...iconS}/>,
                name: "appMenu.exportItem",
                path: "/export",
                availableForRoles: [RoleEnum.ADMINISTRATOR, RoleEnum.OPERATOR]
            }
        ]
    },
    {
        title: 'appMenu.handbookSection',
        pages: [
            {
                icon: <BsBank2 {...iconS}/>,
                name: "appMenu.banksItem",
                path: "/banks",
                availableForRoles: [RoleEnum.ADMINISTRATOR]
            },
            {
                icon: <BsWifi {...iconS}/>,
                name: "appMenu.organizationsItem",
                path: "/organizations",
                availableForRoles: [RoleEnum.ADMINISTRATOR, RoleEnum.OPERATOR]
            },
            {
                icon: <BsShop {...iconS}/>,
                name: "appMenu.tradeServiceEnterpriseItem",
                path: "/merchants",
                availableForRoles: [RoleEnum.ADMINISTRATOR, RoleEnum.OPERATOR]
            },
            {
                icon: <BsFileSpreadsheet {...iconS}/>,
                name: "appMenu.terminalsItem",
                path: "/terminals",
            }
        ]
    },
    {
        title: 'appMenu.administrationSection',
        pages: [
            {
                icon: <BsPersonSquare {...iconS}/>,
                name: "appMenu.userProfile",
                path: "/profile",
            },
            {
                icon: <BsTools {...iconS}/>,
                name: "appMenu.systemSettingsItem",
                path: "/system/params",
                availableForRoles: [RoleEnum.ADMINISTRATOR]
            },
            {
                icon: <BsPerson {...iconS}/>,
                name: "appMenu.userSystemItem",
                path: "/persons",
                availableForRoles: [RoleEnum.ADMINISTRATOR]
            },
            {
                icon: <BsPeople {...iconS}/>,
                name: "appMenu.groupUserSystemItem",
                path: "/group_persons",
                availableForRoles: [RoleEnum.ADMINISTRATOR]
            },
            {
                icon: <BsShieldCheck {...iconS}/>,
                name: "appMenu.groupPermissionsItem",
                path: "/group_access",
                availableForRoles: [RoleEnum.ADMINISTRATOR]
            }
        ]
    }
]