import {useEffect} from "react";
import AccessDeniedComponent from "../../components/AccessDeniedComponent";
import {Container} from "@mui/material";
import GoMainPage from "../../components/GoMainPage";

const ForbiddenPage = () => {

    useEffect(() => {
        document.title = 'Forbidden | UniPORT E-slip';

    }, []);

    return (
        <Container maxWidth="md">
            <AccessDeniedComponent />
            <GoMainPage />
        </Container>
    );
};

export default ForbiddenPage;