import React from 'react';
import {Box, IconButton, MenuItem, Pagination, Select, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const PagingComponentList = (props) => {
    const {t} = useTranslation()

    const {
        totalPages,
        page, setPageFn,
    } = props

    const setPageL = (num) => {
        setPageFn(num)
    }

    return (
        <Box sx={{
            mt: '10px', mb: '20px', pb:'13px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: "center",
            flexWrap: 'wrap'
        }} size="small">


            {
                !!totalPages &&
                <Pagination color="primary"
                            count={totalPages}
                            page={page}
                            onChange={(_, num) => setPageL(num)}
                />
            }

            {
                !totalPages && <Box width={150}></Box>
            }
        </Box>
    );
};

export default PagingComponentList;