import {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Outlet} from "react-router-dom";
import Footer from "../components/layout/Footer";
import {Box} from "@mui/material";
import {DashboardNavbar} from "../components/layout/dashboard-navbar";
import {DashboardSidebar} from "../components/layout/dashboard-sidebar";

const DashboardLayoutRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280
    }
}));

const BaseLayout = (props) => {
    const {children} = props;
    const [isSidebarOpen, setSidebarOpen] = useState(true);


    return (
        <>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            minHeight: 'calc(100vh - 126px)',
                            //flex: '1 1 auto', width: '100%'
                        }}
                    >
                        <Outlet/>
                    </Box>

                    <Box>
                        <Footer/>
                    </Box>
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar />
            <DashboardSidebar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </>
    );
};

export default BaseLayout;