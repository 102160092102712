import React, {useEffect, useState} from 'react';
import {
    Alert,
    Avatar, Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Divider, Grid,
    IconButton,
    Typography
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import instanceAxiosApi from "../../service/axiosApi";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import IconButtonWithLoader from "../buttons/IconButtonWithLoader";
import {useTranslation} from "react-i18next";

const LicenseInfo = (props) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(true)

    const [colorIcon, setColorIcon] = useState('secondary.main')
    const [cntDayEndLicense, setCntDayEndLicense] = useState('')
    const [clientId, setClientId] = useState('-')
    const [licenseType, setLicenseType] = useState('-')
    const [licenseDateExpired, setLicenseDateExpired] = useState('-')
    const [isWarnLicense, setIsWarnLicense] = useState(false)
    const [isErrorLicense, setIsErrorLicense] = useState(false)

    useEffect(() => {
        refreshLicenseInfo()

    }, []);

    const fetchApi = async () => {
        // Идем на сервер
        let userResponse = await instanceAxiosApi({
            method: "get",
            url: "/ui/v1/licence_info"
        })

        let licenseInfo = userResponse.data;

        // Ответ сервера
        //{
        // "licenseInfoContainer":{
        //                          "clientId":-1,"licenseType":"FILE","licenseDateExpired":"-999999999-01-01",
        //                          "licenseTypeUICaption":"File license","licenseDateExpiredUICaption":"01-01-+1000000000"
        //                        },
        // "valid":false,"licenseWarning":false,"licenseWarningEndDay":-1
        // }

        let clientId = licenseInfo.licenseInfoContainer.clientId
        if ("-1" == clientId) {
            setClientId('-')
            setIsErrorLicense(true)
        } else {
            setClientId(licenseInfo.licenseInfoContainer.clientId)
            setIsErrorLicense(false)
        }


        setLicenseType(licenseInfo.licenseInfoContainer.licenseTypeUICaption)

        let dtexp = licenseInfo.licenseInfoContainer.licenseDateExpiredUICaption
        if ('01-01-+1000000000' === dtexp) {
            setLicenseDateExpired('-')
        } else {
            setLicenseDateExpired(dtexp)
        }

        setIsWarnLicense(licenseInfo.licenseWarning === true)
        setCntDayEndLicense(licenseInfo.licenseWarningEndDay)

        if (!licenseInfo.valid) {
            setColorIcon('error.main')
        } else if (licenseInfo.licenseWarning) {
            setColorIcon('warning.main')
        } else {
            setColorIcon('secondary.main')
        }
    }

    const refreshLicenseInfo = () => {
        setIsLoading(true)
        fetchApi()
            //.then(response => console.log(response))
            .catch(e => {
                // console.error("Error read license info")
                // TODO : отправить событие, что-бы перечитать права
                setIsErrorLicense(true)
                setClientId("-")
                setLicenseType("-")
                setLicenseDateExpired("-")
                setIsWarnLicense(false)
                setColorIcon('error.main')
            })
            .finally(() => setIsLoading(false))
    }


    return (
        <Card {...props}>
            <CardHeader sx={{backgroundColor: colorIcon,}}
                        avatar={
                            <IconButtonWithLoader isLoading={isLoading}
                                                  backgroundColor={colorIcon}
                                                  refreshComponentInfo={refreshLicenseInfo}>
                                <KeyOutlinedIcon/>
                            </IconButtonWithLoader>
                        }

                        // action={(
                        //     <IconButton
                        //         disabled={isLoading}
                        //         onClick={(event) => refreshLicenseInfo()}>
                        //         <RefreshOutlinedIcon/>
                        //     </IconButton>
                        // )}
                        title={t('pages.dashboard.licenceInfo')}
            />
            <Divider/>
            <CardContent>
                {

                        <>
                            {
                                !isErrorLicense && isWarnLicense &&
                                <Alert severity="warning">{`${t('pages.dashboard.licenseWarningCaption')} ${cntDayEndLicense}`}</Alert>
                            }
                            {
                                isErrorLicense &&
                                <Alert severity="error">{t('pages.dashboard.licenseNotFoundCaption')}</Alert>
                            }

                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    lg={3}
                                    sm={6}
                                    xl={3}
                                    xs={12}
                                >
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="subtitle1">
                                            {t('pages.dashboard.clientId')}
                                        </Typography>
                                        <Typography variant="h5" color='primary.main'>
                                            {clientId}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    lg={3}
                                    sm={6}
                                    xl={3}
                                    xs={12}
                                >
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="subtitle1">
                                            {t('pages.dashboard.licenseType')}
                                        </Typography>
                                        <Typography variant="h5" color='primary.main'>
                                            {licenseType}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid
                                    item
                                    lg={3}
                                    sm={6}
                                    xl={3}
                                    xs={12}
                                >
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="subtitle1">
                                            {t('pages.dashboard.endDate')}
                                        </Typography>
                                        <Typography variant="h5" color='primary.main'>
                                            {licenseDateExpired}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </>
                }
            </CardContent>
        </Card>

    );
};

export default LicenseInfo;