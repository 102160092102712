import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";

/* Роли - полный список */
export const fetchRolesSystem = createAsyncThunk(
    'personCardPage/fetchRolesSystem',
    async function() {
        try {
            const response = await instanceAxiosApi.get("/ui/v1/roles?")

            return response?.data || []
        } catch (error) {
            return []
        }
    }
)

/* Инфа для карточки какого-то пользователя */
export const fetchPersonCardData = createAsyncThunk(
    'personCardPage/fetchPersonCardData',
    async function(_, {getState, rejectWithValue}) {
        try {
            const {userId} = getState().personCardPage

            const response = await instanceAxiosApi.get(`/ui/v1/users/admin/profile/${userId}`)
            return response?.data || {}
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue)
        }
    }
)



export const fetchSaveCardInfo = createAsyncThunk(
    'personCardPage/fetchSaveCardInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const {
                userId,
                avatarChange,
                avatar,

                login,
                firstName,
                patronymic,
                lastName,
                phone,
                email,

                rolesSystem,
                roles,
            } = getState().personCardPage

            // Этот объект будем сохранять
            const userForSave = {
                id: userId,
                login: login,
                avatar: null,
                firstName: firstName,
                patronymic: patronymic,
                lastName: lastName,
                phone: phone,
                email: email,
                roles: []
            }

            // В отдельном блоке изменили фото значит нужно сохранять
            if (avatarChange && avatar) {
                let arrTemp = avatar.split(',')
                userForSave.avatar = arrTemp.slice(arrTemp.length - 1)[0]
            }

            // в массиве выбранных лежат только названия, а нам нужны объекты
            let roleSelectedAsArrObjects = rolesSystem.filter((value) => roles.find(e => e === value.value))

            userForSave.roles = roleSelectedAsArrObjects

            let response = null
            if (userId) {
                // редактирование пользователя
                response = await instanceAxiosApi.put(`/ui/v1/users/admin/profile/${userId}`, JSON.stringify(userForSave))
            } else {
                // Создание пользователя
                // const response = {"data":{"id":1}}
                response = await instanceAxiosApi.post("/ui/v1/users/admin/profile", JSON.stringify(userForSave))
            }

            showSnackMsg('success', 'systemCommon.saveDataSuccessMsg')
            return response
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({errorCode: 403,
            errorMessageForSnack: 'systemCommon.accessDeniedMessage',
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({errorCode: 400,
            errorMessageForSnack: 'systemCommon.errorRequestInfoCaption',
            error: errorMessage}
        )
    } else {
        return rejectWithValue({errorCode: 500,
            errorMessageForSnack: 'authProvider.internalServerErrorMessage',
            error: 'authProvider.internalServerErrorMessage'}
        )
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
    // state.showSnackError = action.payload.errorMessageForSnack

    if (action.payload.errorCode === 403) {
        state.is403 = true
    } else {
        state.is403 = false
    }
}

const personCardPageSlice = createSlice({
    name: 'personCardPage',
    initialState: {
        userId: null,
        avatar: null,

        // Признак, что нужно сохранить изображение при сохранении информации
        // сразу не сохраняем, пользователь может быть еше не создан
        avatarChange: false,

        login: '',
        firstName: '',
        patronymic: '',
        lastName: '',
        phone: '',
        email: '',

        roles: [],

        rolesSystem: [],

        status: null,
        error: null,
        is403: false,
    },

    reducers: {
        initNewUser(state, action) {
            state.userId = null
            state.status = 'resolved'
            state.error = null
        },

        initAllData(state, action) {
            //state.userId = null
            state.createUserMode = false

            state.avatar = null
            state.avatarChange = false

            state.login = ''
            state.firstName = ''
            state.patronymic = ''
            state.lastName = ''
            state.phone = ''
            state.email = ''

            state.status = null
            state.error = null
            state.is403 = false

            state.roles = []
            // state.rolesSystem = []
        },

        updateFormValue(state, action) {
            if (!state.userId) {
                state.login = action.payload.login
            }
            state.firstName = action.payload.firstName
            state.patronymic = action.payload.patronymic
            state.lastName = action.payload.lastName
            state.phone = action.payload.phone
            state.email = action.payload.email

            state.roles = action.payload.roles
        },

        changeAvatar(state, action) {
            state.avatar = action.payload
            state.avatarChange = true
        },

        setUserId(state, action) {
            state.userId = action.payload
        },

        setRoles(state, action) {
            let copyState = state.roles.slice()
            copyState = [...action.payload.roles]
            state.roles = copyState
        }
    },

    extraReducers: {
        [fetchRolesSystem.pending]: (state, action) => {

        },
        [fetchRolesSystem.fulfilled]: (state, action) => {
            state.rolesSystem = action.payload
            state.error = null
        },
        [fetchRolesSystem.rejected]: (state, action) => {

        },

        /* Админ просматривает данные */
        [fetchPersonCardData.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchPersonCardData.fulfilled]: (state, action) => {
            state.status = 'resolved'
            state.error = null

            state.avatar = action.payload.avatar

            state.login = action.payload.login
            state.firstName = action.payload.firstName
            state.patronymic = action.payload.patronymic
            state.lastName = action.payload.lastName
            state.phone = action.payload.phone
            state.email = action.payload.email

            let rolesRes = action.payload.roles || []
            if (rolesRes && rolesRes.length) {
                state.roles = rolesRes.map(i => i.value)
            } else {
                state.roles = []
            }
        },
        [fetchPersonCardData.rejected]: setError,

        /* Сохранение информации */
        [fetchSaveCardInfo.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchSaveCardInfo.fulfilled]: (state, action) => {
            state.status = 'resolved'
            state.error = null
            state.avatarChange = false

            // Если запуск из списка пользователей
            if (!state.userId) {
                // Создание пользователя
                // const response = {"data":{"id":1}}
                const response = action.payload

                state.userId = response.data.id
            }
        },
        [fetchSaveCardInfo.rejected]: setError

    }
})

export const {
    initNewUser,
    initAllData,
    changeAvatar,
    updateFormValue,
    setUserId,
    setRoles
} = personCardPageSlice.actions;

export default personCardPageSlice.reducer;
