import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Alert, Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {
    setConfirmationCode,
    setStepNumber
} from "../../../store/auth/passwordRecoveryPageSlice";

const Step2 = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const userLogin = useSelector(state => state.passwordRecoveryPage.login)
    const {transportConfirmationCode, error, status} = useSelector(state => state.passwordRecoveryPage)

    const [showPassword, setShowPassword] = useState(false)

    const formik = useFormik({
        initialValues: {
            restoreCode: ''
        },
        validationSchema: Yup.object({
            restoreCode: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.confirmationCodeCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            try {
                await dispatch(setConfirmationCode({confirmationCode: values.restoreCode}))
                dispatch(setStepNumber({stepNumber: 3}))
            } catch (e) {
                formikHelpers.setSubmitting(false)
            }
        }
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.step2Caption')}
            </Typography>

            <Alert severity="info">
                {(transportConfirmationCode === 'EMAIL')
                    ? t('pages.restorePassword.preStep2Description')
                    : t('pages.restorePassword.preStep2DescriptionSms')}
            </Alert>

            <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.loginInputLabel')}: {userLogin}
            </Typography>

            {
                error &&
                <Alert severity="error">{t(error)}</Alert>
            }

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    error={Boolean(formik.touched.restoreCode && formik.errors.restoreCode)}
                    fullWidth
                    helperText={formik.touched.restoreCode && formik.errors.restoreCode}
                    label={t('pages.restorePassword.confirmationCodeInputLabel')}
                    margin="normal"
                    name="restoreCode"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.restoreCode}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <Box sx={{py: 2}}>
                    <Button
                        color="primary"
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('pages.restorePassword.step2BtnCaption')}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default Step2;