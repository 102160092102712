import React, {useEffect, useState} from 'react';
import instanceAxiosApi from "../../service/axiosApi";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ShowEslipPageHtml = () => {
    const {t} = useTranslation()
    const {archive, encryptedLink} = useParams();
    const [data, setData] = useState({})
    const [status, setStatus] = useState(null)

    useEffect(() => {
        const fetchApi = async () => {

            const response = await instanceAxiosApi.get(`/ui/v1/show-eslip/${archive}/${encryptedLink}`);
            setData(response?.data || {})
        }

        document.title = t('pages.eslips.viewSlipActionCaption') + ' | UniPORT E-slip';

        setStatus('load')
        fetchApi()
            .finally(setStatus('complete'))
    }, [])

    const getEslipImage = (eslip) => {
        let src = "data:image/jpeg;base64," + eslip

        return <img className="receipt-image" width="200px" height="120px"
             src={src}/>
    }

    const renderDiv1 = () => {
        let res = data.images.map((img, key) => (
                    <>
                        <div className="receipt-text">{img}</div>

                        {
                            data.eslip &&
                            getEslipImage(data.eslip)
                        }

                    </>
                ))

        return <>
            {res}
            <div className="receipt-text">{data.image_last_piece}</div>
        </>
    }

    if (!status) return (<div></div>)

    if (status === 'load') return (<div>Loading...</div>)

    return (
            <div className="receipt">
                {/*Зигзаг рисуем только в этом случае*/}
                <div className={(data.is_manual !== 1 || !data.eslip) ? "receipt-zigzag" : ""}>

                    <div className="receipt-content">
                    {
                        data.is_manual === 0 && data.images?.length > 0 &&
                        renderDiv1()
                    }

                    {
                        data.is_manual === 0 && data.image &&
                        <div className="receipt-text">{data.image}</div>
                    }
                </div>

                </div>
            </div>
    );
};

export default ShowEslipPageHtml;