import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const ErrorLoadingAppSetting = () => {
    const {t} = useTranslation()

    return (
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100%',
            }}
        >
            <Container maxWidth="false"
                       sx={{
                           backgroundColor: 'rgb(8,39,245)',
                           borderRadius: 2,
                           maxWidth: '50rem',
                           minWidth: '25rem',
                           // minHeight: '50rem',
                           minHeight: '100%',
                           // alignItems: 'center',
                           display: 'flex',
                           flexDirection: 'column',
                       }}
            >
                <Typography color="rgb(255,255,255)"
                            variant="h1"
                            sx={{
                                    mt: '5%',
                            }}
                >
                    :(
                </Typography>
                <Typography color="rgb(255,255,255)"
                            variant="body1"
                            sx={{
                                //m: 'auto'
                                mb:'20%',
                                mt: '5%',
                                // ml: 'auto',
                                // mr: 'auto'
                            }}
                >
                    {t('layouts.auth.bsdHeaderMessage')}
                    <br/>
                    <br/>
                    {t('layouts.auth.bsdBodyMessage')}

                </Typography>
            </Container>
        </Box>
    );
};

export default ErrorLoadingAppSetting;