import React from 'react';
import {Paper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import GroupPersonsBindingAdd from "./GroupPersonsBindingAdd";
import GroupPersonsBindingCurrent from "./GroupPersonsBindingCurrent";

const GroupPersonsBindingWorkspace = () => {
    const isAddMode = useSelector(state => state.groupPersonsBindingPage.isAddMode)

    return (
            <Paper>
                {
                    isAddMode &&
                    <GroupPersonsBindingAdd />
                }
                {
                    !isAddMode &&
                    <GroupPersonsBindingCurrent />
                }
            </Paper>
    );
};

export default GroupPersonsBindingWorkspace;