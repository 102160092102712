import React, {useEffect, useState} from 'react';
import {
    Alert, AlertTitle, Box,
    Container, Divider, IconButton, InputAdornment,
    List,
    ListItem,
    ListItemAvatar, ListItemButton,
    ListItemText, SvgIcon, TextField, Tooltip, Typography
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchIcon from "@mui/icons-material/Search";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import instanceAxiosApi from "../../../service/axiosApi";
import {hasText} from "../../../util/UtilFunction";
import {mappingAccessLevel} from "../../../util/BackEndUiMapperValueUtils";
import PagingComponentList from "../../../components/list_element/PagingComponentList";
import {
    setIsLoading, setNeedRefreshData, setPage, setRowsGroup,
    setSearchString, setTotalPages,
    initGroupInfoAccessComponent
} from "../../../store/administration/groupAccessPageSlice";




const GroupAccessSelectGroup = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const isLoading = useSelector(state => state.groupAccessPage.isLoading)
    const searchString = useSelector(state => state.groupAccessPage.searchString)
    const page = useSelector(state => state.groupAccessPage.page)
    const limit = useSelector(state => state.groupAccessPage.limit)
    const totalPages = useSelector(state => state.groupAccessPage.totalPages)

    const rows = useSelector(state => state.groupAccessPage.rowsGroup)

    const needRefreshData = useSelector(state => state.groupAccessPage.needRefreshData)

    // Инициируем тем значением фильтра, что в сторе содержится
    const [searchValue, setSearchValue] = useState(searchString || '')
    const [isLoadError, setIsLoadError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    // При старте скидываем прошлые данные
    // useEffect(() => {
    //     dispatch(initParamsOnCurrentPageStart())
    // }, [])

    /* Вдруг из диалога изменили информацию */
    useEffect(() => {
        if (!needRefreshData) return;

        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    useEffect(() => {
        loadData()

    }, [page])

    // кнопка настройка группы
    const handleToggle = (value) => {
        dispatch(initGroupInfoAccessComponent({value:value}))
    };

    // const isCheckedItem = (id) => {
    //     if (!rowsForDelete || rowsForDelete.length < 1) return false
    //
    //     return rowsForDelete.filter(itemi => itemi.id == id).length > 0
    // }

    const clearLocalErrorState = () => {
        setIsLoadError(false)
        setErrorMsg('')
    }

    // Идем на сервер
    const fetchApi = async (params) => {
        return await instanceAxiosApi.get(`/ui/v1/group_access?${params}`) // тип возвращаемого объекта будет другой
    }

    const buildParams = () => {
        let filter = [
            {"key": "isSystem", "operation": "eq", "value": ["false"]}
        ]

        if (hasText(searchString)) {
            filter.push({"key": "name", "operation": "like", "value": [searchString]})
        }

        let filterAsString = JSON.stringify(filter)
        return `page=${page}&limit=${limit}&filter=${filterAsString}`
    }

    const loadData = async () => {
        dispatch(setIsLoading(true))
        // TODO : что-то сделать со второй панелью dispatch(clearRowsForDelete())
        try {
            const response = await fetchApi(buildParams())

            let dataJson = response?.data || {rows: [], totalPages: 0}

            dispatch(setRowsGroup({rows: [...dataJson.rows]}))
            dispatch(setTotalPages({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                dispatch(setPage({page: 1}))
            }

            clearLocalErrorState()
        } catch (e) {
            dispatch(setRowsGroup({rows: []}))

            setIsLoadError(true)
            let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')
            setErrorMsg(clientMessage)
        } finally {
            setSearchValue(searchString)
            dispatch(setIsLoading(false))
        }
    }

    const handleRefresh = () => {
        dispatch(setSearchString({searchString: searchValue}))
        dispatch(setNeedRefreshData(true))
    }

    const handleChangeSearchString = (event) => {
        setSearchValue(event.target.value)
    }

    const handleClickClearSearchString = () => {
        setSearchValue('')
        dispatch(setSearchString({searchString: ''}))
        dispatch(setNeedRefreshData(true))
    }

    const pressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(setSearchString({searchString: searchValue}))
            dispatch(setNeedRefreshData(true))
        }
    }

    const setPageL = (num) => {
        dispatch(setPage({page: num}))
    }

    return (
        <Container maxWidth={false}>


                <Box sx={{maxWidth: "sm"}}>
                    <Typography sx={{mt: 1, mb: 2, pt: '32px', pl: '10px'}} variant="h6" component="div">
                        {t('pages.groupAccess.groupsUsersCaption')}
                    </Typography>

                    <List dense={true}
                          sx={{width: '100%', /*maxWidth: 360, */bgcolor: 'background.paper'}}
                          subheader={
                              <Box sx={{display: "flex", flexDirection: "column"}}>
                                  <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                      <Tooltip title={t('systemCommon.updateInfo')}>
                                          <IconButton sx={{mr: '10px'}} onClick={handleRefresh}>
                                              <RefreshOutlinedIcon/>
                                          </IconButton>
                                      </Tooltip>
                                      <TextField
                                          type={"text"}
                                          fullWidth
                                          InputProps={{
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <SvgIcon
                                                          fontSize="small"
                                                          color="action"
                                                      >
                                                          <SearchIcon/>
                                                      </SvgIcon>
                                                  </InputAdornment>
                                              ),

                                              endAdornment: searchString && (
                                                  <IconButton size={"small"}
                                                              aria-label="toggle password visibility"
                                                              onClick={handleClickClearSearchString}
                                                  >
                                                      <CancelRoundedIcon fontSize={"small"}/>
                                                  </IconButton>
                                              )

                                          }}
                                          placeholder={t('pages.groupAccess.searchByNameCaption')}
                                          variant="outlined"
                                          size={"small"}
                                          value={searchValue}
                                          onChange={handleChangeSearchString}
                                          onKeyDown={(e) => pressEnter(e)}
                                          disabled={isLoading}
                                          inputRef={(input) => input?.focus()}
                                      />
                                  </Box>
                              </Box>
                          }
                    >
                        {rows.map((value, key) => {
                            const labelId = `checkbox-list-secondary-label-${value.id}`;
                            return (
                                <Box key={key}>
                                    <ListItem
                                        key={value.id}
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                // inputProps={'aria-labelledby': labelId}
                                                onClick={() => handleToggle(value)}
                                            >
                                                <SettingsOutlinedIcon />
                                            </IconButton>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <GroupsOutlinedIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={value.name}
                                                secondary={mappingAccessLevel(t, value.accessLevel)}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </Box>
                            );
                        })}
                    </List>

                    {
                        isLoadError &&
                        <Alert severity={"error"}>
                            <AlertTitle>{t('systemCommon.errorRequestInfoCaption')}</AlertTitle>
                            {errorMsg}
                        </Alert>
                    }

                    {
                        !totalPages && !isLoadError &&
                        <Box>
                            <Alert severity={"info"}>{t('systemCommon.noEntriesToDisplay')}</Alert>
                        </Box>
                    }


                    <PagingComponentList
                        totalPages={totalPages}
                        page={page}
                        setPageFn={setPageL}
                    />
                </Box>

        </Container>
    );
};

export default GroupAccessSelectGroup;