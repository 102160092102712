import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Typography
} from "@mui/material";
import {mappingAccessLevel} from "../../../../util/BackEndUiMapperValueUtils";
import {useSnackbar} from "notistack";
import {setAccessLevel, setIsLoading, setMode} from "../../../../store/administration/groupAccessPageSlice";
import {theme} from "../../../../theme";

const IndefinedComponent = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();


    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)
    const groupId = useSelector(state => state.groupAccessPage.groupId)
    const groupName = useSelector(state => state.groupAccessPage.groupName)

    // const [isProcessed, setIsProcessed] = useState(false)
    const [open, setOpen] = useState(false)
    const [accessLevelLocal, setAccessLevelLocal] = useState('BANK') // это то что будем задавать заново

    const fetchApi = async (groupId, params) => {

    }

    const handleChange = (event) => {
        setAccessLevelLocal(event.target.value)
    }

    const setNextStep = () => {
        dispatch(setAccessLevel(accessLevelLocal))
        dispatch(setMode('EDITING'))
    }

    const handleNextStep = (event) => {
        setNextStep()
    }

    // подтверждение в диалоге
    const handleSubmit = async () => {
        // setIsProcessed(true)
        dispatch(setIsLoading(true))
        try {
            let params = {accessLevel:accessLevelLocal}
            await fetchApi(groupId, params)

            enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
            setNextStep()
        } catch (e) {


            enqueueSnackbar(t('systemCommon.operationFailedMsg'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            // setIsProcessed(false)
            setOpen(false)
            dispatch(setIsLoading(false))
        }
    }


    return (
        <Box sx={{display: "flex", flexDirection:"column", alignItems: "center", mt:'2rem'}}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                    {t('pages.groupAccess.accessLevelCaption')}
                </InputLabel>
                <Select
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            width: '500px',
                        },

                        width:"320px"
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={accessLevelLocal}
                    label={t('pages.groupAccess.accessLevelCaption')}
                    onChange={handleChange}
                    size={"medium"}
                >
                    <MenuItem value={'BANK'}>{mappingAccessLevel(t, 'BANK')}</MenuItem>
                    <MenuItem value={'ORG'}>{mappingAccessLevel(t, 'ORG')}</MenuItem>
                    <MenuItem value={'MERCHANT'}>{mappingAccessLevel(t, 'MERCHANT')}</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{mt:'3rem'}}>
                {
                    // тут нужно спросить подтверждение
                    !accessLevel === 'INDEFINED' &&
                    <Button size={"small"}
                            variant="contained"
                            onClick={(event) => setOpen(true)}
                    >
                        {t('pages.groupAccess.saveBtnCaption')}
                    </Button>
                }

                {
                    // если режим не был задан, то просто переходим на следующий шаг
                    accessLevel === 'INDEFINED' &&
                    <Button size={"small"} fullWidth={true}
                            variant="contained"
                            onClick={handleNextStep}
                    >
                        {t('pages.groupAccess.selectObjectBtnCaption')}
                    </Button>
                }
            </Box>



            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={'paper'}
            >
                <DialogTitle bgcolor={'warning.main'} id="scroll-dialog-title">
                    {t('systemCommon.confirmationRequiredCaption')}
                </DialogTitle>

                <DialogContent dividers={false}>
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.groupUserCaption')}
                        </Typography>

                        <Typography component="span" variant="h6" color='primary.main'>
                            {groupName}
                        </Typography>
                    </DialogContentText>

                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.newAccessLevelCaption')}
                        </Typography>
                        <Typography component="span" variant="h6" color='primary.main'>
                            {mappingAccessLevel(t, accessLevelLocal)}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.confirmChangeAccessLevel')}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        {t('systemCommon.dlgCancelBtnCaption')}
                    </Button>
                    <Button onClick={handleSubmit}>
                        {t('systemCommon.dlgYesBtnCaption')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>




    );
};

export default IndefinedComponent;