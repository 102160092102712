import React from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {RouteEnum} from "../types/RouteEnum";
import {setActiveSection} from "../store/baseLayoutSlice";
import {useNavigate} from "react-router-dom";

const GoMainPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const clickBtnHandler = () => {
        dispatch(setActiveSection({href:"/home"}))
        navigate(RouteEnum.HOME, {replace: true})
    }

    return (
        <Button onClick={clickBtnHandler}
                startIcon={<ArrowBackIcon fontSize="small" />}
        >
            {t('systemCommon.goMainPageCaption')}
        </Button>
    );
};

export default GoMainPage;