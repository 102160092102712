import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    setAddMode,
    setOpenDeleteBindingDlg
} from "../../../../store/administration/groupPersonsBindingPageSlice";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";
import DeleteGroupBindingPersonDialog from "./DeleteGroupBindingPersonDialog";
import {useSnackbar} from "notistack";

const GroupPersonsBindingToolBar = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const isAddMode = useSelector(state => state.groupPersonsBindingPage.isAddMode)
    const groupName = useSelector(state => state.groupPersonsBindingPage.groupName)
    const isLoading = useSelector(state => state.groupPersonsBindingPage.isLoading)

    const rowsForDelete = useSelector(store => store.groupPersonsBindingPage.rowsForDelete)

    const [isExistsRowForDelete, setIsExistsRowForDelete] = useState(false)
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    useEffect(() => {
        setIsExistsRowForDelete(rowsForDelete.length > 0)
    }, [rowsForDelete])


    const handleClickDelete = () => {
        if (rowsForDelete.length < 1) {
            enqueueSnackbar(t('pages.groupBinding.selectEntriesToDeleteMsg'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })

            return
        }
        dispatch(setOpenDeleteBindingDlg({openDeleteBindingDlg:true}))
    }

    const handleClickBackAddMode = () => {
        dispatch(setAddMode({isAddMode:false}))
    }

    const handleClickNewBinding = () => {
        dispatch(setAddMode({isAddMode:true}))
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Box sx={{display:"flex", justifyContent:"start"}}>
                    <Typography
                        sx={{m: 1}}
                        variant="h4"
                    >
                        {t('pages.groupBinding.pageTitle')}
                    </Typography>
                    <Typography color={'primary.main'}
                                sx={{m: 1}}
                                variant="h4"
                    >
                        {groupName}
                    </Typography>
                </Box>
                {
                    !isAddMode && <Box sx={{display: 'flex', m: 1}}>
                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip title={lgUp ? "" : t('pages.groupBinding.deleteSelectedCaption')} >
                            <Button
                                startIcon={<DeleteForeverOutlinedIcon />}
                                // color="primary"
                                size={"small"}
                                sx={{mr:'10px'}}
                                variant="contained"
                                color="error"
                                onClick={() => handleClickDelete()}
                                disabled={isLoading || !isExistsRowForDelete}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('pages.groupBinding.deleteSelectedCaption') : ''}
                            </Button>
                        </Tooltip>

                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip title={lgUp ? "" : t('pages.groupBinding.addNewUsersCaption')} >
                            <Button
                                startIcon={<PersonAddOutlinedIcon />}
                                size={"small"}
                                color="primary"
                                variant="contained"
                                onClick={() => handleClickNewBinding()}
                                disabled={isLoading}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('pages.groupBinding.addNewUsersCaption') : ''}
                            </Button>
                        </Tooltip>
                    </Box>
                }

                {
                    isAddMode && <Box sx={{display: 'flex', m: 1}}>

                            <Button
                                startIcon={<ArrowBackIcon />}
                                size={"small"}
                                // color="primary"
                                sx={{mr:'10px'}}
                                // variant="contained"
                                // color="error"
                                onClick={() => handleClickBackAddMode()}
                                disabled={isLoading}
                            >
                                {t('pages.groupBinding.exitAddMode')}
                            </Button>

                    </Box>
                }
            </Box>

            <DeleteGroupBindingPersonDialog />
        </Box>
    );
};

export default GroupPersonsBindingToolBar;