import {createSvgIcon} from "@mui/material/utils";

export const RuFlag = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 6"
    >
        <path fill="#fff" d="M0 0h9v3H0z"/>
        <path fill="#d52b1e" d="M0 3h9v3H0z"/>
        <path fill="#0039a6" d="M0 2h9v2H0z"/>
    </svg>,
    'RuFlag'
);



