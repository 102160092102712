import React from 'react';
import {Box, IconButton, MenuItem, Pagination, Select, Tooltip, Typography} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import {useTranslation} from "react-i18next";

const PagingComponentTable = (props) => {
    const {t} = useTranslation()

    const {
        totalPages,
        page, setPageFn,
        limit, setLimitFn,
        refreshDataFn,
        additionCntRow  // Подаем дополнительные элементы для количества строк в списке
    } = props

    const setLimitL = (value) => {
        setLimitFn(value)
    }

    const setPageL = (num) => {
        setPageFn(num)
    }

    return (
        <Box sx={{
            mt: '5px', mb: '5px', mr: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: "center",
            flexWrap: 'wrap'
        }} size="small">
            <Box sx={{m: 1, pl: 1, display: 'flex', justifyContent: 'flex-start', alignItems: "center"}} size="small">
                <Typography id="select-page-count">{t('systemCommon.recordsPerPage')}</Typography>
                <Select size="small"
                        id="select-page-count"
                        value={limit}
                        onChange={(event) => setLimitL(event.target.value)}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    {
                        additionCntRow &&
                        additionCntRow.map((rowi, key) => (
                            <MenuItem key={key} value={rowi}>{rowi}</MenuItem>
                        ))
                    }
                </Select>
            </Box>
            {
                !!totalPages &&
                <Tooltip title={t('systemCommon.updateInfo')}>
                    <IconButton onClick={() => refreshDataFn()}>
                        <RefreshOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            }
            {
                !!totalPages &&
                <Pagination color="primary"
                            count={totalPages}
                            page={page}
                            onChange={(_, num) => setPageL(num)}
                />
            }

            {
                !totalPages && <Box width={150}></Box>
            }
        </Box>
    );
};

export default PagingComponentTable;