import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Typography, useMediaQuery} from "@mui/material";

import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import TerminalsFilterDrawer from "./TerminalsFilterDrawer";
import {changeFilterPanelOpenStatus} from "../../../store/handbook/terminalsPageSlice";
import {useTranslation} from "react-i18next";

const TerminalsToolBar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const isLoading = useSelector(state => state.terminalsPage.isLoading)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.terminals.pageTitle')}
                </Typography>
                <Box sx={{display: 'flex', m: 1}}>
                    <Button size={"small"}
                        startIcon={(<ManageSearchOutlinedIcon />)}
                            onClick={() => {filterOpenBtnClickHandler()}}>
                        {t('filter.filterCaption')}
                    </Button>
                </Box>
            </Box>

            {/*фильтр*/}
            <TerminalsFilterDrawer />
        </Box>

    );
};

export default TerminalsToolBar;