import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {
    Alert,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {checkEmptyString} from "../../../../../util/UtilFunction";
import instanceAxiosApi from "../../../../../service/axiosApi";
import {
    setNeedRefreshData,
    setOpenDeleteBindingDlg
} from "../../../../../store/administration/groupAccessPageSlice";
import {mappingAccessLevel} from "../../../../../util/BackEndUiMapperValueUtils";


const DeleteGroupAccessDialog = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    // Индикатор выполнения запроса
    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')

    // Из стора
    const open = useSelector(store => store.groupAccessPage.openDeleteBindingDlg)
    const rowId = useSelector(store => store.groupAccessPage.groupId)
    const rowName = useSelector(store => store.groupAccessPage.groupName)
    const rowsForDelete = useSelector(store => store.groupAccessPage.rowsForDelete)
    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)

    const isEmptyRowId = () => {
        return checkEmptyString(rowId + '')
    }

    const handleClose = () => {
        clearError()
        dispatch(setOpenDeleteBindingDlg({openDeleteBindingDlg:false}))
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    // Идем на сервер
    const fetchApiDelete = async (params) => {
        if ('BANK' === accessLevel) {
            return await instanceAxiosApi.put(`/ui/v1/group_access/${rowId}/banks/access`, JSON.stringify(params))
        } else if ('ORG'  === accessLevel) {
            return await instanceAxiosApi.put(`/ui/v1/group_access/${rowId}/organizations/access`, JSON.stringify(params))
        } else if ('MERCHANT' === accessLevel) {
            return await instanceAxiosApi.put(`/ui/v1/group_access/${rowId}/merchants/access`, JSON.stringify(params))
        } else {
            throw Error("unknown access level")
        }


    }

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            if (isEmptyRowId()) throw new Error(t('pages.groupAccess.errorGroupIdNotSet'))

            let params = [...rowsForDelete]

            // идем на сервер
            await fetchApiDelete(params)

            handleClose()
            dispatch(setNeedRefreshData(true))

            // Сообщение
            enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } catch (e) {
            setErrorSubmitMsg(e.message)

            setIsSubmitError(true)

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteErrorCaption'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={'paper'}
            >
                <DialogTitle bgcolor={'warning.main'} id="scroll-dialog-title">
                    {t('systemCommon.confirmationRequiredCaption')}
                </DialogTitle>

                {
                    isProcessed &&
                    <Box sx={{}}>
                        <LinearProgress/>
                    </Box>
                }
                <DialogContent dividers={false}>
                    {
                        isSubmitError &&
                        <DialogContentText tabIndex={-1} sx={{mt:'15px'}}>
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        </DialogContentText>
                    }
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.groupUserCaption')}
                        </Typography>

                        <Typography component="span" variant="h6" color='primary.main'>
                            {rowName}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.accessLevelCaption2')}
                        </Typography>
                        <Typography component="span" variant="h6" color='primary.main'>
                            {mappingAccessLevel(t, accessLevel)}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupAccess.confirmDeleteAccessForObjects')}
                        </Typography>
                    </DialogContentText>
                    {
                        [...rowsForDelete]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((item, key) => (
                            <DialogContentText tabIndex={-1} key={item.id}>
                                <Typography component="span" variant="h6" color='primary.main' key={item.id}>
                                    {`${key + 1}. ${item.name}`}
                                </Typography>
                            </DialogContentText>
                        ))
                    }

                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                        {t('systemCommon.dlgCancelBtnCaption')}
                    </Button>
                    <Button disabled={isProcessed} /*collor={"submit"}*/ onClick={handleSubmit}>
                        {t('systemCommon.dlgYesBtnCaption')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteGroupAccessDialog;