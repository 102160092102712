import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../hook/useAuth";
import {Box, Button, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {changeFilterPanelOpenStatus, setNeedRefreshData} from "../../../store/administration/personsPageSlice";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import {RoleEnum} from "../../../types/RoleEnum";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import {useNavigate} from "react-router-dom";
import PersonsFilterDrawer from "./PersonsFilterDrawer";
import {useTranslation} from "react-i18next";
import {fetchRolesSystem, initNewUser} from "../../../store/administration/personCardPageSlice";


const PersonsToolBar = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {checkExistsRoles} = useAuth()
    const navigate = useNavigate()

    const isLoading = useSelector(state => state.personsPage.isLoading)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const refreshListRows = () => {
        dispatch(setNeedRefreshData({needRefreshData: true}))
    }

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }

    const addNewUserHandler = async () => {
        await dispatch(initNewUser())
        await dispatch(fetchRolesSystem())
        navigate("/persons/card")
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.persons.pageTitle')}
                </Typography>
                <Box sx={{display: 'flex', m: 1}}>
                    {/*На большом экране подсказка не нужна*/}
                    { checkExistsRoles(RoleEnum.ADMINISTRATOR) &&
                        <Tooltip title={lgUp ? "" : t('pages.persons.addUserBtnCaption')}>
                            <Button
                                size={"small"}
                                startIcon={<PlaylistAddOutlinedIcon/>}
                                color="primary"
                                // variant="contained"
                                onClick={addNewUserHandler}
                                disabled={isLoading}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                {lgUp ? t('pages.persons.addUserBtnCaption') : ''}
                            </Button>
                        </Tooltip>
                    }

                    <Button size={"small"}
                            startIcon={(<ManageSearchOutlinedIcon/>)}
                            disabled={isLoading}
                            onClick={() => {
                                filterOpenBtnClickHandler()
                            }}>
                        {t('filter.filterCaption')}
                    </Button>
                </Box>
            </Box>


            {/*Фильтр на боковой панели*/}
            <PersonsFilterDrawer />
        </Box>
    );
};

export default PersonsToolBar;