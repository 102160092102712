import React, {useEffect} from 'react';
import {Alert, Box, Button, Container, Paper, Typography} from "@mui/material";
import {theme} from "../../theme";
import {RouteEnum} from "../../types/RouteEnum";
import {useNavigate} from "react-router-dom";
import ChangePasswordWithOldPwd from "../../components/auth/ChangePasswordWithOldPwd";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSelector} from "react-redux";
import instanceAxiosApi from "../../service/axiosApi";

const PasswordMandatoryChangePage = (props) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const userLogin = useSelector(state => state.passwordChangePage.login)

    useEffect(() => {
        document.title = 'Password Mandatory Change | UniPORT E-slip';
    }, []);

    const fetchApi = async (json) => {
        return await instanceAxiosApi.put('/auth/password/mandatory_change', JSON.stringify(json));
    }

    const changePasswordFn = async (oldPassword, newPassword, passwordConfirm) => {
        let json = {
            "login": userLogin,
            "oldPassword":oldPassword,
            "newPassword": newPassword,
            "passwordConfirm": passwordConfirm
        }

        await fetchApi(json)

        navigate(RouteEnum.AUTH, {replace: true}) // true - По кнопке НАЗАД не можем вернуться на страницу логина
    }

    return (
        <Paper elevation={16}
               sx={{
                   // maxWidth: '30rem',
                   width: '98%',
                   // Когда привысим размеры маленького экрана, размер становится фиксированным
                   [theme.breakpoints.up('sm')]: {
                       width: '30rem',
                   },
                   pt: '30px', pb: '30px', height: 'auto'

               }}>
            <Box sx={{
                pl: '20px',
                pr: '20px',
            }}
            >
                <Button href="#/auth/login"
                        startIcon={<ArrowBackIcon fontSize="small" />}
                >
                    {t('pages.passwordComponent.loginAnotherAccountCaption')}
                </Button>

                <Typography
                    color="textPrimary"
                    variant="h4"
                >
                    {t('pages.passwordComponent.userChangePasswordCaption')}
                </Typography>

                <Alert severity="warning">{t('pages.passwordComponent.userNeedChangeCaption')}</Alert>

                <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                    {t('pages.restorePassword.loginInputLabel')}: {userLogin}
                </Typography>

                <ChangePasswordWithOldPwd changePasswordFn={changePasswordFn}/>
            </Box>
        </Paper>
    );
};

export default PasswordMandatoryChangePage;