import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import PersonGroupsToolBar from "./PersonGroupsToolBar";
import PersonGroupsList from "./PersonGroupsList";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {useDispatch} from "react-redux";

const PersonGroupsPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Person Group | UniPORT E-slip';

        dispatch(setActiveSection({href: '/group_persons'}))

        const items = [
            {href:"/group_persons", caption:'pages.groupUsers.pageCaption', active: false},
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <PersonGroupsToolBar/>
                <PersonGroupsList/>
            </Container>
        </Box>
    );
};

export default PersonGroupsPage;