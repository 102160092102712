import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {
    Alert,
    Box, Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Typography
} from "@mui/material";
import {checkEmptyString} from "../../../util/UtilFunction";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

const DeleteOrganizationDialog = (props) => {
    const {t} = useTranslation()

    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Индикатор выполнения запроса
    const [isProcessed, setIsProcessed] = useState(false)
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, oldOrganizationName, organizationId, refreshListRows} = props

    const isEmptyOrganizationId = () => {
        return checkEmptyString(organizationId + '')
    }

    const handleClose = () => {
        clearError()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    // Идем на сервер
    const fetchApiDelete = async () => {
        return await instanceAxiosApi.delete(`/ui/v1/organizations/${organizationId}`)
    }

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            if (isEmptyOrganizationId()) throw new Error("Не задан идентификатор организации для удаления")

            // идем на сервер
            await fetchApiDelete()

            //throw new Error("При сохранении произошла ошибка")
            handleClose()

            refreshListRows()

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } catch (e) {
            let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.rowDeleteErrorCaption')
            setErrorSubmitMsg(clientMessage)
            setIsSubmitError(true)

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteErrorCaption'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <Box sx={{}}>
                    <DialogTitle bgcolor={'warning.main'}>
                        {t('systemCommon.confirmationRequiredCaption')}
                    </DialogTitle>
                    {
                        isProcessed &&
                        <Box sx={{}}>
                            <LinearProgress/>
                        </Box>
                    }
                    <DialogContent>
                        <DialogContentText>
                            <Typography sx={{mt: '15px'}}>
                                {t('pages.organizations.deleteOrgQuestion')}
                            </Typography>

                            <Typography variant={"h6"} color={'primary.main'}>
                                {oldOrganizationName}
                            </Typography>
                        </DialogContentText>

                        {
                            isSubmitError &&
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        }
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                            {t('systemCommon.dlgCancelBtnCaption')}
                        </Button>
                        <Button disabled={isProcessed} /*collor={"submit"}*/ onClick={handleSubmit}>
                            {t('systemCommon.dlgYesBtnCaption')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default DeleteOrganizationDialog;