import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid, LinearProgress,
    TextField, Typography,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {mappingRoleName} from "../../../util/BackEndUiMapperValueUtils";
import {useAuth} from "../../../hook/useAuth";
import {
    fetchSaveCardInfo,
    setOpenChangePasswordDlg,
    updateFormValue
} from "../../../store/administration/profilePageSlice";
import PersonProfileChangePasswordDialog from "./PersonProfileChangePasswordDialog";


const PersonProfileDetail = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {userAuth, reloadUserInfo} = useAuth()

    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const [rolesUser, setRolesUser] = useState([])
    const {roles, avatarChange, userId, login, status, error} = useSelector(state => state.profilePage)

    const firstName = useSelector(state => state.profilePage.firstName)
    const patronymic = useSelector(state => state.profilePage.patronymic)
    const lastName = useSelector(state => state.profilePage.lastName)
    const phone = useSelector(state => state.profilePage.phone)
    const email = useSelector(state => state.profilePage.email)


    useEffect(() => {
        if (roles && roles.length) {
            setRolesUser(roles.map(i => i.value))
        } else {
            setRolesUser([])
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            login: login,
            firstName: firstName,
            patronymic: patronymic,
            lastName: lastName,
            phone: phone,
            email: email
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            login: Yup
                .string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterLoginVC')),
            firstName: Yup.string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterFirstNameVC')),
            patronymic: Yup.string()
                .nullable()
                .max(50, t('pages.profilePage.notExceed50characters')),
            lastName: Yup
                .string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterLastNameVC')),
            email: Yup
                .string()
                .nullable()
                .email(t('pages.profilePage.validEmailVC'))
                .max(255, t('pages.profilePage.notExceed255characters'))
                .required(t('pages.profilePage.enterEmailVC'))
            ,
        }),

        onSubmit: async (values, formikHelpers) => {

            if (!formik.dirty && !avatarChange) {
                showSnackMsg('warning', "systemCommon.dataHasNotChanged")
                return
            }

            try {
                const formicCurrentData = {
                    // id: userId,
                    avatar: null,
                    firstName: values.firstName,
                    patronymic: values.patronymic,
                    lastName: values.lastName,
                    phone: values.phone,
                    email: values.email
                }
                await dispatch(updateFormValue(formicCurrentData))

                dispatch(fetchSaveCardInfo({
                        userAuth:userAuth,
                        reloadUserInfo:reloadUserInfo,
                        showSnackMsg:showSnackMsg
                    })
                )
            } catch (e) {
                formikHelpers.setSubmitting(false)
            }
        }
    });


    const handleChangePassword = () => {
        dispatch(setOpenChangePasswordDlg(true))
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
                    variant: type,
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
    }

    return (
        <>
            <form noValidate
                  onSubmit={formik.handleSubmit}
            >
                <Card>
                    <CardHeader
                        subheader={t('pages.profilePage.profileCardSubHeader')}
                        title={t('pages.profilePage.profileCardHeader')}
                    />
                    <Divider/>
                    {status === 'loading' && <LinearProgress/>}
                    {
                        status === 'resolved' &&
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                            >
                                {
                                    error &&
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <Alert severity={"error"}>{t(error)}</Alert>
                                    </Grid>
                                }

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                        fullWidth
                                        label={t('pages.profilePage.lastNameLabel')}
                                        name="lastName"
                                        variant="outlined"
                                        required={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                        fullWidth
                                        label={t('pages.profilePage.firstNameLabel')}
                                        name="firstName"
                                        variant="outlined"
                                        required={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.patronymic && formik.errors.patronymic)}
                                        helperText={formik.touched.patronymic && formik.errors.patronymic}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.patronymic}
                                        fullWidth
                                        label={t('pages.profilePage.patronymicLabel')}
                                        name="patronymic"
                                        variant="outlined"
                                        required={false}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Box></Box>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.email && formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        fullWidth
                                        label={t('pages.profilePage.emailLabel')}
                                        name="email"
                                        variant="outlined"
                                        required={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                                        helperText={formik.touched.phone && formik.errors.phone}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.phone}
                                        fullWidth
                                        label={t('pages.profilePage.phoneLabel')}
                                        name="phone"
                                        type="text"
                                        variant="outlined"
                                        required={false}
                                    />
                                </Grid>

                                <Grid item
                                      md={12}
                                      xs={12}
                                >
                                    <Typography variant="h6">
                                        {t('pages.profilePage.rolesLabel')}
                                    </Typography>
                                    <Typography component="span" variant="h6" color='primary.main'>
                                        {rolesUser.map(r => mappingRoleName(t, r)).join(', ')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    }
                    <Divider/>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            sx={{mr:'0.5rem'}}
                            color="primary"
                            variant="outlined"
                            type={"button"}
                            onClick={handleChangePassword}
                            disabled={formik.isSubmitting}
                        >
                            {t('pages.persons.profileChangePasswordBtnCaption')}
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            type={"submit"}
                            disabled={formik.isSubmitting}
                        >
                            {t('pages.persons.profileSaveBtnCaption')}
                        </Button>
                    </Box>
                </Card>
            </form>

            <PersonProfileChangePasswordDialog />
        </>
    );
};

export default PersonProfileDetail;