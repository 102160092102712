import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Grid, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography,
} from "@mui/material";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";
import {mappingRoleName} from "../../../util/BackEndUiMapperValueUtils";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

/**
 * Диалог для редактирования ролей пользователя
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PersonsSetRoleDialog = (props) => {
    const {t} = useTranslation()
    const [isProcessed, setIsProcessed] = useState(false)
    const [isInitError, setIsInitError] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, personNameForAction, personIdForAction, refreshListRows} = props

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    /* Если не вызвать, то подставится прошлые значения */
    useEffect(() => {
        if (open) {
            loadData()
        }
    }, [open])

    const fetchApi = async () => {
        return await instanceAxiosApi.get(`/ui/v1/users/${personIdForAction}/roles`);
    }

    const fetchApiSaveRoles = async (params) => {
        return await instanceAxiosApi.put(`/ui/v1/users/${personIdForAction}/roles`, JSON.stringify(params));
    }

    const loadData = async () => {
        setIsProcessed(true)
        setIsInitError(false)
        try {
            let response = await fetchApi()

            let dataJson = response?.data || {systemRoles: [], userRoles: []}

            setLeft(dataJson.systemRoles.map((i) => ({key: i.key, value:mappingRoleName(t, i.key)})))
            setRight(dataJson.userRoles.map((j) => ({key: j.key, value:mappingRoleName(t, j.key)})))

        } catch (e) {
            // Ошибки могут быть: Нет доступа и все остальные
            let errorStatus = e?.response?.status || 400
            let errorMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')

            if (errorStatus === 403) {
                enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            }

            setIsInitError(true)
            // Скидываем данные в списках
            setLeft([])
            setRight([])
        } finally {
            setIsProcessed(false)
        }
    }



    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.key}-label`;

                    return (
                        <ListItem
                            key={value.key}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.value} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    const handleClose = async () => {
        setLeft([])
        setRight([])
        setOpen(false);
    };

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            // Нужны только ключи
            let params = right.map(r => r.key)

            await fetchApiSaveRoles(params)

            enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })

            setOpen(false);
        } catch (e) {
            // Ошибки могут быть: Нет доступа и все остальные
            let errorStatus = e?.response?.status || 400

            if (errorStatus === 403) {
                enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            } else {
                enqueueSnackbar(t('systemCommon.errorChangingInformationMsg'), {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            }

            setIsSubmitError(true)
            setErrorSubmitMsg(t('pages.persons.failedUpdateUserRolesCaption'))
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <DialogTitle>
                    {t('pages.persons.editUserAccessLevelCaption')} {personNameForAction}
                </DialogTitle>
                {
                    isProcessed &&
                    <Box sx={{}}>
                        <LinearProgress/>
                    </Box>
                }
                <Divider/>
                <DialogContent>
                    { isInitError &&
                        <Alert severity="error">{t('systemCommon.errorRequestInfoCaption')}</Alert>
                    }
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Box>
                                <Typography variant={"h6"} color={'primary.main'}>
                                    {t('pages.persons.rolesForAddCaption')}
                                </Typography>
                                {customList(left)}
                            </Box>
                        </Grid>

                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Box>
                                <Typography variant={"h6"} color={'primary.main'}>
                                    {t('pages.persons.rolesAddedCaption')}
                                </Typography>
                                {customList(right)}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                        {t('systemCommon.dlgCloseBtnCaption')}
                    </Button>
                    <Button disabled={isProcessed || isInitError} /*collor={"submit"}*/ onClick={handleSubmit}>
                        {t('systemCommon.dlgApplyBtnCaption')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PersonsSetRoleDialog;