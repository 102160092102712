import React, {useEffect, useState} from 'react';
import {
    Alert, AlertTitle,
    Box,
    Container, Paper, Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import OrganizationBindingToolBar from "./OrganizationBindingToolBar";
import OrganizationBindingWorkspace from "./OrganizationBindingWorkspace";
import instanceAxiosApi from "../../../../service/axiosApi";
import {initParamsOnPageStart, setIsLoading} from "../../../../store/handbook/bankOrganizationsBindingPageSlice";
import GoBanksPage from "../../../../components/GoBanksPage";
import {setActiveSection, setBreadcrumbs} from "../../../../store/baseLayoutSlice";



const EditOrganizationsBindingPage = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {bankId} = useParams();

    const [isInit, setIsInit] = useState(false)
    const [isInitError, setIsInitError] = useState(false)

    useEffect(() => {
        document.title = 'Banks Organization binding | UniPORT E-slip';

        dispatch(setActiveSection({href: '/banks'}))

        const items = [
            {href:"/banks", caption:'pages.banks.toolbar.pageCaption', active: true},
            {href:"/organizations_binding", caption:'pages.banks.orgBinding.pageHeaderCaption', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        loadData()
    }, []);

    const fetchApi = async () => {
        return await instanceAxiosApi.get(`/ui/v1/banks/${bankId}/info`)
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading:true}))
        try {
            const response = await fetchApi()
            let dataJson = response?.data || {id:null, name:null}

            // Очищаем стор от прошлых следов, и проставляем новые данные
            dispatch(initParamsOnPageStart({
                rowId: dataJson.id,
                rowName: dataJson.name
            }))

            setIsInit(true)
            setIsInitError(false)
        } catch (e) {
            setIsInitError(true)
            // Очищаем стор от прошлых следов
            dispatch(initParamsOnPageStart({
                rowId: null,
                rowName: null
            }))
        } finally {
            dispatch(setIsLoading({isLoading:false}))
        }
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <OrganizationBindingToolBar/>
                <Box sx={{mt: 3}}>
                    {
                        isInitError &&
                        <Paper>
                            <Alert severity={"error"}>
                                <AlertTitle>{t('systemCommon.initParamsErrorTitle')}</AlertTitle>
                                <Box>
                                    <Typography >
                                        {t('systemCommon.initParamsErrorMsg')}
                                    </Typography>
                                    <GoBanksPage sx={{mt:'20px'}} />
                                </Box>
                            </Alert>
                        </Paper>
                    }
                    {
                        isInit && !isInitError &&
                        <OrganizationBindingWorkspace />
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default EditOrganizationsBindingPage;