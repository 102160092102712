import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, MenuItem, MenuList, Popover, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hook/useAuth";
import {useTranslation} from "react-i18next";

export const AccountPopover = (props) => {
    const {t} = useTranslation()
    const {anchorEl, onClose, open, ...other} = props
    const navigate = useNavigate()
    const {signout, userAuth} = useAuth()
    const [userInfoCaption, setUserInfoCaption] = useState()

    useEffect(() => {
        setUserInfoCaption(
            userAuth.login + "( "
            + userAuth?.lName + " " + userAuth?.fName + " " + userAuth?.mName
            + " )"
        )

    }, [userAuth])

    const handleSignOut = () => {
        signout(() => navigate("/auth/login", {replace: true}))
    };



    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {width: '300px'}
            }}
            {...other}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Typography variant="overline">
                    {t('topMenu.userMenu.credentialsCaption')}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {userInfoCaption}
                </Typography>
            </Box>
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                        '&:first-of-type': {
                            borderTopColor: 'divider',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px'
                        },
                        padding: '12px 16px'
                    }
                }}
            >
                <MenuItem onClick={() => {
                    onClose()
                    navigate("/profile")
                }}>
                    {t('topMenu.userMenu.profileCaption')}
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    {t('topMenu.userMenu.exitCaption')}
                </MenuItem>
            </MenuList>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired
};
