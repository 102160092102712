import {useEffect} from "react";
import GoMainPage from "../components/GoMainPage";
import {Box, Container, Typography} from "@mui/material";
import image404 from '../assets/img/error-404.png';
import {useTranslation} from "react-i18next";

const NotFoundPage = () => {
    const {t} = useTranslation()

    useEffect(() => {
        document.title = 'Not found | UniPORT E-slip';
    }, []);

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <img
                        alt="Under development"
                        src={image404}
                        style={{
                            marginTop: 50,
                            marginBottom: 50,
                            display: 'inline-block',
                            maxWidth: '100%',
                            width: 560
                        }}
                    />
                </Box>
            <Typography align="center"
                        color="textPrimary"
                        variant="h4"
                        sx={{mb:'24px'}}
            >
                {t('404page.cardHeaderText')}
            </Typography>
            <Typography  align="center"
                         color="textPrimary"
                         variant="subtitle2"
                         sx={{mb:'24px'}}
            >
                {t('404page.cardBodyText')}
            </Typography>
            <GoMainPage />
            </Box>
        </Container>
    );
};

export default NotFoundPage;