import {configureStore} from '@reduxjs/toolkit';
// home
import baseLayoutReducer from './baseLayoutSlice'
// auth
import passwordRecoveryPageReducer from './auth/passwordRecoveryPageSlice'
import passwordChangePageReducer from './auth/passwordChangePageSlice'
// Общее
import eslipsPageReducer from './common/eslipsPageSlice'
import eslipsArchivePageReducer from './common/eslipsArchivePageSlice'
import exportManagerPageReducer from './common/exportManagerPageSlice'
import eslipsArchiveJobPageReducers from './common/eslipsArchiveJobPageSlice'
// Справочники
import banksPageReducer from './handbook/banksPageSlice'
import organizationsPageReducer from './handbook/organizationsPageSlice'
import merchantsPageReducer from './handbook/merchantsPageSlice'
import terminalsPageReducer from './handbook/terminalsPageSlice'
import bankOrganizationsBindingPageReducer from './handbook/bankOrganizationsBindingPageSlice'
// Администрирование
import personsPageReducer from './administration/personsPageSlice'
import systemParamsPageReducer from './administration/systemParamsPageSlice'
import groupAccessPageReducer from './administration/groupAccessPageSlice'
import personGroupsPageReducer from './administration/personGroupsPageSlice'
import groupPersonsBindingPageReducer from './administration/groupPersonsBindingPageSlice'
import profilePageReducer from "./administration/profilePageSlice"
import personCardPageReducer from "./administration/personCardPageSlice"

export default configureStore({
    reducer: {
        baseLayout: baseLayoutReducer,
        passwordRecoveryPage: passwordRecoveryPageReducer,
        passwordChangePage: passwordChangePageReducer,

        // ******* Для страниц ****** //
        // Общее
        eslipsPage: eslipsPageReducer,
        eslipsArchivePage: eslipsArchivePageReducer,
        exportManagerPage: exportManagerPageReducer,
        eslipsArchiveJobPage: eslipsArchiveJobPageReducers,
        // Справочники
        banksPage: banksPageReducer,
        bankOrganizationsBindingPage: bankOrganizationsBindingPageReducer,
        organizationsPage: organizationsPageReducer,
        merchantsPage: merchantsPageReducer,
        terminalsPage: terminalsPageReducer,
        // Администрирование
        personsPage: personsPageReducer,
        systemParamsPage: systemParamsPageReducer,
        groupAccessPage: groupAccessPageReducer,
        personGroupsPage: personGroupsPageReducer,
        groupPersonsBindingPage: groupPersonsBindingPageReducer,
        profilePage: profilePageReducer,
        personCardPage: personCardPageReducer
    },
})