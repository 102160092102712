import React, {useState} from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AddEditGroupDialog from "./AddEditGroupDialog";
import DeleteGroupDialog from "./DeleteGroupDialog";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {
    setNeedRefreshData
} from "../../../store/administration/personGroupsPageSlice";
import {useTranslation} from "react-i18next";



const PersonGroupCard = ({group, ...rest}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [open, setOpen] = useState(false)
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false)

    const editGroupHandler = () => {
        setOpen(true)
    }

    const usersBindingHandler = () => {
        navigate(`/group_persons/${group.id}/users_binding`, {replace: false})
    }

    const refreshListRows = () => {
        dispatch(setNeedRefreshData({needRefreshData:true}))
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
            {...rest}
        >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 3
                    }}
                >
                    <GroupsOutlinedIcon />
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                >
                    {group.name}
                </Typography>

                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="body1"
                        sx={{
                            mr: 1
                        }}
                    >
                        {t('pages.groupUsers.userLinkCaption')}
                    </Typography>
                    <Typography
                        align="center"
                        color="secondary.main"
                        variant="body1"
                    >
                        {group.countUsers || 0}
                    </Typography>
                </Box>
            </CardContent>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="edit" onClick={editGroupHandler}>
                            <EditOutlinedIcon color='info'/>
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="addOrganization" onClick={usersBindingHandler}>
                            <PersonAddAltOutlinedIcon color={"success"}/>
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="delete" onClick={() => setOpenDeleteDlg(true)}>
                            <ClearOutlinedIcon color={"error"}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>

            {/*Диалог для исправления наименования*/}
            <AddEditGroupDialog open={open} setOpen={setOpen} oldRowName={group.name}
                               rowId={group.id} refreshListRows={refreshListRows}/>

            {/*Диалог удаления банка*/}
            <DeleteGroupDialog open={openDeleteDlg} setOpen={setOpenDeleteDlg}
                              oldRowName={group.name}
                              rowId={group.id} refreshListRows={refreshListRows}/>

        </Card>
    );
};

export default PersonGroupCard;