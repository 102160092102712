import React, {useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addApiBaseUrl, addVersion} from "../store/baseLayoutSlice";
import {Avatar, Box, Button, Container, Toolbar, Tooltip, Typography} from "@mui/material";
import instanceAxiosPortal from "../service/axiosPortal";
import LoadingAppSetting from "./LoadingAppSetting";
import ErrorLoadingAppSetting from "./ErrorLoadingAppSetting";
import {useTranslation} from "react-i18next";
import {RuFlag} from "../icons/flag/RuFlag";
import {EnFlag} from "../icons/flag/EnFlag";
import {KzFlag} from "../icons/flag/KzFlag";
import IconButton from "@mui/material/IconButton";

const AuthLayout = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [isErrorLoading, setIsErrorLoading] = useState(false)
    const apiBaseUrl = useSelector(state => state.baseLayout.apiBaseUrl)
    const {t, i18n} = useTranslation()
    const version = useSelector(state => state.baseLayout.version)
    const year = new Date().getFullYear();

    // Для отладки
    // useEffect(() => {
    //     // Получение страницы с ошибками
    //     // setIsLoading(false)
    //     // setIsErrorLoading(true)
    //     // Получение индикатора загрузки параметров
    //     setIsLoading(true)
    //     setIsErrorLoading(false)
    // }, [])


    // Основная работа
    useEffect(() => {
        const getStartAppSetting = async () => {
            try {
                const data = await instanceAxiosPortal
                    .get("/ui/starter/params")
                    .then(res => {
                        //console.log(res)
                        dispatch(addApiBaseUrl({apiBaseUrl: res.data.apiBaseUrl}))
                        dispatch(addVersion({version: res.data.version}))
                    })

            } catch (e) {
                // console.log(e);
                setIsErrorLoading(true)
            }

            setIsLoading(false)
        }

        // Идем на сервер за настройкой
        getStartAppSetting()

        // Вызов настроек приложения
        if (apiBaseUrl) {
            setIsErrorLoading(false)
            setIsLoading(false)
            return
        }

        setIsErrorLoading(false)

    }, [])

    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            // minHeight: '100%',
            height: '100%',
        }}>
            <Box sx={{
                // backgroundColor: 'green',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: '20px',
                pt: '10px',
                pb: '10px',
                pl: '15px', pr: '15px'
            }}>
                <Typography variant={"h5"}>
                    UniPORT E-Slip
                </Typography>

                <Box>
                    <Tooltip title="Русский">
                        <IconButton onClick={() => i18n.changeLanguage('ru')}>
                            <RuFlag/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="English">
                        <IconButton onClick={() => i18n.changeLanguage('en')}>
                            <EnFlag/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Қазақша">
                        <IconButton onClick={() => i18n.changeLanguage('kz')}>
                            <KzFlag/>
                        </IconButton>
                    </Tooltip>
                </Box>


            </Box>
            <Box sx={{
                //backgroundColor:'red'
                minHeight: 'calc(100vh - 208px)',
                mt: '50px',
                mb: '30px',
                alignItems: 'flex-start',
                display: 'flex',
                justifyContent: 'center',
            }}>
                {
                    isErrorLoading && <ErrorLoadingAppSetting/>
                }
                {
                    isLoading && <LoadingAppSetting/>
                }
                {
                    !isErrorLoading && !isLoading &&
                    <Outlet/>
                }
            </Box>

            {
                !isErrorLoading && !isLoading &&

                <Box sx={{
                    //backgroundColor: 'yellow',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    pt: '15px', pb: '15px',
                    pl: '15px', pr: '15px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}>
                        <Typography variant="small">
                            {t('layouts.auth.versionCaption')}:
                        </Typography>
                        <Typography variant="small">
                            {version}
                        </Typography>
                    </Box>

                    <Box sx={{}}>
                        <Typography variant="small">
                            &copy; {year}
                        </Typography>
                    </Box>
                </Box>
            }
        </Container>
    )
};

export default AuthLayout;