import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import OrganizationsToolBar from "./OrganizationsToolBar";
import OrganizationsList from "./OrganizationsList";
import {useDispatch} from "react-redux";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";

const OrganizationsPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Organizations | UniPORT E-slip';

        dispatch(setActiveSection({href: '/organizations'}))

        const items = [
            {href:"/organizations", caption:'pages.organizations.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <OrganizationsToolBar />
                <Box sx={{mt: 3}}>
                    <OrganizationsList />
                </Box>
            </Container>
        </Box>
    );
};

export default OrganizationsPage;