import React, {useState} from 'react';
import {
    Box,
    Button,
    InputAdornment,
    SvgIcon,
    TextField, Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import {useDispatch, useSelector} from "react-redux";
import {setFilterModel, setNeedRefreshData} from "../../../store/handbook/banksPageSlice";
import IconButton from "@mui/material/IconButton";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AddEditBankDialog from "./AddEditBankDialog";
import {useTranslation} from "react-i18next";

const BanksToolBar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const filterBankName = useSelector(state => state.banksPage.filterModel?.bankName?.value || '')
    const isLoading = useSelector(state => state.banksPage.isLoading)
    // Инициируем тем значением фильтра, что в сторе содержится
    const [searchValue, setSearchValue] = useState(filterBankName || '')
    // Диалог добавления банка
    const [open, setOpen] = useState(false)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const handleClickClear = () => {
        setSearchValue("")
        dispatch(setFilterModel({filterModel: {"bankName": {}}}))
    }

    const pressEnter = (e) => {
        if (e.key === "Enter") {
            if (searchValue) {
                dispatch(setFilterModel({filterModel: {"bankName": {"name": "name", "operation": "like", "value": searchValue}}}))
            } else {
                dispatch(setFilterModel({filterModel: {"bankName": {}}}))
            }
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const refreshListRows = () => {
        dispatch(setNeedRefreshData({needRefreshData:true}))
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.banks.toolbar.pageCaption')}
                </Typography>
                <Box sx={{display: 'flex', m: 1}}>
                    <Box sx={{minWidth: '300px', maxWidth: '500px', mr:'10px'}}>
                        <TextField
                            type={"text"}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon/>
                                        </SvgIcon>
                                    </InputAdornment>
                                ),

                                endAdornment: searchValue && (
                                    <IconButton size={"small"}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickClear}
                                    >
                                        <CancelRoundedIcon fontSize={"small"}/>
                                    </IconButton>
                                )
                            }}
                            placeholder={t('pages.banks.toolbar.searchByNameCaption')}
                            variant="outlined"
                            size={"small"}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => pressEnter(e)}
                            disabled={isLoading}
                            inputRef={(input) => input?.focus()}
                        />

                    </Box>
                    {/*На большом экране подсказка не нужна*/}
                    <Tooltip title={lgUp ? "" : t('pages.banks.toolbar.addBankTooltipCaption')} >
                        <Button
                            startIcon={<PlaylistAddOutlinedIcon/>}
                            color="primary"
                            variant="contained"
                            onClick={() => handleClickOpen()}
                            disabled={isLoading}
                        >
                            {/*На маленьком экране подсказку не выводим*/}
                            { lgUp ? t('pages.banks.toolbar.addBankTooltipCaption') : ''}
                        </Button>
                    </Tooltip>

                </Box>
            </Box>

            <AddEditBankDialog open={open} setOpen={setOpen} oldBankName={''}
                               bankId={-1} refreshListRows={refreshListRows}/>
        </Box>

    );
};

export default BanksToolBar;