import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {
    Alert,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Typography
} from "@mui/material";
import {checkEmptyString} from "../../../util/UtilFunction";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

const BlockPersonsDialog = (props) => {
    const {t} = useTranslation()
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Индикатор выполнения запроса
    const [isProcessed, setIsProcessed] = useState(false)
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, personNameForAction, personIdForAction, refreshListRows, action} = props

    const isEmptyRowId = () => {
        return checkEmptyString(personIdForAction + '')
    }

    const handleClose = () => {
        clearError()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    // Идем на сервер
    const fetchApiDelete = async () => {
        if ("block" === action) {
            return await instanceAxiosApi.put(`/ui/v1/users/${personIdForAction}/block`)
        } else if ("unblock" === action) {
            return await instanceAxiosApi.put(`/ui/v1/users/${personIdForAction}/unblock`)
        } else {
            throw Error("Unknown action")
        }

    }

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            if (isEmptyRowId()) throw new Error(t('pages.persons.errorIdUserNotSet'))

            // идем на сервер
            await fetchApiDelete()

            //throw new Error("При сохранении произошла ошибка")
            handleClose()

            refreshListRows()

            // Сообщение
            enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } catch (e) {
            if (e?.response?.data?.errorCode === 'E_DOM_4') {
                setErrorSubmitMsg(t('pages.persons.errorOperationSysUserMsg'))
            } else {
                setErrorSubmitMsg(e?.response?.data?.uiMessage || e?.message)
            }

            setIsSubmitError(true)

            // Сообщение
            enqueueSnackbar(t('systemCommon.operationFailedMsg'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <Box sx={{}}>
                    <DialogTitle bgcolor={'warning.main'}>
                        {t('systemCommon.confirmationRequiredCaption')}
                    </DialogTitle>
                    {
                        isProcessed &&
                        <Box sx={{}}>
                            <LinearProgress/>
                        </Box>
                    }
                    <DialogContent>
                        <DialogContentText>
                            <Typography sx={{mt: '15px'}}>
                                {
                                    action === 'block' ?
                                        t('pages.persons.confirmBlockUserCaption')
                                    :
                                        t('pages.persons.confirmUnlockUserCaption')
                                }
                            </Typography>

                            <Typography variant={"h6"} color={'primary.main'}>
                                {personNameForAction}
                            </Typography>
                        </DialogContentText>

                        {
                            isSubmitError &&
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        }
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                            {t('systemCommon.dlgCancelBtnCaption')}
                        </Button>
                        <Button disabled={isProcessed} /*collor={"submit"}*/ onClick={handleSubmit}>
                            {t('systemCommon.dlgYesBtnCaption')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default BlockPersonsDialog;