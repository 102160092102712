import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import PagingComponentTable from "../../../components/dataGridCustom/PagingComponentTable";

import {
    setLimit,
    setPage,
    setNeedRefreshData,
    fetchEslipsRows,
    updateSelectedRows
} from "../../../store/common/eslipsPageSlice";
import {useSnackbar} from "notistack";
import ActionMenuButton from "../../../components/buttons/ActionMenuButton";
import {useNavigate} from "react-router-dom";
import {
    intersectionFullArray,
    intersectionPartArray
} from "../../../util/UtilFunction";
import EslipFilterNonSetComponent from "./EslipFilterNonSetComponent";
import {actionExportPdfHandler, actionExportPngHandler, actionViewHtmlHandler} from "../exportHandlers";


const EslipListResult = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate()

    const {
        columnSettings, dataRows,
        page, totalPages, limit,
        needRefreshData, filterParams, filterNormal, status
    } = useSelector(state => state.eslipsPage)

    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        loadData(true)
    }, [filterParams])

    useEffect(() => {
        loadData(false)
    }, [page, totalPages, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData(true).then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])

    useEffect(() => {
        dispatch(updateSelectedRows([...selectedIds]))
    }, [selectedIds])

    /* Определение видима колонка или нет */
    const defVisibleColumn = (name) => {
        let columni = columnSettings.find(r => r.key === name)

        return columni?.visible
    }
    /* Модель колонок таблицы */
    const headerModel = [
        {
            name: "numberSequence", dataField:null,
            caption: t('pages.eslips.numberSequence'),
            isVisible: true, align: "left", alignRow: "left",
            orderColumn: 1 // заготовка для изменения порядка колонок
        },
        {
            name: "currency.codeAlpha", dataField:'currency.codeAlpha',
            caption: t('pages.eslips.currency'),
            isVisible: defVisibleColumn("currency.codeAlpha"), align: "center", alignRow: "center",
            orderColumn: 2
        },
        {
            name: "pan", dataField:'pan',
            caption: t('pages.eslips.pan'),
            isVisible: defVisibleColumn("pan"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "formattedAmount", dataField: "formattedAmount",
            caption: t('pages.eslips.amount'),
            isVisible: defVisibleColumn("formattedAmount"), align: "center", alignRow: "right",
            //width: "200px"
        },
        {
            name: "serviceTimeFormatted", dataField: "serviceTimeFormatted",
            caption: t('pages.eslips.device_time'),
            isVisible: defVisibleColumn("serviceTimeFormatted"), align: "left", alignRow: "left",
            width: "130px"
        },
        {
            name: "terminal.terminalhostId", dataField: "terminal.terminalhostId",
            caption: t('pages.eslips.tid'),
            isVisible: defVisibleColumn("terminal.terminalhostId"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "terminal.merchants.name", dataField: "terminal.merchants.name",
            caption: t('pages.eslips.merchantname'),
            isVisible: defVisibleColumn("terminal.merchants.name"),
            align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "cardHolder", dataField: "cardHolder",
            caption: t('pages.eslips.cardholder'),
            isVisible: defVisibleColumn("cardHolder"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "origOperationName", dataField: "origOperationName",
            caption: t('pages.eslips.operName'),
            isVisible: defVisibleColumn("origOperationName"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "rrn", dataField: "rrn",
            caption: t('pages.eslips.rrn'),
            isVisible: defVisibleColumn("rrn"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "isEslipAttributeName", dataField: "isEslipAttributeName",
            caption: t('pages.eslips.isEslip'),
            isVisible: defVisibleColumn("isEslipAttributeName"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "email", dataField: "email",
            caption: t('pages.eslips.email'),
            isVisible: defVisibleColumn("email"), align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "phone", dataField: "phone",
            caption: t('pages.eslips.phone'),
            isVisible: defVisibleColumn("phone"), align: "left", alignRow: "left",
            width: "120px"
        },
        {
            name: "actions", dataField: null,
            caption: t('pages.eslips.actions'),
            isVisible: true, align: "center", alignRow: "center",
            width: "25px"
        }

    ]

    const loadData = async (clearSelected) => {
        if (clearSelected) {
            setSelectedIds([])
        }
        await dispatch(fetchEslipsRows({
                showSnackMsg:showSnackMsg
            })
        )
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedIds([...selectedIds, ...dataRows.map(row => row.id)])
        } else {
            let newArr = [...selectedIds]

            dataRows.map(row => newArr = [...newArr.filter(idi => idi !== row.id)])

            setSelectedIds(newArr)
        }
    }

    const headerCheckBoxIsChecked = () => {
        let a1 = dataRows.map(row => row.id)

        return intersectionFullArray(selectedIds, a1)
    }

    const indeterminateCheckBoxHeader = () => {
        let a1 = dataRows.map(row => row.id)

        return selectedIds.length > 0
        && intersectionPartArray(selectedIds, a1) === 0
    }

    const handleSelectOne = (event, id) => {
        // console.log(event)
        if (event.target.checked) {
            let newArr = [...selectedIds]
                newArr.push(id)
            setSelectedIds(newArr)
        } else {
            setSelectedIds(selectedIds.filter(element => element !== id))
        }
    }

    const setPageL = (num) => {
        dispatch(setPage(num))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit(limit))
    }

    const buildActionMenu = (rowi) => {
        let menuItems = []

        menuItems.push({
            "key": "viewSlip",
            "caption": t('pages.eslips.viewSlipActionCaption'),
            "fn": () => actionViewHtmlHandler(rowi, 0)
        })
        menuItems.push({
            "key": "exportPdf",
            "caption": t('pages.eslips.exportPdfSlipCaption'),
            "fn": () => actionExportPdfHandler(rowi, 0)
        })
        menuItems.push({
            "key": "exportPng",
            "caption": t('pages.eslips.exportPNGSlipCaption'),
            "fn": () => actionExportPngHandler(rowi, 0)
        })

        return <ActionMenuButton menuItems={menuItems}/>
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    /* С сервера могут прийти вложенные объекты, разбираем... */
    const getValueByColumnName = (rowi, columni) => {
        //"currency.codeAlpha"
        let arr = columni.name.split('.')

        let t = rowi;
        for (let i=0; i<arr.length; i++) {
            t = t[arr[i]]
        }

        return t || '';
    }

    if (!filterNormal || filterNormal === 'error') {
        return (
            <Card {...props}>
                <EslipFilterNonSetComponent />
            </Card>
        )
    }

    return (
        <Card {...props}>
            <PerfectScrollbar>
                <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                    <Table size="small">
                        <TableHead sx={{height: '60px'}}>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={headerCheckBoxIsChecked()}
                                        color="primary"
                                        indeterminate={
                                            indeterminateCheckBoxHeader()
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>

                                {
                                    headerModel.map((rowi, key) => (

                                        rowi.isVisible &&
                                        <TableCell align={rowi.align} key={key}
                                                   style={{minWidth: rowi.width}}>
                                            {rowi.caption}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>

                            <TableBody>
                                {dataRows.map((rowi, number) => (
                                    <TableRow
                                        hover
                                        key={rowi.id}
                                        selected={selectedIds.indexOf(rowi.id) !== -1}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedIds.indexOf(rowi.id) !== -1}
                                                onChange={(event) => handleSelectOne(event, rowi.id)}
                                                value="true"
                                            />
                                        </TableCell>

                                        <TableCell align={"center"}>
                                            {limit * (page - 1) + number + 1}
                                        </TableCell>

                                        {
                                            headerModel
                                                .filter(headeri1 => headeri1.dataField)
                                                .filter(headerj => headerj.isVisible)
                                                .map((columni, key) => (
                                                    <TableCell align={columni.alignRow} key={key}
                                                               style={{minWidth: columni.width}}
                                                    >
                                                        {getValueByColumnName(rowi, columni)}
                                                    </TableCell>
                                                ))
                                        }

                                        <TableCell align={"center"}>
                                            {buildActionMenu(rowi)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                    </Table>
                </Box>
            </PerfectScrollbar>

            {
                !totalPages && status === 'resolved' &&
                <DataNotFoundComponent refreshDataFn={loadData} />
            }

            <PagingComponentTable
                totalPages={totalPages}
                page={page} setPageFn={setPageL}
                limit={limit} setLimitFn={setLimitL}
                refreshDataFn={loadData}
                additionCntRow={[100]}/>
        </Card>
    );
};


export default EslipListResult;