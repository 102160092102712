import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import RemoveModeratorOutlinedIcon from '@mui/icons-material/RemoveModeratorOutlined';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useSnackbar} from "notistack";
import {
    initParamsEditCurrentObject,
    initParamsOnAddPageStart,
    returnAccessPageSelectGroup,
    setOpenDeleteBindingDlg
} from "../../../store/administration/groupAccessPageSlice";
import DeleteGroupAccessDialog from "./edit/add/DeleteGroupAccessDialog";
import {useTranslation} from "react-i18next";


const GroupAccessToolBar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const mode = useSelector(state => state.groupAccessPage.mode)
    const rowsForDelete = useSelector(store => store.groupAccessPage.rowsForDelete)

    const isLoading = useSelector(state => state.groupAccessPage.isLoading)
    const isAddingObject = useSelector(state => state.groupAccessPage.isAddingObject)
    const [isExistsRowForDelete, setIsExistsRowForDelete] = useState(false)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    useEffect(() => {
        setIsExistsRowForDelete(rowsForDelete.length > 0)
    }, [rowsForDelete])


    const handleClickBackGroupList = () => {
        dispatch(returnAccessPageSelectGroup())
    }

    const handleClickBackAddMode = () => {
        dispatch(initParamsEditCurrentObject())
    }

    const handleClickDelete = () => {
        if (rowsForDelete.length < 1) {
            enqueueSnackbar(t('systemCommon.selectEntriesToDeleteMsg'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })

            return
        }
        dispatch(setOpenDeleteBindingDlg({openDeleteBindingDlg:true}))
    }


    const handleClickNewBinding = () => {
        dispatch(initParamsOnAddPageStart())
    }



    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.groupAccess.pageCaption')}
                </Typography>
                <Box sx={{display: 'flex', m: 1}}>
                {
                    mode === 'EDITING' && !isAddingObject &&
                    <Box sx={{display: 'flex', m: 1}}>
                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip
                            title={lgUp ? "" : t('pages.groupAccess.deleteSelectedAccessCaption')}
                             disabled={isLoading || !isExistsRowForDelete}
                        >
                            <Button
                                startIcon={<RemoveModeratorOutlinedIcon />}
                                size="small"
                                // color="primary"
                                sx={{mr:'10px'}}
                                variant="contained"
                                color="error"
                                onClick={() => handleClickDelete()}
                                disabled={isLoading || !isExistsRowForDelete}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('pages.groupAccess.deleteSelectedAccessCaption') : ''}
                            </Button>
                        </Tooltip>

                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip title={lgUp ? "" : t('pages.groupAccess.addNewAccessCaption')} disabled={isLoading} >
                            <Button
                                startIcon={<AddModeratorOutlinedIcon />}
                                size="small"
                                color="primary"
                                variant="contained"
                                sx={{mr:'10px'}}
                                onClick={() => handleClickNewBinding()}
                                disabled={isLoading}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('pages.groupAccess.addNewAccessCaption') : ''}
                            </Button>
                        </Tooltip>
                    </Box>
                }

                {
                    isAddingObject &&
                    <Box sx={{display: 'flex', m: 1}}>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            // color="primary"
                            sx={{mr:'10px'}}
                            // variant="contained"
                            // color="error"
                            onClick={() => handleClickBackAddMode()}
                            // disabled={isLoading}
                        >
                            {t('pages.groupAccess.exitAddModeCaption')}
                        </Button>

                    </Box>
                }

                {
                    mode !== 'SELECT_GROUP' &&
                    <Box sx={{display: 'flex', m: 1}}>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            sx={{ml:'10px'}}
                            onClick={() => handleClickBackGroupList()}

                        >
                            {t('pages.groupAccess.toGroupSelection')}
                        </Button>
                    </Box>

                }
                </Box>
            </Box>

            <DeleteGroupAccessDialog />
        </Box>

    );
};

export default GroupAccessToolBar;