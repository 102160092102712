import React from 'react';
import {Avatar, Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import image from "../assets/img/load-settings.png"
import {useTranslation} from "react-i18next";
import {theme} from "../theme";

const LoadingAppSetting = () => {
    const {t} = useTranslation()

    return (
        <Paper elevation={16} sx={{
            textAlign: 'center',
            width: '98%',
            // Когда привысим размеры маленького экрана, размер становится фиксированным
            [theme.breakpoints.up('sm')]: {
                width: '40rem',
            },

            pt: '40px', pb: '30px', height: 'auto',

        }}>
            <Typography color="textPrimary"
                        variant="h6"
                        sx={{
                            mb: '15%',
                            // ml: '15%',
                            // mr: '15%'
                        }}
            >
                {t('layouts.auth.loadSystemParamsCaption')}
            </Typography>
            <Avatar
                alt="load settings"
                src={image}
                variant="square"
                sx={{width: 100, height: 100, m: 'auto'}}
            />
            <Box sx={{
                mt: '10%',
                mb: '15%',
                ml: '20%',
                mr: '20%',
                minWidth: '60%'
            }}>
                <LinearProgress
                    sx={{
                        height: 20,
                        borderRadius: 5
                    }}
                />
            </Box>
        </Paper>
    );
};

export default LoadingAppSetting;