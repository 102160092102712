import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import TerminalsToolBar from "./TerminalsToolBar";
import TerminalsList from "./TerminalsList";
import {useDispatch} from "react-redux";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";

const TerminalsPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Terminals | UniPORT E-slip';

        dispatch(setActiveSection({href: '/terminals'}))

        const items = [
            {href:"/terminals", caption:'pages.terminals.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <TerminalsToolBar/>
                <Box sx={{mt: 3}}>
                    <TerminalsList/>
                </Box>
            </Container>
        </Box>
    );
};

export default TerminalsPage;