import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import GroupAccessCurrentEdit from "./GroupAccessCurrentEdit";
import GroupAccessCurrentAdd from "./GroupAccessCurrentAdd";

const GaEditWorkspace = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // Удаляем текущие или добавляем новые
    const isAddingObject = useSelector(state => state.groupAccessPage.isAddingObject)

    return (
        <>
            {
                !isAddingObject &&
                <GroupAccessCurrentEdit />
            }

            {
                isAddingObject &&
                <GroupAccessCurrentAdd />
            }
        </>
    );
};

export default GaEditWorkspace;