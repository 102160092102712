import PropTypes from 'prop-types';
import {Button, ListItem} from '@mui/material';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setActiveSection, setOpenMainLeftMenu} from "../../store/baseLayoutSlice";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../hook/useAuth";

export const NavItem = (props) => {
        const {href, icon, title, availableForRoles, ...others} = props;
        const dispatch = useDispatch()
        const location = useLocation()
        const navigate = useNavigate()
        const {checkExistsRoles} = useAuth()
        const {t} = useTranslation()

        const activeSection = useSelector(state => state.baseLayout.activeSection)

        const [active, setActive] = useState(false)

        const [visibleItemMenu, setVisibleItemMenu] = useState(
            availableForRoles
                ? availableForRoles && checkExistsRoles(availableForRoles)
                : true
        )


        useEffect(() => {
            if (!href) {
                setActive(false);
                return
            }

            // Перешли в раздел или в подраздел, получили 403
            // и потом обновили страницу - pathname #/users/12/info/403 или pathname #/403
            // if (location.pathname.endsWith("403")) {
            //     if (activeSection && href == activeSection) {
            //         setActive(true)
            //     } else {
            //         setActive(false)
            //     }
            //
            //     return;
            // }

            if (!activeSection && (location.pathname === href)) {
                setActive(true)
                dispatch(setActiveSection({href: href}))
                return;
            }

            // Может зашли в подраздел
            if (!activeSection && location.pathname.startsWith(href)) {
                setActive(true)
                dispatch(setActiveSection({href: href}))
                return;
            }

            if (!activeSection && (href === '/home' || href === '/')) {
                setActive(true)
                dispatch(setActiveSection({href: '/home'}))
                return;
            }

            if (href == activeSection) {
                setActive(true)
                return;
            }

            setActive(false)

        }, [activeSection])


        return (
            <>
                {
                    !visibleItemMenu
                        ? null
                        :
                        <ListItem
                            disableGutters
                            sx={{
                                display: 'flex',
                                mb: 0.5,
                                py: 0,
                                px: 2
                            }}
                            {...others}
                        >
                            <Button fullWidth={true}
                                    startIcon={icon}
                                    onClick={() => {
                                        dispatch(setActiveSection({href: href}))
                                        dispatch(setOpenMainLeftMenu(false))
                                        navigate(href, {replace: false})
                                    }}
                                    sx={{
                                        backgroundColor: active && 'rgba(148, 166, 205, 0.8)',
                                        borderRadius: 1,
                                        color: 'neutral.300', // active ? 'secondary.main' : 'neutral.300',
                                        fontWeight: active && 'fontWeightBold',
                                        justifyContent: 'flex-start',
                                        px: 3,
                                        textAlign: 'left',
                                        textTransform: 'none',
                                        width: '100%',
                                        '& .MuiButton-startIcon': {
                                            color: 'neutral.300' //active ? 'secondary.main' : 'neutral.400'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(255,255,255, 0.08)'
                                        }
                                    }}
                            >
                                {t(title)}
                            </Button>
                        </ListItem>
                }
            </>
        );
    }
;

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
};
