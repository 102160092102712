import {createSlice} from "@reduxjs/toolkit";

const personGroupsPageSlice = createSlice({
    name: 'personGroupsPage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        isLoading: false,
        filterModel: {"groupName": {}},
        sortModel: {"groupName": {}},
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 8
    },

    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        setIsLoading(state, action) {
            state.isLoading = action.payload.isLoading
        },

        setFilterModel(state, action) {
            state.filterModel = {...state.filterMode, ...action.payload.filterModel}
        },

        setSortModel(state, action) {
            state.sortModel = action.payload.sortModel
        },

        setPage(state, action) {
            state.page = action.payload.page
        },

        setTotalRows(state, action) {
            state.totalRows = action.payload.totalRows
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload.totalPages
        },

        setLimit(state, action) {
            state.limit = action.payload.limit
        }
    }
})

export const {
    setNeedRefreshData,
    setIsLoading,
    setFilterModel,
    setSortModel,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit
} = personGroupsPageSlice.actions

export default personGroupsPageSlice.reducer