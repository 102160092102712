import React, {useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {NavItem} from "./nav-item";
import {useTranslation} from "react-i18next";
import {RoleEnum} from "../../types/RoleEnum";


const SideBarMenuItems = (props) => {
    const {t} = useTranslation()

    const { href, icon, title, pages, ...others } = props;
    return (

        <div >
            {title && (
                <Box
                    sx={{
                        px: 2,
                        my: 2
                    }}
                >
                    <Typography
                        variant="h6" sx={{color: 'neutral.400'}}
                    >
                        {t(title)}
                    </Typography>
                </Box>
            )}


            {pages.map(({icon, name, path, availableForRoles}) => (

                <NavItem
                    key={name}
                    icon={icon}
                    href={path}
                    title={name}
                    availableForRoles={availableForRoles}
                />
            ))}
        </div>
    );
};

export {SideBarMenuItems};