import React from 'react';
import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";

const ShowEslipLayout = () => {


    return (
        <Box sx={{
            backgroundColor: '#505050',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            padding: '0px',
            margin: '0px'
        }}>
            <Outlet />
        </Box>
    );
};

export default ShowEslipLayout;