import React from 'react';
import {Box, Button, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import image404 from "../../assets/img/error-404.png";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';


const DataNotFoundComponent = ({refreshDataFn, ...props}) => {
    const {t} = useTranslation()

    return (
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <CommentsDisabledOutlinedIcon color="primary"
                                      sx={{
                                          fontSize: 80,
                                          marginTop: 10,
                                          marginBottom: 5,
                                      }}/>
                    {/*<img*/}
                    {/*    alt="Under development"*/}
                    {/*    src={image404}*/}
                    {/*    style={{*/}
                    {/*        marginTop: 50,*/}
                    {/*        marginBottom: 50,*/}
                    {/*        display: 'inline-block',*/}
                    {/*        // maxWidth: '50%',*/}
                    {/*        width: 150*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Box>
                <Typography align="center"
                            color="textPrimary"
                            variant="h4"
                            sx={{mb:'24px'}}
                >
                    {t('systemCommon.dataNotFound')}
                </Typography>
                <Typography  align="center"
                             color="textPrimary"
                             variant="subtitle2"
                             sx={{mb:'24px'}}
                >
                    {t('systemCommon.dataNotFoundHint')}
                </Typography>

                {
                    refreshDataFn &&
                    <Button startIcon={(<RefreshOutlinedIcon />)}
                            onClick={() => refreshDataFn()}>{t('systemCommon.updateInfo')}</Button>
                }
            </Box>
    );
};

export default DataNotFoundComponent;