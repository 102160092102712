import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import PersonsToolBar from "./PersonsToolBar";
import PersonsList from "./PersonsList";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const PersonsPage = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        document.title = 'Persons | UniPORT E-slip';

        const items = [
            {href:"/persons", caption:'pages.persons.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        dispatch(setActiveSection({href:'/persons'}))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <PersonsToolBar/>
                <Box sx={{mt: 3}}>
                    <PersonsList/>
                </Box>
            </Container>
        </Box>
    );
};

export default PersonsPage;