import React, {useEffect, useState} from 'react';
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {useDispatch, useSelector} from "react-redux";
import {Backdrop, Box, CircularProgress, Container, Tab, Tabs, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import EslipArchiveListResult from "./EslipArchiveListResult";
import JobArchiveTab from "./tabs/JobArchiveTab";
import EslipsArchiveToolbar from "./EslipsArchiveToolbar";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


const EslipsArchivePage = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const status = useSelector(state => state.eslipsArchivePage.status)

    const [value, setValue] = useState(0)

    useEffect(() => {
        document.title = 'Eslip Archive | UniPORT E-slip';

        dispatch(setActiveSection({href: '/archive'}))

        const items = [
            {href:"/archive", caption:'pages.archive.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });





    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Backdrop
                open={status === 'loading'}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>


            <Container maxWidth={false}>
                <EslipsArchiveToolbar />
                <Box sx={{ bgcolor: 'background.paper', borderRadius:1, mt:3}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value}
                              onChange={handleChange}
                              variant={lgUp ? "standard" : "scrollable"}
                        >
                            <Tab label={t('pages.archive.jobTabTitle')}/>
                            <Tab label={t('pages.archive.findByArchiveTabTitle')}/>
                        </Tabs>
                    </Box>
                        <TabPanel value={value} index={0}>
                            <JobArchiveTab />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <EslipArchiveListResult />
                        </TabPanel>
                </Box>
            </Container>
        </Box>
    );
};

export default EslipsArchivePage;