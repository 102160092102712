import React from 'react';
import OrganizationsBindingAdd from "./OrganizationsBindingAdd";
import OrganizationsBindingCurrent from "./OrganizationsBindingCurrent";
import {Paper} from "@mui/material";
import {useSelector} from "react-redux";

const OrganizationBindingWorkspace = () => {
    const isAddMode = useSelector(state => state.bankOrganizationsBindingPage.isAddMode)

    return (
            <Paper>
                {
                    isAddMode &&
                    <OrganizationsBindingAdd />
                }
                {
                    !isAddMode &&
                    <OrganizationsBindingCurrent />
                }
            </Paper>
    );
};

export default OrganizationBindingWorkspace;