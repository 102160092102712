import React, {useState} from 'react';
import {Box, Button, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";

const SettingsToolBar = (props) => {
    const {t} = useTranslation()

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.systemParams.pageTitle')}
                </Typography>

            </Box>
        </Box>
    );
};

export default SettingsToolBar;