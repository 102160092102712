import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import instanceAxiosApi from "../../service/axiosApi";

export const fetchOrganizationRows = createAsyncThunk(
    'eslipsArchivePage/fetchOrganizationRows',
    async function(params, {rejectWithValue}) {
        try {
            const { searchString} = params

            return await instanceAxiosApi.get(`/ui/v1/organizations/filter?searchString=${searchString}`)
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchCurrencyRows = createAsyncThunk(
    'eslipsArchivePage/fetchCurrencyRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/currency")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchOriginOperationRows = createAsyncThunk(
    'eslipsArchivePage/fetchOriginOperationRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/origin_operations")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchIsEslipAttrRows = createAsyncThunk(
    'eslipsArchivePage/fetchIsEslipAttrRows',
    async function(_, {rejectWithValue}) {
        try {
            return await instanceAxiosApi.get("/ui/v1/eslips/filter/is_eslips")
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchEslipArchiveRows = createAsyncThunk(
    'eslipsArchivePage/fetchEslipArchiveRows',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const {page, limit, filterParams, filterNormal} = getState().eslipsArchivePage

            if ('normal' !== filterNormal) {
                return {}
            }

            let params = buildParamsForRequest(filterParams, page, limit)

            const response = await instanceAxiosApi.get(`/ui/v1/eslips/archive?${params}`)

            return response
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

const buildParamsForRequest = (filterParams, page, limit) => {

    let filterPredicateArray = []

    for (const [key, value] of Object.entries(filterParams)) {
        //console.log(`${key}: ${value}`);

        if (value && typeof value === "string") {
            filterPredicateArray.push(`&${key}=${value}`)
        } else if (value && typeof value === "object") {
            filterPredicateArray.push(`&${key}=${value.key}`)
        }
    }


    let paramsString = filterPredicateArray.join('')

    let params = `page=${page}&limit=${limit}${paramsString}`

    return params
}

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({
            errorCode: 403,
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({
            errorCode: 400,
            error: errorMessage}
        )
    } else {
        // Сообщение выдаст центральный axios interceptor
        return rejectWithValue({
            errorCode: 500,
            error: 'authProvider.internalServerErrorMessage'
        })
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
}

const eslipsArchivePageSlice = createSlice({
    name: 'eslipsArchivePage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        status: null,
        error: null,

        archiveMode: 'task', // Задания - task,  или поиск - search

        dataRows: [],
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 30,

        filterParams: {
            currency: '',
            pan: '',
            amount: '',
            dateFrom: null,
            dateTo: null,
            terminalHostId: '',
            merchantName: '',
            cardHolder: '',
            origOperationCode: '',
            rrn: '',
            isEslip: '',
            email: '',
            phone: '',
            orgId: null,
            archDateFrom: null,
            archDateTo: null
        },
        filterNormal: null,

        filterPanelOpen: false,

        settingsPanelOpen: false,

        columnSettings: [
            {key: "currency.codeAlpha", visible: true},
            {key: "pan", visible: true},
            {key: 'formattedAmount', visible: true},
            {key: 'serviceTimeFormatted', visible: true},
            {key: 'terminal.terminalhostId', visible: true},
            {key: 'terminal.merchants.name', visible: true},
            {key: 'cardHolder', visible: true},
            {key: 'origOperationName', visible: true},
            {key: 'rrn', visible: true},
            {key: 'isEslipAttributeName', visible: true},
            {key: 'email', visible: true},
            {key: 'phone', visible: true},
            {key: 'archiveTimeFormatted', visible: true}
        ],

        currencyRows: [],
        originOperationRows: [],
        isEslipAttrs: [],

        isLoadingOrganizations: false,
        organizationRow: []
    },
    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        changeFilterParams(state, action) {
            state.filterParams = {...state.filterParams, ...action.payload}
        },

        clearFilterInfo(state, action) {
            let clearParams = {
                currency: '',
                pan: '',
                amount: '',
                dateFrom: null,
                dateTo: null,
                terminalHostId: '',
                merchantName: '',
                cardHolder: '',
                origOperationCode: '',
                rrn: '',
                isEslip: '',
                email: '',
                phone: '',
                orgId: null,
                archDateFrom: null,
                archDateTo: null
            }

            state.filterParams = {
                ...state.filterParams,
                ...clearParams
            }
        },

        changeFilterPanelOpenStatus(state, action) {
            state.filterPanelOpen = action.payload.filterPanelOpen;
        },

        changSettingsPanelOpenStatus(state, action) {
            state.settingsPanelOpen = action.payload
        },

        changeVisibleColumn(state, action) {
            let elementi = state.columnSettings.find(element => element.key === action.payload)
            elementi.visible = !elementi.visible

            localStorage.setItem('eslipsArchiveColumnSetting', JSON.stringify(state.columnSettings))
        },

        /* Инициализация всей страницы при открытии */
        initPageData(state, action) {
            state.status = null
            state.error = null

            let lsinfo = JSON.parse(localStorage.getItem("eslipsArchiveColumnSetting"))

            // Если ничего нет, ставим из стейта и уходим
            if (!lsinfo) {
                localStorage.setItem('eslipsArchiveColumnSetting', JSON.stringify(state.columnSettings))

                return;
            }

            // Если настройки есть, применяем
            let columnSettingL = [...state.columnSettings]

            columnSettingL.map(e => {
                let t = lsinfo.find(el => el.key === e.key)
                if (t) {
                    e.visible = t.visible
                }
            })

            state.columnSettings = columnSettingL
            localStorage.setItem('eslipsArchiveColumnSetting', JSON.stringify(state.columnSettings))
        },

        setLimit(state, action) {
            state.limit = action.payload
        },

        setPage(state, action) {
            state.page = action.payload
        },

        setFilterNormal(state, action) {
            state.filterNormal = action.payload
        },

        changeArchiveMode(state, action) {
            state.archiveMode = action.payload //'task' 'search'
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEslipArchiveRows.pending, (state, action) => {
                state.status = 'loading'
            })

            .addCase(fetchEslipArchiveRows.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let dataJson = action.payload?.data || {rows: [], totalPages: 0}

                state.dataRows = dataJson.rows

                state.totalPages = dataJson.totalPages

                // Если на какой-то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
                if (dataJson.totalPages < state.page) {
                    state.page = 1
                }
            })
            .addCase(fetchEslipArchiveRows.rejected, (state, action) => setError(state, action))

            .addCase(fetchIsEslipAttrRows.pending, (state, action) => {})
            .addCase(fetchIsEslipAttrRows.fulfilled, (state, action) => {
                state.isEslipAttrs = action.payload?.data || []
            })
            .addCase(fetchIsEslipAttrRows.rejected, (state, action) => {
                state.isEslipAttrs = []
            })

            .addCase(fetchOriginOperationRows.pending, (state, action) => {})
            .addCase(fetchOriginOperationRows.fulfilled, (state, action) => {
                state.originOperationRows = action.payload?.data || []
            })
            .addCase(fetchOriginOperationRows.rejected, (state, action) => {
                state.originOperationRows = []
            })

            .addCase(fetchCurrencyRows.pending, (state, action) => {})
            .addCase(fetchCurrencyRows.fulfilled, (state, action) => {
                state.currencyRows = action.payload?.data || []
            })
            .addCase(fetchCurrencyRows.rejected, (state, action) => {
                state.currencyRows = []
            })

            .addCase(fetchOrganizationRows.pending, (state, action) => {
                state.isLoadingOrganizations = true
            })
            .addCase(fetchOrganizationRows.fulfilled, (state, action) => {
                state.organizationRow = action.payload?.data || []
                state.isLoadingOrganizations = false
            })
            .addCase(fetchOrganizationRows.rejected, (state, action) => {
                state.organizationRow = []
                state.isLoadingOrganizations = false
            })


    }
})

export const {
    setNeedRefreshData,
    changeFilterParams, changeFilterPanelOpenStatus,
    changSettingsPanelOpenStatus, changeVisibleColumn, initPageData,
    setLimit, setPage, changeArchiveMode, setFilterNormal, clearFilterInfo
} = eslipsArchivePageSlice.actions;

export default eslipsArchivePageSlice.reducer;