import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Tooltip, Typography, useMediaQuery} from "@mui/material";

import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import AddEditOrganizationDialog from "./AddEditOrganizationDialog";
import {useAuth} from "../../../hook/useAuth";
import {RoleEnum} from "../../../types/RoleEnum";
import OrganizationFilterDrawer from "./OrganizationFilterDrawer";
import {changeFilterPanelOpenStatus, setNeedRefreshData} from "../../../store/handbook/organizationsPageSlice";
import {useTranslation} from "react-i18next";


const OrganizationsToolBar = (props) => {
    const dispatch = useDispatch()
    const {checkExistsRoles} = useAuth()
    const {t} = useTranslation()

    const isLoading = useSelector(state => state.organizationsPage.isLoading)

    // Диалог добавления
    const [open, setOpen] = useState(false)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const refreshListRows = () => {
        dispatch(setNeedRefreshData({needRefreshData: true}))
    }

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Typography
                    sx={{m: 1}}
                    variant="h4"
                >
                    {t('pages.organizations.pageTitle')}
                </Typography>
                <Box sx={{display: 'flex', m: 1}}>
                    {/*На большом экране подсказка не нужна*/}
                    { checkExistsRoles(RoleEnum.ADMINISTRATOR) &&
                        <Tooltip title={lgUp ? "" : t('pages.organizations.addOrgBtnCaption')}>
                        <Button
                            startIcon={<PlaylistAddOutlinedIcon/>}
                            size={"small"}
                            color="primary"
                            // variant="contained"
                            onClick={() => handleClickOpen()}
                            disabled={isLoading}
                        >
                            {/*На маленьком экране подсказку не выводим*/}
                            {lgUp ? t('pages.organizations.addOrgBtnCaption') : ''}
                        </Button>
                    </Tooltip>
                    }

                    <Button size={"small"}
                            startIcon={<ManageSearchOutlinedIcon/>}
                            onClick={filterOpenBtnClickHandler}
                    >
                        {t('filter.filterCaption')}
                    </Button>
                </Box>
            </Box>

            <AddEditOrganizationDialog open={open} setOpen={setOpen}
                                       oldOrganizationName={''}
                                       organizationId={-1}
                                       refreshListRows={refreshListRows}/>

            {/*фильтр*/}
            <OrganizationFilterDrawer />
        </Box>

    );
};

export default OrganizationsToolBar;