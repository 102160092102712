import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import MerchantsToolBar from "./MerchantsToolBar";
import MerchantsList from "./MerchantsList";
import {useDispatch} from "react-redux";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";


const MerchantsPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Merchants | UniPORT E-slip';

        dispatch(setActiveSection({href: '/merchants'}))

        const items = [
            {href:"/merchants", caption:'pages.merchant.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <MerchantsToolBar />
                <Box sx={{mt: 3}}>
                    <MerchantsList />
                </Box>
            </Container>
        </Box>
    );
};

export default MerchantsPage;