import React, {useState} from "react";
import {
    HashRouter,
    Route,
    Routes,
} from "react-router-dom";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
import {RouteEnum} from "./types/RouteEnum";
import LoginPage from "./pages/auth/LoginPage";
import BaseLayout from "./layout/BaseLayout";
import AuthLayout from "./layout/AuthLayout";
import NotFoundPage from "./pages/NotFoundPage";
import PasswordRepairPage from "./pages/auth/repair/PasswordRepairPage";
import AuthProvider from "./hoc/AuthProvider";
import {RequiredAuth} from "./hoc/RequaredAuth";
import {NotAuthOnly} from "./hoc/NotAuthOnly";
import EslipsPage from "./pages/eslip/eslip/EslipsPage";
import EslipsArchivePage from "./pages/eslip/archive/EslipsArchivePage";
import EslipExportPage from "./pages/eslip/exportmanager/EslipExportPage";
import BanksPage from "./pages/handbook/banks/BanksPage";
import OrganizationsPage from "./pages/handbook/organizations/OrganizationsPage";
import TerminalsPage from "./pages/handbook/terminals/TerminalsPage";
import MerchantsPage from "./pages/handbook/merchants/MerchantsPage";
import SystemParamsPage from "./pages/administration/param_system/SystemParamsPage";
import PersonGroupsPage from "./pages/administration/group_persons/PersonGroupsPage";
import GroupAccessPage from "./pages/administration/access_group_persons/GroupAccessPage";
import PasswordMandatoryChangePage from "./pages/auth/PasswordMandatoryChangePage";
import {setupAxiosApiInterceptors} from "./service/setupAxiosInterceptors";
import ForbiddenPage from "./pages/auth/ForbiddenPage";
import {useAuth} from "./hook/useAuth";
import './assets/languages/i18n';
import {SnackbarProvider, useSnackbar} from "notistack";
import EditOrganizationsBindingPage from "./pages/handbook/banks/org_binding/EditOrganizationsBindingPage";
import PersonProfilePage from "./pages/administration/profile/PersonProfilePage";
import PersonsPage from "./pages/administration/persons/PersonsPage";
import GroupEditUsersBindingPage from "./pages/administration/group_persons/group_binding/GroupEditUsersBindingPage";
import PersonCardPage from "./pages/administration/persons/person/PersonCardPage";
import {useTranslation} from "react-i18next";
import ShowEslipPageHtml from "./pages/export/ShowEslipPageHtml";
import ShowEslipLayout from "./layout/ShowEslipLayout";

/**
 * Описание всех маршрутов в приложении.
 * Каждая страница может иметь свои вложенные маршруты, которые тут не описаны
 */
function App() {

    /**
     * Компонент для axios interceptors
     *
     * @param props
     * @returns {JSX.Element}
     * @constructor
     */
    const AxiosNavigateComponent = () => {
        const [ran, setRan] = useState(false);
        const {clearUserInfoFromLS, forbiddenPageRedirect, userPasswordNeedChange, refreshAccessToken} = useAuth()
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        const {t} = useTranslation()

        {/* only run setup once */}
        if (!ran) {
            setupAxiosApiInterceptors(
                forbiddenPageRedirect,  clearUserInfoFromLS,
                userPasswordNeedChange, enqueueSnackbar,
                t,
                refreshAccessToken
            );
            setRan(true);
        }
        return <></>;
    }

    return (
        // Так как приложение разворачивается внутри SB приложения, BrowserRouter не удалось использовать - используем HashRouter
        // <BrowserRouter>
        <HashRouter>
            <SnackbarProvider maxSnack={3} preventDuplicate autoHideDuration={3000}>
                <AuthProvider>
                    {<AxiosNavigateComponent />}

                    <Routes>
                        <Route path="/" element={<RequiredAuth><BaseLayout/></RequiredAuth>} errorElement={<ErrorPage/>}>
                            <Route index element={<DashboardPage />}/>
                            <Route path="dashboard" element={<DashboardPage/>}/>
                            <Route path="home" element={<DashboardPage/>}/>
                            <Route path="eslips" element={<EslipsPage />}/>
                            <Route path="archive" element={<EslipsArchivePage />}/>
                            <Route path="export" element={<EslipExportPage />}/>
                            <Route path="banks" element={<BanksPage />}/>
                            <Route path="banks/:bankId/organizations_binding" element={ <EditOrganizationsBindingPage />}/>
                            <Route path="organizations" element={<OrganizationsPage />}/>
                            <Route path="merchants" element={<MerchantsPage />} />
                            <Route path="terminals" element={<TerminalsPage />}/>
                            <Route path="system/params" element={<SystemParamsPage />} />
                            <Route path="persons" element={<PersonsPage />} />
                            <Route path="persons/card" element={<PersonCardPage />} />
                            <Route path="group_persons" element={<PersonGroupsPage />} />
                            <Route path="group_persons/:groupId/users_binding" element={<GroupEditUsersBindingPage />} />
                            <Route path="group_access" element={<GroupAccessPage />} />
                            <Route path="profile" element={<PersonProfilePage />} />

                            <Route path={RouteEnum.FORBIDDEN} element={<ForbiddenPage />}/>
                        </Route>

                        <Route path={RouteEnum.AUTH} errorElement={<ErrorPage />} element={
                            <NotAuthOnly>
                                <AuthLayout/>
                            </NotAuthOnly>
                        }>
                            <Route index element={<LoginPage />}/>
                            <Route path="login" element={<LoginPage />}/>
                            <Route path="password_change" element={<PasswordMandatoryChangePage />}/>
                            <Route path="recovery" element={<PasswordRepairPage />}/>
                        </Route>

                        <Route path={RouteEnum.SHOW_ESLIP} element={<ShowEslipLayout />}>
                            <Route index element={<ShowEslipPageHtml />}/>
                            <Route path={":archive/:encryptedLink"} element={<ShowEslipPageHtml />}/>
                        </Route>

                        {/*если ничего не нашли, попадаем сюда*/}
                        <Route path={"*"} element={<NotFoundPage/>}/>

                    </Routes>
                </AuthProvider>
            </SnackbarProvider>
        {/*</BrowserRouter>*/}
        </HashRouter>
    );
}

export default App;
