import React, {useEffect} from 'react';
import {
    Alert,
    Box,
    Button, Card, CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    TextField
} from "@mui/material";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    fetchCommonInfo,
    fetchSaveInfoByTab,
    initCommonTab,
    updateCommonTab, setNeedRefreshData
} from "../../../store/administration/systemParamsPageSlice";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";


const CommonSettingsTab = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const schema = Yup.object({
        externalSiteAddress: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),
        directoryCheckExport: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),
        linkExportChecks: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption'))
    });

    const {commonTabValues, needRefreshData, error, status, is403} = useSelector(state => state.systemParamsPage)

    const {control, reset, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            ...commonTabValues
        }
    })

    useEffect(() => {
        reset(commonTabValues);
    }, [commonTabValues]);

    useEffect(() => {
        async function initTab() {
            await dispatch(initCommonTab())
        }

        // Все чистим в сторе
        initTab()
        // Грузим новые данные
        loadData()
    }, [dispatch])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    const loadData = async () => {
        await dispatch(fetchCommonInfo({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }


    const handleSubmitLocal = (data) => {
        //console.log(data);
        dispatch(updateCommonTab({...data}))
        dispatch(fetchSaveInfoByTab({
            tab: 'common',
            showSnackMsg: showSnackMsg
        }))
    }

    if (is403) {
        return (
            <Box sx={{pt:'-24px', mt:'-20px'}}>
                <AccessDeniedComponent />
            </Box>
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(handleSubmitLocal)}>
            <Card>
                <CardHeader
                    subheader={t('pages.systemParams.commonSystemSettingsSubHeader')}
                    title={t('pages.systemParams.commonSystemSettingsHeader')}
                />
                <Divider/>
                {/*{isProcessing && <LinearProgress/>}*/}
                <CardContent>
                    <Grid container spacing={3} columns={16}>
                        {
                            (status === 'rejected') &&
                            <Grid xs={16} md={16} item>
                                <Alert severity={"error"}>{t(error)}</Alert>
                            </Grid>
                        }

                        {
                            (status === 'resolved' || status === 'rejected') &&
                            <>
                                <Grid xs={16} md={8} item>
                                    <Controller
                                        name={"allowUnencryptedPackets"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <FormControlLabel
                                                {...field}
                                                // disabled={isProcessing}
                                                control={
                                                    <Checkbox checked={field.value}/>
                                                }
                                                label={t('pages.systemParams.allowUnencryptedPackets')}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid xs={0} md={8} item>
                                    <Box></Box>
                                </Grid>

                                <Grid item xs={16} md={8}>
                                    <Controller
                                        name={"externalSiteAddress"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                error={!!error}
                                                helperText={error?.message}
                                                // disabled={isProcessing}
                                                label={t('pages.systemParams.externalSiteAddress')}
                                                required
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid xs={0} md={8} item>
                                    <Box></Box>
                                </Grid>

                                <Grid xs={16} md={8} item>
                                    <Controller
                                        name={"directoryCheckExport"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                error={!!error}
                                                helperText={error?.message}
                                                // disabled={isProcessing}
                                                label={t('pages.systemParams.directoryCheckExport')}
                                                required
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={0} md={8} item>
                                    <Box></Box>
                                </Grid>

                                <Grid xs={16} md={8}
                                      item>
                                    <Controller
                                        name={"linkExportChecks"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                error={!!error}
                                                helperText={error?.message}
                                                // disabled={isProcessing}
                                                label={t('pages.systemParams.linkExportChecks')}
                                                required
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid xs={0} md={8} item>
                                    <Box></Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                </CardContent>
                <Divider/>
                <Box sx={{
                    display: "flex", justifyContent: 'flex-end',
                    p: 2
                }}>
                    <Button
                        color="primary"
                        variant="contained"
                        type={"submit"}
                        //disabled={isProcessing || isSubmitError}
                    >
                        {t('pages.systemParams.saveInfoBtnCaption')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

export default CommonSettingsTab;