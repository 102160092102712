import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {convertBase64} from "../../../../util/UtilFunction";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {changeAvatar} from "../../../../store/administration/personCardPageSlice";



const PersonCardAvatar = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {userId, login, avatar} = useSelector(state => state.personCardPage)

    // Изображение в Base64 (если загрузили с сервера) или то что выбрали в uploadfile
    const [image, setImage] = useState('')
    // ссылка на скрытый компонент uploadfile
    const filePicker = useRef(null)

    useEffect(() => {
        if (avatar) {
            let r =  avatar.replace("\ufeff", "")
            let arrTemp = r.split(',')
            let imageBase = arrTemp.slice(arrTemp.length - 1)[0]
            setImage("data:image/png;base64," + imageBase)
        }
    }, [avatar])



    /* Нажатие кнопки */
    const handlePick = () => {
        filePicker.current.click()
    }

    /* Нажатие скрытого компонента fileUpload */
    const handleChange = async (event) => {
        //console.log(event.target.files)

        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]))

            let newAvatar = await convertBase64(event.target.files[0])
            dispatch(changeAvatar(newAvatar))
        }
    }

    return (
        <Card >
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Avatar
                        src={image}
                        sx={{
                            height: 200,
                            mb: 2,
                            width: 200
                        }}
                    />

                    {
                        userId &&
                        <>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h5"
                            >
                                {login}
                            </Typography>
                        </>
                    }


                </Box>
            </CardContent>
            <input type={"file"}
                   className={"hidden"}
                   onChange={handleChange}
                   ref={filePicker}
                   accept={"image/*, .png, .gpg"}
            />
            <Divider />
            <CardActions>
                <Button
                    color="primary"
                    fullWidth
                    variant="text"
                    onClick={handlePick}
                >
                    {t('pages.profilePage.uploadAvatarCaption')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default PersonCardAvatar;