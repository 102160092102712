import React, {useState} from 'react';
import {Alert, Box, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLoginUser, fetchRestoreCode} from "../../../store/auth/passwordRecoveryPageSlice";

const Step1 = () => {
    const dispatch = useDispatch()
    // const [isSubmitError, setIsSubmitError] = useState(false)
    // const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    const {t} = useTranslation()

    const {error, status} = useSelector(state => state.passwordRecoveryPage)

    const formik = useFormik({
        initialValues: {
            loginUserForRepair: ''
        },
        validationSchema: Yup.object({
            loginUserForRepair: Yup
                .string()
                .max(50)
                .required(t('pages.restorePassword.loginCheckRequired')),
        }),
        onSubmit: async (values, formikHelpers) => {
            try {
                await dispatch(setLoginUser({login: values.loginUserForRepair}))
                await dispatch(fetchRestoreCode())
            } catch (e) {
                formikHelpers.setSubmitting(false)
            }
        }
    });


    return (
        <>
            <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.step1Caption')}
            </Typography>

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    error={Boolean(formik.touched.loginUserForRepair && formik.errors.loginUserForRepair)}
                    fullWidth
                    helperText={formik.touched.loginUserForRepair && formik.errors.loginUserForRepair}
                    label={t('pages.restorePassword.loginInputLabel')}
                    margin="normal"
                    name="loginUserForRepair"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.loginUserForRepair}
                    variant="outlined"
                />

                {
                    error &&
                    <Alert severity="error">{t(error)}</Alert>
                }

                <Box sx={{py: 2}}>
                    <Button
                        color="primary"
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('pages.restorePassword.step1BtnCaption')}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default Step1;