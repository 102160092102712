import React from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../hook/useAuth";
import {RouteEnum} from "../types/RouteEnum";

const NotAuthOnly = ({children}) => {
    const {userAuth} = useAuth();

    if (userAuth) {
        return <Navigate to={RouteEnum.HOME} />
    }

    return children;
};

export {NotAuthOnly};