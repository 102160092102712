import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ru from './ru.json';
import en from './en.json';
import kz from './kz.json';


const resources = {
    ru: ru,
    en: en,
    kz: kz
};


i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        supportedLngs: ['ru', 'en', 'kz'],
        fallbackLng: 'ru',
        // interpolation: {
        //     escapeValue: false, // not needed for react as it escapes by default
        // }
    })
    .then();


export default i18next;

