import React, {useEffect, useState} from 'react';
import {Box, Container, Grid} from "@mui/material";
import LicenseInfo from "../../components/dashboard/LicenseInfo";
import AccountInfo from "../../components/dashboard/AccountInfo";
import TerminalInfo from "../../components/dashboard/TerminalInfo";
import SlipInfo from "../../components/dashboard/SlipInfo";
import UsersInfo from "../../components/dashboard/UsersInfo";
import {setActiveSection, setBreadcrumbs} from "../../store/baseLayoutSlice";
import {useDispatch} from "react-redux";
import {useAuth} from "../../hook/useAuth";
import {RoleEnum} from "../../types/RoleEnum";

const DashboardPage = () => {
    const dispatch = useDispatch()
    const {checkExistsRoles} = useAuth()

    useEffect(() => {
        document.title = 'Dashboard | UniPORT E-slip';

        dispatch(setActiveSection({href:"/home"}))

        const items = []
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                {
                    checkExistsRoles(RoleEnum.ADMINISTRATOR) &&
                    <LicenseInfo sx={{mb: '24px'}}/>
                }

                <Grid
                    container
                    spacing={3}
                >
                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    lg={4}*/}
                    {/*    sm={6}*/}
                    {/*    xl={3}*/}
                    {/*    xs={12}*/}
                    {/*>*/}
                    {/*    <TerminalInfo />*/}
                    {/*</Grid>*/}

                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    lg={4}*/}
                    {/*    sm={6}*/}
                    {/*    xl={3}*/}
                    {/*    xs={12}*/}
                    {/*>*/}
                    {/*    <SlipInfo />*/}
                    {/*</Grid>*/}

                    {
                        checkExistsRoles(RoleEnum.ADMINISTRATOR) &&
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={3}

                        >
                            <UsersInfo />
                        </Grid>
                    }

                </Grid>

                <AccountInfo sx={{mt: '24px'}}/>
            </Container>
        </Box>
    );
};

export default DashboardPage;