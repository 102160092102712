import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setOpenShowTaskIdDlg} from "../../../store/common/eslipsPageSlice";

/**
 * Отображение идентификатора задачи по экспорту чеков.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ExportShowTaskIdDialog = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // Приходят из-вне
    const {openShowTaskIdDlg, exportObject} = useSelector(state => state.eslipsPage)

    const handleClose = () => {
        dispatch(setOpenShowTaskIdDlg(false))
    };


    return (
        <>
            <Dialog open={openShowTaskIdDlg}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <Box sx={{
                }}>
                    <DialogTitle>{t('pages.eslips.showEslipExportTaskIdDldHeader')}</DialogTitle>
                    <Divider/>
                    <DialogContent>

                        <DialogContentText>

                                {/*<Box sx={{display: 'flex', mb: '1rem'}}>*/}
                                    <Typography>
                                        {t('pages.eslips.exportTaskIdMsg') + ' ' + exportObject?.taskId}
                                    </Typography>
                                    {/*<Typography color={'secondary.main'} sx={{ml:'0.5rem'}}>*/}
                                    {/*    {exportObject?.taskId}*/}
                                    {/*</Typography>*/}
                                {/*</Box>*/}

                        </DialogContentText>
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button //color={"error"}
                                onClick={handleClose}>
                            {t('systemCommon.dlgCloseBtnCaption')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default ExportShowTaskIdDialog;