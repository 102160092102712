import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {useState} from "react";
import {IconButton, InputAdornment, ListSubheader, Box, SvgIcon, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {fetchOrganizationRows} from "../../../store/common/eslipsPageSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const SelectOrgComponent = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {options, // список организаций
        isLoadingOrganizations, // Признак что сейчас идет получение спсика организаций
        loadOrganizationRow, // функция которая грузит список организаций
        setOrganizationObjOut, // Функция которая меняет значение в родителе
        initOrganizationObj // Значение присваиваемое при старте
    } = props

    const [searchValue, setSearchValue] = useState('')

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            loadOrganizationRow(searchValue)
        }

        if (e.key !== "Escape") {
            // Prevents autoselecting item while typing (default Select behaviour)
            e.stopPropagation();
        }
    }

    const handleMenuItemClick = (event, index, option) => {
        setSelectedIndex(index);
        setOrganizationObjOut(option)
        setAnchorEl(null);
        setOrganizationObjOut(option)
    };

    const handleChangeSearchString = (event) => {
        setSearchValue(event.target.value)
    }

    const handleClickClearSearchString = () => {
        setSearchValue('')
        loadOrganizationRow('')
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <List
                component="nav"
                aria-label="Device settings"
                sx={{ bgcolor: 'background.paper' }}
            >
                <ListItem
                    button
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                    <ListItemText
                        primary={t('pages.eslipsFilter.orgainizationHeader')}
                        secondary={initOrganizationObj?.value || t('pages.eslipsFilter.orgainizationEmptySubHeader')}
                    />
                </ListItem>
            </List>

            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <Box sx={{ width: 370, maxWidth: '100%' }}>
                <ListSubheader>

                        <TextField
                            type={"text"}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            fontSize="small"
                                            color="action"
                                        >
                                            <SearchIcon/>
                                        </SvgIcon>
                                    </InputAdornment>
                                ),

                                endAdornment: searchValue && (
                                    <IconButton size={"small"}
                                                aria-label="toggle password visibility"
                                                onClick={handleClickClearSearchString}
                                    >
                                        <CancelRoundedIcon fontSize={"small"}/>
                                    </IconButton>
                                )

                            }}
                            placeholder={t('pages.groupAccess.searchByNameCaption')}
                            variant="outlined"
                            size={"small"}
                            value={searchValue}
                            onChange={handleChangeSearchString}
                            onKeyDown={(e) => onKeyDown(e)}
                            disabled={isLoadingOrganizations}
                            inputRef={(input) => input?.focus()}
                        />

                    </ListSubheader>
                {options.map((option, index) => (
                    <MenuItem
                        key={option.key}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index, option)}
                    >
                        {option.value}
                    </MenuItem>
                ))}
                </Box>
            </Menu>
        </>
    );
};

export default SelectOrgComponent;