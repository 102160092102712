import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Drawer, FormControl, IconButton, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select, TextField, Tooltip
} from "@mui/material";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterPanelOpenStatus,
    fetchEslipsExportStatusRows,
    setFilterModel
} from '../../../store/common/exportManagerPageSlice'
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {buildNewFilterModelItem} from "../../../util/FilterUtilFuctions";
import {useSnackbar} from "notistack";

const formValuesEmpty = {
    id: '',
    username: '',
    requestStartDate: null,
    requestEndDate: null,
    exportStatus: '-1'
};


const EslipExportDrawer = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar()
    const exportStatuses = useSelector(state => state.exportManagerPage.exportStatuses)
    const filterOpen = useSelector(state => state.exportManagerPage.filterPanelOpen)
    const filterModelInit = useSelector(state => state.exportManagerPage.filterModel)

    const [formValue, setFormValue] = useState({...formValuesEmpty})

    const {control, reset, handleSubmit, setValue, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        // resolver: yupResolver(schema),
        defaultValues: {
            formValue
        }
    })

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

// При открытии перечитываем все из стора
    useEffect(() => {
        //console.log('filterParamsInit' + filterParamsInit );
        if (filterOpen) {
            dispatch(fetchEslipsExportStatusRows({showSnackMsg:showSnackMsg}))
            readFilterValue()
        }
    }, [filterOpen])

    // Если изменили значения поиска, тоже перечитваем
    useEffect(() => {
        //console.log('filterParamsInit' + filterParamsInit );
        // при открытии нужно перечитать
        if (filterOpen) {
            reset(formValue)
        }
    }, [formValue])

    const readFilterValue = () => {
        // То что лежит в сторе, нужно перечитать
        let filterModel = {}
        Object.keys(formValue).forEach(keyi => {
            if (keyi === 'active') {
                filterModel[keyi] = filterModelInit[keyi]?.value || '-1'
            } else {
                filterModel[keyi] = filterModelInit[keyi]?.value || ''
            }

        })

        // мержим текущие с тем что есть в сторе
        setFormValue({
            ...formValue,
            ...filterModel
        });
    }

    /* Закрытие панели с фильтрами */

    const filterCloseBtnHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: false}))
    }

    /* Кнопка очистить */
    const handleOnCancelClick = () => {

        setFormValue({
            ...formValue,
            ...formValuesEmpty
        })
    }

    /* кнопка применить */
    const handleOnApplyClick = (data) => {
        //console.log(data)
        const newfilterModel = buildFilterModel(data)
        dispatch(setFilterModel({filterModel: newfilterModel}))
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: false}))
    }

    const buildFilterModel = (form_data) => {
        let newFilterModel = {id:{}, username:{}, requestStartDate:{}, requestEndDate:{}, exportStatus:{}};

        newFilterModel.id = buildNewFilterModelItem('id', 'eq', form_data.id)
        newFilterModel.username = buildNewFilterModelItem('username', 'like', form_data.username)
        newFilterModel.requestStartDate = buildNewFilterModelItem('requestStartDate', 'gteq', form_data.requestStartDate)
        newFilterModel.requestEndDate = buildNewFilterModelItem('requestEndDate', 'lteq', form_data.requestEndDate)
        newFilterModel.exportStatus = buildNewFilterModelItem('exportStatus', 'eq', form_data.exportStatus)

        return newFilterModel
    }


    return (
        <Drawer
            anchor={"right"}
            open={filterOpen}
            onClose={filterCloseBtnHandler}
        >
            <form noValidate onSubmit={handleSubmit(handleOnApplyClick)}>
                <List sx={{width: '400px'}}>
                <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <ListItemIcon>
                        <ManageSearchOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('filter.filterCaption')}/>

                    <Tooltip title={t("filter.closeBtnToolTipText")}>
                        <IconButton aria-label="delete" color="error" size="small"
                                    onClick={() => {
                                        filterCloseBtnHandler()
                                    }}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <Divider sx={{mb: '15px'}}/>


                {/*id*/}
                <ListItem>
                        <FormControl fullWidth>
                            <Controller
                                name={"id"}
                                control={control}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        size={'small'}
                                        label={t('pages.exportManager.guid')}
                                        error={!!error}
                                        helperText={error?.message}
                                        variant="outlined"
                                        value={field.value || ''}
                                        inputProps={{ maxLength: 25}}
                                        type={"number"}
                                    />
                                )}
                            />
                        </FormControl>
                        <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                            <IconButton size={'small'} onClick={() => setValue('id', '')}>
                                <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>

                {/*пользователь*/}
                <ListItem>
                    <FormControl fullWidth>
                        <Controller
                            name={"username"}
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size={'small'}
                                    label={t('pages.exportManager.user')}
                                    error={!!error}
                                    helperText={error?.message}
                                    variant="outlined"
                                    value={field.value || ''}
                                    inputProps={{ maxLength: 25}}
                                />
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setValue('username', '')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*дата С*/}
                <ListItem>
                    <FormControl fullWidth>
                        <Controller
                            name={"requestStartDate"}
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <DatePicker
                                    {...field}
                                    id="startDate-input"
                                    label={t('pages.exportManager.requestDateFrom')}
                                    inputFormat="DD.MM.YYYY"
                                    value={field.value || null}

                                    renderInput={(params) => (
                                        <TextField {...params} helperText={null}
                                                   size={'small'} InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setValue('requestStartDate', null)}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*дата ПО*/}
                <ListItem>
                    <FormControl fullWidth>
                        <Controller
                            name={"requestEndDate"}
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <DatePicker
                                    {...field}
                                    id="endDate-input"
                                    label={t('pages.exportManager.requestDateTo')}
                                    inputFormat="DD.MM.YYYY"
                                    value={field.value || null}

                                    renderInput={(params) => (
                                        <TextField {...params} helperText={null}
                                                   size={'small'} InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setValue('requestEndDate', null)}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Статус*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="operation-name-select-label">{t('pages.exportManager.status')}</InputLabel>
                        <Controller
                            name={"exportStatus"}
                            control={control}
                            render={({field, fieldState: {error}}) => (
                                <Select
                                    {...field}
                                    labelId="operation-name-select-label"
                                    id="operation-name-select"
                                    value={field.value || -1}
                                    label="Статус"
                                >
                                    <MenuItem value='-1'>&nbsp;</MenuItem>
                                    {exportStatuses.map((rowi, number) => (
                                        <MenuItem key={number} value={rowi.key}>{rowi.value}</MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setValue('exportStatus', null)}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>


                <Divider sx={{mt: '15px'}}/>
                <ListItem sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        size="small"
                        type={"submit"}
                    >
                        {t('filter.applyBtnCaption')}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleOnCancelClick}
                    >
                        {t('filter.clearAllFieldBtnCaption')}
                    </Button>
                </ListItem>
            </List>
            </form>
        </Drawer>
    );
};

export default EslipExportDrawer;