import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { Box, Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import IconButton from "@mui/material/IconButton";
import AddEditBankDialog from "./AddEditBankDialog";
import DeleteBankDialog from "./DeleteBankDialog";
import {setNeedRefreshData} from "../../../store/handbook/banksPageSlice";
import {useTranslation} from "react-i18next";


const BankCard = ({bank, ...rest}) => {
    const {t}  = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false)

    const editBankHandler = () => {
        setOpen(true)
    }

    const organizationBindingHandler = () => {
        navigate(`/banks/${bank.id}/organizations_binding`, {replace: false});
    }

    const refreshListRows = () => {
        dispatch(setNeedRefreshData({needRefreshData:true}))
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
            {...rest}
        >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 3
                    }}
                >
                    <AccountBalanceOutlinedIcon />
                    {/*<Avatar*/}
                    {/*    alt="Product"*/}
                    {/*    //src={bank.media}                        */}
                    {/*    variant="square"*/}
                    {/*/>*/}
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                >
                    {bank.name}
                </Typography>

                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="body1"
                        sx={{
                            mr: 1
                        }}
                    >
                        {t('pages.banks.card.organizationBindingCaption')}
                    </Typography>
                    <Typography
                        align="center"
                        color="secondary.main"
                        variant="body1"
                    >
                        {bank.countOrganization || 0}
                    </Typography>
                </Box>
            </CardContent>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="edit" onClick={editBankHandler}>
                            <EditOutlinedIcon color='info'/>
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="addOrganization" onClick={organizationBindingHandler}>
                            <AddBusinessOutlinedIcon color={"success"}/>
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <IconButton aria-label="delete" onClick={() => setOpenDeleteDlg(true)}>
                            <ClearOutlinedIcon color={"error"}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>

            {/*Диалог для исправления наименования*/}
            <AddEditBankDialog open={open} setOpen={setOpen} oldBankName={bank.name}
                               bankId={bank.id} refreshListRows={refreshListRows}/>

            {/*Диалог удаления банка*/}
            <DeleteBankDialog open={openDeleteDlg} setOpen={setOpenDeleteDlg} oldBankName={bank.name}
                              bankId={bank.id} refreshListRows={refreshListRows}/>

        </Card>
    );
};

export default BankCard;