import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {BsPerson} from "react-icons/bs";
import instanceAxiosApi from "../../service/axiosApi";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import IconButtonWithLoader from "../buttons/IconButtonWithLoader";

const UsersInfo = (props) => {
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [usersQuantity, setUsersQuantity] = useState(0)
    const [activeSessions, setActiveSessions] = useState(0)
    const [is403, setIs403] = useState(false)


    useEffect(() => {
        refreshComponentInfo()
    }, []);

    const fetchApi = async () => {
        // Идем на сервер
        let response = await instanceAxiosApi({
            method: "get",
            url: "/ui/v1/dashboard/statistics/users"
        })

        let responseData = response.data?.statisticsData || {"usersQuantity":"--", "activeSessions": "--"};

        // Ответ сервера
        //{
        // "statisticsData":{
        //                          "usersQuantity":100,
        //                          "activeSessions":20
        //                        },
        // }

       return responseData
    }

    const refreshComponentInfo = () => {
        setIsLoading(true)
        fetchApi()
            .then(responseData => {
                setUsersQuantity(responseData.usersQuantity)
                setActiveSessions(responseData.activeSessions)

                setIs403(false)
                setIsError(false)
            })
            .catch(e => {
                let errorStatus = e?.response?.status || 400

                if (errorStatus === 403) {
                    setIs403(true)

                    enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                        variant: 'warning',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                } else {
                    setIs403(false)
                }

                setIsError(true)
                setUsersQuantity('--')
                setActiveSessions('--')
            })
            .finally(() => setIsLoading(false))
    }


    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            {t('pages.dashboard.users')}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {usersQuantity}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButtonWithLoader isLoading={isLoading}
                                              backgroundColor="info.light"
                                              refreshComponentInfo={refreshComponentInfo}>
                            <BsPerson />
                        </IconButtonWithLoader>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        color="secondary.main"
                        sx={{
                            mr: 1
                        }}
                        variant="body2"
                    >
                        {activeSessions}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        {t('pages.dashboard.activeSessions')}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default UsersInfo;