
export const RouteEnum = {
    ROOT : '/',
    AUTH : '/auth',

    HOME : '/home',
    DASHBOARD : '/dashboard',
    PROFILE: '/profile',

    FORBIDDEN : '403',

    SHOW_ESLIP: "/show_eslip"
}
