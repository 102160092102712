import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Divider,
    Drawer, FormControl, IconButton, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select, TextField, Tooltip
} from "@mui/material";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterPanelOpenStatus,
    changeFilterParams, clearFilterInfo, fetchCurrencyRows,
    fetchIsEslipAttrRows, fetchOrganizationRows, fetchOriginOperationRows,
    setFilterNormal
} from '../../../store/common/eslipsPageSlice'
import dayjs from "dayjs";
import {checkEmptyString} from '../../../util/UtilFunction'
import {useTranslation} from "react-i18next";
import SelectOrgComponent from "./SelectOrgComponent";

const EslipFilterDrawer = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const filterOpen = useSelector(state => state.eslipsPage.filterPanelOpen)
    const filterParamsInit = useSelector(state => state.eslipsPage.filterParams)
    const filterNormal = useSelector(state => state.eslipsPage.filterNormal)

    const {
        currencyRows,
        originOperationRows,
        isEslipAttrs,
        organizationRow,
        isLoadingOrganizations
    } = useSelector(state => state.eslipsPage)

    const [organizationObj, setOrganizationObj] = useState(null);
    const [startDate, setStartDate] = useState(undefined); // null должно быть
    const [finishDate, setFinishDate] = useState(undefined); // null должно быть
    const [amount, setAmount] = useState('');
    const [operationName, setOperationName] = useState('');
    const [isEslip, setIsEslip] = useState('');
    const [currency, setCurrency] = useState('');
    const [merchantName, setMerchantName] = useState('')
    const [rrn, setRrn] = useState('')
    const [pan, setPan] = useState('')
    const [cardHolder, setCardHolder] = useState('')
    const [tid, setTid] = useState('')
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [alertBlockVisible, setAlertBlockVisible] = useState(false)
    const [alertText, setAlertText] = useState(t('pages.eslipsFilter.alertMsg'))
    const [isRequiredFilterIsEmpty, setRequiredFilterIsEmpty] = useState(false)

    // При открытии перечитываем все из стора
    useEffect(() => {
        if (filterOpen) {
            dispatch(fetchOrganizationRows({searchString: ''}))
            dispatch(fetchCurrencyRows())
            dispatch(fetchOriginOperationRows())
            dispatch(fetchIsEslipAttrRows())
        }
    }, [filterOpen])

    // При старте грузим все из стора
    useEffect(() => {
        setOrganizationObj(filterParamsInit.orgId)
        // Нельзя просто так установить дату из строки
        let dateFrom = dayjs(filterParamsInit.dateFrom, 'DD.MM.YYYY')
        let dateTo =  dayjs(filterParamsInit.dateTo, 'DD.MM.YYYY')

        setStartDate(dateFrom)
        setFinishDate(dateTo)

        setAmount(filterParamsInit.amount)
        setOperationName(filterParamsInit.origOperationCode)
        setIsEslip(filterParamsInit.isEslip)
        setCurrency(filterParamsInit.currency)
        setMerchantName(filterParamsInit.merchantName)
        setRrn(filterParamsInit.rrn)
        setPan(filterParamsInit.pan)
        setCardHolder(filterParamsInit.cardHolder)
        setTid(filterParamsInit.terminalHostId)
        setEmail(filterParamsInit.email)
        setPhone(filterParamsInit.phone)

    }, [])

    const filterCloseBtnHandler = () => {
        setRequiredFilterIsEmpty(false)
        hideAlertBlock()

        //console.log("Закрытие фильтра")
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: false}))
    }

    const setFilterInStore = () => {
        dispatch(changeFilterParams({
            currency: currency,
            pan: pan,
            amount: amount,
            dateFrom: (startDate.isValid() ? dayjs(startDate).format('DD.MM.YYYY') : null),
            dateTo: (finishDate.isValid() ? dayjs(finishDate).format('DD.MM.YYYY') : null),
            terminalHostId: tid,
            merchantName: merchantName,
            cardHolder: cardHolder,
            origOperationCode: operationName,
            rrn: rrn,
            isEslip: isEslip,
            email: email,
            phone: phone,
            orgId: organizationObj,
        }))
    }

    const handleOnCancelClick = () => {
        hideAlertBlock()
        setRequiredFilterIsEmpty(false)

        setOrganizationObj(null)
        setStartDate(null)
        setFinishDate(null)
        setAmount('')
        setOperationName('')
        setIsEslip('')
        setCurrency('')
        setMerchantName('')
        setRrn('')
        setPan('')
        setCardHolder('')
        setTid('')
        setEmail('')
        setPhone('')

        dispatch(clearFilterInfo())
        dispatch(setFilterNormal(null))
    }

    const displayAlertBlock = (message) => {
        if (!message) {
            message = t('pages.eslipsFilter.alertMsg');
        }

        setAlertText(message)
        setAlertBlockVisible(true)
    }

    const hideAlertBlock = () => {
        setAlertText(t('pages.eslipsFilter.alertMsg'))
        setAlertBlockVisible(false)
    }

    const checkDateFieldValueIsValid = (dateFieldValue) => {
        return dateFieldValue && dateFieldValue.isValid()
    }

    const checkIsEmptyRequiredFilterField = () => {
        // и не заполненны обязательные
        return (checkEmptyString(rrn)
            && checkEmptyString(tid)
            && !checkDateFieldValueIsValid(startDate)
            && !checkDateFieldValueIsValid(finishDate)
        )
    }

    const handleOnApplyClick = () => {
        hideAlertBlock()

        // Проверка нет ли ошибок
        if (checkIsEmptyRequiredFilterField()) {
            displayAlertBlock(t('pages.eslipsFilter.needSetFilterAlertMsg'))
            setRequiredFilterIsEmpty(true)

            dispatch(setFilterNormal("error"))
            return
        }

        // Если все нормально обновляем стор
        dispatch(setFilterNormal("normal"))
        setFilterInStore()

        // закрыть панель с фильтрами
        filterCloseBtnHandler()
    }

    const loadOrganizationRow = (searchValue) => {
        dispatch(fetchOrganizationRows({searchString: searchValue || ''}))
    }

    return (
        <Drawer
            anchor={"right"}
            open={filterOpen}
            onClose={filterCloseBtnHandler}
        >
            <List sx={{width: '400px'}}>
                <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <ListItemIcon>
                        <ManageSearchOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('pages.eslips.filterHeaderCaption')}/>

                    <Tooltip title={t("filter.closeBtnToolTipText")}>
                        <IconButton aria-label="delete" color="error" size="small"
                                    onClick={() => {
                                        filterCloseBtnHandler()
                                    }}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <Divider sx={{mb: '15px'}}/>

                {
                    alertBlockVisible &&
                    <ListItem>
                        <Alert severity="error">{alertText}</Alert>
                    </ListItem>
                }

                {/*организация*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <SelectOrgComponent
                            options={organizationRow}
                            isLoadingOrganizations={isLoadingOrganizations}
                            loadOrganizationRow={loadOrganizationRow}
                            setOrganizationObjOut={setOrganizationObj}
                            initOrganizationObj={organizationObj}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => {
                            setOrganizationObj(null)
                        }}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*дата С*/}
                <ListItem>
                    <FormControl fullWidth>
                        <DatePicker
                            id="startDate-input"
                            label={t('pages.eslipsFilter.dateFromCaption')}
                            inputFormat="DD.MM.YYYY"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                            }}

                            renderInput={(params) => (
                                <TextField {...params} helperText={null}
                                           error={isRequiredFilterIsEmpty}
                                           size={'small'} InputLabelProps={{
                                    shrink: true,
                                }}/>
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => {
                            setStartDate(null)
                        }}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>
                {/*дата ПО*/}
                <ListItem>
                    <FormControl fullWidth>
                        <DatePicker
                            id="finishDate-input"
                            label={t('pages.eslipsFilter.dateToCaption')}
                            inputFormat="DD.MM.YYYY"
                            value={finishDate}
                            onChange={(newValue) => {
                                setFinishDate(newValue);
                            }}

                            renderInput={(params) => (
                                <TextField {...params} helperText={null}
                                           error={isRequiredFilterIsEmpty}
                                           size={'small'} InputLabelProps={{
                                    shrink: true,
                                }}/>
                            )}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setFinishDate(null)}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Имя оригинальной операции*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="operation-name-select-label">
                            {t('pages.eslipsFilter.originOperationCaption')}
                        </InputLabel>
                        <Select
                            labelId="operation-name-select-label"
                            id="operation-name-select"
                            value={operationName}
                            label={t('pages.eslipsFilter.originOperationCaption')}
                            onChange={(event) => {
                                setOperationName(event.target.value)
                            }}
                        >
                            <MenuItem value="">
                                <em>{t('filter.noneValueItemCaption')}</em>
                            </MenuItem>
                            {originOperationRows.map((itemi, key) => (
                                <MenuItem key={key} value={itemi.key}>{itemi.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => {
                            setOperationName('')
                        }}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Признак ввода подписи*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="iseslip-select-label">
                            {t('pages.eslipsFilter.isEslipCaption')}
                        </InputLabel>
                        <Select
                            labelId="iseslip-select-label"
                            id="iseslip-select"
                            value={isEslip}
                            label={t('pages.eslipsFilter.isEslipCaption')}
                            onChange={(event) => {
                                setIsEslip(event.target.value)
                            }}
                        >
                            <MenuItem value="">
                                <em>{t('filter.noneValueItemCaption')}</em>
                            </MenuItem>
                            {isEslipAttrs.map((itemi, key) => (
                                <MenuItem key={key} value={itemi.key}>{itemi.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => {
                            setIsEslip('')
                        }}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Валюта*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="demo-simple-select-label">{t('pages.eslipsFilter.currencyCaption')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currency}
                            label={t('pages.eslipsFilter.currencyCaption')}
                            onChange={(event) => {
                                setCurrency(event.target.value)
                            }}
                        >
                            <MenuItem value="">
                                <em>{t('filter.noneValueItemCaption')}</em>
                            </MenuItem>
                            {currencyRows.map((itemi, key) => (
                                <MenuItem key={key} value={itemi.key}>{itemi.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={(e) => {
                            setCurrency(null)
                        }}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Сумма*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="standard-basic" label={t('pages.eslipsFilter.amountCaption')}
                                   variant="outlined" size={'small'} type={'number'}
                                   value={amount}
                                   onChange={(event) => setAmount(event.target.value)}
                                   inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setAmount('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*Название мерчанта*/}
                <ListItem>
                    <FormControl fullWidth size={'small'}>
                        <TextField id="merchantName-input"
                                   label={t('pages.eslipsFilter.merchantNameCaption')}
                                   variant="outlined" size={'small'}
                                   value={merchantName}
                                   onChange={(event) => {
                                       setMerchantName(event.target.value);
                                   }}
                            // error={true}
                            // focused={true}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setMerchantName('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*RRN*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="rrn-input"
                                   label={t('pages.eslipsFilter.rrnCaption')}
                                   variant="outlined" size={'small'}
                                   error={isRequiredFilterIsEmpty}
                                   value={rrn}
                                   onChange={(event) => {
                                       setRrn(event.target.value)
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setRrn('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*PAN*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="pan-input"
                                   label={t('pages.eslipsFilter.panCaption')}
                                   variant="outlined"
                                   size={'small'}
                                   value={pan}
                                   onChange={(event) => {
                                       setPan(event.target.value)
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setPan('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*CARDHOLDER*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="cardholder-input" variant="outlined" size={'small'}
                                   label={t('pages.eslipsFilter.cardHolderCaption')}
                                   value={cardHolder}
                                   onChange={(event) => {
                                       setCardHolder(event.target.value)
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setCardHolder('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*TID*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="tid-input" variant="outlined"
                                   label={t('pages.eslipsFilter.tidCaption')}
                                   error={isRequiredFilterIsEmpty}
                                   size={'small'}
                                   value={tid}
                                   onChange={(event) => {
                                       setTid(event.target.value)
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setTid('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*почта*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="email-input"
                                   label={t('pages.eslipsFilter.emailCaption')}
                                   variant="outlined"
                                   size={'small'}
                                   value={email}
                                   onChange={(event) => {
                                       setEmail(event.target.value)
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'} onClick={() => setEmail('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {/*телефон*/}
                <ListItem>
                    <FormControl fullWidth>
                        <TextField id="phone-input"
                                   label={t('pages.eslipsFilter.phoneCaption')}
                                   variant="outlined"
                                   size={'small'}
                                   value={phone}
                                   onChange={(event) => {
                                       setPhone(event.target.value)
                                   }}
                                   InputProps={{
                                       classes: {
                                           // root: classes.cssOutlinedInput,
                                           // focused: classes.cssFocused,
                                           notchedOutline: 'green !important',
                                       },
                                       inputMode: "numeric"
                                   }}
                        />
                    </FormControl>
                    <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                        <IconButton size={'small'}
                                    onClick={() => setPhone('')}>
                            <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                        </IconButton>
                    </Tooltip>
                </ListItem>

                <Divider sx={{mt: '15px'}}/>
                <ListItem sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleOnApplyClick}
                    >
                        {t('filter.applyBtnCaption')}
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleOnCancelClick}
                    >
                        {t('filter.clearAllFieldBtnCaption')}
                    </Button>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default EslipFilterDrawer;