import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";
import {
    buildFilterPredicateCommon,
    valueDateToStringConverter
} from "../../util/FilterUtilFuctions";

export const fetchEslipsExportStatusRows = createAsyncThunk(
    'exportManagerPage/fetchEslipsExportStatusRows',
    async function(addInfo, {rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const response = await instanceAxiosApi.get("/ui/v1/eslips/export/statuses")
            return response
        } catch (error) {
            showSnackMsg('error', 'systemCommon.errorRequestInfoCaption')
        }
    }
)

export const fetchEslipsExportRows = createAsyncThunk(
    'exportManagerPage/fetchEslipsExportRows',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const {page, limit, filterModel} = getState().exportManagerPage
            let params = buildParamsForRequest(filterModel, page, limit)

            const response = await instanceAxiosApi.get(`/ui/v1/eslips/export?${params}`)
            // Может понадобится вернуть сообщение
            //showSnackMsg('success', 'systemCommon.dataRowsResponseSuccessfully')
            return response
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

const buildParamsForRequest = (filterModel, page, limit) => {
    let checkField = ['id', 'username', 'requestStartDate', 'requestEndDate', 'exportStatus']
    let fieldNeedConvert = { 'requestStartDate': valueDateToStringConverter, 'requestEndDate': valueDateToStringConverter}
    let filterPredicateArray = []
    checkField.forEach(fieldName => {
        let predicatei = buildFilterPredicateCommon(filterModel, fieldName, fieldNeedConvert[fieldName])
        if (predicatei) filterPredicateArray.push(predicatei)
    })

    // сервер ожидает параметры в таком виде
    //page=1&limit=10&filter=[{"key":"name","operation":"like","value":["Н1к"]}]&order=[]
    let params = `page=${page}&limit=${limit}&filter=${JSON.stringify(filterPredicateArray)}`



    return params
}

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({
            errorCode: 403,
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({
            errorCode: 400,
            error: errorMessage}
        )
    } else {
        // Сообщение выдаст центральный axios interceptor
        return rejectWithValue({
            errorCode: 500,
            error: 'authProvider.internalServerErrorMessage'
        })
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
}



const exportManagerPageSlice = createSlice({
    name: 'exportManagerPage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        status: null,
        error: null,

        filterModel: {
            id: {},
            username: {},
            requestStartDate: {},
            requestEndDate:{},
            exportStatus:{}
        },

        dataRows: [],
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 30,

        filterPanelOpen: false,

        exportStatuses: []
    },

    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        changeFilterPanelOpenStatus(state, action) {
            state.filterPanelOpen = action.payload.filterPanelOpen;
        },

        setFilterModel(state, action) {
            state.filterModel = {...state.filterMode, ...action.payload.filterModel}
        },

        setSortModel(state, action) {
            state.sortModel = action.payload.sortModel
        },

        setPage(state, action) {
            state.page = action.payload
        },

        setTotalRows(state, action) {
            state.totalRows = action.payload.totalRows
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload.totalPages
        },

        setLimit(state, action) {
            state.limit = action.payload
        },

        /* Инициализация всей страницы при открытии */
        initPageData(state, action) {
            state.status = null
            state.error = null
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEslipsExportRows.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchEslipsExportRows.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let dataJson = action.payload?.data || {rows: [], totalPages: 0}

                state.dataRows = dataJson.rows

                state.totalPages = dataJson.totalPages

                // Если на какой-то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
                if (dataJson.totalPages < state.page) {
                    state.page = 1
                }
            })
            .addCase(fetchEslipsExportRows.rejected, (state, action) => setError(state, action))

            /* Загрузка статусов */
            .addCase(fetchEslipsExportStatusRows.pending, (state, action) => {
                //
            })
            .addCase(fetchEslipsExportStatusRows.fulfilled, (state, action) => {
                state.exportStatuses = action.payload?.data || []
            })
            .addCase(fetchEslipsExportStatusRows.rejected, (state, action) => {
                //
            })

    }
})

export const {
    setNeedRefreshData,
    changeFilterPanelOpenStatus,
    setFilterModel,
    setSortModel,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit,
    initPageData
} = exportManagerPageSlice.actions

export default exportManagerPageSlice.reducer