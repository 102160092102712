import React, {useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {setConfirmationCode} from "../../store/auth/passwordRecoveryPageSlice";
import {RouteEnum} from "../../types/RouteEnum";
import {Alert, Box, Button, InputAdornment, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ChangePasswordWithOldPwd = (props) => {
    const {changePasswordFn} = props

    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    const [showPasswordOld, setShowPasswordOld] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const {t} = useTranslation()

    const formik = useFormik({
        initialValues: {
            passwordUserOld: '',
            passwordUser: '',
            passwordUserConfirm: ''
        },
        validationSchema: Yup.object({
            passwordUserOld: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.passwordComponent.passwordOldCheckRequired')),
            passwordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordCheckRequired'))
                .matches(/^(?=.*\d)(?=.*[!"#$%&'()\*+,-.\/:;<=>\\?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).\S{3,}$/, t('pages.restorePassword.passwordRulesError')),
            passwordUserConfirm: Yup
                .string()
                .oneOf([Yup.ref('passwordUser'), null], t('pages.restorePassword.passwordNotEqual'))
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordConfirmCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            try {
                // Внешняя асинхронная функция по изменению данных на сервер
                await changePasswordFn(values.passwordUserOld, values.passwordUser, values.passwordUserConfirm)
                //formikHelpers.resetForm()
            } catch (e) {
                let errorStatus = e?.response?.status || 400
                let errorMessage = e?.response?.data?.uiMessage || e?.message

                formikHelpers.setSubmitting(false)
                setErrorSubmitMsg(errorMessage)
                setIsSubmitError(true)
            }
        }
    });

    const handleClickShowPasswordOld = () => setShowPasswordOld((show) => !show);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            {
                isSubmitError &&
                <Alert severity="error">{errorSubmitMsg}</Alert>
            }

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    error={Boolean(formik.touched.passwordUserOld && formik.errors.passwordUserOld)}
                    fullWidth
                    helperText={formik.touched.passwordUserOld && formik.errors.passwordUserOld}
                    label={t('pages.passwordComponent.passwordUserOldLabel')}
                    margin="normal"
                    name="passwordUserOld"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPasswordOld ? 'text' : 'password'}
                    value={formik.values.passwordUserOld}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordOld}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPasswordOld ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <TextField
                    error={Boolean(formik.touched.passwordUser && formik.errors.passwordUser)}
                    fullWidth
                    helperText={formik.touched.passwordUser && formik.errors.passwordUser}
                    label={t('pages.restorePassword.passwordUserLabel')}
                    margin="normal"
                    name="passwordUser"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.passwordUser}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <TextField
                    error={Boolean(formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm)}
                    fullWidth
                    helperText={formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm}
                    label={t('pages.restorePassword.passwordUserConfirmLabel')}
                    margin="normal"
                    name="passwordUserConfirm"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    value={formik.values.passwordUserConfirm}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConfirm}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <Box sx={{py: 2}}>
                    <Button
                        color="primary"
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('pages.restorePassword.step3BtnCaption')}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default ChangePasswordWithOldPwd;