import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, InputAdornment, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

/**
 * Диалог для добавления новой или изменения старой организации.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PersonsSetPasswordDialog = (props) => {
    const {t} = useTranslation()
    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, personNameForAction, personIdForAction, refreshListRows} = props

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            clearError()
            setShowPassword(false)
            setShowPasswordConfirm(false)
            formik.setFieldValue('passwordUser', '')
            formik.setFieldValue('passwordUserConfirm', '')
            formik.resetForm()
        }
    }, [open])

    const fetchApi = async (json) => {
        return await instanceAxiosApi.put("/ui/v1/users/admin/change_password", JSON.stringify(json));
    }

    const formik = useFormik({
        initialValues: {
            passwordUser: '',
            passwordUserConfirm: ''
        },
        validationSchema: Yup.object({
            passwordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordCheckRequired'))
                .matches(/^(?=.*\d)(?=.*[!"#$%&'()\*+,-.\/:;<=>\\?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).\S{3,}$/, t('pages.restorePassword.passwordRulesError')),
            passwordUserConfirm: Yup
                .string()
                .oneOf([Yup.ref('passwordUser'), null], t('pages.restorePassword.passwordNotEqual'))
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordConfirmCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)
            try {
                let json = {
                    "userId": personIdForAction,
                    "newPassword": values.passwordUser,
                    "passwordConfirm": values.passwordUserConfirm
                }

                await fetchApi(json)

                enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                    variant: 'success',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })

                setOpen(false);
            } catch (e) {
                // Ошибки могут быть: Нет доступа и все остальные
                let errorStatus = e?.response?.status || 400
                let errorMessage = e?.response?.data?.uiMessage || t('systemCommon.errorChangingInformationMsg')

                if (errorStatus === 403) {
                    enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                        variant: 'warning',
                        preventDuplicate: true,
                        anchorOrigin: {vertical: 'top', horizontal: 'right'},
                    })

                    setErrorSubmitMsg(t('systemCommon.accessDeniedMessage'))
                } else {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: {vertical: 'top', horizontal: 'right'},
                    })

                    setErrorSubmitMsg( errorMessage)
                }

                setIsSubmitError(true)

                formikHelpers.setSubmitting(false)
            } finally {
                setIsProcessed(false)
            }
        }
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = async () => {
        clearError()
        formik.resetForm()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }



    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle> {t('pages.passwordComponent.userChangePasswordCaption')}: {personNameForAction}</DialogTitle>
                    {
                        isProcessed &&
                        <Box sx={{}}>
                            <LinearProgress/>
                        </Box>
                    }
                    <Divider/>
                    <DialogContent>
                        {
                            isSubmitError &&
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        }


                            <TextField
                                error={Boolean(formik.touched.passwordUser && formik.errors.passwordUser)}
                                fullWidth
                                helperText={formik.touched.passwordUser && formik.errors.passwordUser}
                                label={t('pages.restorePassword.passwordUserLabel')}
                                margin="normal"
                                name="passwordUser"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.passwordUser}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextField
                                error={Boolean(formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm)}
                                fullWidth
                                helperText={formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm}
                                label={t('pages.restorePassword.passwordUserConfirmLabel')}
                                margin="normal"
                                name="passwordUserConfirm"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type={showPasswordConfirm ? 'text' : 'password'}
                                value={formik.values.passwordUserConfirm}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                            {t('systemCommon.dlgCloseBtnCaption')}
                        </Button>
                        <Button disabled={isProcessed} /*collor={"submit"}*/ type={"submit"}>
                            {t('systemCommon.dlgApplyBtnCaption')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default PersonsSetPasswordDialog;