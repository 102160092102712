import instanceAxiosApi from "./axiosApi";
import instanceAxiosPortal from "./axiosPortal";
import {dellEndSlash} from "../util/UtilFunction";

/**
 * Если настройка не задана (так бывает для STANDALONE режима)
 * сервер возвратит это значение.
 * !! Важно задается на сервере только для CLUSTER режима
 *
 * @type {string}
 */
const STANDALONE_URL = 'standalone'

/**
 * Для режима РАЗРАБОТКИ в файле package.json необходимо прописать прокси для API
 * STANDALONE: "proxy": "http://127.0.0.1:8081", - тут развернуто приложение все в одном
 * CLUSTER:    "proxy": "http://127.0.0.1:8082", - адрес главной части приложения eslip-main-app
 *
 * и так же для РАЗРАБОТКИ CLUSTER режима необходимо
 * в файле .env указать REACT_APP_PORTAL_APP_BASE_URL=http://localhost:8083/eslip-portal
 * прокси указать нельзя, он будет обслуживать вызовы API
 *
 * process.env.NODE_ENV
 * Определяет только режим запуска. Существует только на момент сборки.
 * в процессе работы поменять уже нельзя.
 */
const APP_PORTAL_BASE_URL = (process.env.NODE_ENV === 'production')
    ? dellEndSlash(window.location.origin + window.location.pathname)
    : dellEndSlash(process.env.REACT_APP_PORTAL_APP_BASE_URL);

/**
 *  CLUSTER: среде возвращает, что в настройказ получили + "/ui"
 *  Например: http://127.0.0.1:8080/eslip-main-app/rest/api/ui",
 *  STANDALONE: без адреса и порта. Например: "/eslip-main-app/rest/api/ui",
 * @returns {string|string}
 */
const resolveBaseApiForUI = () => {
    let url = localStorage.getItem("apiBaseUrl") || STANDALONE_URL

    if (STANDALONE_URL === url) {
        return "/eslip-main-app/rest/api";
    } else {
        return url;
    }
}

/**
 * Определение адреса портальной части, в зависимости от режима разработки и режима запуска.
 *
 * @returns {string}
 */
const resolveBaseApiForPortal = () => {
    return APP_PORTAL_BASE_URL + '/rest/api'
}

/**
 * Переменная для присваивания функции по обновлению токена доступа.
 * Если не делать общей функции, то несколько запросов могут пойти за обновлением токена,
 * а так у всех будет ссылка на один и тот-же вызов.
 *
 * По следам статьи:
 * https://andreyka26.com/handling-refreshing-token-on-multiple-requests-using-react
 *  */
let refreshingFunc = undefined;

/**
 * Определение интерцепторов для всех axios инстансов.
 *
 * @param forbiddenPageRedirect
 * @param clearUserInfoFromLS
 * @param userPasswordNeedChange
 * @param enqueueSnackbar
 * @param t
 * @param refreshAccessToken
 */
const setupAxiosApiInterceptors = (
    forbiddenPageRedirect, clearUserInfoFromLS,
    userPasswordNeedChange, enqueueSnackbar, t,
    refreshAccessToken
) => {

    /**
     * Предобработчик ОТВЕТА для вызовов API.
     * Если протух токен доступа, делается вызов на обновление токена.
     */
    instanceAxiosApi.interceptors.response.use(response => {
        return response;
    }, async error => {
        // Если кода ошибки нет?!?! считаем что внутренняя ошибка сервера
        let errorStatus = error?.response?.status || 500
        let errorCause = error?.response?.headers?.unauthorized_add_code || "E_401_1"
        let errorUrl = error?.config?.url || 'api'

        // Если ошибка при входе или выходе просто прокидываем ее дальше
        if (errorUrl === '/auth/login' || errorUrl === '/auth/logout') {
            return Promise.reject(error)
        }

        // Значит произошел вызов из вызова
        if (errorStatus === 401 && errorUrl === '/auth/token/refresh') {
            clearUserInfoFromLS(true)
            return Promise.reject(error)
        }

        // Сохраняем оригинальный запрос
        const originalConfig = error.config;

        // E_401_1 - Доступ запрещен
        // E_401_2 - Пользователь заблокирован
        // E_401_3 - Необходимо изменить пароль
        // E_401_4 - Необходимо изменить пароль E_401_4 похож на E_401_3 - различаются причины изменения пароля
        // E_401_5 - Истек срок действия token-а доступа
        if (errorStatus === 401) {
            switch (errorCause) {
                case "E_401_1" : {
                    clearUserInfoFromLS()
                    return
                }
                case "E_401_2" : {
                    clearUserInfoFromLS()
                    return
                }
                case "E_401_3" : {
                    userPasswordNeedChange()
                    return
                }
                case "E_401_4" : {
                    userPasswordNeedChange()
                    return
                }

                case "E_401_5" : {
                    try {
                        // Если глобальной функции еще нет - присваиваем
                        if (!refreshingFunc) {
                            refreshingFunc = refreshAccessToken()
                        }
                        // ждем выполнения ГЛОБАЛЬНОЙ функции
                        await refreshingFunc

                        return await instanceAxiosApi.request(originalConfig);
                    } catch(innerError) {
                        return // Ошибку не выидываем, просто уходим
                    } finally {
                        refreshingFunc = undefined
                    }
                }
            }
        }

        // 403 не можем тут перехватить, потому что может быть страница запрашивается
        // и выполнение операции (вызов рест).
        // 403 обрабатываем по месту
        // 400 обрабатываем по месту, не всегда нужно сообщение об ошибке

        // 500 Всегда показываем сообщение, но прокидываем ошибку дальше
        if (errorStatus === 500) {
            console.error("Internal API server error")

            enqueueSnackbar(t('authProvider.internalServerErrorMessage'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        }

        return Promise.reject(error)
    });

    /**
     * Для запросов API. При запросе необходимо указать: токен, локаль.
     */
    instanceAxiosApi.interceptors.request.use(
        config => {
            let isAuthUrl = true

            // Список ссылок для которых не нужно прописывать токен
            let restNoToken = [
                "/auth/password/mandatory_change",
                "/auth/login",
                "/auth/logout"
            ];

            if (restNoToken.filter(r => r === config.url).length > 0) {
                isAuthUrl = false
            }

            if (isAuthUrl) {
                config.headers.Authorization = 'Bearer ' + (localStorage.getItem('accessToken') || "empty");
            }

            config.headers.lang = localStorage.getItem('i18nextLng') || 'ru'
            config.baseURL = resolveBaseApiForUI()

            return config;
        },
        error => Promise.reject(error)
    );

    /**
     * Для запросов к портальной части. При запросе необходимо указать локаль.
     */
    instanceAxiosPortal.interceptors.request.use(
        config => {
            config.headers.lang = localStorage.getItem('i18nextLng') || 'ru'
            config.baseURL = resolveBaseApiForPortal()

            return config;
        },
        error => Promise.reject(error)
    );
}


export {setupAxiosApiInterceptors}