import React from 'react';
import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import EslipArchiveFilterDrawer from "./EslipArchiveFilterDrawer";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import EslipArchiveSettingShowColumn from "./EslipArchiveSettingShowColumn";
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {changeFilterPanelOpenStatus, changSettingsPanelOpenStatus} from "../../../store/common/eslipsArchivePageSlice";
import {
    changeAddToArchiveDlgOpenStatus,
    changeDeleteFromArchiveDlgOpenStatus
} from "../../../store/common/eslipsArchiveJobPageSlice";
import {RoleEnum} from "../../../types/RoleEnum";
import {useAuth} from "../../../hook/useAuth";


const EslipsArchiveToolbar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {checkExistsRoles} = useAuth()

    const {archiveMode} = useSelector(state => state.eslipsArchivePage)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const isSearchMode = () => {
        return archiveMode === 'search'
    }

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }

    const settingsBtnClickHandler = () => {
        dispatch(changSettingsPanelOpenStatus(true))
    }

    const archiveBtnClickHandler = () => {
        dispatch(changeAddToArchiveDlgOpenStatus(true));
    }

    const deleteFromArchiveBtnClickHandler = () => {
        dispatch(changeDeleteFromArchiveDlgOpenStatus(true));
    }

    return (
        <div>
            <Box {...props}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1
                    }}
                >
                    <Typography
                        sx={{ m: 1 }}
                        variant="h4"
                    >
                        {t('pages.archive.pageTitle')}
                    </Typography>

                    <Box sx={{ m: 1 }}>
                        {
                            !isSearchMode() && checkExistsRoles([RoleEnum.ADMINISTRATOR, RoleEnum.OPERATOR]) &&
                            <>
                                <Button
                                    size={"small"}
                                    startIcon={<ArchiveOutlinedIcon />}
                                    onClick={archiveBtnClickHandler}
                                >
                                    {lgUp && t('pages.archive.addToArchiveBtnCaption')}
                                </Button>

                                <Button
                                    size={"small"}
                                    startIcon={<DeleteForeverOutlinedIcon />}
                                    onClick={deleteFromArchiveBtnClickHandler}
                                >
                                    {lgUp && t('pages.archive.deleteFromArchiveCaption')}
                                </Button>
                            </>
                        }

                        {
                            isSearchMode() &&
                            <>

                                <Button
                                    size={"small"}
                                    startIcon={<TuneOutlinedIcon />}
                                    onClick={settingsBtnClickHandler}
                                >
                                    {lgUp && t('pages.eslips.tableSettingsCaption')}
                                </Button>

                                <Button
                                    size={"small"}
                                    startIcon={<ManageSearchOutlinedIcon />}
                                    onClick={filterOpenBtnClickHandler}
                                >
                                    {t('pages.eslips.filterBtnCaption')}
                                </Button>
                            </>
                        }

                    </Box>
                </Box>
            </Box>

            {/*Фильтр на боковой панели*/}
            <EslipArchiveFilterDrawer />

            {/*панель с настройкой отображения колонок*/}
            <EslipArchiveSettingShowColumn />
        </div>
    );
};

export default EslipsArchiveToolbar;