import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Box, Button, Container, Grid, LinearProgress, Typography, useMediaQuery} from "@mui/material";

import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonCardAvatar from "./PersonCardAvatar";
import PersonCardDetail from "./PersonCardDetail";
import {setActiveSection, setBreadcrumbs} from "../../../../store/baseLayoutSlice";
import {useTranslation} from "react-i18next";
import {
    fetchPersonCardData,
    fetchRolesSystem,
    initAllData, initNewUser,
    setUserId
} from "../../../../store/administration/personCardPageSlice";
import AccessDeniedComponent from "../../../../components/AccessDeniedComponent";


const PersonCardPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()

    // const {userId_param} = useParams()
    const {status, error, is403, userId}  = useSelector(state => state.personCardPage)

    useEffect(() => {
        async function initData() {
            dispatch(setActiveSection({href:'/persons'}))

            await dispatch(initAllData())
            await dispatch(fetchRolesSystem())

            if (userId) {
                // await dispatch(setUserId(userId_param))
                await dispatch(fetchPersonCardData())
            } else {
                await dispatch(initNewUser())
            }
        }

        if (!userId) {
            document.title = 'Add new User | UniPORT E-slip';
        } else {
            document.title = 'Edit User | UniPORT E-slip';
        }

        dispatch(setActiveSection({href: '/persons'}))

        const items = [
            {href:"/persons", caption:'pages.persons.pageTitle', active: true},
            {href:"/persons",
                caption: !userId ? 'pages.profilePage.pageTitleNew' : 'pages.profilePage.pageTitleEdit',
                active: false
            },
        ]
        dispatch(setBreadcrumbs(items))

        initData()
    }, [dispatch, userId]);

    useEffect(() => {
        async function initData() {
            dispatch(setActiveSection({href:'/persons'}))

            await dispatch(initAllData())
            await dispatch(fetchRolesSystem())

            if (userId) {
                // await dispatch(setUserId(userId_param))
                await dispatch(fetchPersonCardData())
            } else {
                await dispatch(initNewUser())
            }
        }

        if (!userId) {
            document.title = 'Add new User | UniPORT E-slip';
        } else {
            document.title = 'Edit User | UniPORT E-slip';
        }

        dispatch(setActiveSection({href: '/persons'}))

        const items = [
            {href:"/persons", caption:'pages.persons.pageTitle', active: true},
            {href:"/persons",
                caption: !userId ? 'pages.profilePage.pageTitleNew' : 'pages.profilePage.pageTitleEdit',
                active: false
            },
        ]
        dispatch(setBreadcrumbs(items))

        initData()
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}>
                    <Typography
                        sx={{ mb: 3 }}
                        variant="h4"
                    >
                        {!userId ? t('pages.profilePage.pageTitleNew') : t('pages.profilePage.pageTitleEdit')}
                    </Typography>

                    <Box sx={{display: 'flex', m: 1, mb:2}}>
                        <Button
                            startIcon={<ArrowBackIcon fontSize="small" />}
                            onClick={() => navigate("/persons")}
                        >
                            {t('pages.profilePage.goToListUserBtnCaption')}
                        </Button>
                    </Box>
                </Box>

                {
                    (status === 'loading') && <LinearProgress/>
                }

                {
                    is403 && <AccessDeniedComponent />

                }
                {
                    status !== 'loading' &&
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <PersonCardAvatar />
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={8}
                            xs={12}
                        >
                            <PersonCardDetail />
                        </Grid>
                    </Grid>
                }
            </Container>
        </Box>
    );
};

export default PersonCardPage;