import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ThemeProvider} from "@mui/material";
import {theme} from './theme';
import {Provider} from 'react-redux';
import store from './store'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/material/locale";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs} adapterLocale={ruRU}
                >
                    <App/>
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);


