import axios from "axios";

/**
 * Получение инстанса axios с преднастроенными значениями по умолчанию для API сервера.
 *
 * @type {axios.AxiosInstance}
 */
const instanceAxiosApi = axios.create({
    // Настроится в interceptor: setupAxiosInterceptors.js
    //baseURL: "http://127.0.0.1:8080/eslip-main-app/rest/api/ui", // "/eslip-main-app/rest/api/ui", //http://127.0.0.1:8082/eslip-main-app/rest/api",
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':'*',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
})






export default instanceAxiosApi