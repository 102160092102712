import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {
    setIsLoading,
    setLimit,
    setNeedRefreshData,
    setPage,
    setTotalPages
} from "../../../store/handbook/terminalsPageSlice";
import instanceAxiosApi from "../../../service/axiosApi";
import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import LoadDataInTable from "../../../components/dataGridCustom/LoadDataInTable";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import PagingComponentTable from "../../../components/dataGridCustom/PagingComponentTable";
import {buildFilterPredicateCommon, valueBooleanConverter} from "../../../util/FilterUtilFuctions";

const TerminalsList = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()

    const needRefreshData = useSelector(state => state.terminalsPage.needRefreshData)
    const isLoading = useSelector(state => state.terminalsPage.isLoading)
    const limit = useSelector(state => state.terminalsPage.limit)

    const filterModel = useSelector(state => state.terminalsPage.filterModel)
    const sortModel = useSelector(state => state.terminalsPage.sortModel)

    const page = useSelector(state => state.terminalsPage.page)
    const totalPages = useSelector(state => state.terminalsPage.totalPages)
    const [is403, setIs403] = useState(false)
    const [dataRows, setDataRows] = useState([])

    const [selectRow, setSelectRow] = useState('')

    useEffect(() => {
        // console.log("изменили параметры поиска")
        // console.log("filterModel: " + filterModel )
        // console.log("sortModel: " + sortModel)
        // console.log("page: " + page)

        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            )

    }, [filterModel, sortModel, page, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])

    const setPageL = (num) => {
        dispatch(setPage({page: num}))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit({limit: limit}))
    }

    const fetchApi = async (params) => {
        // Идем на сервер
        return await instanceAxiosApi.get("/ui/v1/terminals?" + params)
    }

    const buildParamsForRequest = () => {
        let checkField = ['tid', 'serial', 'merchantName']
        // Например: {'active': valueBooleanConverter}
        let fieldNeedConvert = {}
        let filterPredicateArray = []
        checkField.forEach(fieldName => {
            let predicatei = buildFilterPredicateCommon(filterModel, fieldName, fieldNeedConvert[fieldName])
            if (predicatei) filterPredicateArray.push(predicatei)
        })

        // сервер ожидает параметры в таком виде
        //page=1&limit=10&filter=[{"key":"name","operation":"like","value":["Н1к"]}]&order=[]
        let params = `page=${page}&limit=${limit}&filter=${JSON.stringify(filterPredicateArray)}`

        // TODO : реализовать если нужно по аналогии с фильтр модель
        if (sortModel && Object.entries(sortModel.tid).length > 0) {
            // let sorti={"key":"name","operation":"","value":[]}
        }

        return params
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading: true}))
        try {
            let params = buildParamsForRequest()
            let response = await fetchApi(params)

            let dataJson = response?.data || {rows: [], totalPages: 0}

            setDataRows(dataJson.rows)
            dispatch(setTotalPages({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                setPageL(1)
            }
        } catch (e) {
            // Ошибки могут быть: Нет доступа и все остальные
            let errorStatus = e?.response?.status || 400

            if (errorStatus === 403) {
                setIs403(true)

                enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            } else {
                setIs403(false)

                enqueueSnackbar(t('systemCommon.errorRequestInfoCaption'), {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            }
            // Скидываем данные в таблице
            setDataRows([])

            dispatch(setTotalPages({totalPages: (0)}))
        } finally {
            dispatch(setIsLoading({isLoading: false}))
        }
    }

    const headerModel = [
        {"name": "terminalhostId", "caption": t('pages.terminals.tidColumn'), "isVisible": "true", "align": "left", "width": "200px"},
        {"name": "serial", "caption": t('pages.terminals.serialColumn'), "isVisible": "true", "align": "left", "width": "200px"},
        {"name": "merchant", "caption": t('pages.terminals.merchantColumn'), "isVisible": "true", "align": "left", "width": "400px"},
    ]

    return (
        <Box>
            {
                isLoading && <LoadDataInTable/>
            }
            {
                !isLoading && is403 &&
                <AccessDeniedComponent/>
            }
            {
                !isLoading && !is403 &&
                <Card>
                    <PerfectScrollbar>
                        <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                            <Table size="medium">
                                <TableHead sx={{height: '60px'}}>
                                    <TableRow>
                                        {
                                            headerModel.map((rowi, key) => (
                                                <TableCell align={rowi.align} key={key}
                                                           style={{minWidth: rowi.width}}>
                                                    {rowi.caption}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    // !! иначе 0 будет писаться
                                    !!totalPages &&
                                    <TableBody>
                                        {dataRows.map((rowi) => (
                                            <TableRow onClick={() => setSelectRow(rowi.id)}
                                                      hover
                                                      key={rowi.id}
                                                      selected={rowi.id === selectRow}
                                            >
                                                <TableCell>
                                                    {rowi.terminalhostId}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.serial}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.merchant.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                        </Box>
                    </PerfectScrollbar>

                    {
                        !totalPages &&
                        <DataNotFoundComponent refreshDataFn={loadData} />
                    }

                    {
                        !isLoading &&
                        <PagingComponentTable
                            totalPages={totalPages}
                            page={page} setPageFn={setPageL}
                            limit={limit} setLimitFn={setLimitL}
                            refreshDataFn={loadData} />
                    }
                </Card>
            }
        </Box>
    )
};

export default TerminalsList;