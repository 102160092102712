import {createSlice} from "@reduxjs/toolkit";

const organizationsPageSlice = createSlice({
    name: 'organizationsPage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        isLoading: false,

        filterModel: {
            orgName: {},
            orgId: {},
            bankName: {}
        },

        sortModel: {
            orgName: {},
        },

        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 10,
        filterPanelOpen: false,
    },

    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        setIsLoading(state, action) {
            state.isLoading = action.payload.isLoading
        },

        setFilterModel(state, action) {
            state.filterModel = {...state.filterMode, ...action.payload.filterModel}
        },

        setSortModel(state, action) {
            state.sortModel = action.payload.sortModel
        },

        setPage(state, action) {
            state.page = action.payload.page
        },

        setTotalRows(state, action) {
            state.totalRows = action.payload.totalRows
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload.totalPages
        },

        setLimit(state, action) {
            state.limit = action.payload.limit
        },

        changeFilterPanelOpenStatus(state, action) {
            state.filterPanelOpen = action.payload.filterPanelOpen;
        }
    }
})

export const {
    setNeedRefreshData,
    setIsLoading,
    setFilterModel,
    setSortModel,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit,
    changeFilterPanelOpenStatus
} = organizationsPageSlice.actions

export default organizationsPageSlice.reducer