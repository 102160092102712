import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";

/* Роли - полный список */
// export const fetchRolesSystem = createAsyncThunk(
//     'profilePage/fetchRolesSystem',
//     async function() {
//         try {
//             const response = await instanceAxiosApi.get("/ui/v1/roles?")
//
//             return response?.data || []
//         } catch (error) {
//             return []
//         }
//     }
// )

/* Инфа для профиля */
export const fetchUserProfileData = createAsyncThunk(
    'profilePage/fetchUserProfileData',
    async function(_, {rejectWithValue}) {
        try {
            const response = await instanceAxiosApi.get(`/ui/v1/users/profile`)
            return response?.data || {}
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue)
        }
    }
)

export const fetchSaveCardInfo = createAsyncThunk(
    'profilePage/fetchSaveCardInfo',
    async function(addInfo, {getState, rejectWithValue}) {
        const {userAuth, reloadUserInfo, showSnackMsg} = addInfo
        try {
            const {
                avatarChange,
                avatar,

                firstName,
                patronymic,
                lastName,
                phone,
                email,
            } = getState().profilePage

            // Этот объект будем сохранять
            const userForSave = {
                id: userAuth.id,
                avatar: null,
                firstName: firstName,
                patronymic: patronymic,
                lastName: lastName,
                phone: phone,
                email: email,
                roles: []
            }

            // В отдельном блоке изменили фото значит нужно сохранять
            if (avatarChange && avatar) {
                let arrTemp = avatar.split(',')
                userForSave.avatar = arrTemp.slice(arrTemp.length - 1)[0]
            }

            const response = await instanceAxiosApi.put(`/ui/v1/users/profile`, JSON.stringify(userForSave))
            showSnackMsg('success', 'systemCommon.saveDataSuccessMsg')

            // Если страница открыта из профиля, нужно в сторе обновить инфу о текущем пользователе
            reloadUserInfo()
            return response
        } catch (error) {
            return rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        return rejectWithValue({errorCode: 403,
            errorMessageForSnack: 'systemCommon.accessDeniedMessage',
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {

        return rejectWithValue({errorCode: 400,
            errorMessageForSnack: errorMessage,
            error: errorMessage}
        )
    } else {
        return rejectWithValue({errorCode: 500,
            errorMessageForSnack: 'authProvider.internalServerErrorMessage',
            error: 'authProvider.internalServerErrorMessage'}
        )
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
    state.showSnackError = action.payload.errorMessageForSnack

    // showSnackMsg('error', 'error')

    if (action.payload.errorCode === 403) {
        state.is403 = true
    } else {
        state.is403 = false
    }
}

const profilePageSlice = createSlice({
    name: 'profilePage',
    initialState: {
        avatar: null,
        // Признак, что нужно сохранить изображение при сохранении информации
        avatarChange: false,

        login: '',
        firstName: '',
        patronymic: '',
        lastName: '',
        phone: '',
        email: '',

        roles: [],

        status: null,
        error: null,

        openChangePasswordDlg: false
    },

    reducers: {
        initAllData(state, action) {
            state.avatar = null
            state.avatarChange = false

            state.login = ''
            state.firstName = ''
            state.patronymic = ''
            state.lastName = ''
            state.phone = ''
            state.email = ''

            state.status = null
            state.error = null

            state.roles = []
        },

        changeAvatar(state, action) {
            state.avatar = action.payload
            state.avatarChange = true
        },

        updateFormValue(state, action) {
            state.firstName = action.payload.firstName
            state.patronymic = action.payload.patronymic
            state.lastName = action.payload.lastName
            state.phone = action.payload.phone
            state.email = action.payload.email
        },

        setOpenChangePasswordDlg(state, action) {
            state.openChangePasswordDlg = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            /* Пользователь сам просматривает данные */
            .addCase(fetchUserProfileData.pending, (state, action) => {
                state.status = 'loading'
            })

            .addCase(fetchUserProfileData.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                state.avatar = action.payload.avatar

                state.login = action.payload.login
                state.firstName = action.payload.firstName
                state.patronymic = action.payload.patronymic
                state.lastName = action.payload.lastName
                state.phone = action.payload.phone
                state.email = action.payload.email

                state.roles = action.payload.roles
            })

            .addCase(fetchUserProfileData.rejected, (state, action) => setError(state, action))

            /* Сохранение информации */
            .addCase(fetchSaveCardInfo.pending, (state, action) => {
                state.status = 'loading'
            })

            .addCase(fetchSaveCardInfo.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null
                state.avatarChange = false
            })

            .addCase(fetchSaveCardInfo.rejected, (state, action) => setError(state, action))
    },
})

export const {
    initAllData,
    changeAvatar,
    updateFormValue,
    setOpenChangePasswordDlg
} = profilePageSlice.actions;

export default profilePageSlice.reducer;
