import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider, FormControl,
    Grid, InputLabel, LinearProgress, OutlinedInput,
    Select,
    TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {mappingRoleName} from "../../../../util/BackEndUiMapperValueUtils";
import {useDispatch, useSelector} from "react-redux";
import {fetchSaveCardInfo, updateFormValue} from "../../../../store/administration/personCardPageSlice";


const PersonCardAvatar = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const {
        avatarChange,
        userId,
        roles, rolesSystem,
        status, error,
        login, firstName, patronymic, lastName, phone, email
    } = useSelector(state => state.personCardPage)

    const [rolesName, setRolesName] = useState([]);
    const [rolesError, setRolesError] = useState(false)

    useEffect(() => {
        if (rolesSystem && rolesSystem.length) {
            setRolesName(rolesSystem.map(r => r.value))
        }

    }, [rolesSystem])

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    /* Изменение ролей в списке */
    const handleChangeRoles = (event) => {
        const {
            target: {value},
        } = event;

        formik.setFieldValue('roles', value)

        if (!value.length) {
            formik.setErrors({roles:"Необходимо указать роль"})
            setRolesError(true)
        } else {
            setRolesError(false)
        }
    };

    const handleBlurRoles = (event) => {
        const {
            target: {value},
        } = event;

        if (!value.length) {
            formik.setErrors({roles:"Необходимо указать роль"})
            setRolesError(true)
        } else {
            setRolesError(false)
        }
    }



    const formik = useFormik({
        initialValues: {
            login: login,
            firstName: firstName,
            patronymic: patronymic,
            lastName: lastName,
            phone: phone,
            email: email,

            roles: roles || []
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            login: Yup
                .string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterLoginVC')),
            firstName: Yup.string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterFirstNameVC')),
            patronymic: Yup.string()
                .nullable()
                .max(50, t('pages.profilePage.notExceed50characters')),
            lastName: Yup
                .string()
                .max(50, t('pages.profilePage.notExceed50characters'))
                .required(t('pages.profilePage.enterLastNameVC')),
            email: Yup
                .string()
                .nullable()
                .email(t('pages.profilePage.validEmailVC'))
                .max(255, t('pages.profilePage.notExceed255characters'))
                .required(t('pages.profilePage.enterEmailVC'))
            ,
            // не получилось заставить работать вместе со всеми
            //roles: Yup.string().required('Роли не заполнены')
        }),

        onSubmit: async (values, formikHelpers) => {
            try {
                if (!formik.dirty && !avatarChange) {
                    showSnackMsg('warning', "systemCommon.dataHasNotChanged")
                    return
                }

                // проверка ролей
                // if (rolesError) {
                //     formik.setErrors({roles:"Необходимо указать роль"})
                //     return
                // }

                // Если еще не разу не касались поля roles
                if (!values.roles.length) {
                    formik.setErrors({roles:"Необходимо указать роль"})
                    setRolesError(true)
                    return
                }

                const formicCurrentData = {
                    //id: userId,
                    login: values.login,
                    avatar: null,
                    firstName: values.firstName,
                    patronymic: values.patronymic,
                    lastName: values.lastName,
                    phone: values.phone,
                    email: values.email,
                    roles: values.roles
                }
                await dispatch(updateFormValue(formicCurrentData))

                dispatch(fetchSaveCardInfo({
                    showSnackMsg: showSnackMsg
                }))
            } catch (e) {
                formikHelpers.setSubmitting(false)
                // setRolesUser(roles.map(i => i.value))
            }
        },

        onReset: () => {
            // setRolesUser([])
        }
    });

    return (
        <form noValidate
            onSubmit={formik.handleSubmit}
        >
            <Card>
                <CardHeader
                    subheader={t('pages.profilePage.profileCardSubHeader')}
                    title={t('pages.profilePage.profileCardHeader')}
                />
                <Divider/>
                {status === 'loading' && <LinearProgress/>}
                {
                    status !== 'loading' &&
                    <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            error &&
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Alert severity={"error"}>{t(error)}</Alert>
                            </Grid>
                        }
                        {
                            !userId &&
                            <>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.login && formik.errors.login)}
                                        helperText={formik.touched.login && formik.errors.login}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.login}
                                        fullWidth
                                        label={t('pages.profilePage.loginLabel')}
                                        name="login"
                                        variant="outlined"
                                        required={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Box></Box>
                                </Grid>
                            </>
                        }

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                                fullWidth
                                label={t('pages.profilePage.lastNameLabel')}
                                name="lastName"
                                // onChange={handleChange}
                                // required
                                variant="outlined"
                                required={true}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                                fullWidth
                                label={t('pages.profilePage.firstNameLabel')}
                                name="firstName"
                                // onChange={handleChange}
                                // required
                                // value={user.firstName}
                                variant="outlined"
                                required={true}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formik.touched.patronymic && formik.errors.patronymic)}
                                helperText={formik.touched.patronymic && formik.errors.patronymic}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.patronymic}
                                fullWidth
                                label={t('pages.profilePage.patronymicLabel')}
                                name="patronymic"
                                // onChange={handleChange}
                                // required
                                // value={user.patronymic}
                                variant="outlined"
                                required={false}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <Box></Box>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                fullWidth
                                label={t('pages.profilePage.emailLabel')}
                                name="email"
                                // onChange={handleChange}
                                // required
                                // value={user.email}
                                variant="outlined"
                                required={true}
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                fullWidth
                                label={t('pages.profilePage.phoneLabel')}
                                name="phone"
                                // onChange={handleChange}
                                type="text"
                                // value={user.phone}
                                variant="outlined"
                                required={false}
                            />
                        </Grid>


                        <Grid item
                              md={12}
                              xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel id="role-multiple-chip-select-label" required={true}>
                                    {t('pages.profilePage.rolesLabel')}
                                </InputLabel>
                                <Select fullWidth={true}
                                        labelId="role-multiple-chip-select-label"
                                        id="roles"
                                        mame="roles"
                                        required={true}
                                        multiple
                                        error={Boolean(formik.touched.roles && formik.errors.roles)}
                                        // helperText={formik.touched.roles && formik.errors.roles}
                                        error={Boolean(formik.touched.roles && rolesError)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.roles}
                                        onChange={handleChangeRoles}
                                        // onBlur={handleBlurRoles}

                                        input={<OutlinedInput id="roles1"
                                                              name={"roles1"}
                                                              label={t('pages.profilePage.rolesLabel')}
                                                />
                                        }

                                        renderValue={(selected) => (
                                            //{return selected.join(', ');}
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {
                                                    selected.map((value) => (
                                                        <Chip key={value} label={mappingRoleName(t, value)}/>
                                                    ))
                                                }
                                            </Box>
                                        )}

                                >
                                    {rolesName.map((rowi, index) => (
                                        <MenuItem
                                            key={index}
                                            value={rowi}
                                        >
                                            {mappingRoleName(t, rowi)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </CardContent>
                }
                <Divider/>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        type={"submit"}
                        // onClick={handleSave}
                        disabled={formik.isSubmitting}
                    >
                        {t('pages.persons.profileSaveBtnCaption')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

export default PersonCardAvatar;