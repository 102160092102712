import React from 'react';
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const LoadDataInTable = () => {
    const {t} = useTranslation()

    return (
            <Paper >
                <Box
                    sx={{
                        pt: 3,
                        minHeight: '50vh',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ textAlign: 'center', mt: 'auto', mb:'auto', }}>
                        <CircularProgress size={'5rem'}/>
                    </Box>
                    <Typography align="center"
                                color="textPrimary"
                                variant="h4"
                                sx={{mb:'24px'}}
                    >
                        {t('systemCommon.dataLoading')}
                    </Typography>
                </Box>
            </Paper>

    );
};

export default LoadDataInTable;