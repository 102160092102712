import {dellEndSlash} from "../../util/UtilFunction";
import {RouteEnum} from "../../types/RouteEnum";
import instanceAxiosApi from "../../service/axiosApi";

const actionViewHtmlHandler = (rowi, archive) => {
    let url = dellEndSlash(window.location.origin + window.location.pathname) +
        "/#" +
        `${RouteEnum.SHOW_ESLIP}/${archive}/${rowi.encryptedLink}`
    window.open(url, '_blank', 'noreferrer');
}

const actionExportPdfHandler = async (rowi, archive) => {
    const response = await instanceAxiosApi.get(`/ui/v1/show-eslip/export-pdf/${archive}/${rowi.encryptedLink}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                'accept': "application/pdf"
            },
            'responseType': 'arraybuffer'
        });

    const file = new Blob([response.data], {
        type: 'application/pdf',
    });
    // FileSaver.saveAs(blob, 'file.pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
}

const actionExportPngHandler = async (rowi, archive) => {

    const response = await instanceAxiosApi.get(`/ui/v1/show-eslip/export-png/${archive}/${rowi.encryptedLink}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                'accept': "image/png"
            },
            'responseType': 'arraybuffer'
        });

    const file = new Blob([response.data], {
        type: 'image/png',
    });
    // FileSaver.saveAs(blob, 'file.pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
}

export {
    actionViewHtmlHandler, actionExportPdfHandler, actionExportPngHandler
}