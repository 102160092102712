import React, {useState} from 'react';
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ITEM_HEIGHT = 54;

const ActionMenuButton = (props) => {
    // Элементы меню ожидаются в таком виде
    //{"key":"change", "caption":"Изменить", "fn":() => actionChangeHandler(organization.id)},
    const menuItems = props?.menuItems || []


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, cb) => {
        setAnchorEl(null);

        if (cb === "escapeKeyDown" || cb === "backdropClick") {
            return
        }

        cb()
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {menuItems.map((option, key) => (
                    <MenuItem
                        key={option.key} /*selected={option === 'Pyxis'}*/
                        onClick={(event) => {handleClose(event, option.fn)}}>
                        {option.caption}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default ActionMenuButton;