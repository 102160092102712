import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import PagingComponentTable from "../../../components/dataGridCustom/PagingComponentTable";

import {useSnackbar} from "notistack";
import {fetchEslipsExportRows,
    setLimit, setPage, setNeedRefreshData
} from "../../../store/common/exportManagerPageSlice";




const EslipExportResult = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const [selectRow, setSelectRow] = useState('')

    const {
        dataRows, filterModel,
        page, totalPages, limit,
        needRefreshData
    } = useSelector(state => state.exportManagerPage)



    useEffect(() => {
        loadData()
    }, [filterModel, page, totalPages, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])


    /* Модель колонок таблицы */
    const headerModel = [
        {
            name: "id", caption: t('pages.exportManager.guid'),
            isVisible: true, align: "center", alignRow: "center",
            orderColumn: 1 // заготовка для изменения порядка колонок
        },
        {
            name: "username", caption: t('pages.exportManager.user'),
            isVisible: true, align: "left", alignRow: "left",
            width: "100px",
            orderColumn: 2
        },
        {
            name: "requestStartDate", caption: t('pages.exportManager.startDate'),
            isVisible: true, align: "left", alignRow: "left",
            width: "100px"
        },
        {
            name: "requestEndDate", caption: t('pages.exportManager.finishDate'),
            isVisible: true, align: "left", alignRow: "left",
            width: "100px"
        },
        {
            name: "exportStatus", caption: t('pages.exportManager.status'),
            isVisible: true, align: "center", alignRow: "center",
            //width: "200px"
        },
        {
            name: "downloadLink", caption: t('pages.exportManager.filePath'),
            isVisible: true, align: "left", alignRow: "left",
            width: "300px"
        }

    ]

    const loadData = async () => {
        setSelectRow('')
        await dispatch(fetchEslipsExportRows({
                showSnackMsg:showSnackMsg
            })
        )
    }


    const setPageL = (num) => {
        dispatch(setPage(num))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit(limit))
    }


    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    return (
        <Card {...props}>
            <PerfectScrollbar>
                <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                    <Table size="small">
                        <TableHead sx={{height: '60px'}}>
                            <TableRow>
                                {
                                    headerModel.map((rowi, key) => (

                                        rowi.isVisible &&
                                        <TableCell align={rowi.align} key={key}
                                                   style={{minWidth: rowi.width}}>
                                            {rowi.caption}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>

                            <TableBody>
                                {dataRows.map((rowi, number) => (
                                    <TableRow onClick={() => setSelectRow(rowi.id)}
                                              hover
                                              key={rowi.id}
                                              selected={rowi.id === selectRow}
                                    >

                                        {
                                            headerModel
                                                .filter(headerj => headerj.isVisible)
                                                .map((columni, key) => (
                                                    Object.keys(rowi).find(keyi => keyi === columni.name) &&
                                                    <TableCell align={columni.alignRow} key={key}
                                                               style={{minWidth: columni.width}}
                                                    >
                                                        {rowi[columni.name]}
                                                    </TableCell>
                                                ))
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>

                    </Table>
                </Box>
            </PerfectScrollbar>

            {
                !totalPages &&
                <DataNotFoundComponent refreshDataFn={loadData} />
            }

            <PagingComponentTable
                totalPages={totalPages}
                page={page} setPageFn={setPageL}
                limit={limit} setLimitFn={setLimitL}
                refreshDataFn={loadData}/>
        </Card>
    );
};


export default EslipExportResult;