import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Backdrop, Box, Card, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataNotFoundComponent from "../../../../components/dataGridCustom/DataNotFoundComponent";
import PagingComponentTable from "../../../../components/dataGridCustom/PagingComponentTable";
import {changeArchiveMode} from "../../../../store/common/eslipsArchivePageSlice";
import {fetchEslipsArchiveJobRows, setLimit,
    setNeedRefreshData,
    setPage
} from "../../../../store/common/eslipsArchiveJobPageSlice";
import DeleteFromArchiveDialog from "./DeleteFromArchiveDialog";
import AddToArchiveDialog from "./AddToArchiveDialog";

const JobArchiveTab = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const [selectRow, setSelectRow] = useState('')
    const status = useSelector(state => state.eslipsArchiveJobPage.status)

    const {
        dataRows,
        page, totalPages, limit,
        needRefreshData
    } = useSelector(state => state.eslipsArchiveJobPage)



    useEffect(() => {
        loadData()
    }, [page, totalPages, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])


    /* Модель колонок таблицы */
    const headerModel = [
        {
            name: "createdDate", caption: t('pages.archive.createdDateColCaption'),
            isVisible: true, align: "left", alignRow: "left",
            orderColumn: 1 // заготовка для изменения порядка колонок
        },
        {
            name: "jobType", caption: t('pages.archive.jobTypeColCaption'),
            isVisible: true, align: "left", alignRow: "left",
            //width: "130px"
        },

        {
            name: "jobStatus", caption: t('pages.archive.jobStatusColCaption'),
            isVisible: true, align: "center", alignRow: "center",
            orderColumn: 2
        },
        {
            name: "details", caption: t('pages.archive.detailsColCaption'),
            isVisible: true, align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "finishDate", caption: t('pages.archive.finishDateColCaption'),
            isVisible: true, align: "left", alignRow: "left",
            //width: "200px"
        },
        {
            name: "user", caption: t('pages.archive.userColCaption'),
            isVisible: true, align: "left", alignRow: "left",
            //width: "200px"
        }
    ]

    const loadData = async () => {
        setSelectRow('')
        dispatch(changeArchiveMode('task'))
        await dispatch(fetchEslipsArchiveJobRows({
                showSnackMsg:showSnackMsg
            })
        )
    }

    const setPageL = (num) => {
        dispatch(setPage(num))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit(limit))
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    return (
        <>
            <Card {...props} sx={{borderTopLeftRadius:0, borderTopRightRadius: 0}}>
                <Backdrop
                    open={status === 'loading'}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
                >
                    <CircularProgress/>
                </Backdrop>

                <PerfectScrollbar>
                    <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                        <Table size="small">
                            <TableHead sx={{height: '60px'}}>
                                <TableRow>
                                    {
                                        headerModel.map((rowi, key) => (

                                            rowi.isVisible &&
                                            <TableCell align={rowi.align} key={key}
                                                       style={{minWidth: rowi.width}}>
                                                {rowi.caption}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {dataRows.map((rowi, number) => (
                                    <TableRow onClick={() => setSelectRow(rowi.id)}
                                              hover
                                              key={rowi.id}
                                              selected={rowi.id === selectRow}
                                    >

                                        {
                                            headerModel
                                                .filter(headerj => headerj.isVisible)
                                                .map((columni, key) => (
                                                    Object.keys(rowi).find(keyi => keyi === columni.name) &&
                                                    <TableCell align={columni.alignRow} key={key}
                                                               style={{minWidth: columni.width}}
                                                    >
                                                        {rowi[columni.name]}
                                                    </TableCell>
                                                ))
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </Box>
                </PerfectScrollbar>

                {
                    !totalPages &&
                    <DataNotFoundComponent refreshDataFn={loadData} />
                }

                <PagingComponentTable
                    totalPages={totalPages}
                    page={page} setPageFn={setPageL}
                    limit={limit} setLimitFn={setLimitL}
                    refreshDataFn={loadData}/>
            </Card>

            <AddToArchiveDialog />

            <DeleteFromArchiveDialog />
        </>
    );
};

export default JobArchiveTab;