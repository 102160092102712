import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../hook/useAuth";

const RequiredAuth = ({children}) => {
    const location = useLocation()
    const {userAuth} = useAuth();

    if (!userAuth) {
        return <Navigate to='/auth/login' state={{from: location}} />
    }

    return children;
};

export {RequiredAuth};