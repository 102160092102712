import React, {useEffect} from 'react';
import {Backdrop, Box, CircularProgress, Container} from "@mui/material";
import GroupAccessToolBar from "./GroupAccessToolBar";
import GroupAccessWorkspace from "./GroupAccessWorkspace";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoading, initAccessPage} from "../../../store/administration/groupAccessPageSlice";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";


const GroupAccessPage = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.groupAccessPage.isLoading)

    useEffect(() => {
        document.title = 'Group Access | UniPORT E-slip';

        dispatch(setActiveSection({href: '/group_access'}))

        const items = [
            {href:"/group_access", caption:'pages.groupAccess.pageCaption', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        dispatch(setIsLoading(true))
        dispatch(initAccessPage())
    }, []);


    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Backdrop
                open={isLoading}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>
            <Container maxWidth={false}>
                <GroupAccessToolBar/>
                <GroupAccessWorkspace/>
            </Container>
        </Box>
    );
};

export default GroupAccessPage;