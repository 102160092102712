import React from 'react';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Footer = () => {
    const {t} = useTranslation()

    const year = new Date().getFullYear();
    const version = useSelector(state => state.baseLayout.version)

    return (

        <Box sx={{
            pt: '20px',
            pb: '20px',
            pl: '40px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        }}>
            <Typography variant="subtitle2">
                &copy; {year}
            </Typography>
            <Typography variant="subtitle2" sx={{ml: '20px'}}>
                {t('systemCommon.versionCaption')}:
            </Typography>
            <Typography variant="subtitle2" color='primary.main' sx={{ml: '7px'}}>
                {version}
            </Typography>
        </Box>
    );
};

export default Footer;