import React, {useEffect, useState} from 'react';
import {
    Box, Container,
} from "@mui/material";
import BanksToolBar from "./BanksToolBar";
import BankList from "./BankList";
import {useDispatch} from "react-redux";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";

const BanksPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Banks | UniPORT E-slip';

        dispatch(setActiveSection({href: '/banks'}))

        const items = [
            {href:"/banks", caption:'pages.banks.toolbar.pageCaption', active: false}
        ]
        dispatch(setBreadcrumbs(items))
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Container maxWidth={false}>
                <BanksToolBar/>
                <BankList/>
            </Container>
        </Box>
    );
};

export default BanksPage;