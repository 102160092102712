import React from 'react';
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import INDEFINEDComponent from "./accesslevel/INDEFINEDComponent";
import NO_RESTRICTIONSComponent from "./accesslevel/NO_RESTRICTIONSComponent";

const GroupAccessDefinition = () => {
    const {t} = useTranslation()
    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)
    const groupId = useSelector(state => state.groupAccessPage.groupId)
    const groupName = useSelector(state => state.groupAccessPage.groupName)


    // Редкий случай, но обработаем отдельно
    if (!groupId) {
        return (
            <Box>Не задана группа</Box>
        )
    }


    return (
        <Box sx={{minHeight:'45vh'}}>
            <Box sx={{display: "flex", pl: '1.5rem', pt: '1rem'}}>
                <Typography variant="h6" >
                    {t('pages.groupAccess.groupUserCaption')}
                </Typography>

                <Typography component="span" variant="h6" color='primary.main' sx={{ml:'0.5rem'}}>
                    {groupName}
                </Typography>
            </Box>

            <Box sx={{display: "flex", pl:'1.5rem', mt: '0.5rem'}}>
                {
                    accessLevel === 'INDEFINED' &&
                    <INDEFINEDComponent />
                }
                {
                    accessLevel === 'NO_RESTRICTIONS' &&
                    <NO_RESTRICTIONSComponent />
                }
            </Box>
        </Box>
    );
};

export default GroupAccessDefinition;