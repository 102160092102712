import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, InputAdornment, LinearProgress,
    TextField
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setOpenChangePasswordDlg} from "../../../store/administration/profilePageSlice";
import instanceAxiosApi from "../../../service/axiosApi";

/**
 * Диалог для изменения пароля пользователя в профиле пользователя.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PersonProfileChangePasswordDialog = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {
        openChangePasswordDlg
    } = useSelector(state => state.profilePage)

    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, personNameForAction, personIdForAction, refreshListRows} = props

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (openChangePasswordDlg) {
            clearError()
            setShowOldPassword(false)
            setShowPassword(false)
            setShowPasswordConfirm(false)
            formik.setFieldValue('oldPasswordUser', '')
            formik.setFieldValue('passwordUser', '')
            formik.setFieldValue('passwordUserConfirm', '')
            formik.resetForm()
        }
    }, [openChangePasswordDlg])

    const fetchApi = async (json) => {
        return await instanceAxiosApi.put("/auth/password/current_change", JSON.stringify(json));
    }

    const formik = useFormik({
        initialValues: {
            oldPasswordUser: '',
            passwordUser: '',
            passwordUserConfirm: ''
        },
        validationSchema: Yup.object({
            oldPasswordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.profilePage.oldPasswordVC')),
            // Старый пароль может быть высталвен через бд
                //.matches(/^(?=.*\d)(?=.*[!"#$%&'()\*+,-.\/:;<=>\\?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).\S{3,}$/, t('pages.restorePassword.passwordRulesError')),
            passwordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordCheckRequired'))
                .matches(/^(?=.*\d)(?=.*[!"#$%&'()\*+,-.\/:;<=>\\?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).\S{3,}$/, t('pages.restorePassword.passwordRulesError')),
            passwordUserConfirm: Yup
                .string()
                .oneOf([Yup.ref('passwordUser'), null], t('pages.restorePassword.passwordNotEqual'))
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordConfirmCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)
            try {
                let json = {
                    "userId": "",
                    "oldPassword": values.oldPasswordUser,
                    "newPassword": values.passwordUser,
                    "passwordConfirm": values.passwordUserConfirm
                }

                await fetchApi(json)

                enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                    variant: 'success',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })

                dispatch(setOpenChangePasswordDlg(false));
            } catch (e) {
                // Ошибки могут быть: Нет доступа и все остальные
                let errorStatus = e?.response?.status || 400
                let errorMessage = e?.response?.data?.uiMessage || t('systemCommon.errorChangingInformationMsg')

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })

                //setErrorSubmitMsg( e?.response?.data?.debugMessage || t('pages.persons.userPasswordChangeError'))
                setErrorSubmitMsg(errorMessage)

                setIsSubmitError(true)

                formikHelpers.setSubmitting(false)
            } finally {
                setIsProcessed(false)
            }
        }
    });

    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = async () => {
        clearError()
        formik.resetForm()
        dispatch(setOpenChangePasswordDlg(false))
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }



    return (
        <>
            <Dialog open={openChangePasswordDlg}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{t('pages.passwordComponent.userChangePasswordCaption')}</DialogTitle>
                    {
                        isProcessed &&
                        <Box sx={{}}>
                            <LinearProgress/>
                        </Box>
                    }
                    <Divider/>
                    <DialogContent>
                        {
                            isSubmitError &&
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        }
                            <TextField
                                error={Boolean(formik.touched.oldPasswordUser && formik.errors.oldPasswordUser)}
                                fullWidth
                                helperText={formik.touched.oldPasswordUser && formik.errors.oldPasswordUser}
                                label={t('pages.profilePage.oldPasswordLabel')}
                                margin="normal"
                                name="oldPasswordUser"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type={showOldPassword ? 'text' : 'password'}
                                value={formik.values.oldPasswordUser}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextField
                                error={Boolean(formik.touched.passwordUser && formik.errors.passwordUser)}
                                fullWidth
                                helperText={formik.touched.passwordUser && formik.errors.passwordUser}
                                label={t('pages.profilePage.passwordUserLabel')}
                                margin="normal"
                                name="passwordUser"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.passwordUser}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextField
                                error={Boolean(formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm)}
                                fullWidth
                                helperText={formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm}
                                label={t('pages.profilePage.passwordUserConfirmLabel')}
                                margin="normal"
                                name="passwordUserConfirm"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type={showPasswordConfirm ? 'text' : 'password'}
                                value={formik.values.passwordUserConfirm}
                                variant="outlined"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                            {t('systemCommon.dlgCloseBtnCaption')}
                        </Button>
                        <Button disabled={isProcessed} /*collor={"submit"}*/ type={"submit"}>
                            {t('systemCommon.dlgApplyBtnCaption')}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default PersonProfileChangePasswordDialog;