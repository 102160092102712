import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {
    Alert,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Typography
} from "@mui/material";
import {checkEmptyString} from "../../../util/UtilFunction";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

const DeleteGroupDialog = (props) => {
    const {t} = useTranslation()

    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Индикатор выполнения запроса
    const [isProcessed, setIsProcessed] = useState(false)
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, oldRowName, rowId, refreshListRows} = props

    const isEmptyRowId = () => {
        return checkEmptyString(rowId + '')
    }

    const handleClose = () => {
        clearError()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    // Идем на сервер
    const fetchApiDelete = async () => {
        return await instanceAxiosApi.delete(`/ui/v1/group_users/${rowId}`)
    }

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            if (isEmptyRowId()) throw new Error(t('pages.groupUsers.errorGroupIdNotSet'))

            // идем на сервер
            await fetchApiDelete()

            handleClose()

            refreshListRows()

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } catch (e) {
            if (e?.response?.data?.errorCode === 'E_DOM_4') {
                setErrorSubmitMsg(t('pages.groupUsers.cannotDeleteSystemGroupMsg'))
            } else {
                setErrorSubmitMsg(e?.response?.data?.uiMessage || e.message)
            }

            setIsSubmitError(true)

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteErrorCaption'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <Box>
                    <DialogTitle bgcolor={'warning.main'}>
                        {t('systemCommon.confirmationRequiredCaption')}
                    </DialogTitle>
                    {
                        isProcessed &&
                        <Box sx={{}}>
                            <LinearProgress/>
                        </Box>
                    }
                    <DialogContent>
                        <DialogContentText>
                            <Typography sx={{mt: '15px'}}>
                                {t('pages.groupUsers.confirmDeleteGroupText')}
                            </Typography>

                            <Typography component="span" variant="h6" color='primary.main'>
                                {oldRowName}
                            </Typography>
                        </DialogContentText>

                        {
                            isSubmitError &&
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        }
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                            {t('systemCommon.dlgCancelBtnCaption')}
                        </Button>
                        <Button disabled={isProcessed} /*collor={"submit"}*/ onClick={handleSubmit}>
                            {t('systemCommon.dlgYesBtnCaption')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default DeleteGroupDialog;