import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../hook/useAuth";
import {
    setIsLoading,
    setLimit,
    setNeedRefreshData,
    setPage,
    setTotalPages
} from "../../../store/administration/personsPageSlice";
import instanceAxiosApi from "../../../service/axiosApi";
import {RoleEnum} from "../../../types/RoleEnum";
import {Box, Card, Divider, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow} from "@mui/material";
import LoadDataInTable from "../../../components/dataGridCustom/LoadDataInTable";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import ActionMenuButton from "../../../components/buttons/ActionMenuButton";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import PagingComponentTable from "../../../components/dataGridCustom/PagingComponentTable";
import BlockPersonsDialog from "./BlockPersonsDialog";
import DeletePersonsDialog from "./DeletePersonsDialog";
import PersonsSetPasswordDialog from "./PersonsSetPasswordDialog";
import PersonsSetRoleDialog from "./PersonsSetRoleDialog";
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import {buildFilterPredicateCommon, valueBooleanConverter} from "../../../util/FilterUtilFuctions";
import {setUserId} from "../../../store/administration/personCardPageSlice";

const PersonsList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()
    const {checkExistsRoles} = useAuth()

    const needRefreshData = useSelector(state => state.personsPage.needRefreshData)
    const isLoading = useSelector(state => state.personsPage.isLoading)
    const limit = useSelector(state => state.personsPage.limit)

    const filterModel = useSelector(state => state.personsPage.filterModel)
    const sortModel = useSelector(state => state.personsPage.sortModel)

    const page = useSelector(state => state.personsPage.page)
    const totalPages = useSelector(state => state.personsPage.totalPages)
    const [is403, setIs403] = useState(false)
    const [dataRows, setDataRows] = useState([])

    const [selectRow, setSelectRow] = useState('')

    // Диалог удаления
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false)
    const [openBlockDlg, setOpenBlockDlg] = useState(false)
    const [openChangePasswordDlg, setOpenChangePasswordDlg] = useState(false)
    const [openEditRoleDlg, setOpenEditRoleDlg] = useState(false)

    const [personIdForAction, setPersonIdForAction] = useState('')
    const [personNameForAction, setPersonNameForAction] = useState('')
    const [actionBlock, setActionBlock] = useState('')

    useEffect(() => {
        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            )

    }, [filterModel, sortModel, page, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])

    const setPageL = (num) => {
        dispatch(setPage({page: num}))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit({limit: limit}))
    }

    const fetchApi = async (params) => {
        // Идем на сервер
        return await instanceAxiosApi.get("/ui/v1/users?" + params)
    }

    const buildParamsForRequest = () => {
        let checkField = ['login', 'fio', 'email', 'phone', 'active']
        let fieldNeedConvert = {'active': valueBooleanConverter}
        let filterPredicateArray = []
        checkField.forEach(fieldName => {
            let predicatei = buildFilterPredicateCommon(filterModel, fieldName, fieldNeedConvert[fieldName])
            if (predicatei) filterPredicateArray.push(predicatei)
        })

        // сервер ожидает параметры в таком виде
        //page=1&limit=10&filter=[{"key":"name","operation":"like","value":["Н1к"]}]&order=[]
        let params = `page=${page}&limit=${limit}&filter=${JSON.stringify(filterPredicateArray)}`

        // TODO : реализовать если нужно по аналогии с фильтр модель
        if (sortModel && Object.entries(filterModel.login).length > 0) {
            // let sorti={"key":"name","operation":"","value":[]}
        }

        return params
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading: true}))
        try {
            let params = buildParamsForRequest()
            let response = await fetchApi(params)

            let dataJson = response?.data || {rows: [], totalPages: 0}

            setDataRows(dataJson.rows)
            dispatch(setTotalPages({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                setPageL(1)
            }
        } catch (e) {
            // Ошибки могут быть: Нет доступа и все остальные
            let errorStatus = e?.response?.status || 400
            let errorMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')

            if (errorStatus === 403) {
                setIs403(true)

                enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            } else {
                setIs403(false)

                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            }
            // Скидываем данные в таблице
            setDataRows([])

            dispatch(setTotalPages({totalPages: (0)}))
        } finally {
            dispatch(setIsLoading({isLoading: false}))
        }
    }

    const headerModel = [
        {"name": "npp", "caption": t('pages.persons.orderColumn'), "isVisible": "true", "align": "center", "width": "10px"},
        {"name": "login", "caption": t('pages.persons.loginColumn'), "isVisible": "true", "align": "left", "width": "100px"},
        {"name": "fio", "caption": t('pages.persons.fioColumn'), "isVisible": "true", "align": "left", "width": "200px"},
        {"name": "phone", "caption": t('pages.persons.phoneColumn'), "isVisible": "true", "align": "left", "width": "100px"},
        {"name": "email", "caption": t('pages.persons.emailColumn'), "isVisible": "true", "align": "left", "width": "100px"},
        {"name": "active", "caption": t('pages.persons.statusColumn'), "isVisible": "true", "align": "center", "width": "10px"},
        {
            "name": "actions",
            "caption": t('pages.persons.actionColumn'),
            "isVisible": checkExistsRoles(RoleEnum.ADMINISTRATOR),
            "align": "center",
            "width": "25px"
        }
    ]

    const actionEditInfoHandler = async (id, login) => {
        setPersonIdForAction(id)
        setPersonNameForAction(login)
        await dispatch(setUserId(id))
        navigate("/persons/card")
    }

    const actionChangePasswordHandler = (id, login) => {
        setPersonIdForAction(id)
        setPersonNameForAction(login)
        setOpenChangePasswordDlg(true)
    }

    const actionDeleteHandler = (id, login) => {
        setPersonIdForAction(id)
        setPersonNameForAction(login)
        setOpenDeleteDlg(true)
    }

    const actionBlockHandler = (id, login, action) => {
        setPersonIdForAction(id)
        setPersonNameForAction(login)
        setOpenBlockDlg(true)
        setActionBlock(action)
    }

    const actionRoleEditHandler = (id, login) => {
        setPersonIdForAction(id)
        setPersonNameForAction(login)
        setOpenEditRoleDlg(true)
    }

    const buildActionMenu = (rowi) => {
        let menuItems = []


        menuItems.push({
            "key": "edit",
            "caption": t('pages.persons.editActionCaption'),
            "fn": () => actionEditInfoHandler(rowi.id, rowi.login)
        })
        menuItems.push({
            "key": "edit_role",
            "caption": t('pages.persons.rolesActionCaption'),
            "fn": () => actionRoleEditHandler(rowi.id, rowi.login)
        })
        menuItems.push({
            "key": "change_password",
            "caption": t('pages.persons.setPwdActionCaption'),
            "fn": () => actionChangePasswordHandler(rowi.id, rowi.login)
        })

        if (rowi.active) {
            menuItems.push({
                "key": "block",
                "caption": t('pages.persons.blockActionCaption'),
                "fn": () => actionBlockHandler(rowi.id, rowi.login, 'block')
            })
        } else {
            menuItems.push({
                "key": "unblock",
                "caption": t('pages.persons.unblockActionCaption'),
                "fn": () => actionBlockHandler(rowi.id, rowi.login, 'unblock')
            })
        }

        menuItems.push({
            "key": "delete",
            "caption": t('pages.persons.deleteActionCaption'),
            "fn": () => actionDeleteHandler(rowi.id, rowi.login)
        })


        return <ActionMenuButton menuItems={menuItems}/>
    }

    return (
        <Box>
            {
                isLoading && <LoadDataInTable/>
            }
            {
                !isLoading && is403 &&
                <AccessDeniedComponent/>
            }
            {
                !isLoading && !is403 &&
                <Card>
                    <PerfectScrollbar>
                        <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                            <Table size="small">
                                <TableHead sx={{height: '60px'}}>
                                    <TableRow>
                                        {
                                            headerModel.map((rowi, key) => (

                                                rowi.isVisible &&
                                                <TableCell align={rowi.align} key={key}
                                                           style={{minWidth: rowi.width}}>
                                                    {rowi.caption}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    // !! иначе 0 будет писаться
                                    !!totalPages &&
                                    <TableBody>
                                        {dataRows.map((rowi, key) => (

                                            <TableRow onClick={() => setSelectRow(rowi.id)}
                                                      hover
                                                      key={rowi.id}
                                                      selected={rowi.id === selectRow}
                                            >
                                                <TableCell align={"center"}>
                                                    {key + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.login}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.lastName} {rowi.firstName} {rowi.patronymic}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.phone}
                                                </TableCell>
                                                <TableCell>
                                                    {rowi.email}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {
                                                        !rowi.active ?
                                                            <LockPersonOutlinedIcon/>
                                                            :
                                                            <LockOpenOutlinedIcon/>
                                                    }
                                                </TableCell>
                                                {
                                                    headerModel
                                                        .filter((itemi => itemi.name === "actions"))
                                                        .map(i => i.isVisible)[0] &&
                                                    <TableCell align={"center"}>
                                                        {buildActionMenu(rowi)}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                        </Box>
                    </PerfectScrollbar>

                    {
                        !totalPages &&
                        <DataNotFoundComponent refreshDataFn={loadData}/>
                    }

                    {
                        !isLoading &&
                        <PagingComponentTable
                            totalPages={totalPages}
                            page={page} setPageFn={setPageL}
                            limit={limit} setLimitFn={setLimitL}
                            refreshDataFn={loadData}/>
                    }

                    {/*Диалог для смены пароля*/}
                    <PersonsSetPasswordDialog open={openChangePasswordDlg} setOpen={setOpenChangePasswordDlg}
                                              personIdForAction={personIdForAction}
                                              personNameForAction={personNameForAction}
                                              refreshListRows={loadData}/>

                    {/*Диалог редактирования ролей*/}
                    <PersonsSetRoleDialog open={openEditRoleDlg} setOpen={setOpenEditRoleDlg}
                                          personIdForAction={personIdForAction}
                                          personNameForAction={personNameForAction}
                                          refreshListRows={loadData}/>

                    {/* Диалог блокировки или разблокировки */}
                    <BlockPersonsDialog open={openBlockDlg} setOpen={setOpenBlockDlg}
                                        personIdForAction={personIdForAction}
                                        personNameForAction={personNameForAction}
                                        refreshListRows={loadData}
                                        action={actionBlock}/>

                    {/*Диалог удаления*/}
                    <DeletePersonsDialog open={openDeleteDlg} setOpen={setOpenDeleteDlg}
                                         personIdForAction={personIdForAction}
                                         personNameForAction={personNameForAction}
                                         refreshListRows={loadData}/>
                </Card>
            }
        </Box>
    );
    ;
};

export default PersonsList;



