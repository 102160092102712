import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Avatar, Box, Button, Divider, Drawer, Typography, useMediaQuery} from '@mui/material';
import { ChartBar as ChartBarIcon } from '../../icons/chart-bar';
import { Cog as CogIcon } from '../../icons/cog';
import { Lock as LockIcon } from '../../icons/lock';
import { ShoppingBag as ShoppingBagIcon } from '../../icons/shopping-bag';
import { User as UserIcon } from '../../icons/user';
import { UserAdd as UserAddIcon } from '../../icons/user-add';
import { Users as UsersIcon } from '../../icons/users';
import { XCircle as XCircleIcon } from '../../icons/x-circle';
import {Link} from "react-router-dom";
import {appMenuArray} from "../../menu_route/AppMenuArray";
import {SideBarMenuItems} from "./SideBarMenuItems";
import {useDispatch, useSelector} from "react-redux";
import {setOpenMainLeftMenu} from "../../store/baseLayoutSlice";



export const DashboardSidebar = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => state.baseLayout.openMainLeftMenu)

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });


  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3, display:'flex', alignItems: 'center'}}>
            {/*<Link*/}
            {/*  to="/"*/}
            {/*>*/}
              <a>
                {/*<Logo2*/}
                {/*  sx={{*/}
                {/*    height: 42,*/}
                {/*    width: 42*/}
                {/*  }}*/}
                {/*/>*/}
                <Avatar
                    alt="Logo"
                    src="static/images/logo1.png"
                    variant="square"
                    sx={{width: 100, height: 100, m: 'auto', borderColor: 'white'}}
                />

              </a>
            {/*</Link>*/}
            <Typography variant="h5" sx={{pl:'1rem', pr: '1rem'}}>UniPORT E-slip</Typography>
          </Box>
        </div>

        <Divider
          sx={{
            borderColor: '#2D3748',
            mb: 3
          }}
        />

        <Box sx={{ flexGrow: 1 }}>
          {
            appMenuArray.map(({title, pages}, key) => (
              <SideBarMenuItems key={key} title={title} pages={pages}/>
            ))
          }
        </Box>

        <Divider sx={{ borderColor: '#2D3748' }} />
        {/*дополнительная информация....*/}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={() => dispatch(setOpenMainLeftMenu(false))}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
