import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Drawer, FormControl, IconButton, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField, Tooltip
} from "@mui/material";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {buildNewFilterModelItem} from "../../../util/FilterUtilFuctions";
import {changeFilterPanelOpenStatus, setFilterModel} from "../../../store/handbook/organizationsPageSlice";
import {useTranslation} from "react-i18next";


const formValuesEmpty = {
    orgName: '',
    orgId: '',
    bankName: '',
};


const OrganizationFilterDrawer = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const filterOpen = useSelector(state => state.organizationsPage.filterPanelOpen)
    const filterModelInit = useSelector(state => state.organizationsPage.filterModel)

    const [formValue, setFormValue] = useState({...formValuesEmpty})


    const {control, reset, handleSubmit, setValue, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        // resolver: yupResolver(schema),
        defaultValues: {
            formValue
        }
    })

    // При открытии перечитываем все из стора
    useEffect(() => {
        //console.log('filterParamsInit' + filterParamsInit );
        if (filterOpen) {
            readFilterValue()
        }
    }, [filterOpen])

    // Если изменили значения поиска, тоже перечитваем
    useEffect(() => {
        //console.log('filterParamsInit' + filterParamsInit );
        // при открытии нужно перечитать
        if (filterOpen) {
            reset(formValue)
        }
    }, [formValue])

    const readFilterValue = () => {
        // То что лежит в сторе, нужно перечитать
        let filterModel = {}
        Object.keys(formValue).forEach(keyi => {
            if (keyi == 'active') {
                filterModel[keyi] = filterModelInit[keyi]?.value || '-1'
            } else {
                filterModel[keyi] = filterModelInit[keyi]?.value || ''
            }

        })

        // мержим текущие с тем что есть в сторе
        setFormValue({
                ...formValue,
                ...filterModel
            });
    }

    /* Закрытие панели с фильтрами */
    const filterCloseBtnHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: false}))
    }

    /* Кнопка очистить */
    const handleOnCancelClick = () => {
        setFormValue({
            ...formValue,
            ...formValuesEmpty
        })
    }

    /* кнопка применить */
    const handleOnApplyClick = (data) => {
        //console.log(data)
        const newfilterModel = buildFilterModel(data)
        // console.log(newfilterModel)
        dispatch(setFilterModel({filterModel: newfilterModel}))
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: false}))
    }

    const buildFilterModel = (form_data) => {
        let newFilterModel = {orgName: {}, orgId: {}, bankName:{}};

        newFilterModel.orgName = buildNewFilterModelItem('orgName', 'like', form_data.orgName)
        newFilterModel.orgId = buildNewFilterModelItem('orgId', 'like', form_data.orgId)
        newFilterModel.bankName = buildNewFilterModelItem('bankName', 'like', form_data.bankName)

        return newFilterModel
    }

    return (
        <Drawer
            anchor={"right"}
            open={filterOpen}
            onClose={filterCloseBtnHandler}
        >
            <form noValidate onSubmit={handleSubmit(handleOnApplyClick)}>
                <List sx={{width: '400px'}}>
                    <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <ListItemIcon>
                            <ManageSearchOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('filter.filterCaption')}/>

                        <Tooltip title={t('filter.closeBtnToolTipText')}>
                            <IconButton aria-label="delete" color="error" size="small"
                                        onClick={filterCloseBtnHandler}>
                                <CloseOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    <Divider sx={{mb: '15px'}}/>

                    {/* name */}
                    <ListItem>
                        <FormControl fullWidth>
                            <Controller
                                name={"orgName"}
                                control={control}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        size={'small'}
                                        label={t('filter.nameFieldLabel')}
                                        error={!!error}
                                        helperText={error?.message}
                                        variant="outlined"
                                        value={field.value || ''}
                                        inputProps={{ maxLength: 25 }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                            <IconButton size={'small'} onClick={() => setValue('orgName', '')}>
                                <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>

                    {/* orgId */}
                    <ListItem>
                        <FormControl fullWidth>
                            <Controller
                                name={"orgId"}
                                control={control}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        size={'small'}
                                        label={t('filter.idFieldLabel')}
                                        error={!!error}
                                        helperText={error?.message}
                                        variant="outlined"
                                        value={field.value || ''}
                                        inputProps={{ maxLength: 25 }}
                                    />
                                )}
                            />
                        </FormControl>
                        <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                            <IconButton size={'small'} onClick={() => setValue('orgId', '')}>
                                <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>

                    {/*bankName*/}
                    <ListItem>
                        <FormControl fullWidth>
                            <Controller
                                name={"bankName"}
                                control={control}
                                render={({field, fieldState: {error}}) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        size={'small'}
                                        label={t('filter.bankNameFieldCaption')}
                                        error={!!error}
                                        helperText={error?.message}
                                        variant="outlined"
                                        value={field.value || ''}
                                        inputProps={{ maxLength: 25 }}
                                    />
                                )}
                            />



                        </FormControl>
                        <Tooltip title={t('filter.clearFieldBtnToolTipText')}>
                            <IconButton size={'small'} onClick={() => setValue('bankName', '')}>
                                <BackspaceOutlinedIcon size={'small'} color={"action"}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>



                    <Divider sx={{mt: '15px'}}/>
                    <ListItem sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Button
                            variant="contained"
                            size="small"
                            type={"submit"}
                        >
                            {t('filter.applyBtnCaption')}
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleOnCancelClick}
                        >
                            {t('filter.clearAllFieldBtnCaption')}
                        </Button>
                    </ListItem>
                </List>
            </form>
        </Drawer>
    );
};

export default OrganizationFilterDrawer;