import React, {useState} from 'react';
import {useSnackbar} from "notistack";
import {
    Alert,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Typography
} from "@mui/material";

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    clearRowsForAdd,
    setNeedRefreshData,
    setOpenSaveBindingDlg
} from "../../../../store/administration/groupPersonsBindingPageSlice";
import {checkEmptyString} from "../../../../util/UtilFunction";
import instanceAxiosApi from "../../../../service/axiosApi";

const AddGroupBindingPersonDialog = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    // Индикатор выполнения запроса
    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')

    // Из стора
    const open = useSelector(store => store.groupPersonsBindingPage.openSaveBindingDlg)
    const rowId = useSelector(store => store.groupPersonsBindingPage.groupId)
    const rowName = useSelector(store => store.groupPersonsBindingPage.groupName)
    const rowsForAdd = useSelector(store => store.groupPersonsBindingPage.rowsForAdd)

    const isEmptyRowId = () => {
        return checkEmptyString(rowId + '')
    }

    const handleClose = () => {
        clearError()
        dispatch(setOpenSaveBindingDlg(false))
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    // Идем на сервер
    const fetchApiAdd = async (params) => {
        return await instanceAxiosApi.post(`/ui/v1/group_users/${rowId}/users/bindings`, JSON.stringify(params))
    }

    const handleSubmit = async () => {
        setIsProcessed(true)
        try {
            if (isEmptyRowId()) throw new Error(t('pages.groupBinding.errorGroupIdNotSet'))

            let params = [...rowsForAdd]

            // идем на сервер
            await fetchApiAdd(params)

            handleClose()
            dispatch(clearRowsForAdd())
            dispatch(setNeedRefreshData(true))

            // Сообщение
            enqueueSnackbar(t('systemCommon.operationCompletedSuccessCaption'), {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } catch (e) {
            setErrorSubmitMsg(e.message)


            setIsSubmitError(true)

            // Сообщение
            enqueueSnackbar(t('systemCommon.rowDeleteErrorCaption'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })
        } finally {
            setIsProcessed(false)
        }
    }

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={'paper'}
            >
                <DialogTitle bgcolor={'warning.main'} id="scroll-dialog-title">
                    {t('systemCommon.confirmationRequiredCaption')}
                </DialogTitle>

                {
                    isProcessed &&
                    <Box sx={{}}>
                        <LinearProgress/>
                    </Box>
                }
                <DialogContent dividers={false}>
                    {
                        isSubmitError &&
                        <DialogContentText tabIndex={-1} sx={{mt:'15px'}}>
                            <Alert severity="error">{errorSubmitMsg}</Alert>
                        </DialogContentText>
                    }
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupBinding.groupLabel')}
                        </Typography>

                        <Typography component="span" variant="h6" color='primary.main'>
                            {rowName}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText tabIndex={-1}>
                        <Typography sx={{mt: '15px'}}>
                            {t('pages.groupBinding.confirmAddUserInGroup')}
                        </Typography>
                    </DialogContentText>

                    {
                        [...rowsForAdd]
                            .sort((a, b) => a.login.localeCompare(b.login))
                            .map((item, key) => (
                            <DialogContentText tabIndex={-1} key={item.id}>
                                <Typography component="span" variant="h6" color='primary.main' key={item.id}>
                                    {`${key + 1}. ${item.login} (${item.firstName} ${item.patronymic} ${item.lastName})`}
                                </Typography>
                            </DialogContentText>
                        ))
                    }

                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button disabled={isProcessed} /*color={"error"}*/ onClick={handleClose}>
                        {t('systemCommon.dlgCancelBtnCaption')}
                    </Button>
                    <Button disabled={isProcessed} /*collor={"submit"}*/ onClick={handleSubmit}>
                        {t('systemCommon.dlgYesBtnCaption')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddGroupBindingPersonDialog;