import {createSlice} from "@reduxjs/toolkit";

const baseLayoutSlice = createSlice({
    name: 'baseLayout',
    initialState: {
        apiBaseUrl: localStorage.getItem("apiBaseUrl"), // Если открывает еще одну вкладку, нужно чем-то заполнить
        activeSection: null,
        version: localStorage.getItem("version"), // Если открывает еще одну вкладку, нужно чем-то заполнить
        openMainLeftMenu: false,
        breadcrumbs: []
    },

    reducers: {
        addApiBaseUrl(state, action) {
            //console.log("добавили в стор baseUrlApi")
            state.apiBaseUrl = action.payload.apiBaseUrl
            localStorage.setItem("apiBaseUrl", action.payload.apiBaseUrl)
        },

        setActiveSection(state, action) {
            state.activeSection = action.payload.href
        },

        addVersion(state, action) {
            state.version = action.payload.version
            localStorage.setItem("version", action.payload.version)
        },

        setOpenMainLeftMenu(state, action) {
            state.openMainLeftMenu = action.payload
        },

        setBreadcrumbs(state, action) {
            state.breadcrumbs = action.payload
        }
    }
})

export const {
    addApiBaseUrl, setActiveSection, addVersion, setOpenMainLeftMenu,
    setBreadcrumbs
} = baseLayoutSlice.actions;

export default baseLayoutSlice.reducer;