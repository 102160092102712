/**
 * Преобразует с учетом локадицации, то что пришло с бекенда в вид для UI.
 *
 * @param t - объект для изменния локали, должен прийти из-вне.
 * @param value
 */
const mappingAccessLevel = (t, key) => {

    let mappObj = [
        {key: 'INDEFINED', value: 'handbook.group.accesslevel.indefined'},
        {key: 'NO_RESTRICTIONS', value: 'handbook.group.accesslevel.no_restrictions'},
        {key: 'BANK', value: 'handbook.group.accesslevel.bank'},
        {key: 'ORG', value: 'handbook.group.accesslevel.org'},
        {key: 'MERCHANT', value: 'handbook.group.accesslevel.merchant'},
    ]

    let tt = mappObj.filter(r => r.key === key).map(r => r.value)[0];

    if (!tt) {
        tt = mappObj.filter(r => r.key === 'INDEFINED').map(r => r.value)[0];
    }

    return t(tt)
}

const mappingRoleName = (t, key) => {
    let mappObj = [
        {key: 'MERCHANT', value: 'handbook.profileRoleName.MERCHANT'},
        {key: 'OPERATOR', value: 'handbook.profileRoleName.OPERATOR'},
        {key: 'ADMINISTRATOR', value: 'handbook.profileRoleName.ADMINISTRATOR'}
    ]


    let tt = mappObj.filter(r => r.key === key.toUpperCase()).map(r => r.value)[0];

    if (tt) {
        return t(tt)
    } else {
        return key
    }


}


export {mappingAccessLevel, mappingRoleName}