import React from 'react';
import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {changeFilterPanelOpenStatus} from '../../../store/common/exportManagerPageSlice'
import EslipExportDrawer from "./EslipExportDrawer";


const EslipExportToolbar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }




    return (
        <div>
            <Box {...props}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1
                    }}
                >
                    <Typography
                        sx={{ m: 1 }}
                        variant="h4"
                    >
                        {t('pages.exportManager.pageTitle')}
                    </Typography>
                    <Box sx={{ m: 1 }}>

                        <Button
                            size={"small"}
                            startIcon={<ManageSearchOutlinedIcon />}
                            onClick={filterOpenBtnClickHandler}
                        >
                            {t('filter.filterCaption')}
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/*Фильтр на боковой панели*/}
            <EslipExportDrawer />
        </div>
    );
};

export default EslipExportToolbar;