import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    addRowForDelete, clearRowsForDelete,
    deleteRowForDelete,
    initParamsEditCurrentObject, setIsLoading,
    setNeedRefreshData, setPageEdit, setRowsCurrentIn, setSearchStringEdit, setTotalPagesEdit
} from "../../../../store/administration/groupAccessPageSlice";
import instanceAxiosApi from "../../../../service/axiosApi";
import {hasText} from "../../../../util/UtilFunction";
import {
    Alert, AlertTitle,
    Box, Checkbox,
    Container, Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem, ListItemAvatar, ListItemButton, ListItemText,
    SvgIcon,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import PagingComponentList from "../../../../components/list_element/PagingComponentList";
import { BsShop } from "react-icons/bs";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

const iconS = {
    className: "w-5 h-5 text-inherit",
};

const MerchantCurrentList = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const isLoading = useSelector(state => state.groupAccessPage.isLoading)
    const searchString = useSelector(state => state.groupAccessPage.searchStringEdit)
    const page = useSelector(state => state.groupAccessPage.pageEdit)
    const limit = useSelector(state => state.groupAccessPage.limitEdit)
    const totalPages = useSelector(state => state.groupAccessPage.totalPagesEdit)

    const rowId = useSelector(store => store.groupAccessPage.groupId)
    const rows = useSelector(store => store.groupAccessPage.rowsCurrentIn) || []
    const rowsForDelete = useSelector(store => store.groupAccessPage.rowsForDelete) || []
    const needRefreshData = useSelector(state => state.groupAccessPage.needRefreshData)

    // Инициируем тем значением фильтра, что в сторе содержится
    const [searchValue, setSearchValue] = useState(searchString || '')
    const [isLoadError, setIsLoadError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    // При старте скидываем прошлые данные
    useEffect(() => {
        dispatch(initParamsEditCurrentObject())
    }, [])

    /* Вдруг из диалога изменили информацию */
    useEffect(() => {
        if (!needRefreshData) return;

        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    useEffect(() => {
        loadData()

    }, [page])

    const handleToggle = (value) => () => {
        if (isCheckedItem(value.id)) {
            dispatch(deleteRowForDelete({key: value.id}))
        } else {
            dispatch(addRowForDelete({rowForDelete: value}))
        }
    };

    const isCheckedItem = (id) => {
        if (!rowsForDelete || rowsForDelete.length < 1) return false

        return rowsForDelete.filter(itemi => itemi.id == id).length > 0
    }

    const clearLocalErrorState = () => {
        setIsLoadError(false)
        setErrorMsg('')
    }

    // Идем на сервер
    const fetchApi = async (params) => {
        return await instanceAxiosApi.get(`/ui/v1/group_access/merchants?${params}`)
    }

    const buildParams = () => {
        let filter = [{"key": "groupId", "operation": "eq", "value": [rowId]}]

        if (hasText(searchString)) {
            filter.push({"key": "name", "operation": "like", "value": [searchString]})
        }

        let filterAsString = JSON.stringify(filter)
        return `page=${page}&limit=${limit}&filter=${filterAsString}`
    }

    const loadData = async () => {
        dispatch(setIsLoading(true))
        dispatch(clearRowsForDelete())
        try {
            const response = await fetchApi(buildParams())

            let dataJson = response?.data || {rows: [], totalPages: 0}

            // Необходимо поправить имена полей
            dataJson.rows.map(r => r.name = r.merchantName)
            dispatch(setRowsCurrentIn({rows: [...dataJson.rows]}))
            dispatch(setTotalPagesEdit({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                dispatch(setPageEdit({page: 1}))
            }

            clearLocalErrorState()
        } catch (e) {
            dispatch(setRowsCurrentIn({rows: []}))

            setIsLoadError(true)
            let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')
            setErrorMsg(clientMessage)
        } finally {
            setSearchValue(searchString)
            dispatch(setIsLoading(false))
        }
    }

    const handleRefresh = () => {
        dispatch(setSearchStringEdit({searchString: searchValue}))
        dispatch(setNeedRefreshData(true))
    }

    const handleChangeSearchString = (event) => {
        setSearchValue(event.target.value)
    }

    const handleClickClearSearchString = () => {
        setSearchValue('')
        dispatch(setSearchStringEdit({searchString: ''}))
        dispatch(setNeedRefreshData(true))
    }

    const pressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(setSearchStringEdit({searchString: searchValue}))
            dispatch(setNeedRefreshData(true))
        }
    }

    const setPageL = (num) => {
        dispatch(setPageEdit({page: num}))
    }

    return (
        <Container maxWidth={false}>

            <Box sx={{maxWidth: "sm"}}>
                <Typography sx={{mt: 1, mb: 2, pt: '32px',}} variant="h6" component="div">
                    {t('pages.groupAccess.allowedAccessMerchantsCaption')}
                </Typography>

                <List dense={true}
                      sx={{width: '100%', /*maxWidth: 360, */bgcolor: 'background.paper'}}
                      subheader={
                          <Box sx={{display: "flex", flexDirection: "column"}}>
                              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                  <Tooltip title={t('systemCommon.updateInfo')}>
                                      <IconButton sx={{mr: '10px'}} onClick={handleRefresh}>
                                          <RefreshOutlinedIcon/>
                                      </IconButton>
                                  </Tooltip>
                                  <TextField
                                      type={"text"}
                                      fullWidth
                                      InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <SvgIcon
                                                      fontSize="small"
                                                      color="action"
                                                  >
                                                      <SearchIcon/>
                                                  </SvgIcon>
                                              </InputAdornment>
                                          ),

                                          endAdornment: searchString && (
                                              <IconButton size={"small"}
                                                          aria-label="toggle password visibility"
                                                          onClick={handleClickClearSearchString}
                                              >
                                                  <CancelRoundedIcon fontSize={"small"}/>
                                              </IconButton>
                                          )

                                      }}
                                      placeholder={t('pages.groupAccess.searchByNameCaption')}
                                      variant="outlined"
                                      size={"small"}
                                      value={searchValue}
                                      onChange={handleChangeSearchString}
                                      onKeyDown={(e) => pressEnter(e)}
                                      disabled={isLoading}
                                      inputRef={(input) => input?.focus()}
                                  />
                              </Box>
                          </Box>
                      }
                >
                    {rows.map((value, key) => {
                        const labelId = `checkbox-list-secondary-label-${value.id}`;
                        return (
                            <Box key={key}>
                                <ListItem
                                    key={value.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={isCheckedItem(value.id)}
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            {/*<BsShop {...iconS}/>*/}
                                            <StorefrontOutlinedIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={value.merchantName}
                                            secondary={value.merchantGuid }
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                            </Box>
                        );
                    })}
                </List>

                {
                    isLoadError &&
                    <Alert severity={"error"}>
                        <AlertTitle>{t('systemCommon.errorRequestInfoCaption')}</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

                {
                    !totalPages && !isLoadError &&
                    <Box>
                        <Alert severity={"info"}>{t('systemCommon.noEntriesToDisplay')}</Alert>
                    </Box>
                }


                <PagingComponentList
                    totalPages={totalPages}
                    page={page}
                    setPageFn={setPageL}
                />
            </Box>

        </Container>
    );
};

export default MerchantCurrentList;