import React, {useEffect, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {IconButton, Tooltip, Typography} from "@mui/material";
import {RuFlag} from "../../icons/flag/RuFlag";
import {EnFlag} from "../../icons/flag/EnFlag";
import {KzFlag} from "../../icons/flag/KzFlag";
import {useTranslation} from "react-i18next";

const LangSelect = () => {
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'ru');
    const [currentFlag, setCurrentFlag] = useState(<RuFlag />)
    const [currentTooltip, setCurrentTooltip] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { t, i18n } = useTranslation();


    useEffect(() => {
        showFlag()
    }, [lang])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLang = (lang) => {
        i18n.changeLanguage(lang)
            .then(res =>  setLang(lang))
            .finally(() => handleClose())
    }

    const showFlag = () => {
        if (lang === 'ru') {
            setCurrentFlag(<RuFlag />)
            setCurrentTooltip('Русский')
        } else if (lang === 'en') {
            setCurrentFlag(<EnFlag />)
            setCurrentTooltip('English')
        } else if (lang === 'kz') {
            setCurrentFlag(<KzFlag />)
            setCurrentTooltip('Қазақша')
        }
    }

    return (
        <div>
            <Tooltip title={currentTooltip}>
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {currentFlag}
                </IconButton>
            </Tooltip>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => changeLang('ru')}>Русский</MenuItem>
                <MenuItem onClick={() => changeLang('en')}>English</MenuItem>
                <MenuItem onClick={() => changeLang('kz')}>Қазақша</MenuItem>
            </Menu>
        </div>
    );
};

export default LangSelect;