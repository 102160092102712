import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";

export const fetchEslipsArchiveJobRows = createAsyncThunk(
    'eslipsArchiveJobPage/fetchEslipsArchiveJobRows',
    async function(addInfo, {getState, rejectWithValue}) {
        const {showSnackMsg} = addInfo
        try {
            const {page, limit} = getState().eslipsArchiveJobPage
            const response = await instanceAxiosApi.get(`/ui/v1/eslips/archive/jobs?page=${page}&limit=${limit}`)

            // showSnackMsg('success', 'systemCommon.dataRowsResponseSuccessfully')
            return response
        } catch (error) {
            rejectWithValueCustom(error, rejectWithValue, showSnackMsg)
        }
    }
)

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({
            errorCode: 403,
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({
            errorCode: 400,
            error: errorMessage}
        )
    } else {
        // Сообщение выдаст центральный axios interceptor
        return rejectWithValue({
            errorCode: 500,
            error: 'authProvider.internalServerErrorMessage'
        })
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
}


const eslipsArchiveJobPageSlice = createSlice({
    name: 'eslipsArchiveJobPage',
    initialState: {
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        status: null,
        error: null,

        dataRows: [],
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 30,

        filterParams: {
            currency: null,
            pan: null,
            amount: null,
            dateFrom: null,
            dateTo: null,
            terminalHostId: null,
            merchantName: null,
            cardHolder: null,
            origOperationCode: null,
            rrn: null,
            isEslip: null,
            email: null,
            phone: null,
            orgId: null,
        },

        filterPanelOpen: false,

        openAddToArchiveDlg: false,

        openDeleteFromArchiveDlg: false

    },
    reducers: {
        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload.needRefreshData
        },

        changeFilterParams(state, action) {
            state.filterParams = {...state.filterParams, ...action.payload}
        },


        changeFilterPanelOpenStatus(state, action) {
            state.filterPanelOpen = action.payload.filterPanelOpen;
        },

        changeAddToArchiveDlgOpenStatus(state, action) {
            state.openAddToArchiveDlg = action.payload;
        },

        changeDeleteFromArchiveDlgOpenStatus(state, action) {
            state.openDeleteFromArchiveDlg = action.payload;
        },


        /* Инициализация всей страницы при открытии */
        initPageData(state, action) {
            state.status = null
            state.error = null


        },

        setLimit(state, action) {
            state.limit = action.payload
        },

        setPage(state, action) {
            state.page = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEslipsArchiveJobRows.pending, (state, action) => {
                state.status = 'loading'
            })

            .addCase(fetchEslipsArchiveJobRows.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let dataJson = action.payload?.data || {rows: [], totalPages: 0}

                state.dataRows = dataJson.rows

                state.totalPages = dataJson.totalPages

                // Если на какой-то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
                if (dataJson.totalPages < state.page) {
                    state.page = 1
                }
            })

            .addCase(fetchEslipsArchiveJobRows.rejected, (state, action) => setError(state, action))


    }
})

export const {
    setNeedRefreshData,
    setIsLoading,
    setFilterModel,
    setSortModel,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit,
    changeFilterPanelOpenStatus,
    changeAddToArchiveDlgOpenStatus,
    changeDeleteFromArchiveDlgOpenStatus
} = eslipsArchiveJobPageSlice.actions

export default eslipsArchiveJobPageSlice.reducer