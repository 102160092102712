import {createSlice} from "@reduxjs/toolkit";

const bankOrganizationsBindingPageSlice = createSlice({
    name: 'bankOrganizationsBindingPage',
    initialState: {
        bankId: null,
        bankName: null,
        isAddMode: false,

        needRefreshData: false, // Когда произошли изменения в соседних окнах
        isLoading: false,

        openDeleteBindingDlg: false,
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 10,
        searchString: '',
        rowsCurrentIn: [],
        rowsForDelete: [],

        openSaveBindingDlg: false,
        pageAddMode: 1,
        totalRowsAddMode: 0,
        totalPagesAddMode: 0,
        limitAddMode: 10,
        searchStringAddMode: '',
        rowsNotIn: [],
        rowsForAdd: [],
    },

    reducers: {
        setBankId(state, action) {
            state.bankId = action.payload.bankId
        },

        setBankName(state, action) {
            state.bankName = action.payload.bankName
        },

        setAddMode(state, action) {
            state.isAddMode = action.payload.isAddMode
        },

        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload
        },

        setIsLoading(state, action) {
            state.isLoading = action.payload.isLoading
        },


        /** Режим удаления **/
        setOpenDeleteBindingDlg(state, action) {
            state.openDeleteBindingDlg = action.payload.openDeleteBindingDlg
        },

        setPage(state, action) {
            state.page = action.payload.page
        },

        setTotalRows(state, action) {
            state.totalRows = action.payload.totalRows
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload.totalPages
        },

        setLimit(state, action) {
            state.limit = action.payload.limit
        },

        setSearchString(state, action) {
            state.searchString = action.payload.searchString
        },

        setRowsCurrentIn(state, action) {
            let copyState = state.rowsCurrentIn.slice()
            copyState = [...action.payload.rows]
            state.rowsCurrentIn = copyState
        },

        addRowForDelete(state, action) {
            let copyState = state.rowsForDelete.slice()
            copyState.push(action.payload.rowForDelete)
            state.rowsForDelete = copyState
        },

        deleteRowForDelete(state, action) {
            state.rowsForDelete = state.rowsForDelete.filter(row => row.id != action.payload.key);
        },

        clearRowsForDelete(state, action) {
            state.rowsForDelete = []
        },



        /** Режим добавления **/
        setOpenSaveBindingDlg(state, action) {
            state.openSaveBindingDlg = action.payload
        },

        setPageAddMode(state, action) {
            state.pageAddMode = action.payload.page
        },

        setTotalRowsAddMode(state, action) {
            state.totalRowsAddMode = action.payload.totalRows
        },

        setTotalPagesAddMode(state, action) {
            state.totalPagesAddMode = action.payload.totalPages
        },

        setLimitAddMode(state, action) {
            state.limitAddMode = action.payload.limitAddMode
        },

        setSearchStringAddMode(state, action) {
            state.searchStringAddMode = action.payload.searchString
        },

        setRowsNotIn(state, action) {
            let copyState = state.rowsNotIn.slice()
            copyState = [...action.payload.rows]
            state.rowsNotIn = copyState
        },

        addRowsForAdd(state, action) {
            let copyState = state.rowsForAdd.slice()
            copyState.push(action.payload.row)
            state.rowsForAdd = copyState
        },

        deleteRowsForAdd(state, action) {
            state.rowsForAdd = state.rowsForAdd
                .filter(row => row.id != action.payload.key);
        },

        clearRowsForAdd(state, action) {
            state.rowsForAdd = []
        },

        /* При загрузке страницы нужно очистить прошлые значения*/
        initParamsOnPageStart(state, action) {
            state.isAddMode = false

            initParamsOnCurrentPageStart(state, action)
            initParamsOnAddPageStart(state, action)

            state.bankId = action.payload.rowId
            state.bankName =  action.payload.rowName
        },

        initParamsOnCurrentPageStart(state, action) {
            state.openDeleteBindingDlg = false
            state.page = 1
            state.totalRows = 0
            state.totalPages = 0
            state.limit = 10
            state.searchString = ''
            state.rowsCurrentIn = []
            state.rowsForDelete = []
        },

        /* При загрузке страницы нужно очистить прошлые значения*/
        initParamsOnAddPageStart(state, action) {

            state.openSaveBindingDlg = false
            state.pageAddMode = 1
            state.totalRowsAddMode = 0
            state.totalPagesAddMode = 0
            state.limitAddMode = 10
            state.searchStringAddMode = ''
            state.rowsNotIn = []
            state.rowsForAdd = []
        }
    }
})

export const {
    setBankId,
    setBankName,

    setAddMode,

    setNeedRefreshData,
    setIsLoading,

    setOpenDeleteBindingDlg,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit,
    setSearchString,
    setRowsCurrentIn,
    deleteRowForDelete,
    addRowForDelete,
    clearRowsForDelete,

    setOpenSaveBindingDlg,
    setPageAddMode,
    setTotalRowsAddMode,
    setTotalPagesAddMode,
    setLimitAddMode,
    setSearchStringAddMode,
    setRowsNotIn,
    addRowsForAdd,
    deleteRowsForAdd,
    clearRowsForAdd,

    initParamsOnPageStart,
    initParamsOnCurrentPageStart,
    initParamsOnAddPageStart
} = bankOrganizationsBindingPageSlice.actions

export default bankOrganizationsBindingPageSlice.reducer