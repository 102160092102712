import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import accessDenied from "../assets/img/access-denied.png";

const AccessDeniedComponent = () => {
    const {t} = useTranslation()

    return (
        <Paper sx={{mt:'20px'}}>
            <Box
                sx={{
                    pt: 3,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <img
                        alt="Under development"
                        src={accessDenied}
                        style={{
                            marginTop: 50,
                            marginBottom: 50,
                            display: 'inline-block',
                            maxWidth: '50%',
                            width: 560
                        }}
                    />
                </Box>
                <Typography align="center"
                            color="textPrimary"
                            variant="h4"
                            sx={{mb:'24px'}}
                >
                    {t('systemCommon.accessDeniedCaption')}
                </Typography>
                <Typography  align="center"
                             color="textPrimary"
                             variant="subtitle2"
                             sx={{mb:'24px'}}
                >
                    {t('systemCommon.accessDeniedHint')}
                </Typography>
            </Box>
        </Paper>
    );
};

export default AccessDeniedComponent;