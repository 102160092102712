import React from 'react';
import HomeIcon from '@mui/icons-material/Home';

import {Box, Breadcrumbs, Button, Link, Typography, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// const breadcrumbs2 = [
//     {href:"/persons",  caption:"Пользователи", active: true},
//     {href:"/group_persons",  caption:"Группы пользователей", active: true},
//     {href:"/persons",  caption:"Пользователи", active: false}
// ]


const BreadcrumbsCustom = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const breadcrumbs = useSelector(state => state.baseLayout.breadcrumbs)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
        defaultMatches: true,
        noSsr: false
    });

    return (
        <Breadcrumbs aria-label="breadcrumb"
                     // separator={<NavigateNextIcon fontSize="small" />}
            separator={"›"}
        >
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="#"
            >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>

            {
                mdUp &&
                breadcrumbs.map((row, number) => (
                    <Box key={number}>
                        {
                            row.active
                                ?
                            <Link variant="body1" sx={{mt:'-3px'}}
                                component="button"
                                key={number}
                                  underline="hover"
                                  onClick={() => navigate(row.href)}
                            >
                                {t(row.caption)}
                            </Link>
                                :
                            <Typography key={number}>
                                {t(row.caption)}
                            </Typography>
                        }
                    </Box>
                ))
            }
        </Breadcrumbs>
    );
};

export {BreadcrumbsCustom};