import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../../service/axiosApi";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    changeAddToArchiveDlgOpenStatus,
    changeDeleteFromArchiveDlgOpenStatus, setNeedRefreshData
} from "../../../../store/common/eslipsArchiveJobPageSlice";

/**
 * Диалог для удаления чеков из архива.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteFromArchiveDialog = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const open = useSelector(state => state.eslipsArchiveJobPage.openDeleteFromArchiveDlg)

    const [startArchiveDate, setStartArchiveDate] = useState('')
    const [finishArchiveDate, setFinishArchiveDate] = useState('')

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            formik.setFieldValue("startArchiveDate", startArchiveDate);
            formik.setFieldValue("finishArchiveDate", finishArchiveDate);
        }
    }, [open])

    const fetchApi = async (startArchiveDate, finishArchiveDate) => {
        let params = {"startArchiveDate": startArchiveDate, "finishArchiveDate": finishArchiveDate,}
        return await instanceAxiosApi.post(`/ui/v1/eslips/archive/remove`, JSON.stringify(params));
    }

    const handleClose = async () => {
        clearError()
        formik.resetForm()
        dispatch(changeDeleteFromArchiveDlgOpenStatus(false));
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    const formik = useFormik({
        initialValues: {
            startArchiveDate: startArchiveDate,
            finishArchiveDate: finishArchiveDate
        },
        validationSchema: Yup.object({
            startArchiveDate: Yup
                .date().typeError(t('pages.archive.typeErrorDateMsg'))
                .min(new Date(1980, 0, 1), t('pages.archive.minDateErrorMsg'))
                .max(new Date(5000, 0, 1), t('pages.archive.maxDateErrorMsg'))
                .required(t('pages.archive.archiveDateRequiredMsg')),
            finishArchiveDate: Yup
                .date().typeError(t('pages.archive.typeErrorDateMsg'))
                .min(new Date(1980, 0, 1), t('pages.archive.minDateErrorMsg'))
                .max(new Date(5000, 0, 1), t('pages.archive.maxDateErrorMsg'))
                .when("startArchiveDate",
                    (startArchiveDate, yup) => startArchiveDate && yup.min(startArchiveDate, t('pages.archive.validateStartEndDateMsg')))
                .required(t('pages.archive.archiveDateRequiredMsg')),
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)

            await fetchApi(values.startArchiveDate, values.finishArchiveDate)
                .then((response) => {
                    handleClose()
                    // Заставляем обновить данные на сервере
                    dispatch(setNeedRefreshData({needRefreshData: true}))

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoSaveSuccessCaption'), {
                        variant: 'success',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .catch(e => {
                    let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.infoErrorSaveCaption')
                    formikHelpers.setSubmitting(false)
                    setErrorSubmitMsg(clientMessage)
                    setIsSubmitError(true)

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoErrorSaveCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .finally(() => setIsProcessed(false))
        }
    });

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{
                    }}>
                        <DialogTitle>{t('pages.archive.deletingDlgHeader')}</DialogTitle>
                        {
                            isProcessed &&
                            <Box sx={{}}>
                                <LinearProgress/>
                            </Box>
                        }
                        <DialogContent>
                            <TextField
                                error={Boolean(formik.touched.startArchiveDate && formik.errors.startArchiveDate)}
                                fullWidth
                                helperText={formik.touched.startArchiveDate && formik.errors.startArchiveDate}
                                label={t('pages.archive.deletingDateStartQuestionMsg')}
                                margin="normal"
                                name="startArchiveDate"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="date"
                                value={formik.values.startArchiveDate}
                                variant="outlined"
                                disabled={isProcessed}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                error={Boolean(formik.touched.finishArchiveDate && formik.errors.finishArchiveDate)}
                                fullWidth
                                helperText={formik.touched.finishArchiveDate && formik.errors.finishArchiveDate}
                                label={t('pages.archive.deletingDateFinishQuestionMsg')}
                                margin="normal"
                                name="finishArchiveDate"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="date"
                                value={formik.values.finishArchiveDate}
                                variant="outlined"
                                disabled={isProcessed}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            {
                                isSubmitError &&
                                <Alert severity="error">{errorSubmitMsg}</Alert>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isProcessed} color={"error"} onClick={handleClose}>
                                {t('systemCommon.dlgCancelBtnCaption')}
                            </Button>
                            <Button disabled={isProcessed} type={"submit"}>
                                {t('systemCommon.dlgApplyBtnCaption')}
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

export default DeleteFromArchiveDialog;