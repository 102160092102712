/**
 * Синхронизация ролей UI и backend -а
 *
 * Например:
 * UI использует - SYS_ADMIN
 * backend использует - ADMINISTRATOR
 * заводим строку ADMIN: ADMINISTRATOR
 *
 */
export const RoleEnum = {
    ADMINISTRATOR : 'ADMINISTRATOR',
    OPERATOR: 'OPERATOR',
    MERCHANT: 'MERCHANT'
}