/**
 * Проверка строки на пустоту. Включает проверки null и длину
 *
 * @param str
 * @returns {boolean}
 */
const checkEmptyString = (str) => {
    // checking the string using ! operator and length
    // will return true if empty string and false if string is not empty
    return (!str || str.trim().length < 1 );
}

const hasText = (str) => {
    return !checkEmptyString(str);
}

const dellEndSlash = (str) => {
    if (checkEmptyString(str)) return str

    if (str.slice(-1) === '/') {
        return str.slice(0, -1)
    }

    return str
}

/**
 * Проверка на пересечение массивов.
 * Использовать можно для проверки прав пользователя.
 *
 * @param a
 * @param b
 * @constructor
 */
const intersectionArray = (a, b) => {
    if (a.length < 1) return true

    a = Array.from(new Set(a))
    let b1 = new Set(b)
    let resa = [...a].filter(v => b1.has(v));
    return resa.length > 0
};

/**
 * Проверка на то, что первый массив включает в себя второй массив полностью
 *
 * @param a
 * @param b
 * @constructor
 */
const intersectionFullArray = (a, b) => {
    if (a.length < 1) return false
    if (b.length < 1) return false

    a = Array.from(new Set(a))
    let b1 = new Set(b)
    let resa = [...a].filter(v => b1.has(v));
    return resa.length === b.length
};

/**
 * Проверка на то, что первый массив частично пересекается совторым.
 * (из воторого массива, есть хотябы один элемен в первом)
 *
 * @param a
 * @param b
 * @return -1 не пересекается, 0 частично пересекается, 1 полностью пересекаются
 * @constructor
 */
const intersectionPartArray = (a, b) => {
    if (a.length < 1) return -1
    if (b.length < 1) return -1

    a = Array.from(new Set(a))
    let b1 = new Set(b)
    let resa = [...a].filter(v => b1.has(v));

    if (resa.length === b.length) {
        return 1
    } else if (resa.length > 0) {
        return 0
    } else {
        return -1
    }
};

/**
 *
 * @param arr - массив объектов {name:'', value:''}
 * @param key - ключ для поиска в сассиве
 * @returns {*}
 */
const findItemInArrayObject = (arr, key) => {
    let res = arr.find(r => r.name === key)
    return res.value
};

/**
 * Для работы с изображениями.
 *
 * @param file
 * @returns {Promise<unknown>}
 */
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result)
        };
        fileReader.onerror = (error) => {
            reject(error)
        };
    });
};

export {
    checkEmptyString, dellEndSlash, hasText,
    intersectionArray, intersectionFullArray, intersectionPartArray,
    findItemInArrayObject,
    convertBase64};