import React, {useEffect} from 'react';
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {RouteEnum} from "../../../types/RouteEnum";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Box, Container, Grid, LinearProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    //fetchRolesSystem,
    fetchUserProfileData,
    initAllData
} from "../../../store/administration/profilePageSlice";
import PersonProfileAvatar from "./PersonProfileAvatar";
import PersonProfileDetail from "./PersonProfileDetail";


const PersonProfilePage = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {status, error} = useSelector(state => state.profilePage)

    useEffect(() => {
        async function initData() {
            dispatch(setActiveSection({href:RouteEnum.PROFILE}))

            await dispatch(initAllData())
            //dispatch(fetchRolesSystem())
            await dispatch(fetchUserProfileData())
        }
        document.title = 'Profile User | UniPORT E-slip';

        const items = [
            {href:"/profile", caption:'pages.persons.userProfile', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        initData()
    }, [dispatch]);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}>
                    <Typography
                        sx={{ mb: 3 }}
                        variant="h4"
                    >
                        {t('pages.persons.userProfile')}
                    </Typography>
                </Box>

                {
                    (status === 'loading') && <LinearProgress/>
                }
                {
                    error &&
                    <Alert severity="error">{t(error)}</Alert>
                }

                {
                    status === 'resolved' &&
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={4}
                            md={4}
                            xs={12}
                        >
                            <PersonProfileAvatar />
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            md={8}
                            xs={12}
                        >
                            <PersonProfileDetail />
                        </Grid>
                    </Grid>

                }
            </Container>
        </Box>
    );
};

export default PersonProfilePage;