import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import EslipFilterDrawer from "./EslipFilterDrawer";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterPanelOpenStatus,
    changSettingsPanelOpenStatus, fetchExportEslipsSelected,
    processBufferedEslip, setOpenShowTaskIdDlg
} from '../../../store/common/eslipsPageSlice'
import {useTranslation} from "react-i18next";
import EslipSettingShowColumn from "./EslipSettingShowColumn";
import ButtonWithMenu from "../../../components/buttons/ButtonWithMenu";
import {useAuth} from "../../../hook/useAuth";
import {RoleEnum} from "../../../types/RoleEnum";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import ExportShowTaskIdDialog from "./ExportShowTaskIdDialog";



const EslipsToolbar = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const {checkExistsRoles} = useAuth()

    const {exportObject, selectedRows} = useSelector(state => state.eslipsPage)

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    useEffect(() => {
        if (!exportObject) {
            return
        }

        // Если файл пришел сразу
        if (exportObject.processType === 'fast') {
            commonDownloadFile(exportObject.fileName, exportObject.blob)
        }

        // Если отправлена задача на выполнение
        if (exportObject.processType === 'long') {
            // dispatch(setOpenShowTaskIdDlg(true))
        }
    }, [exportObject])

    useEffect(() => {

    }, [selectedRows])

    const filterOpenBtnClickHandler = () => {
        dispatch(changeFilterPanelOpenStatus({filterPanelOpen: true}))
    }

    const settingsBtnClickHandler = () => {
        dispatch(changSettingsPanelOpenStatus(true))
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    const processBufferedEslipBtnClickHandler = () => {
        dispatch(processBufferedEslip({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const actionExportSelectedTLV = async () => {
        // exportType = pdf, csv, tlv
        // exportContentType = 'application/pdf',
        dispatch(fetchExportEslipsSelected({showSnackMsg:showSnackMsg, exportType: 'tlv', exportContentType: 'application/octet-stream'}))
    }

    const actionExportSelectedCSV = async () => {
        // exportType = pdf, csv, tlv
        // exportContentType = 'application/pdf',
        dispatch(fetchExportEslipsSelected({showSnackMsg:showSnackMsg, exportType: 'csv', exportContentType: 'text/csv'}))
    }

    const actionExportSelectedPdf = async () => {
        // exportType = pdf, csv, tlv
        // exportContentType = 'application/pdf',
        dispatch(fetchExportEslipsSelected({showSnackMsg:showSnackMsg, exportType: 'pdf', exportContentType: 'application/pdf'}))
    }

    const commonDownloadFile = (fileName, blob) => {
        // let fileName = response.headers["content-disposition"].split('filename=')[1].split(';')[0]
        // const file = new Blob([response.data], {
        //     type: type,
        // });
        const fileURL = URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        a.click();
        // Объект в сторе нужно зачистить
        dispatch(setOpenShowTaskIdDlg(false))
    }



    const buildActionMenu = () => {
        let menuItems = []
        let captionTlv = selectedRows.length > 0 ? t('pages.eslips.exportSelectedTLV') : t('pages.eslips.exportAllTLV')
        menuItems.push({
            "key": "exportTlv",
            "caption": captionTlv,
            "fn": () => actionExportSelectedTLV()
        })
        let captionCsv = selectedRows.length > 0 ? t('pages.eslips.exportSelectedCSV') : t('pages.eslips.exportAllCSV')
        menuItems.push({
            "key": "exportCsv",
            "caption": captionCsv,
            "fn": () => actionExportSelectedCSV()
        })
        let captionPdf = selectedRows.length > 0 ? t('pages.eslips.exportSelectedPdf') : t('pages.eslips.exportAllPdf')
            menuItems.push({
            "key": "exportPdf",
            "caption": captionPdf,
            "fn": () => actionExportSelectedPdf()
        })

        return <ButtonWithMenu
            menuItems={menuItems}
            size={"small"}
            startIcon={<FileDownloadOutlinedIcon fontSize="small"/>}
            sx={{mr: 1}}
        >
            {t('pages.eslips.exportBtnCaption')}
        </ButtonWithMenu>
    }


    return (
        <div>
            <Box {...props}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        m: -1
                    }}
                >
                    <Typography
                        sx={{m: 1}}
                        variant="h4"
                    >
                        {t('pages.eslips.pageTitle')}
                    </Typography>
                    <Box sx={{m: 1}}>
                        {
                            checkExistsRoles([RoleEnum.ADMINISTRATOR]) &&
                            <>
                                {/*если нужно будет содавать чек*/}
                                {/*<Button*/}
                                {/*    size={"small"}*/}
                                {/*    // color="primary"*/}
                                {/*    // variant="outlined"*/}
                                {/*    startIcon={<PostAddOutlinedIcon />}*/}
                                {/*    onClick={settingsBtnClickHandler}*/}
                                {/*>*/}
                                {/*    {lgUp && t('pages.eslips.addSlipBtnCaption')}*/}
                                {/*</Button>*/}

                                <Button
                                    size={"small"}
                                    startIcon={<SettingsSuggestOutlinedIcon/>}
                                    onClick={processBufferedEslipBtnClickHandler}
                                >
                                    {lgUp && t('pages.eslips.processEslipBufferedBtnCaption')}
                                </Button>
                            </>
                        }

                        {
                            checkExistsRoles([RoleEnum.ADMINISTRATOR, RoleEnum.OPERATOR]) &&
                            buildActionMenu()
                        }

                        <Button
                            size={"small"}
                            startIcon={<TuneOutlinedIcon/>}
                            onClick={settingsBtnClickHandler}
                        >
                            {lgUp && t('pages.eslips.tableSettingsCaption')}
                        </Button>

                        <Button
                            size={"small"}
                            startIcon={<ManageSearchOutlinedIcon/>}
                            onClick={filterOpenBtnClickHandler}
                        >
                            {t('pages.eslips.filterBtnCaption')}
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/*Фильтр на боковой панели*/}
            <EslipFilterDrawer/>

            {/*панель с настройкой отображения колонок*/}
            <EslipSettingShowColumn/>

            {/*диалог с id задачи по экспорту*/}
            <ExportShowTaskIdDialog />
        </div>
    );
};

export default EslipsToolbar;