import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

/**
 * Диалог для добавления новой или изменения старой организации.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddEditOrganizationDialog = (props) => {
    const {t} = useTranslation()

    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, oldOrganizationName, organizationId, refreshListRows} = props

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            formik.setFieldValue("organizationName", oldOrganizationName);
        }
    }, [open])

    const fetchApi = async (newOrganizationName) => {
        let baseUrl = "/ui/v1/organizations";
        let params = {"id": null, "name": newOrganizationName}
        if (isNewOrganization()) {
            return await instanceAxiosApi.post(`${baseUrl}`, JSON.stringify(params));
        } else {
            params.id = organizationId
            return await instanceAxiosApi.put(`${baseUrl}/${organizationId}`, JSON.stringify(params));
        }
    }

    const isNewOrganization = () => {
        return organizationId === -1
    }

    const handleClose = async () => {
        clearError()
        formik.resetForm()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    const formik = useFormik({
        initialValues: {
            organizationName: oldOrganizationName
        },
        validationSchema: Yup.object({
            organizationName: Yup
                .string()
                .notOneOf([oldOrganizationName, null], t('pages.organizations.diffOldNameCaption'))
                .max(50)
                .required(t('pages.organizations.requiredCaption')),
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)

            await fetchApi(values.organizationName)
                .then((response) => {
                    handleClose()
                    // Заставляем обновить данные на сервере
                    refreshListRows()

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoSaveSuccessCaption'), {
                        variant: 'success',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .catch(e => {
                    let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.infoErrorSaveCaption')
                    formikHelpers.setSubmitting(false)
                    setErrorSubmitMsg(clientMessage)
                    setIsSubmitError(true)

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoErrorSaveCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .finally(() => setIsProcessed(false))
        }
    });

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{
                    }}>
                        <DialogTitle>{isNewOrganization() ? t('pages.organizations.titleCreateCaption') : t('pages.organizations.titleEditCaption')}</DialogTitle>
                        {
                            isProcessed &&
                            <Box sx={{}}>
                                <LinearProgress/>
                            </Box>
                        }
                        <DialogContent>
                            <DialogContentText>
                                {
                                    !isNewOrganization() &&
                                    <Box sx={{display: 'flex', mb: '1rem'}}>
                                        <Typography>
                                            {t('pages.organizations.oldNameCaption')}
                                        </Typography>
                                        <Typography color={'secondary.main'} sx={{ml:'0.5rem'}}>
                                            {oldOrganizationName}
                                        </Typography>
                                    </Box>
                                }

                                {isNewOrganization() ? t('pages.organizations.nameNewOrgCaption') : t('pages.organizations.newNameCaption')}
                            </DialogContentText>

                            <TextField
                                error={Boolean(formik.touched.organizationName && formik.errors.organizationName)}
                                fullWidth
                                helperText={formik.touched.organizationName && formik.errors.organizationName}
                                label={t('pages.organizations.nameOrgLabel')}
                                margin="normal"
                                name="organizationName"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.organizationName}
                                variant="outlined"
                                disabled={isProcessed}
                            />

                            {
                                isSubmitError &&
                                <Alert severity="error">{errorSubmitMsg}</Alert>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isProcessed} color={"error"} onClick={handleClose}>
                                {t('systemCommon.dlgCancelBtnCaption')}
                            </Button>
                            <Button disabled={isProcessed} type={"submit"}>
                                {t('systemCommon.dlgSaveBtnCaption')}
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

export default AddEditOrganizationDialog;