import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {UserCircle as UserCircleIcon} from '../../icons/user-circle';
import {AccountPopover} from './account-popover';
import {BreadcrumbsCustom} from "./navbar/BreadcrumbsCustom";
import LangSelect from "../i18n/LangSelect";
import {useAuth} from "../../hook/useAuth";
import {setOpenMainLeftMenu} from "../../store/baseLayoutSlice";
import {useDispatch} from "react-redux";

const DashboardNavbarRoot = styled(AppBar)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
    const dispatch = useDispatch()

    const {...other} = props;
    const settingsRef = useRef(null);
    const [openAccountPopover, setOpenAccountPopover] = useState(false);
    const {userAuth} = useAuth()

    const onSidebarOpen = () => {
        dispatch(setOpenMainLeftMenu(true))
    }

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280
                    },
                    width: {
                        lg: 'calc(100% - 280px)'
                    }
                }}
                {...other}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none'
                            }, mr:'1rem'
                        }}
                    >
                        <MenuIcon fontSize="small"/>
                    </IconButton>

                    <BreadcrumbsCustom/>


                    {/*<Tooltip title="Search">*/}
                    {/*  <IconButton sx={{ ml: 1 }}>*/}
                    {/*    <SearchIcon fontSize="small" />*/}
                    {/*  </IconButton>*/}
                    {/*</Tooltip>*/}
                    <Box sx={{flexGrow: 1}}/>
                    {/*<Tooltip title="Contacts">*/}
                    {/*  <IconButton sx={{ ml: 1 }}>*/}
                    {/*    <UsersIcon fontSize="small" />*/}
                    {/*  </IconButton>*/}
                    {/*</Tooltip>*/}
                    {/*<Tooltip title="Notifications">*/}
                    {/*  <IconButton sx={{ ml: 1 }}>*/}
                    {/*    <Badge*/}
                    {/*      badgeContent={4}*/}
                    {/*      color="primary"*/}
                    {/*      variant="dot"*/}
                    {/*    >*/}
                    {/*      <BellIcon fontSize="small" />*/}
                    {/*    </Badge>*/}
                    {/*  </IconButton>*/}
                    {/*</Tooltip>*/}
                    <LangSelect/>
                    <Avatar
                        src={`data:image/png;base64, ${userAuth.avatar}`}
                        onClick={() => setOpenAccountPopover(true)}
                        ref={settingsRef}
                        sx={{
                            cursor: 'pointer',
                            height: 40,
                            width: 40,
                            ml: 1
                        }}
                    >
                        <UserCircleIcon fontSize="small"/>
                    </Avatar>
                </Toolbar>
            </DashboardNavbarRoot>
            <AccountPopover
                anchorEl={settingsRef.current}
                open={openAccountPopover}
                onClose={() => setOpenAccountPopover(false)}
            />
        </>
    );
};

DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func
};
