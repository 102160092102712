import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {
    setIsLoading,
    setLimit,
    setNeedRefreshData,
    setPage,
    setTotalPages
} from "../../../store/handbook/organizationsPageSlice";
import instanceAxiosApi from "../../../service/axiosApi";
import {Box, Card, Divider, Pagination, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import LoadDataInTable from "../../../components/dataGridCustom/LoadDataInTable";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import ActionMenuButton from "../../../components/buttons/ActionMenuButton";
import DeleteOrganizationDialog from "./DeleteOrganizationDialog";
import AddEditOrganizationDialog from "./AddEditOrganizationDialog";
import PagingComponentTable from "../../../components/dataGridCustom/PagingComponentTable";
import {RoleEnum} from "../../../types/RoleEnum";
import {useAuth} from "../../../hook/useAuth";
import {buildFilterPredicateCommon} from "../../../util/FilterUtilFuctions";


const OrganizationsList = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()
    const {checkExistsRoles} = useAuth()

    const needRefreshData = useSelector(state => state.organizationsPage.needRefreshData)
    const isLoading = useSelector(state => state.organizationsPage.isLoading)
    const limit = useSelector(state => state.organizationsPage.limit)

    const filterModel = useSelector(state => state.organizationsPage.filterModel)
    const sortModel = useSelector(state => state.organizationsPage.sortModel)

    const page = useSelector(state => state.organizationsPage.page)
    const totalPages = useSelector(state => state.organizationsPage.totalPages)
    const [is403, setIs403] = useState(false)
    const [dataRows, setDataRows] = useState([])

    const [selectRow, setSelectRow] = useState('')
    const [open, setOpen] = useState(false)
    const [openDeleteDlg, setOpenDeleteDlg] = useState(false)

    const [organizationIdForEdit, setOrganizationIdForEdit] = useState('')
    const [organizationNameForEdit, setOrganizationNameForEdit] = useState('')


    useEffect(() => {
        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            )

    }, [filterModel, sortModel, page, limit])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData: false})))
    }, [needRefreshData])

    const setPageL = (num) => {
        dispatch(setPage({page: num}))
    }

    const setLimitL = (limit) => {
        dispatch(setLimit({limit: limit}))
    }

    const fetchApi = async (params) => {
        // Идем на сервер
        return await instanceAxiosApi.get("/ui/v1/organizations?" + params)
    }

    const buildParamsForRequest = () => {
        let checkField = ['orgName', 'orgId', 'bankName']
        // Например: {'active': valueBooleanConverter}
        let fieldNeedConvert = {}
        let filterPredicateArray = []
        checkField.forEach(fieldName => {
            let predicatei = buildFilterPredicateCommon(filterModel, fieldName, fieldNeedConvert[fieldName])
            if (predicatei) filterPredicateArray.push(predicatei)
        })

        // сервер ожидает параметры в таком виде
        //page=1&limit=10&filter=[{"key":"name","operation":"like","value":["Н1к"]}]&order=[]
        let params = `page=${page}&limit=${limit}&filter=${JSON.stringify(filterPredicateArray)}`

        // TODO : реализовать если нужно по аналогии с фильтр модель
        if (sortModel && Object.entries(sortModel.orgName).length > 0) {
            // let sorti={"key":"name","operation":"","value":[]}
        }

        return params
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading: true}))
        try {
            let params = buildParamsForRequest()
            let response = await fetchApi(params)

            let dataJson = response?.data || {rows: [], totalPages: 0}

            setDataRows(dataJson.rows)
            dispatch(setTotalPages({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                setPageL(1)
            }
        } catch (e) {
            // Ошибки могут быть: Нет доступа и все остальные
            let errorStatus = e?.response?.status || 400

            if (errorStatus === 403) {
                setIs403(true)

                enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            } else {
                setIs403(false)

                enqueueSnackbar(t('systemCommon.errorRequestInfoCaption'), {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
            }
            // Скидываем данные в таблице
            setDataRows([])

            dispatch(setTotalPages({totalPages: (0)}))
        } finally {
            dispatch(setIsLoading({isLoading: false}))
        }
    }

    const headerModel = [
        {"name": "name", "caption": t('pages.organizations.nameColumn'), "isVisible": "true", "align": "left", "width": "200px"},
        {"name": "org_id", "caption": t('pages.organizations.orgIdColumn'), "isVisible": "true", "align": "left", "width": "100px"},
        {"name": "banksSeparatedByCommas", "caption": t('pages.organizations.banksColumn'), "isVisible": "true", "align": "left", "width": "300px"},
        {"name": "actions", "caption": t('pages.organizations.actionColumn'), "isVisible": checkExistsRoles(RoleEnum.ADMINISTRATOR), "align": "center", "width": "25px"}
    ]

    const actionChangeHandler = (id, name) => {
        setOrganizationIdForEdit(id)
        setOrganizationNameForEdit(name)
        setOpen(true)
    }

    const actionDeleteHandler = (id, name) => {
        setOrganizationIdForEdit(id)
        setOrganizationNameForEdit(name)
        setOpenDeleteDlg(true)
    }


    return (
        <Box>
            {
                isLoading && <LoadDataInTable/>
            }
            {
                !isLoading && is403 &&
                <AccessDeniedComponent/>
            }
            {
                !isLoading && !is403 &&
                <Card>
                                <PerfectScrollbar>
                                    <Box sx={{/*minWidth: 1050,*/ overflowX: 'auto'}}>
                                        <Table size="small">
                                            <TableHead sx={{height: '60px'}}>
                                                <TableRow>
                                                    {
                                                        headerModel.map((rowi, key) => (

                                                                rowi.isVisible &&
                                                                <TableCell align={rowi.align} key={key}
                                                                            style={{minWidth: rowi.width}}>
                                                                    {rowi.caption}
                                                                </TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            {
                                                // !! иначе 0 будет писаться
                                                !!totalPages &&
                                                <TableBody>
                                                    {dataRows.map((rowi) => (
                                                        <TableRow onClick={() => setSelectRow(rowi.id)}
                                                                  hover
                                                                  key={rowi.id}
                                                                  selected={rowi.id === selectRow}
                                                        >
                                                            <TableCell>
                                                                {rowi.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowi.org_id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowi.banksSeparatedByCommas}
                                                            </TableCell>
                                                            {
                                                                headerModel
                                                                    .filter((itemi => itemi.name === "actions"))
                                                                    .map(i => i.isVisible)[0]  &&
                                                                <TableCell align={"center"}>
                                                                    <ActionMenuButton menuItems={[
                                                                        {
                                                                            "key": "change",
                                                                            "caption": t('pages.organizations.actionChangeCaption'),
                                                                            "fn": () => actionChangeHandler(rowi.id, rowi.name)
                                                                        },
                                                                        {
                                                                            "key": "delete",
                                                                            "caption": t('pages.organizations.actionDeleteCaption'),
                                                                            "fn": () => actionDeleteHandler(rowi.id, rowi.name)
                                                                        }
                                                                    ]}/>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            }
                                        </Table>
                                    </Box>
                                </PerfectScrollbar>

                                {
                                    !totalPages &&
                                    <DataNotFoundComponent refreshDataFn={loadData} />
                                }

                                {
                                    !isLoading &&
                                    <PagingComponentTable
                                        totalPages={totalPages}
                                        page={page} setPageFn={setPageL}
                                        limit={limit} setLimitFn={setLimitL}
                                        refreshDataFn={loadData} />
                                }

                                {/*Диалог для исправления наименования*/}
                                <AddEditOrganizationDialog open={open} setOpen={setOpen}
                                                           oldOrganizationName={organizationNameForEdit}
                                                           organizationId={organizationIdForEdit}
                                                           refreshListRows={loadData}/>

                                {/*Диалог удаления*/}
                                <DeleteOrganizationDialog open={openDeleteDlg} setOpen={setOpenDeleteDlg}
                                                          oldOrganizationName={organizationNameForEdit}
                                                          organizationId={organizationIdForEdit}
                                                          refreshListRows={loadData}/>


                            </Card>
            }
        </Box>
    );
};

export default OrganizationsList;