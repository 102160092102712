import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button, Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    fetchSaveInfoByTab,
    fetchTemplateInfo,
    initTemplateTab, updateTemplateTab, setNeedRefreshData
} from "../../../store/administration/systemParamsPageSlice";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";
import {yupResolver} from "@hookform/resolvers/yup";


const NotificationsTemplateTab = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const schema = Yup.object().shape({
        userRegistrationEmailTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        passwordEmailTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        restorePasswordEmailTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        userRegistrationSmsTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        passwordSmsTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        restoreCodeSmsTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),

        eslipSendTemplate: Yup.string()
            .max(1024, t('pages.systemParams.maxLengthMsg') + ' 1024')
            .required(t('pages.systemParams.requiredCaption')),
    })

    const {templateTabValues, needRefreshData, error, status, is403} = useSelector(state => state.systemParamsPage)

    const {control, reset, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            ...templateTabValues
        }
    })

    useEffect(() => {
        reset(templateTabValues);
    }, [templateTabValues]);

    useEffect(() => {
        async function initTab() {
            await dispatch(initTemplateTab())
        }

        // Все чистим в сторе
        initTab()
        // Грузим новые данные
        loadData()
    }, [dispatch])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    const loadData = async () => {
        await dispatch(fetchTemplateInfo({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    const handleSubmitLocal = (data) => {
        //console.log(data);
        dispatch(updateTemplateTab({...data}))
        dispatch(fetchSaveInfoByTab({
            tab: 'template',
            showSnackMsg: showSnackMsg
        }))
    }

    if (is403) {
        return (
            <Box sx={{pt:'-24px', mt:'-20px'}}>
                <AccessDeniedComponent />
            </Box>
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(handleSubmitLocal)}>
            <Card>
            <CardHeader
                subheader={t('pages.systemParams.templateSubHeader')}
                title={t('pages.systemParams.templateHeader')}
            />
            <Divider/>
            {/*{isProcessing && <LinearProgress/>}*/}
            <CardContent>
                <Grid container spacing={4} columns={16}>

                    {
                        (status === 'rejected') &&
                        <Grid xs={16} md={16} item>
                            <Alert severity={"error"}>{t(error)}</Alert>
                        </Grid>
                    }

                    {
                        (status === 'resolved' || status === 'rejected') &&
                        <>
                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"userRegistrationEmailTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.userRegistrationEmailTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.userRegistrationEmailTemplate_placeholder')}
                                            multiline
                                            //ref={field.ref}
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"passwordEmailTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.passwordEmailTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.passwordEmailTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"restorePasswordEmailTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.restorePasswordEmailTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.restorePasswordEmailTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"userRegistrationSmsTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.userRegistrationSmsTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.userRegistrationSmsTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"passwordSmsTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.passwordSmsTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.passwordSmsTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"restoreCodeSmsTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.restoreCodeSmsTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.restoreCodeSmsTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>

                            <Grid xs={16} md={16} item>
                                <Controller
                                    name={"eslipSendTemplate"}
                                    control={control}
                                    render={({ field, fieldState: {error}}) => (
                                        <TextField
                                            {...field}
                                            error={!!error}
                                            helperText={error?.message}
                                            type={"text"}
                                            fullWidth
                                            label={t('pages.systemParams.eslipSendTemplate_label')}
                                            variant="outlined"
                                            placeholder={t('pages.systemParams.eslipSendTemplate_placeholder')}
                                            multiline
                                            rows={5}
                                        />
                                    )}/>
                            </Grid>
                        </>
                    }

                </Grid>
            </CardContent>

            <Divider/>
            <Box sx={{display:"flex", justifyContent: 'flex-end', p:2}}>
                <Button
                    color="primary"
                    variant="contained"
                    type={"submit"}
                    // disabled={isProcessing}
                >
                    {t('pages.systemParams.saveInfoBtnCaption')}
                </Button>
            </Box>
        </Card>
        </form>
    );
};

export default NotificationsTemplateTab;