import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";

const NoRestrictionsComponent = () => {
    const {t} = useTranslation()
    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)
    const groupId = useSelector(state => state.groupAccessPage.groupId)
    const groupName = useSelector(state => state.groupAccessPage.groupName)

    return (
        <Box>
            Работа с доступом не предусмотрена
        </Box>
    );
};

export default NoRestrictionsComponent;