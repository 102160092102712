import React from 'react';
import {
    Divider,
    Drawer,
    FormControlLabel,
    FormGroup, IconButton, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch, Tooltip, Typography
} from "@mui/material";
import {
    changeVisibleColumn,
    changSettingsPanelOpenStatus
} from "../../../store/common/eslipsPageSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';


const EslipSettingShowColumn = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const settingsPanelOpen = useSelector(state => state.eslipsPage.settingsPanelOpen)
    const columnSettings = useSelector(state => state.eslipsPage.columnSettings)

    const metaInfoSetting = [
        {key:'currency.codeAlpha', label:'pages.eslips.currency'},
        {key:'pan', label: 'pages.eslips.pan'},
        {key:'formattedAmount', label: 'pages.eslips.amount'},
        {key:'serviceTimeFormatted', label: 'pages.eslips.device_time'},
        {key:'terminal.terminalhostId', label: 'pages.eslips.tid'},
        {key:'terminal.merchants.name', label: 'pages.eslips.merchantname'},
        {key:'cardHolder', label: 'pages.eslips.cardholder'},
        {key:'origOperationName', label: 'pages.eslips.operName'},
        {key:'rrn', label: 'pages.eslips.rrn'},
        {key:'isEslipAttributeName', label: 'pages.eslips.isEslip'},
        {key:'email', label: 'pages.eslips.email'},
        {key:'phone', label: 'pages.eslips.phone'}
    ]


    /* Закрытие панели с настройками */
    const settingCloseBtnHandler = () => {
        dispatch(changSettingsPanelOpenStatus(false))
    }

    const checkedSettings = (name) => {
        const settingi = columnSettings.find(element => element.key === name)

        return settingi?.visible
    }

    const handleChange = (event, name) => {
        dispatch(changeVisibleColumn(name))
    }


    return (
        <Drawer
            anchor={"right"}
            open={settingsPanelOpen}
            onClose={settingCloseBtnHandler}
        >
            <List sx={{width: '400px'}}>
                <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <ListItemIcon>
                        <TuneOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('pages.eslips.tableSettingsCaption')}/>

                    <Tooltip title={t('filter.closeBtnToolTipText')}>
                        <IconButton aria-label="delete" color="error" size="small"
                                    onClick={settingCloseBtnHandler}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <Divider sx={{mb: '15px'}}/>
                <ListItem>
                    <Typography variant={"h5"}>
                        {t('pages.eslips.settingColumnHeader')}
                    </Typography>
                </ListItem>
                <FormGroup>
                    {
                        metaInfoSetting.map((rowi, key) => (
                            <ListItem key={key}>
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Switch size="medium"
                                                checked={checkedSettings(rowi.key)}
                                                onChange={(event) => handleChange(event, rowi.key)}/>
                                    }
                                    label={t(rowi.label)}
                                />
                            </ListItem>
                        ))
                    }
                </FormGroup>
            </List>

        </Drawer>
    );
};

export default EslipSettingShowColumn;