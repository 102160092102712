import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button, Card,
    CardContent,
    CardHeader,
    Divider, FormControl,
    Grid, InputLabel,
    Select,
    TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    fetchPasswordPolicyInfo, fetchSaveInfoByTab,
    initPasswordPolicyTab, updatePasswordPolicyTab, setNeedRefreshData
} from "../../../store/administration/systemParamsPageSlice";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";


const PasswordPolicyTab = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const schema = Yup.object().shape({
        minLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
        // .test(
        //     'vvvv',
        //     //(value) => has(value, 'email') || has(value, 'mobile')
        //     (value, {createError}) => {
        //         let t =  Yup.ref('maxLength') > value
        //         console.log(Yup.ref('maxLength'))
        //         return t ? true : createError({
        //             message: `Custom Message here ${value}`,
        //             path: 'minLength', // Fieldname
        //         })
        //     }
        //),
        maxLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
        // .when('minLength', {
        //     is: ,
        //     then:
        // }),
        defaultLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
    })

    const {passwordPolicyTabValues, needRefreshData, error, status, is403} = useSelector(state => state.systemParamsPage)

    const {control, reset, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            ...passwordPolicyTabValues
        }
    })

    useEffect(() => {
        reset(passwordPolicyTabValues);
    }, [passwordPolicyTabValues]);

    useEffect(() => {
        async function initTab() {
            await dispatch(initPasswordPolicyTab())
        }

        // Все чистим в сторе
        initTab()
        // Грузим новые данные
        loadData()
    }, [dispatch])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    const loadData = async () => {
        await dispatch(fetchPasswordPolicyInfo({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    const handleSubmitLocal = (data) => {
        //console.log(data);
        dispatch(updatePasswordPolicyTab({...data}))
        dispatch(fetchSaveInfoByTab({
            tab: 'password_policy',
            showSnackMsg: showSnackMsg
        }))
    }

    if (is403) {
        return (
            <Box sx={{pt:'-24px', mt:'-20px'}}>
                <AccessDeniedComponent />
            </Box>
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(handleSubmitLocal)}>
            <Card>
                <CardHeader
                    subheader={t('pages.systemParams.passwordPolicySubHeader')}
                    title={t('pages.systemParams.passwordPolicyHeader')}
                />
                <Divider/>

                {/*{isProcessing && <LinearProgress/>}*/}
                <CardContent>
                    <Grid container spacing={3} columns={16}>
                        {
                            (status === 'rejected') &&
                            <Grid xs={16} md={16} item>
                                <Alert severity={"error"}>{t(error)}</Alert>
                            </Grid>
                        }

                        {
                            (status === 'resolved' || status === 'rejected') &&
                            <>
                                <Grid xs={16} md={8} item>
                                    <Controller
                                        name={"minLength"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label={t('pages.systemParams.minLengthPasswordCaption')}
                                                error={!!error}
                                                helperText={error?.message}
                                                type={"number"}
                                                required
                                                variant="outlined"
                                                value={field.value}
                                            />
                                        )}
                                    />


                                </Grid>
                                <Grid xs={0} md={8}
                                      item>
                                    <Box></Box>
                                </Grid>

                                <Grid xs={16} md={8} item>
                                    <Controller
                                        name={"maxLength"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={!!error}
                                                helperText={error?.message}
                                                type={"number"}
                                                fullWidth
                                                label={t('pages.systemParams.maxLengthPasswordCaption')}
                                                required
                                                variant="outlined"
                                            />
                                        )}/>
                                </Grid>
                                <Grid xs={0} md={8}
                                      item>
                                    <Box></Box>
                                </Grid>

                                <Grid xs={16} md={8} item>
                                    <Controller
                                        name={"defaultLength"}
                                        control={control}
                                        render={({field, fieldState: {error}}) => (
                                            <TextField
                                                {...field}
                                                error={!!error}
                                                helperText={error?.message}
                                                type={"number"}
                                                fullWidth
                                                label={t('pages.systemParams.passwordLengthDefault')}
                                                required
                                                variant="outlined"
                                            />
                                        )}/>
                                </Grid>
                                <Grid xs={0} md={8}
                                      item>
                                    <Box></Box>
                                </Grid>

                                <Grid xs={16} md={8} item>
                                    <FormControl fullWidth>
                                        <InputLabel id="transport-password-notification-select-label">
                                            {t('pages.systemParams.sendPasswordsUse')}
                                        </InputLabel>
                                        <Controller control={control}
                                                    name={'pwdNotificationTransport'}
                                                    render={({field}) => (
                                                        <Select
                                                            {...field}
                                                            labelId="transport-password-notification-select-label"
                                                            id="transport-password-notification-select"
                                                            label={t('pages.systemParams.sendPasswordsUse')}
                                                            variant="outlined"
                                                        >
                                                            <MenuItem
                                                                value={'SMS'}>{t('pages.systemParams.sendPasswordOptionSms')}</MenuItem>
                                                            <MenuItem
                                                                value={'EMAIL'}>{t('pages.systemParams.sendPasswordOptionEmail')}</MenuItem>
                                                        </Select>
                                                    )}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid xs={0} md={8}
                                      item>
                                    <Box></Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                </CardContent>

                <Divider/>
                <Box sx={{display: "flex", justifyContent: 'flex-end', p: 2}}>
                    <Button
                        color="primary"
                        variant="contained"
                        type={"submit"}
                        // disabled={isProcessing || isSubmitError}
                    >
                        {t('pages.systemParams.saveInfoBtnCaption')}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};

export default PasswordPolicyTab;