import {createSlice} from "@reduxjs/toolkit";
import React from "react";

const groupAccessPageSlice = createSlice({
    name: "groupAccessPage",
    initialState: {

        isLoading: false,
        needRefreshData: false, // Когда произошли изменения в соседних окнах
        mode:'SELECT_GROUP', // SELECT_GROUP DEFINITION EDITING DELETING

        groupId: null,
        groupName: null,

        /* Выбор Групп */
        page: 1,
        totalRows: 0,
        totalPages: 0,
        limit: 10,
        searchString: '',

        rowsGroup:[],

        accessLevel: 'INDEFINED',

        /* редактирование объектов */
        openDeleteBindingDlg: false,
        rowsCurrentIn: [],
        pageEdit: 1,
        totalRowsEdit: 0,
        totalPagesEdit: 0,
        limitEdit: 10,
        searchStringEdit: '',
        rowsForDelete:[],

        /* добавление объектов */
        isAddingObject: false,
        openSaveBindingDlg: false,
        pageAddMode: 1,
        totalRowsAddMode: 0,
        totalPagesAddMode: 0,
        limitAddMode: 10,
        searchStringAddMode: '',
        rowsNotIn: [],
        rowsForAdd: []
    },

    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },

        setNeedRefreshData(state, action) {
            state.needRefreshData = action.payload
        },

        setMode(state, action) {
            state.mode = action.payload
        },

        setPage(state, action) {
            state.page = action.payload.page
        },

        setTotalRows(state, action) {
            state.totalRows = action.payload.totalRows
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload.totalPages
        },

        setLimit(state, action) {
            state.limit = action.payload.limit
        },

        setSearchString(state, action) {
            state.searchString = action.payload.searchString
        },

        setRowsGroup(state, action) {
            let copyState = state.rowsGroup.slice()
            copyState = [...action.payload.rows]
            state.rowsGroup = copyState
        },

        setAccessLevel(state, action) {
            state.accessLevel = action.payload
        },

        /* редактирование объектов */
        setPageEdit(state, action) {
            state.pageEdit = action.payload.page
        },


        setTotalPagesEdit(state, action) {
            state.totalPagesEdit = action.payload.totalPages
        },

        setLimitEdit(state, action) {
            state.limitEdit = action.payload.limit
        },

        setSearchStringEdit(state, action) {
            state.searchStringEdit = action.payload.searchString
        },

        setRowsCurrentIn(state, action) {
            let copyState = state.rowsCurrentIn.slice()
            copyState = [...action.payload.rows]
            state.rowsCurrentIn = copyState
        },

        addRowForDelete(state, action) {
            let copyState = state.rowsForDelete.slice()
            copyState.push(action.payload.rowForDelete)
            state.rowsForDelete = copyState
        },

        deleteRowForDelete(state, action) {
            state.rowsForDelete = state.rowsForDelete.filter(row => row.id != action.payload.key);
        },

        clearRowsForDelete(state, action) {
            state.rowsForDelete = []
        },

        /** добавление объектов **/
        setOpenDeleteBindingDlg(state, action) {
            state.openDeleteBindingDlg = action.payload.openDeleteBindingDlg
        },

        setIsAddingObject(state, action) {
            state.isAddingObject = action.payload
        },

        setOpenSaveBindingDlg(state, action) {
            state.openSaveBindingDlg = action.payload
        },

        setPageAddMode(state, action) {
            state.pageAddMode = action.payload.page
        },

        setTotalRowsAddMode(state, action) {
            state.totalRowsAddMode = action.payload.totalRows
        },

        setTotalPagesAddMode(state, action) {
            state.totalPagesAddMode = action.payload.totalPages
        },

        setLimitAddMode(state, action) {
            state.limitAddMode = action.payload.limitAddMode
        },

        setSearchStringAddMode(state, action) {
            state.searchStringAddMode = action.payload.searchString
        },

        setRowsNotIn(state, action) {
            let copyState = state.rowsNotIn.slice()
            copyState = [...action.payload.rows]
            state.rowsNotIn = copyState
        },

        addRowsForAdd(state, action) {
            let copyState = state.rowsForAdd.slice()
            copyState.push(action.payload.row)
            state.rowsForAdd = copyState
        },

        deleteRowsForAdd(state, action) {
            state.rowsForAdd = state.rowsForAdd
                .filter(row => row.id != action.payload.key);
        },

        clearRowsForAdd(state, action) {
            state.rowsForAdd = []
        },





        /* открытие страницы для работы с доступом групп */
        initAccessPage(state, action) {
            state.page = 1
            state.totalRows = 0
            state.totalPages = 0
            state.limit = 10
            state.searchString = ''
            state.rowsGroup = []
            state.mode = 'SELECT_GROUP'

            state.accessLevel = 'INDEFINED'

            returnAccessPageSelectGroup(state, action)
        },

        /* Возвращаемся на страницу выбора групп */
        returnAccessPageSelectGroup(state, action) {
            state.mode = 'SELECT_GROUP'
            // Затереть низлежащие стайты
            state.accessLevel = 'INDEFINED'

            /** редактирование объектов **/
            state.openDeleteBindingDlg = false
            state.rowsCurrentIn = []
            state.pageEdit = 1
            state.totalRowsEdit = 0
            state.totalPagesEdit = 0
            state.limitEdit = 10
            state.searchStringEdit = ''
            state.rowsForDelete = []

            /** добавление объектов **/
            state.isAddingObject = false
            state.openSaveBindingDlg = false
            state.pageAddMode = 1
            state.totalRowsAddMode = 0
            state.totalPagesAddMode = 0
            state.limitAddMode = 10
            state.searchStringAddMode = ''
            state.rowsNotIn = []
            state.rowsForAdd = []
        },


        /* Выбрали группу - и ее редактируем  */
        initGroupInfoAccessComponent(state, action) {
            let value = action.payload.value

            state.groupId = value.id
            state.groupName = value.name
            state.accessLevel = value.accessLevel
            //SELECT_GROUP DEFINITION EDITING

            if (['INDEFINED', 'NO_RESTRICTIONS'].includes(value.accessLevel)) {
                state.mode = 'DEFINITION'
            }

            if (['BANK', 'ORG', 'MERCHANT'].includes(value.accessLevel)) {
                state.mode = 'EDITING'
            }

            state.isAddingObject = false

            state.openDeleteBindingDlg = false
            state.pageEdit = 1
            state.totalRowsEdit = 0
            state.totalPagesEdit = 0
            state.limitEdit = 10
            state.searchStringEdit = ''
            state.rowsForDelete = []

            state.openSaveBindingDlg = false
            state.pageAddMode = 1
            state.totalRowsAddMode = 0
            state.totalPagesAddMode = 0
            state.limitAddMode = 10
            state.searchStringAddMode = ''
            state.rowsNotIn = []
            state.rowsForAdd = []

        },

        /* Правка текущих объектов */
        initParamsEditCurrentObject(state, action) {
            // state.rowsCurrentIn: [],

            state.openDeleteBindingDlg = false
            state.isAddingObject = false
            state.pageEdit = 1
            state.totalRowsEdit = 0
            state.totalPagesEdit = 0
            state.limitEdit = 10
            state.searchStringEdit = ''
            state.rowsForDelete = []
        },

        /* Добавление - При загрузке страницы нужно очистить прошлые значения*/
        initParamsOnAddPageStart(state, action) {


            state.isAddingObject = true
            state.openDeleteBindingDlg = false
            state.openSaveBindingDlg = false
            state.pageAddMode = 1
            state.totalRowsAddMode = 0
            state.totalPagesAddMode = 0
            state.limitAddMode = 10
            state.searchStringAddMode = ''
            state.rowsNotIn = []
            state.rowsForAdd = []
        }
    },


})

export const {
    setIsLoading,
    setNeedRefreshData,
    setMode,
    setPage,
    setTotalRows,
    setTotalPages,
    setLimit,
    setSearchString,
    setRowsGroup,
    setAccessLevel,
    initAccessPage,

    /* редактирование объектов */
    setOpenDeleteBindingDlg,
    setPageEdit,
    setTotalPagesEdit,
    setLimitEdit,
    setSearchStringEdit,
    setRowsCurrentIn,
    addRowForDelete,
    deleteRowForDelete,
    clearRowsForDelete,

    /** добавление объектов **/
    setIsAddingObject,
    setOpenSaveBindingDlg,
    setPageAddMode,
    setTotalRowsAddMode,
    setTotalPagesAddMode,
    setLimitAddMode,
    setSearchStringAddMode,
    setRowsNotIn,
    addRowsForAdd,
    deleteRowsForAdd,
    clearRowsForAdd,



    returnAccessPageSelectGroup,
    // initCurrentPage,
    initGroupInfoAccessComponent,
    initParamsEditCurrentObject,
    initParamsOnAddPageStart,


} = groupAccessPageSlice.actions

export default groupAccessPageSlice.reducer