import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Alert, Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RouteEnum} from "../../../types/RouteEnum";
import {
    setNewPassword,
    setConfirmPassword,
    fetchPasswordRecovery,
    toStepOne
} from "../../../store/auth/passwordRecoveryPageSlice";

const Step3 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const userLogin = useSelector(state => state.passwordRecoveryPage.login)
    const {error, status, confirmationCode, navigateLogin} = useSelector(state => state.passwordRecoveryPage)


    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    useEffect(() => {
        if (navigateLogin) {
            // TODO : всплывающее сообщение

            dispatch(toStepOne())
            navigate(RouteEnum.AUTH, {replace: true}) // true - По кнопке НАЗАД не можем вернуться на страницу логина
        }
    }, [navigateLogin])

    const formik = useFormik({
        initialValues: {
            passwordUser: '',
            passwordUserConfirm: ''
        },
        validationSchema: Yup.object({
            passwordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordCheckRequired'))
                .matches(/^(?=.*\d)(?=.*[!"#$%&'()\*+,-.\/:;<=>\\?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).\S{3,}$/, t('pages.restorePassword.passwordRulesError')),
            passwordUserConfirm: Yup
                .string()
                .oneOf([Yup.ref('passwordUser'), null], t('pages.restorePassword.passwordNotEqual'))
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.restorePassword.passwordConfirmCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            try {
                await dispatch(setNewPassword(values.passwordUser))
                await dispatch(setConfirmPassword(values.passwordUserConfirm))
                await dispatch(fetchPasswordRecovery())
            } catch (e) {
                formikHelpers.setSubmitting(false)
            }
        }
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (
        <>
            <Typography color="textPrimary" variant="h5" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.step3Caption')}
            </Typography>

            <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.loginInputLabel')}: {userLogin}
            </Typography>

            <Typography color="textPrimary" variant="h6" sx={{mt: '1rem'}}>
                {t('pages.restorePassword.confirmationCodeInputLabel')}: {confirmationCode}
            </Typography>

            {
                error &&
                <Alert severity="error">{t(error)}</Alert>
            }

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    error={Boolean(formik.touched.passwordUser && formik.errors.passwordUser)}
                    fullWidth
                    helperText={formik.touched.passwordUser && formik.errors.passwordUser}
                    label={t('pages.restorePassword.passwordUserLabel')}
                    margin="normal"
                    name="passwordUser"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.passwordUser}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <TextField
                    error={Boolean(formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm)}
                    fullWidth
                    helperText={formik.touched.passwordUserConfirm && formik.errors.passwordUserConfirm}
                    label={t('pages.restorePassword.passwordUserConfirmLabel')}
                    margin="normal"
                    name="passwordUserConfirm"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    value={formik.values.passwordUserConfirm}
                    variant="outlined"
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConfirm}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPasswordConfirm ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                    }}
                />

                <Box sx={{py: 2}}>
                    <Button
                        color="primary"
                        disabled={formik.isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('pages.restorePassword.step3BtnCaption')}
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default Step3;