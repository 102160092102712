import {hasText} from "./UtilFunction";
import dayjs from "dayjs";

/**
 * Формирует item в FilterModel на основе переданных парметров.
 *
 * @param name
 * @param operation
 * @param value
 * @returns {{name, operation, value}|{}}
 */
const buildNewFilterModelItem = (name, operation, value) => {
    // dispatch(setFilterModel({filterModel: {"groupName": {"name": "name", "operation": "like", "value": searchValue}}}))
    // переводим в строку на всякий случай
    let valueAsString = value ? value + '' : ''
    if (hasText(valueAsString) && valueAsString !== '-1') {  // -1 считается в фильтрах пустое значение
        return {name:name, operation:operation, value:value}
    } else {
        return {};
    }
}


/**
 * Получает предикат из filterModel по имени.
 *
 * @param filterModel
 * @param item
 * @param converter
 */
const buildFilterPredicateCommon = (filterModel, fieldName, converter) => {

    try {
        if (!filterModel[fieldName] || Object.entries(filterModel[fieldName]).length < 1) {
            return null
        }

        // Считается что это пустое значение
        if (filterModel[fieldName].value + '' === "-1") {
            return null
        }

        // Дополнительное преобразование, если нужно
        let valueAfterConvert = converter ? converter(filterModel[fieldName].value) : filterModel[fieldName].value
        if (!valueAfterConvert) {
            console.error(fieldName + "filter value is empty")
            return null
        }




        // Если есть поле в фильтр модели и объект не пустой, обрабатываем...
        // Итоговый объект
        // Фильтр модели значение содержится как строка, но на бэк нужно конвертировать в массив
        let parami = {
            "key": filterModel[fieldName].name,
            "operation": filterModel[fieldName].operation,
            "value": valueAfterConvert.split(',')
        }

        return parami
    } catch (e) {
        console.error(e)
        return null
    }
}






/**
 * Переводит параметр числовой в boolean строку. "true" или "false"
 *
 * строгий режим, если не смогли конвертировать возвращает null!!!
 * @param value
 */
const valueBooleanConverter = (value) => {
    if (!value) return null

    const accValue = ["true", "false", "1", "0"]

    let valueAsString = (value + '').toLowerCase()
    if (!accValue.includes(valueAsString)) {
        return null
    }

    if (valueAsString === "true" || valueAsString === "1") {
        return "true"
    } else {
        return "false"
    }
}

const valueDateToStringConverter = (value) => {
    if (!value) return null

    return dayjs(value).format('DD.MM.YYYY')
}


export {
    buildNewFilterModelItem, buildFilterPredicateCommon,
    valueBooleanConverter, valueDateToStringConverter
}