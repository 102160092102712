import React, {useEffect} from 'react';
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Backdrop, Box, CircularProgress, Container} from "@mui/material";
import EslipExportToolbar from "./EslipExportToolbar";
import {
    initPageData,
    fetchEslipsExportRows,
    fetchEslipsExportStatusRows
} from "../../../store/common/exportManagerPageSlice";
import EslipExportResult from "./EslipExportResult";

const EslipExportPage = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()

    const {status} = useSelector(state => state.exportManagerPage)

    useEffect(() => {
        async function initData() {
            // Инициализируем настройки колонок из localstorage
            await dispatch(initPageData())
            dispatch(fetchEslipsExportRows({showSnackMsg:showSnackMsg}))
        }


        document.title = 'Export Manager | UniPORT E-slip';

        dispatch(setActiveSection({href: '/export'}))

        const items = [
            {href:"/export", caption:'pages.exportManager.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        initData()
    }, []);

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Backdrop
                open={status === 'loading'}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>


            <Container maxWidth={false}>
                <EslipExportToolbar />
                <Box sx={{mt: 3}}>
                    <EslipExportResult />
                </Box>
            </Container>
        </Box>
    );
};

export default EslipExportPage;