import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../service/axiosApi";
import {useTranslation} from "react-i18next";

/**
 * Диалог для добавления нового или изменения старой группы.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddEditGroupDialog = (props) => {
    const {t} = useTranslation()
    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar } = useSnackbar();
    // Приходят из-вне
    const {open, setOpen, oldRowName, rowId, refreshListRows} = props

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            formik.setFieldValue("rowName", oldRowName);
        }
    }, [open])

    const fetchApi = async (newRowName) => {
        let baseUrl = "/ui/v1/group_users";
        let params = {"id": null, "name": newRowName}
        if (isNewRow()) {
            return await instanceAxiosApi.post(`${baseUrl}`, JSON.stringify(params));
        } else {
            params.id = rowId
            return await instanceAxiosApi.put(`${baseUrl}/${rowId}`, JSON.stringify(params));
        }
    }

    const isNewRow = () => {
        return rowId === -1
    }

    const handleClose = () => {
        clearError()
        formik.resetForm()
        setOpen(false);
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    const formik = useFormik({
        initialValues: {
            rowName: oldRowName
        },
        validationSchema: Yup.object({
            rowName: Yup
                .string()
                .notOneOf([oldRowName, null], t('pages.groupUsers.diffOldNameCaption'))
                .max(50)
                .required(t('pages.groupUsers.requiredCaption')),
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)

            await fetchApi(values.rowName)
                .then((response) => {
                    handleClose()
                    // Заставляем обновить данные на сервере
                    refreshListRows()

                    // Сообщение
                    let rStatus = response?.status || 400
                    if (rStatus === 200 || rStatus === 201) {
                        enqueueSnackbar(t('systemCommon.infoSaveSuccessCaption'), {
                            variant: 'success',
                            preventDuplicate: true,
                            anchorOrigin: { vertical: 'top', horizontal: 'right'},
                        })
                    }
                })
                .catch(e => {
                    formikHelpers.setSubmitting(false)
                    if (e?.response?.data?.errorCode === 'E_DOM_4') {
                        setErrorSubmitMsg(t('pages.groupUsers.cannotEditSystemGroup'))
                    } else {
                        setErrorSubmitMsg(e?.response?.data?.uiMessage || e.message)
                    }

                    setIsSubmitError(true)

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoErrorSaveCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .finally(() => setIsProcessed(false))
        }
    });

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{
                    }}>
                        <DialogTitle>{isNewRow() ? t('pages.groupUsers.titleCreateCaption') : t('pages.groupUsers.titleEditCaption')}</DialogTitle>
                        {
                            isProcessed &&
                            <Box sx={{}}>
                                <LinearProgress/>
                            </Box>
                        }
                        <DialogContent>
                            <DialogContentText>
                                {
                                    !isNewRow() &&
                                    <Box sx={{display: 'flex', mb: '1rem'}}>
                                        <Typography>
                                            {t('pages.groupUsers.oldNameCaption')}
                                        </Typography>
                                        <Typography color={'secondary.main'} sx={{ml:'0.5rem'}}>
                                            {oldRowName}
                                        </Typography>
                                    </Box>
                                }

                                {isNewRow() ? t('pages.groupUsers.nameNewGroupCaption') : t('pages.groupUsers.newNameCaption')}
                            </DialogContentText>

                            <TextField
                                error={Boolean(formik.touched.rowName && formik.errors.rowName)}
                                fullWidth
                                helperText={formik.touched.rowName && formik.errors.rowName}
                                label={t('pages.groupUsers.nameGroupLabel')}
                                margin="normal"
                                name="rowName"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.rowName}
                                variant="outlined"
                                disabled={isProcessed}
                            />

                            {
                                isSubmitError &&
                                <Alert severity="error">{errorSubmitMsg}</Alert>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isProcessed} color={"error"} onClick={handleClose}>
                                {t('systemCommon.dlgCancelBtnCaption')}
                            </Button>
                            <Button disabled={isProcessed} type={"submit"}>
                                {t('systemCommon.dlgSaveBtnCaption')}
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

export default AddEditGroupDialog;