import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Tooltip,
    Typography, useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import DeleteBankBindingGroupDialog from "./DeleteBankBindingGroupDialog";
import {
    setAddMode,
    setOpenDeleteBindingDlg
} from "../../../../store/handbook/bankOrganizationsBindingPageSlice";




const OrganizationBindingToolBar = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const isAddMode = useSelector(state => state.bankOrganizationsBindingPage.isAddMode)
    const bankName = useSelector(state => state.bankOrganizationsBindingPage.bankName)
    const isLoading = useSelector(state => state.bankOrganizationsBindingPage.isLoading)

    const rowsForDelete = useSelector(store => store.bankOrganizationsBindingPage.rowsForDelete)

    const [isExistsRowForDelete, setIsExistsRowForDelete] = useState(false)
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    useEffect(() => {
        setIsExistsRowForDelete(rowsForDelete.length > 0)
    }, [rowsForDelete])


    const handleClickDelete = () => {
        if (rowsForDelete.length < 1) {
            enqueueSnackbar(t('systemCommon.selectEntriesToDeleteMsg'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            })

            return
        }
        dispatch(setOpenDeleteBindingDlg({openDeleteBindingDlg:true}))
    }

    const handleClickBackAddMode = () => {
        dispatch(setAddMode({isAddMode:false}))
    }

    const handleClickNewBinding = () => {
        dispatch(setAddMode({isAddMode:true}))
    }

    return (
        <Box {...props}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
            >
                <Box sx={{display:"flex", justifyContent:"start"}}>
                    <Typography
                        sx={{m: 1}}
                        variant="h4"
                    >
                        {t('pages.banks.orgBinding.pageHeaderCaption')}
                    </Typography>
                    <Typography color={'primary.main'}
                                sx={{m: 1}}
                                variant="h4"
                    >
                        {bankName}
                    </Typography>
                </Box>
                {
                    !isAddMode && <Box sx={{display: 'flex', m: 1}}>
                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip title={lgUp ? "" : t('systemCommon.deleteSelectedQuestionMsg')} >
                            <Button
                                startIcon={<DeleteForeverOutlinedIcon />}
                                // color="primary"
                                size={"small"}
                                sx={{mr:'10px'}}
                                variant="contained"
                                color="error"
                                onClick={() => handleClickDelete()}
                                disabled={isLoading || !isExistsRowForDelete}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('systemCommon.deleteSelectedQuestionMsg') : ''}
                            </Button>
                        </Tooltip>

                        {/*На большом экране подсказка не нужна*/}
                        <Tooltip title={lgUp ? "" : t('systemCommon.addNewRowQuestionMsg')} >
                            <Button
                                startIcon={<AddBusinessOutlinedIcon />}
                                size={"small"}
                                color="primary"
                                variant="contained"
                                onClick={() => handleClickNewBinding()}
                                disabled={isLoading}
                            >
                                {/*На маленьком экране подсказку не выводим*/}
                                { lgUp ? t('systemCommon.addNewRowQuestionMsg') : ''}
                            </Button>
                        </Tooltip>
                    </Box>
                }

                {
                    isAddMode && <Box sx={{display: 'flex', m: 1}}>

                        <Button
                            startIcon={<ArrowBackIcon />}
                            size={"small"}
                            // color="primary"
                            sx={{mr:'10px'}}
                            // variant="contained"
                            // color="error"
                            onClick={() => handleClickBackAddMode()}
                            disabled={isLoading}
                        >
                            {t('pages.banks.orgBinding.exitAddModeCaption')}
                        </Button>

                    </Box>
                }
            </Box>

            <DeleteBankBindingGroupDialog />
        </Box>

    );
};

export default OrganizationBindingToolBar;