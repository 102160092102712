import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Paper,
    Typography
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";


import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import {theme} from "../../../theme";
import {useDispatch, useSelector} from "react-redux";
import {toStepOne} from "../../../store/auth/passwordRecoveryPageSlice";


const PasswordRepairPage = () => {
    const dispatch = useDispatch()
    const stepNumber = useSelector(state => state.passwordRecoveryPage.stepNumber)
    const {t} = useTranslation()

    useEffect(() => {
        async function initPageData() {
            dispatch(toStepOne())
        }
        document.title = 'Password recovery | UniPORT E-slip';

        initPageData()
    }, []);

    const stepNumberComponent = () => {
        switch (stepNumber) {
            case 1: return <Step1 />
            case 2: return <Step2 />
            case 3: return <Step3 />
            default: return <Step1 />
        }
    }

    return (
        <Paper elevation={16}
               sx={{
                   // maxWidth: '30rem',
                   width: '98%',
                   // Когда привысим размеры маленького экрана, размер становится фиксированным
                   [theme.breakpoints.up('sm')]: {
                       width: '30rem',
                   },
                   pt: '30px', pb: '30px', height: 'auto'

        }}>
            <Box sx={{
                pl: '20px',
                pr: '20px',
            }}
            >
                 <Button href="#/auth/login"
                         startIcon={<ArrowBackIcon fontSize="small" />}
                         onClick={() => dispatch(toStepOne())}
                 >
                     {t('pages.restorePassword.signInCaption')}
                 </Button>

                <Typography color="textPrimary" variant="h4">
                    {t('pages.restorePassword.restorePasswordCaption')}
                </Typography>

                <Typography
                    color="textPrimary"
                    variant="body2"
                >
                    {t('pages.restorePassword.restorePasswordCaptionHint')}
                </Typography>

                {
                    stepNumberComponent()
                }

            </Box>
        </Paper>
    );
};

export default PasswordRepairPage;