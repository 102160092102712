import React, {useEffect, useState} from 'react';
import {Box, Grid, LinearProgress, Pagination, Paper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoading, setNeedRefreshData, setPage, setTotalPages} from "../../../store/administration/personGroupsPageSlice";
import instanceAxiosApi from "../../../service/axiosApi";
import {useSnackbar} from "notistack";
import DataNotFoundComponent from "../../../components/dataGridCustom/DataNotFoundComponent";
import {useTranslation} from "react-i18next";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";
import PersonGroupCard from "./PersonGroupCard";


const PersonGroupsList = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()

    const needRefreshData = useSelector(state => state.personGroupsPage.needRefreshData)
    const isLoading = useSelector(state => state.personGroupsPage.isLoading)
    const limit = useSelector(state => state.personGroupsPage.limit)

    const filterModel = useSelector(state => state.personGroupsPage.filterModel)
    const sortModel = useSelector(state => state.personGroupsPage.sortModel)

    const page = useSelector(state => state.personGroupsPage.page)
    const totalPages = useSelector(state => state.personGroupsPage.totalPages)
    const [is403, setIs403] = useState(false)
    const [dataRows, setDataRows] = useState([])

    useEffect(() => {
        loadData()
            .then(r => {
                // console.log('Выполнили поиск')
            }
        )

    }, [filterModel, sortModel, page])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData({needRefreshData:false})))
    }, [needRefreshData])

    const setPageL = (num) => {
        dispatch(setPage({page:num}))
    }


    const fetchApi = async (params) => {
        // Идем на сервер
        return await instanceAxiosApi.get("/ui/v1/group_users?" + params)
    }

    const buildParamsForRequest = () => {
        //page=1&limit=10&filter=[{"key":"name","operation":"like","value":["Н1к"]}]&order=[]
        let params = `page=${page}&limit=${limit}&`

        if (filterModel.groupName && Object.entries(filterModel.groupName).length > 0) {
            let parami={"key":"name","operation":"","value":[]}
            parami.operation = filterModel.groupName.operation
            parami.value.push(filterModel.groupName.value)

            let paramAsString = JSON.stringify(parami)

            params = params + `filter=[${paramAsString}]`
        }

        // TODO : реализоать если нужно
        if (sortModel && Object.entries(filterModel.groupName).length > 0) {
            // let sorti={"key":"name","operation":"","value":[]}
        }

        return params
    }

    const loadData = async () => {
            dispatch(setIsLoading({isLoading:true}))
            try {
                let params = buildParamsForRequest()
                let response = await fetchApi(params)

                let dataJson = response?.data || {rows:[], totalPages:0}

                setDataRows(dataJson.rows)
                dispatch(setTotalPages({totalPages:(dataJson.totalPages || 0)}))

                // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
                if (dataJson.totalPages < page) {
                    setPageL(1)
                }
            } catch (e) {
                // Ошибки могут быть: Нет доступа и все остальные
                let errorStatus = e?.response?.status || 400

                if (errorStatus === 403) {
                    setIs403(true)

                    enqueueSnackbar(t('systemCommon.accessDeniedMessage'), {
                        variant: 'warning',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                } else {
                    setIs403(false)

                    enqueueSnackbar(t('systemCommon.errorRequestInfoCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: {vertical: 'top', horizontal: 'right'},
                    })
                }
                // Скидываем данные в таблице
                setDataRows([])

                dispatch(setTotalPages({totalPages:(0)}))
            } finally {
                dispatch(setIsLoading({isLoading:false}))
            }
    }

    return (
        <Box>
            {
                isLoading && <Box sx={{pt: 1}}><LinearProgress /></Box>
            }
            {
                !isLoading && is403 &&
                <Grid
                    container
                    spacing={3}
                >
                    {
                        is403 &&
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                        >
                            <AccessDeniedComponent />
                        </Grid>
                    }
                </Grid>
            }
            {
                !isLoading && !is403 &&
                <Grid
                    container
                    spacing={3}
                >
                    {
                        totalPages
                        ?
                        dataRows.map((group) => (
                            <Grid
                                item
                                key={group.id}
                                lg={3}
                                md={4}
                                xs={12}
                            >
                                <PersonGroupCard group={group} />
                            </Grid>
                        ))
                        :
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                        >
                            <Paper>
                                <DataNotFoundComponent />
                            </Paper>
                        </Grid>
                    }
                </Grid>
            }
            {
                !isLoading && !!totalPages &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 3
                    }}
                >
                    <Pagination
                        color="primary"
                        count={totalPages}
                        page={page}
                        onChange={(_, num) => setPageL(num)}
                    />
                </Box>
            }
        </Box>
    );
};

export default PersonGroupsList;