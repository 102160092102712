import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import instanceAxiosApi from "../../service/axiosApi";

export const fetchRestoreCode = createAsyncThunk(
    'passwordRecoveryPage/fetchRestoreCode',
    async function(params, {getState, rejectWithValue}) {
        try {
            const {login} = getState().passwordRecoveryPage

            return await instanceAxiosApi.post(`/ui/v1/recovery/code/${login}`)
        } catch (error) {
            return rejectWithValue({
                errorCode: 400,
                error: 'systemCommon.errorRequestInfoCaption'}
            )
        }
    }
)

export const fetchPasswordRecovery = createAsyncThunk(
    'passwordRecoveryPage/fetchPasswordRecovery',
    async function(params, {getState, rejectWithValue}) {
        try {
            const {login, confirmationCode, newPassword, confirmPassword} = getState().passwordRecoveryPage
            let params = {
                confirmationCode: confirmationCode,
                login: login,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            }

            return await instanceAxiosApi.post(`/ui/v1/recovery/password`, JSON.stringify(params))
        } catch (error) {
            let errorCode = error?.response?.status || 400
            let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.infoErrorSaveCaption'

            return rejectWithValue({
                errorCode: errorCode,
                error: errorMessage}
            )
        }
    }
)

/* Подаем объект с ошибкой, более развернутый */
const rejectWithValueCustom = (error, rejectWithValue, showSnackMsg) => {
    let errorStatus = error?.response?.status || 400
    let errorMessage = error?.response?.data?.uiMessage || 'systemCommon.errorRequestInfoCaption'

    if (errorStatus === 403) {
        showSnackMsg('error', 'systemCommon.accessDeniedMessage')
        return rejectWithValue({
            errorCode: 403,
            error: 'systemCommon.accessDeniedMessage'}
        )
    } else if (errorStatus === 400) {
        showSnackMsg('error', errorMessage)
        return rejectWithValue({
            errorCode: 400,
            error: errorMessage}
        )
    } else {
        // Сообщение выдаст центральный axios interceptor
        return rejectWithValue({
            errorCode: 500,
            error: 'authProvider.internalServerErrorMessage'
        })
    }
}

/* Устанавливаем ошибку */
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload.error
    state.navigateLogin = false
}

const passwordRecoveryPageSlice = createSlice({
    name: 'passwordRecoveryPage',
    initialState: {
        stepNumber: 1,
        login: null,
        confirmationCode: null,
        transportConfirmationCode: null, // SMS или EMAIL

        newPassword: null,
        confirmPassword: null,

        status: null,
        error: null,

        navigateLogin: false
    },


    reducers: {
        toStepOne(state, action) {
            state.stepNumber = 1
            state.login = null
            state.confirmationCode = null
            state.transportConfirmationCode = null
            state.newPassword = null
            state.confirmPassword = null

            state.status = null
            state.error = null

            state.navigateLogin = false
        },

        setNewPassword(state, action) {
            state.newPassword = action.payload
        },

        setConfirmPassword(state, action) {
            state.confirmPassword = action.payload
        },

        setStepNumber(state, action) {
            state.stepNumber = action.payload.stepNumber
        },

        setLoginUser(state, action) {
            state.login = action.payload.login
        },

        setConfirmationCode(state, action) {
            state.confirmationCode = action.payload.confirmationCode
        }
    },

    extraReducers: (builder) => {
        builder
            // Получение кода подтверждения
            .addCase(fetchRestoreCode.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchRestoreCode.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                let dataJson = action.payload?.data || {codeStatus:null, transport:null}

                if (dataJson.codeStatus && dataJson.transport) {
                    state.transportConfirmationCode = dataJson.transport
                    state.stepNumber = 2
                } else {
                    state.transportConfirmationCode = null

                    state.error = 'systemCommon.errorRequestInfoCaption'
                    state.status = 'rejected'
                }


            })
            .addCase(fetchRestoreCode.rejected, (state, action) => setError(state, action))

            // Проверка кода подтверждения
            .addCase(fetchPasswordRecovery.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPasswordRecovery.fulfilled, (state, action) => {
                state.status = 'resolved'
                state.error = null

                state.confirmationCode = null
                state.newPassword = null
                state.confirmPassword = null

                state.navigateLogin = true
            })
            .addCase(fetchPasswordRecovery.rejected, (state, action) => setError(state, action))

    }
})

export const {
    toStepOne,
    setNewPassword,
    setConfirmPassword,
    setStepNumber,
    setLoginUser,
    setConfirmationCode
} = passwordRecoveryPageSlice.actions

export default passwordRecoveryPageSlice.reducer