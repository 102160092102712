import React, {useEffect, useState} from 'react';
import {
    Alert, AlertTitle,
    Backdrop, Box,
    Checkbox, CircularProgress,
    Container, Divider, IconButton, InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText, SvgIcon, TextField, Tooltip, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PagingComponentList from "../../../../components/list_element/PagingComponentList";
import instanceAxiosApi from "../../../../service/axiosApi";
import {hasText} from "../../../../util/UtilFunction";
import {
    addRowForDelete, clearRowsForDelete,
    deleteRowForDelete,
    initParamsOnCurrentPageStart, setIsLoading,
    setNeedRefreshData, setPage, setRowsCurrentIn, setSearchString, setTotalPages
} from "../../../../store/handbook/bankOrganizationsBindingPageSlice";



const OrganizationsBindingCurrent = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const isLoading = useSelector(state => state.bankOrganizationsBindingPage.isLoading)
    const searchString = useSelector(state => state.bankOrganizationsBindingPage.searchString)
    const page = useSelector(state => state.bankOrganizationsBindingPage.page)
    const limit = useSelector(state => state.bankOrganizationsBindingPage.limit)
    const totalPages = useSelector(state => state.bankOrganizationsBindingPage.totalPages)

    const rowId = useSelector(store => store.bankOrganizationsBindingPage.bankId)
    const rows = useSelector(store => store.bankOrganizationsBindingPage.rowsCurrentIn) || []
    const rowsForDelete = useSelector(store => store.bankOrganizationsBindingPage.rowsForDelete) || []
    const needRefreshData = useSelector(state => state.bankOrganizationsBindingPage.needRefreshData)

    // Инициируем тем значением фильтра, что в сторе содержится
    const [searchValue, setSearchValue] = useState(searchString || '')
    const [isLoadError, setIsLoadError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    // При старте скидываем прошлые данные
    useEffect(() => {
        dispatch(initParamsOnCurrentPageStart())
    }, [])

    /* Вдруг из диалога изменили информацию */
    useEffect(() => {
        if (!needRefreshData) return;

        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    useEffect(() => {
        loadData()

    }, [page])

    const handleToggle = (value) => () => {
        if (isCheckedItem(value.id)) {
            dispatch(deleteRowForDelete({key: value.id}))
        } else {
            dispatch(addRowForDelete({rowForDelete: value}))
        }
    };

    const isCheckedItem = (id) => {
        if (!rowsForDelete || rowsForDelete.length < 1) return false

        return rowsForDelete.filter(itemi => itemi.id == id).length > 0
    }

    const clearLocalErrorState = () => {
        setIsLoadError(false)
        setErrorMsg('')
    }

    // Идем на сервер
    const fetchApi = async (params) => {
        return await instanceAxiosApi.get(`/ui/v1/banks/organizations/bindings?${params}`)
    }

    const buildParams = () => {
        let filter = [
            {"key": "bankId", "operation": "eq", "value": [rowId]}
        ]

        if (hasText(searchString)) {
            filter.push({"key": "orgName", "operation": "like", "value": [searchString]})
        }

        let filterAsString = JSON.stringify(filter)
        return `page=${page}&limit=${limit}&filter=${filterAsString}`
    }

    const loadData = async () => {
        dispatch(setIsLoading({isLoading: true}))
        dispatch(clearRowsForDelete())
        try {
            const response = await fetchApi(buildParams())

            let dataJson = response?.data || {rows: [], totalPages: 0}

            dispatch(setRowsCurrentIn({rows: [...dataJson.rows]}))
            dispatch(setTotalPages({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                dispatch(setPage({page: 1}))
            }

            clearLocalErrorState()
        } catch (e) {
            dispatch(setRowsCurrentIn({rows: []}))

            setIsLoadError(true)
            let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')
            setErrorMsg(clientMessage)
        } finally {
            setSearchValue(searchString)
            dispatch(setIsLoading({isLoading: false}))
        }
    }

    const handleRefresh = () => {
        dispatch(setSearchString({searchString: searchValue}))
        dispatch(setNeedRefreshData(true))
    }

    const handleChangeSearchString = (event) => {
        setSearchValue(event.target.value)
    }

    const handleClickClearSearchString = () => {
        setSearchValue('')
        dispatch(setSearchString({searchString: ''}))
        dispatch(setNeedRefreshData(true))
    }

    const pressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(setSearchString({searchString: searchValue}))
            dispatch(setNeedRefreshData(true))
        }
    }

    const setPageL = (num) => {
        dispatch(setPage({page: num}))
    }

    return (
        <Container maxWidth={false}>
            <Backdrop
                open={isLoading}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>
            <Box sx={{maxWidth: "sm"}}>
                <Typography sx={{mt: 1, mb: 2, pt: '32px', pl: '10px'}} variant="h6" component="div">
                    {t('pages.banks.orgBinding.orgLinkWithBankCaption')}
                </Typography>

                <List dense={true}
                      sx={{width: '100%', /*maxWidth: 360, */bgcolor: 'background.paper'}}
                      subheader={
                          <Box sx={{display: "flex", flexDirection: "column"}}>
                              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                  <Tooltip title={t('systemCommon.updateInfo')}>
                                      <IconButton sx={{mr: '10px'}} onClick={handleRefresh}>
                                          <RefreshOutlinedIcon/>
                                      </IconButton>
                                  </Tooltip>
                                  <TextField
                                      type={"text"}
                                      fullWidth
                                      InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <SvgIcon
                                                      fontSize="small"
                                                      color="action"
                                                  >
                                                      <SearchIcon/>
                                                  </SvgIcon>
                                              </InputAdornment>
                                          ),

                                          endAdornment: searchString && (
                                              <IconButton size={"small"}
                                                          aria-label="toggle password visibility"
                                                          onClick={handleClickClearSearchString}
                                              >
                                                  <CancelRoundedIcon fontSize={"small"}/>
                                              </IconButton>
                                          )

                                      }}
                                      placeholder={t('pages.banks.orgBinding.searchByNameCaption')}
                                      variant="outlined"
                                      size={"small"}
                                      value={searchValue}
                                      onChange={handleChangeSearchString}
                                      onKeyDown={(e) => pressEnter(e)}
                                      disabled={isLoading}
                                      inputRef={(input) => input?.focus()}
                                  />
                              </Box>
                          </Box>
                      }
                >
                    {rows.map((value, key) => {
                        const labelId = `checkbox-list-secondary-label-${value.id}`;
                        return (
                            <Box key={key}>
                                <ListItem
                                    key={value.id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(value)}
                                            checked={isCheckedItem(value.id)}
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <StoreOutlinedIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={value.name}
                                            secondary={`${value.orgId}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                            </Box>
                        );
                    })}
                </List>

                {
                    isLoadError &&
                    <Alert severity={"error"}>
                        <AlertTitle>{t('systemCommon.errorRequestInfoCaption')}</AlertTitle>
                        {errorMsg}
                    </Alert>
                }

                {
                    !totalPages && !isLoadError &&
                    <Box>
                        <Alert severity={"info"}>{t('systemCommon.noEntriesToDisplay')}</Alert>
                    </Box>
                }


                <PagingComponentList
                    totalPages={totalPages}
                    page={page}
                    setPageFn={setPageL}
                />
            </Box>

        </Container>
    );
};

export default OrganizationsBindingCurrent;