import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Container} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import EslipsToolbar from "./EslipsToolbar";
import EslipListResult from "./EslipListResult";
import {setActiveSection, setBreadcrumbs} from "../../../store/baseLayoutSlice";
import {fetchEslipsRows, initPageData} from "../../../store/common/eslipsPageSlice";


const EslipsPage = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()

    const {status} = useSelector(state => state.eslipsPage)

    useEffect(() => {
        async function initData() {
            // Инициализируем настройки колонок из localstorage
            await dispatch(initPageData())
            // Сразу не выбираем ничего
            // dispatch(fetchEslipsRows({showSnackMsg:showSnackMsg}))
        }


        document.title = t('pages.eslips.pageTitle') + ' | UniPORT E-slip';

        dispatch(setActiveSection({href: '/eslips'}))

        const items = [
            {href: "/eslips", caption: 'pages.eslips.pageTitle', active: false}
        ]
        dispatch(setBreadcrumbs(items))

        initData()
    }, [dispatch]);

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 6
            }}
        >
            <Backdrop
                open={status === 'loading'}
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
            >
                <CircularProgress/>
            </Backdrop>


            <Container maxWidth={false}>
                <EslipsToolbar />
                <Box sx={{mt: 3}}>
                    <EslipListResult />
                </Box>
            </Container>
        </Box>
    )
}

export default EslipsPage;