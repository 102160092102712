import axios from "axios";

/* Получение инстанса axios с преднастроенными значениями по умолчанию для портала */
const instanceAxiosPortal = axios.create({
    // Настроится в interceptor: setupAxiosInterceptors.js
    // baseURL: APP_PORTAL_BASE_URL + '/rest/api',
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':'*',
        'content-type': 'application/json',
        'accept': 'application/json'
    },
})



export default instanceAxiosPortal