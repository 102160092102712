import React, {useState} from 'react';
import {Box, Paper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import GroupAccessSelectGroup from "./GroupAccessSelectGroup";
import GroupAccessDefinition from "./GroupAccessDefinition";
import GaEditWorkspace from "./edit/GAEditWorkspace";

const GroupAccessWorkspace = () => {
    const dispatch = useDispatch()
    const mode = useSelector(state => state.groupAccessPage.mode)

    return (
            <Paper>
                {
                    mode == 'SELECT_GROUP' &&
                    <GroupAccessSelectGroup />
                }
                {
                    mode == 'DEFINITION' &&
                    <GroupAccessDefinition />
                }
                {
                    mode == 'EDITING' &&
                    <GaEditWorkspace />
                }
            </Paper>
    );
};

export default GroupAccessWorkspace;