import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box, Button, Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Accordion, AccordionDetails, AccordionSummary,
    Typography, FormControl, InputLabel, Select
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Controller, useForm} from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {
    fetchNotificationInfo, fetchSaveInfoByTab,
    initNotificationTab, updateNotificationTab, setNeedRefreshData
} from "../../../store/administration/systemParamsPageSlice";
import AccessDeniedComponent from "../../../components/AccessDeniedComponent";



const NotificationsTab = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const [expandedEmail, setExpandedEmail] = useState(true);
    const [expandedSms, setExpandedSms] = useState(true);

    const schema = Yup.object().shape({
        minLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
        // .test(
        //     'vvvv',
        //     //(value) => has(value, 'email') || has(value, 'mobile')
        //     (value, {createError}) => {
        //         let t =  Yup.ref('maxLength') > value
        //         console.log(Yup.ref('maxLength'))
        //         return t ? true : createError({
        //             message: `Custom Message here ${value}`,
        //             path: 'minLength', // Fieldname
        //         })
        //     }
        //),
        maxLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
        // .when('minLength', {
        //     is: ,
        //     then:
        // }),
        defaultLength: Yup.number().typeError(t('pages.systemParams.requiredNumberCaption'))
            .min(7, t('pages.systemParams.minLengthMsg') + ' 7')
            .max(14, t('pages.systemParams.maxLengthMsg') + ' 14')
            .required(t('pages.systemParams.requiredCaption')),
    })

    const {notificationTabValues, needRefreshData, error, status, is403} = useSelector(state => state.systemParamsPage)

    const {control, reset, handleSubmit, watch, formState: {errors}} = useForm({
        mode: 'onBlur',
        // resolver: yupResolver(schema),
        defaultValues: {
            ...notificationTabValues
        }
    })

    const handleChangeExpandedSms = () => {
        setExpandedSms(!expandedSms);
    };

    const handleChangeExpandedEmail = () => {
        setExpandedEmail(!expandedEmail);
    };

    useEffect(() => {
        reset(notificationTabValues);
    }, [notificationTabValues]);

    useEffect(() => {
        async function initTab() {
            await dispatch(initNotificationTab())
        }

        // Все чистим в сторе
        initTab()
        // Грузим новые данные
        loadData()
    }, [dispatch])

    useEffect(() => {
        if (!needRefreshData) return;

        loadData().then(r => {
                // console.log('Выполнили поиск')
            }
        ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    const loadData = async () => {
        await dispatch(fetchNotificationInfo({
                showSnackMsg: showSnackMsg
            })
        )
    }

    const showSnackMsg = (type, msg) => {
        enqueueSnackbar(t(msg), {
            variant: type,
            preventDuplicate: true,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
        })
    }

    const handleSubmitLocal = (data) => {
        //console.log(data);
        dispatch(updateNotificationTab({...data}))
        dispatch(fetchSaveInfoByTab({
            tab: 'notification',
            showSnackMsg: showSnackMsg
        }))
    }

    if (is403) {
        return (
            <Box sx={{pt:'-24px', mt:'-20px'}}>
                <AccessDeniedComponent />
            </Box>
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(handleSubmitLocal)}>
            <Card>
            <CardHeader
                subheader={t('pages.systemParams.notificationsSubHeader')}
                title={t('pages.systemParams.notificationsHeader')}
            />
            <Divider/>

            {/*{isProcessing && <LinearProgress/>}*/}
            <CardContent>
                {
                    (status === 'rejected') &&
                    <Grid xs={16} md={16} item>
                        <Alert severity={"error"}>{t(error)}</Alert>
                    </Grid>
                }

                {
                    (status === 'resolved' || status === 'rejected') &&
                    <>
                        <Accordion expanded={expandedEmail} onChange={handleChangeExpandedEmail}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>{t('pages.systemParams.accordionEmailHeader')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} columns={12}>
                                    <Grid xs={12} sm={8} md={8} item>
                                        <Controller
                                            name={"smtpServer"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"text"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpServer')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                    <Grid xs={12} sm={4} md={4} item>
                                        <Controller
                                            name={'smtpPort'}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"text"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpPort')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>



                                    <Grid xs={12} sm={6} md={6} item>
                                        <Controller
                                            name={"smtpLogin"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="new-password"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"text"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpLogin')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                    <Grid xs={12} sm={6} md={6} item>
                                        <Controller
                                            name={"smtpPassword"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="new-password"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"password"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpPassword')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                    <Grid xs={12} sm={8} md={6} item>
                                        <Controller
                                            name={"smtpEmailFrom"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"text"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpEmailFrom')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expandedSms} onChange={handleChangeExpandedSms}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography>{t('pages.systemParams.accordionSmsHeader')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} columns={12}>
                                    <Grid xs={12} sm={6} md={4}
                                          item>
                                        <FormControl fullWidth>
                                            <InputLabel id="transport-password-notification-select-label">
                                                {t('pages.systemParams.smsProvider')}
                                            </InputLabel>
                                            <Controller control={control}
                                                        name={'smsProvider'}
                                                        render={({field}) => (
                                                            <Select
                                                                {...field}
                                                                labelId="transport-password-notification-select-label"
                                                                id="transport-password-notification-select"
                                                                label={t('pages.systemParams.smsProvider')}
                                                                variant="outlined"
                                                            >
                                                                <MenuItem value={'WEBSMS'}>websms.ru</MenuItem>
                                                                <MenuItem value={'KCELL'}>kcell.kz</MenuItem>
                                                            </Select>
                                                        )}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12} sm={6} md={4} item>
                                        <Controller
                                            name={"sms_provider_login"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="new-password"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"text"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpLogin')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                    <Grid xs={12} sm={6} md={4} item>
                                        <Controller
                                            name={"sms_provider_password"}
                                            control={control}
                                            render={({ field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="new-password"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    type={"password"}
                                                    fullWidth
                                                    label={t('pages.systemParams.smtpPassword')}
                                                    variant="outlined"
                                                />
                                            )}/>
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </>
                }
            </CardContent>

            <Divider/>
            <Box sx={{display:"flex", justifyContent: 'flex-end',
                p:2}}>
                <Button
                    color="primary"
                    variant="contained"
                    type={"submit"}
                    // disabled={isProcessing}
                >
                    {t('pages.systemParams.saveInfoBtnCaption')}
                </Button>
            </Box>
        </Card>
        </form>
    );
};

export default NotificationsTab;