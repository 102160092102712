import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {
    Alert, AlertTitle,
    Backdrop,
    Box, Button,
    CircularProgress,
    Container, Divider,
    Grid,
    IconButton, InputAdornment,
    List, ListItem, ListItemAvatar, ListItemButton, ListItemText,
    Paper, SvgIcon, TextField,
    Tooltip,
    Typography
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import instanceAxiosApi from "../../../../../service/axiosApi";
import {hasText} from "../../../../../util/UtilFunction";
import PagingComponentList from "../../../../../components/list_element/PagingComponentList";
import {theme} from "../../../../../theme";
import {
    addRowsForAdd,
    deleteRowsForAdd,
    initParamsOnAddPageStart,
    setIsLoading,
    setNeedRefreshData,
    setOpenSaveBindingDlg,
    setPageAddMode,
    setRowsNotIn,
    setSearchStringAddMode,
    setTotalPagesAddMode
} from "../../../../../store/administration/groupAccessPageSlice";
import AddGroupAccessDialog from "./AddGroupAccessDialog";

const MerchantAdd = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    // Всплывающие сообщения
    const {enqueueSnackbar} = useSnackbar();

    const isLoading = useSelector(state => state.groupAccessPage.isLoading)
    const searchString = useSelector(state => state.groupAccessPage.searchStringAddMode)
    const page = useSelector(state => state.groupAccessPage.pageAddMode)
    const limit = useSelector(state => state.groupAccessPage.limitAddMode)
    const totalPages = useSelector(state => state.groupAccessPage.totalPagesAddMode)

    const rowId = useSelector(store => store.groupAccessPage.groupId)
    const rows = useSelector(store => store.groupAccessPage.rowsNotIn) || []
    const accessLevel = useSelector(state => state.groupAccessPage.accessLevel)
    const rowsForAdd = useSelector(store => store.groupAccessPage.rowsForAdd) || []
    const needRefreshData = useSelector(state => state.groupAccessPage.needRefreshData)

    // Инициируем тем значением фильтра, что в сторе содержится
    const [searchValue, setSearchValue] = useState(searchString || '')
    const [isLoadError, setIsLoadError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    // При старте скидываем прошлые данные
    useEffect(() => {
        dispatch(initParamsOnAddPageStart())
    }, [])

    /* Вдруг из диалога изменили информацию */
    useEffect(() => {
        if (!needRefreshData) return;

        loadData()
            .then(r => {
                    // console.log('Выполнили поиск')
                }
            ).finally(dispatch(setNeedRefreshData(false)))
    }, [needRefreshData])

    useEffect(() => {
        loadData()

    }, [page])

    const handleToggle = (value) => {
        if (!isCheckedItem(value.id)) {

            if (rowsForAdd.length > 9) { // только 10 записей = 9 + текущая
                enqueueSnackbar(t('systemCommon.manyEntriesMustBeSaved'), {
                    variant: 'warning',
                    preventDuplicate: true,
                    anchorOrigin: {vertical: 'top', horizontal: 'right'},
                })
                return;
            }

            dispatch(addRowsForAdd({row: value}))
        }
    };

    const isCheckedItem = (id) => {
        if (!rowsForAdd || rowsForAdd.length < 1) return false

        return rowsForAdd.filter(itemi => itemi.id == id).length > 0
    }

    const clearLocalErrorState = () => {
        setIsLoadError(false)
        setErrorMsg('')
    }

    // Идем на сервер
    const fetchApi = async (params) => {
        return await instanceAxiosApi.get(`/ui/v1/group_access/merchants/not_access?${params}`)
    }

    const buildParams = () => {
        // группа не равна текущей - ne
        let filter = [
            {"key": "groupId", "operation": "not_exists", "value": [rowId]}
        ]

        if (hasText(searchString)) {
            filter.push({"key": "name", "operation": "like", "value": [searchString]})
        }

        let filterAsString = JSON.stringify(filter)
        return `page=${page}&limit=${limit}&filter=${filterAsString}`
    }

    const loadData = async () => {
        dispatch(setIsLoading(true))
        try {
            const response = await fetchApi(buildParams())

            let dataJson = response?.data || {rows: [], totalPages: 0}

            dispatch(setRowsNotIn({rows: [...dataJson.rows]}))
            dispatch(setTotalPagesAddMode({totalPages: (dataJson.totalPages || 0)}))

            // Если на какой то косяк на сервере, и вернулось меньше страниц, чем номер текущей, вытаскиваем первую страницу
            if (dataJson.totalPages < page) {
                dispatch(setPageAddMode({page: 1}))
            }

            clearLocalErrorState()
        } catch (e) {
            dispatch(setRowsNotIn({rows: []}))

            setIsLoadError(true)
            let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.errorRequestInfoCaption')
            setErrorMsg(clientMessage)
        } finally {
            setSearchValue(searchString)
            dispatch(setIsLoading(false))
        }
    }

    const handleRefresh = () => {
        dispatch(setSearchStringAddMode({searchString: searchValue}))
        dispatch(setNeedRefreshData(true))
    }

    const handleChangeSearchString = (event) => {
        setSearchValue(event.target.value)
    }

    const handleClickClearSearchString = () => {
        setSearchValue('')
        dispatch(setSearchStringAddMode({searchString: ''}))
        dispatch(setNeedRefreshData(true))
    }

    const pressEnter = (e) => {
        if (e.key === "Enter") {
            dispatch(setSearchStringAddMode({searchString: searchValue}))
            dispatch(setNeedRefreshData(true))
        }
    }

    const setPageL = (num) => {
        dispatch(setPageAddMode({page: num}))
    }

    const deleteRowForAddHandler = (value) => {
        dispatch(deleteRowsForAdd({key: value.id}))
    }

    const saveBtnHandler = (event) => {
        dispatch(setOpenSaveBindingDlg(true));
    }

    return (
        <Box sx={{pt:'32px'}}>
            <Container maxWidth={false}>
                <Backdrop
                    open={isLoading}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,}}
                >
                    <CircularProgress/>
                </Backdrop>
                <Grid container spacing={3} columns={16}>
                    <Grid item xs={16} md={8}>
                        <Typography sx={{mt: 1, mb: 2}} variant="h6" component="div">
                            {t('pages.groupAccess.notAllowedAccessMerchantsCaption')}
                        </Typography>
                        <Paper elevation={0}>
                            <List dense={true}
                                  sx={{width: '100%', /*maxWidth: 360, bgcolor: 'background.paper'*/}}
                                  subheader={
                                      <Box sx={{display: "flex", flexDirection: "column"}}>
                                          <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                              <Tooltip title={t('systemCommon.updateInfo')}>
                                                  <IconButton sx={{mr: '10px'}} onClick={handleRefresh}>
                                                      <RefreshOutlinedIcon/>
                                                  </IconButton>
                                              </Tooltip>
                                              <TextField
                                                  type={"text"}
                                                  fullWidth
                                                  InputProps={{
                                                      startAdornment: (
                                                          <InputAdornment position="start">
                                                              <SvgIcon
                                                                  fontSize="small"
                                                                  color="action"
                                                              >
                                                                  <SearchIcon/>
                                                              </SvgIcon>
                                                          </InputAdornment>
                                                      ),

                                                      endAdornment: searchString && (
                                                          <IconButton size={"small"}
                                                                      aria-label="toggle password visibility"
                                                                      onClick={handleClickClearSearchString}
                                                          >
                                                              <CancelRoundedIcon fontSize={"small"}/>
                                                          </IconButton>
                                                      )

                                                  }}
                                                  placeholder={t('pages.groupAccess.searchByNameCaption')}
                                                  variant="outlined"
                                                  size={"small"}
                                                  value={searchValue}
                                                  onChange={handleChangeSearchString}
                                                  onKeyDown={(e) => pressEnter(e)}
                                                  disabled={isLoading}
                                                  inputRef={(input) => input?.focus()}
                                              />
                                          </Box>
                                      </Box>
                                  }

                            >
                                {rows.map((value, key) => {
                                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                                    return (
                                        <Box key={key}>
                                            <ListItem
                                                key={value.id}
                                                secondaryAction={
                                                    <IconButton key={value.id + '_ib'}
                                                                edge="end"
                                                                aria-label="delete"
                                                                color={"success"}
                                                                onClick={() => handleToggle(value)}
                                                                disabled={isCheckedItem(value.id)}
                                                    >
                                                        <AddCircleOutlineOutlinedIcon />
                                                    </IconButton>
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar key={value.id + '_lia'}>
                                                        <StorefrontOutlinedIcon />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        key={value.id + '_lit'}
                                                        primary={value.name}
                                                        secondary={value.guid}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider variant="inset" component="li" key={value.id + '_d'}/>
                                        </Box>
                                    );
                                })}
                            </List>

                            {
                                isLoadError &&
                                <Alert severity={"error"}>
                                    <AlertTitle>{t('systemCommon.errorRequestInfoCaption')}</AlertTitle>
                                    {errorMsg}
                                </Alert>
                            }

                            {
                                !totalPages && !isLoadError &&
                                <Box>
                                    <Alert severity={"info"}>{t('systemCommon.noEntriesToDisplay')}</Alert>
                                </Box>
                            }

                            <PagingComponentList
                                totalPages={totalPages}
                                page={page}
                                setPageFn={setPageL}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={16} md={8}>
                        <Typography sx={{mt: 1, mb: 2}} variant="h6" component="div">
                            {t('pages.groupAccess.forAccessMerchantsCaption')}
                        </Typography>
                        <Paper elevation={0}>
                            <List dense={true}
                                  sx={{
                                      pt:'40px',
                                      [theme.breakpoints.up('lg')]: {
                                          maxHeight:'585px',
                                      },
                                      maxHeight:'60vh', overflow:'auto'
                                  }}
                            >
                                {/*<PerfectScrollbar>*/}
                                {rowsForAdd.map((value, key) => {
                                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                                    return (
                                        <Box key={key + '_k2'}>
                                            <ListItem
                                                key={value.id + 'li2'}
                                                secondaryAction={
                                                    <IconButton key={value.id + '_ib2'}
                                                                edge="end"
                                                                aria-label="delete"
                                                                color={"error"}
                                                                onClick={() => deleteRowForAddHandler(value)}
                                                    >
                                                        <HighlightOffOutlinedIcon />
                                                    </IconButton>
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar key={value.id + '_lia2'}>
                                                        <ListItemAvatar>
                                                            <StorefrontOutlinedIcon />
                                                        </ListItemAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        key={value.id + '_lit2'}
                                                        primary={value.name}
                                                        secondary={value.guid}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider variant="inset" component="li" key={value.id + '_d2'}/>
                                        </Box>
                                    );
                                })}
                                {/*</PerfectScrollbar>*/}
                            </List>

                            <Box sx={{display:"flex", justifyContent:"center", mt:'10px', pb:'10px'}}>
                                <Button size={"small"}
                                        variant="contained"
                                        disabled={rowsForAdd.length < 1}
                                        onClick={saveBtnHandler}>
                                    {t('pages.groupAccess.grantAccessMerchants')}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            {/*Диалог сохранения выбранных объектов */}
            <AddGroupAccessDialog />
        </Box>
    );
};

export default MerchantAdd;