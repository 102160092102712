import React, {useEffect, useState} from 'react';
import {useAuth} from "../../hook/useAuth";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert, Box, Button, Container, IconButton, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {toStepOne} from "../../store/auth/passwordRecoveryPageSlice";

const LoginPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const {signin} = useAuth()
    const {t} = useTranslation();

    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [initLogin] = useState(localStorage.getItem('userLastLogin') || '')


    const fromPage = location.state?.from?.pathname || '/'

    useEffect(() => {
        document.title = 'Login | UniPORT E-slip'

    }, []);

    const formik = useFormik({
        initialValues: {
            loginUser: initLogin,
            passwordUser: ''
        },
        validationSchema: Yup.object({
            loginUser: Yup
                .string()
                //.email('Must be a valid email')
                .max(50, t('pages.restorePassword.charactersNoMore') + ' 50')
                .required(t('pages.login.loginCheckRequired')),
            passwordUser: Yup
                .string()
                .max(14, t('pages.restorePassword.charactersNoMore') + ' 14')
                .required(t('pages.login.passwordCheckRequired'))
        }),
        onSubmit: async (values, formikHelpers) => {
            try {
                await signin(values.loginUser, values.passwordUser,
                    () => {
                        formikHelpers.resetForm()
                        navigate(fromPage, {replace: true}) // true - По кнопке НАЗАД не можем вернуться на страницу логина
                    }
                )
            } catch (e) {
                formikHelpers.setSubmitting(false)
                setErrorSubmitMsg(e.message)
                setIsSubmitError(true)
            }
        }
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Paper elevation={16} sx={{maxWidth: '30rem', pt: '30px', pb: '30px', height: 'auto'}}>
            <Box sx={{
                pl: '20px',
                pr: '20px',
            }}
            >
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        {t('pages.login.enterSystemCaption')}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="body2"
                    >
                        {t('pages.login.enterSystemCaptionHint')}
                    </Typography>

                    {
                        isSubmitError &&
                        <Alert sx={{mt: '20px', mb: '10px'}} severity="error">{errorSubmitMsg}</Alert>
                    }

                    <TextField
                        error={Boolean(formik.touched.loginUser && formik.errors.loginUser)}
                        fullWidth
                        helperText={formik.touched.loginUser && formik.errors.loginUser}
                        label={t('pages.login.loginInputLabel')}
                        margin="normal"
                        name="loginUser"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.loginUser}
                        variant="outlined"
                        focused
                    />

                    <TextField
                        error={Boolean(formik.touched.passwordUser && formik.errors.passwordUser)}
                        fullWidth
                        helperText={formik.touched.passwordUser && formik.errors.passwordUser}
                        label={t('pages.login.passwordInputLabel')}
                        margin="normal"
                        name="passwordUser"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.passwordUser}
                        variant="outlined"
                        focused
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />

                    <Box sx={{py: 2}}>
                        <Button
                            color="primary"
                            disabled={formik.isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t('pages.login.signInBtnCaption')}
                        </Button>
                    </Box>

                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        <Link to="/auth/recovery"
                              onClick={() => dispatch(toStepOne()) // Восстановление пароля на ШАГ 1
                        }>
                            {t('pages.login.restorePasswordCaption')}
                        </Link>
                    </Typography>
                </form>
            </Box>
        </Paper>
    );
};

export default LoginPage;