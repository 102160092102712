import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EslipFilterNonSetComponent = () => {
    const {t} = useTranslation()

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box sx={{ textAlign: 'center' }}>
                <InfoOutlinedIcon color="primary"
                    sx={{
                    fontSize: 80,
                    marginTop: 10,
                    marginBottom: 5,
                }}/>
            </Box>
            <Typography align="center"
                        color="textPrimary"
                        variant="h4"
                        sx={{mb:'24px'}}
            >
                {t('pages.filterNotSetComponent.filterNotSet')}
            </Typography>
            <Typography  align="center"
                         color="textPrimary"
                         variant="subtitle2"
                         sx={{mb:'24px'}}
            >
                {t('pages.filterNotSetComponent.filterNotSetHint')}
            </Typography>


        </Box>
    );
};

export default EslipFilterNonSetComponent;