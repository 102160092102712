import React, {useEffect, useState} from 'react';
import {
    Alert, Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, LinearProgress,
    TextField, Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import instanceAxiosApi from "../../../../service/axiosApi";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeAddToArchiveDlgOpenStatus, setNeedRefreshData} from "../../../../store/common/eslipsArchiveJobPageSlice";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

/**
 * Диалог для добавления чеков в архив.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddToArchiveDialog = (props) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [isProcessed, setIsProcessed] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [errorSubmitMsg, setErrorSubmitMsg] = useState('')
    // Всплывающие сообщения
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const open = useSelector(state => state.eslipsArchiveJobPage.openAddToArchiveDlg)

    const [archiveDate, setArchiveDate] = useState(null)

    /*Если не вызвать, то подставится прошлое наименование*/
    useEffect(() => {
        if (open) {
            formik.setFieldValue("archiveDate", archiveDate);
        }
    }, [open])

    const fetchApi = async (archiveDate) => {
        let params = {"archiveDate": archiveDate}

        return await instanceAxiosApi.post(`/ui/v1/eslips/archive`, JSON.stringify(params));
    }

    const handleClose = async () => {
        clearError()
        formik.resetForm()
        dispatch(changeAddToArchiveDlgOpenStatus(false));
    };

    const clearError = () => {
        setIsProcessed(false)
        setErrorSubmitMsg('')
        setIsSubmitError(false)
    }

    const formik = useFormik({
        initialValues: {
            archiveDate: archiveDate
        },
        validationSchema: Yup.object({
            archiveDate: Yup
                .date()
                .typeError(t('pages.archive.typeErrorDateMsg'))
                .min(new Date(1980, 0, 1), t('pages.archive.minDateErrorMsg'))
                .max(new Date(5000, 0, 1), t('pages.archive.maxDateErrorMsg'))
                .required(t('pages.archive.archiveDateRequiredMsg')),
        }),
        onSubmit: async (values, formikHelpers) => {
            setIsProcessed(true)

            await fetchApi(values.archiveDate)
                .then((response) => {
                    handleClose()
                    // Заставляем обновить данные на сервере
                    dispatch(setNeedRefreshData({needRefreshData: true}))

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoSaveSuccessCaption'), {
                        variant: 'success',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .catch(e => {
                    formikHelpers.setSubmitting(false)
                    let clientMessage = e?.response?.data?.uiMessage || t('systemCommon.infoErrorSaveCaption')
                    setErrorSubmitMsg(clientMessage)
                    setIsSubmitError(true)

                    // Сообщение
                    enqueueSnackbar(t('systemCommon.infoErrorSaveCaption'), {
                        variant: 'error',
                        preventDuplicate: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right'},
                    })
                })
                .finally(() => setIsProcessed(false))
        }
    });

    return (
        <>
            <Dialog open={open}
                    fullWidth={true}
                    maxWidth={"sm"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{
                    }}>
                        <DialogTitle>{t('pages.archive.archivingDlgHeader')}</DialogTitle>
                        {
                            isProcessed &&
                            <Box sx={{}}>
                                <LinearProgress/>
                            </Box>
                        }
                        <DialogContent>
                            <TextField
                                error={Boolean(formik.touched.archiveDate && formik.errors.archiveDate)}
                                fullWidth
                                helperText={formik.touched.archiveDate && formik.errors.archiveDate}
                                label={t('pages.archive.archiveDateQuestionMsg')}
                                margin="normal"
                                name="archiveDate"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="date"
                                value={formik.values.archiveDate}
                                variant="outlined"
                                disabled={isProcessed}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            {
                                isSubmitError &&
                                <Alert severity="error">{errorSubmitMsg}</Alert>
                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isProcessed} color={"error"} onClick={handleClose}>
                                {t('systemCommon.dlgCancelBtnCaption')}
                            </Button>
                            <Button disabled={isProcessed} type={"submit"}>
                                {t('systemCommon.dlgApplyBtnCaption')}
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Dialog>
        </>
    );
};

export default AddToArchiveDialog;